
var UIUtils = {
    getURLParams: function(_location){
        var urlParams = {};
        if (!_location) _location = location;
        var sSearch = _location.search.substr(1).split("&");
        _.each(sSearch, function (ss) {
            var _ss = ss.split("=");
            urlParams[_ss[0]] = _ss[1];
		});

		let host = (location.host || "");
		let envHost = window._HOST;
		if (!envHost) {
			var hosts = [
				'il-tabitorder.tabit-stage.com',
				'il-int-tabitorder.tabit-stage.com'
			]
			for (var i = 0; i < hosts.length; i++) {
				if (host.indexOf(hosts[i]) != -1) {
					envHost = hosts[i];
					break;
				}
			}
		}
		if (envHost) {
			let index = host.indexOf(envHost);
			if (index > 0) {
				let params = host.replace(`.${envHost}`,'').split('.');
				if (params[1] == 'hq') {
					urlParams.orgName = params[0];
				} else {
					urlParams.siteName = params[0];
				}
			}
		}
        return urlParams;
    },
    getCleanURL: function(){
        var sURL = document.location.origin;
        if (document.location.href.indexOf('index_test.html') != -1) sURL += "/index_test.html";
        var keys = UIUtils.getURLParams();
        var sKEys = ["site","org","oid","siteName", "orgName","channel"];
        var arr = [];
        _.each(sKEys, function(key){
            var val = keys[key];
            if (val) arr.push(key + "=" + val);
        })
        sURL += "?" + arr.join("&");
        return sURL;
    },
    deleyFocusBasket: function(hid, delay){
        if (!window.BLOCATIONHASH) return;
        delay = delay || 100;        
        window.setTimeout(function(){
            var $h = $(hid);
            if ($h[0]){
                //delete window.BLOCATIONHASH;
                $h.scrollintoview({duration:0, complete: function(evt){
                    this.scrollTop += 5;                
                }}).addClass("highlight");
                window.setTimeout(function(){
                    $h.removeClass("highlight");
                },1000);
            };
        },delay);
    },
    fixSpecialChars: function(str){
        var regexSpecialChars = /[\uD83D\uFFFD\uFE0F\u203C\u3010\u3011\u300A\u166D\u200C\u202A\u202C\u2049\u20E3\u300B\u300C\u3030\u065F\u0099\u0F3A\u0F3B\uF610\uFFFC]/g;
        return str.replace(regexSpecialChars, "");
    },
    checkCreditCardDigit: function (value) {
        if (/[^0-9-\s]+/.test(value)) return false;

        // The Luhn Algorithm. It's so pretty.
        var nCheck = 0, nDigit = 0, bEven = false;
        value = value.replace(/\D/g, "");

        for (var n = value.length - 1; n >= 0; n--) {
            var cDigit = value.charAt(n),
                  nDigit = parseInt(cDigit, 10);

            if (bEven) {
                if ((nDigit *= 2) > 9) nDigit -= 9;
            }

            nCheck += nDigit;
            bEven = !bEven;
        }

        return (nCheck % 10) == 0;
    },
    isValidIsraelIdCard: function (value) {
        var id = String(value).trim();
        if (id.length > 9 || id.length < 5 || isNaN(id) || Number(id) == 0) return false;

        // Pad string with zeros up to 9 digits
        id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

        return Array.from(id, Number)
                .reduce((counter, digit, i) => {
                    const step = digit * ((i % 2) + 1);
                    return counter + (step > 9 ? step - 9 : step);
                }) % 10 === 0;

	},
	isPointInPolygon: function (point, vs) {
		// ray-casting algorithm based on
		// https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

		var x = point.lat, y = point.lng;

		var inside = false;
		for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
			var xi = vs[i].lat, yi = vs[i].lng;
			var xj = vs[j].lat, yj = vs[j].lng;

			var intersect = ((yi > y) != (yj > y))
				&& (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
			if (intersect) inside = !inside;
		}

		return inside;
	}
    

};

(function () {

	window.PreloadBrand = function (brandPath) {
		var urlParams = {};
		var sSearch = location.search.substr(1).split("&");
		_.each(sSearch, function (ss) {
			var _ss = ss.split("=");
			urlParams[_ss[0]] = _ss[1];
		});
		//agadir fixed css
		if (!urlParams.org) return;
		switch (urlParams.org) {
			case "-KbPYOv1ANPH0fgTwKw_": case "-LIjOzRkx5cPP50pRrPb":
				window.FIXEDBRAND = "agadir";
				break;
		}

		if (window.FIXEDBRAND) {
			var bpath = (brandPath || 'brand/') + window.FIXEDBRAND + '/style.css';
			$('body').append('<link href="' + bpath + '" rel="stylesheet" id="css_brand" />');

		}
	}
})();

(function($) {
    jQuery(function() {
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1;

		if (isAndroid) {
            /* cache dom referencess */ 
            var $body = jQuery('body'); 

            /* bind events */
            jQuery(document)
				.on('focus', 'input', function (e) {
					console.log('focus on input');
					if (this.type != 'checkbox') $body.addClass('fixfixed');
            })
            .on('blur', 'input', function(e) {
				console.log('blur out of input');
                $body.removeClass('fixfixed');
            });

        }

    });

})(jQuery);

(function ($) {
    var converter = {
        vertical: { x: false, y: true },
        horizontal: { x: true, y: false },
        both: { x: true, y: true },
        x: { x: true, y: false },
        y: { x: false, y: true }
    };

    var settings = {
        duration: "fast",
        direction: "vertical"
    };

    var rootrx = /^(?:html)$/i;

    // gets border dimensions
    var borders = function (domElement, styles) {
        styles = styles || (document.defaultView && document.defaultView.getComputedStyle ? document.defaultView.getComputedStyle(domElement, null) : domElement.currentStyle);
        var px = document.defaultView && document.defaultView.getComputedStyle ? true : false;
        var b = {
            top: (parseFloat(px ? styles.borderTopWidth : $.css(domElement, "borderTopWidth")) || 0),
            left: (parseFloat(px ? styles.borderLeftWidth : $.css(domElement, "borderLeftWidth")) || 0),
            bottom: (parseFloat(px ? styles.borderBottomWidth : $.css(domElement, "borderBottomWidth")) || 0),
            right: (parseFloat(px ? styles.borderRightWidth : $.css(domElement, "borderRightWidth")) || 0)
        };
        return {
            top: b.top,
            left: b.left,
            bottom: b.bottom,
            right: b.right,
            vertical: b.top + b.bottom,
            horizontal: b.left + b.right
        };
    };

    var dimensions = function ($element) {
        var win = $(window);
        var isRoot = rootrx.test($element[0].nodeName);
        return {
            border: isRoot ? { top: 0, left: 0, bottom: 0, right: 0 } : borders($element[0]),
            scroll: {
                top: (isRoot ? win : $element).scrollTop(),
                left: (isRoot ? win : $element).scrollLeft()
            },
            scrollbar: {
                right: isRoot ? 0 : $element.innerWidth() - $element[0].clientWidth,
                bottom: isRoot ? 0 : $element.innerHeight() - $element[0].clientHeight
            },
            rect: (function () {
                var r = $element[0].getBoundingClientRect();
                return {
                    top: isRoot ? 0 : r.top,
                    left: isRoot ? 0 : r.left,
                    bottom: isRoot ? $element[0].clientHeight : r.bottom,
                    right: isRoot ? $element[0].clientWidth : r.right
                };
            })()
        };
    };

    $.fn.extend({
        scrollintoview: function (options) {
            /// <summary>Scrolls the first element in the set into view by scrolling its closest scrollable parent.</summary>
            /// <param name="options" type="Object">Additional options that can configure scrolling:
            ///        duration (default: "fast") - jQuery animation speed (can be a duration string or number of milliseconds)
            ///        direction (default: "both") - select possible scrollings ("vertical" or "y", "horizontal" or "x", "both")
            ///        complete (default: none) - a function to call when scrolling completes (called in context of the DOM element being scrolled)
            /// </param>
            /// <return type="jQuery">Returns the same jQuery set that this function was run on.</return>

            options = $.extend({}, settings, options);
            options.direction = converter[typeof (options.direction) === "string" && options.direction.toLowerCase()] || converter.both;

            var dirStr = "";
            if (options.direction.x === true) dirStr = "horizontal";
            if (options.direction.y === true) dirStr = dirStr ? "both" : "vertical";

            var el = this.eq(0);
            var scroller = el.closest(":scrollable(" + dirStr + ")");

            
            // check if there's anything to scroll in the first place
            if (scroller.length > 0) {
                scroller = scroller.eq(0);

                var dim = {
                    e: dimensions(el),
                    s: dimensions(scroller)
                };

                var rel = {
                    top: dim.e.rect.top - (dim.s.rect.top + dim.s.border.top) - 140,
                    bottom: dim.s.rect.bottom - dim.s.border.bottom - dim.s.scrollbar.bottom - dim.e.rect.bottom,
                    left: dim.e.rect.left - (dim.s.rect.left + dim.s.border.left),
                    right: dim.s.rect.right - dim.s.border.right - dim.s.scrollbar.right - dim.e.rect.right
                };

                var animOptions = {};
                
                // vertical scroll
                if (options.direction.y === true) {
                    if (rel.top < 0) {
                        animOptions.scrollTop = dim.s.scroll.top + rel.top;
                    }
                    else if (rel.top > 0 && rel.bottom < 0) {
                        animOptions.scrollTop = dim.s.scroll.top + Math.min(rel.top, -rel.bottom);
                    }
                }

                // horizontal scroll
                if (options.direction.x === true) {
                    if (rel.left < 0) {
                        animOptions.scrollLeft = dim.s.scroll.left + rel.left;
                    }
                    else if (rel.left > 0 && rel.right < 0) {
                        animOptions.scrollLeft = dim.s.scroll.left + Math.min(rel.left, -rel.right);
                    }
                }

                // scroll if needed
                if (!$.isEmptyObject(animOptions)) {
                    if (rootrx.test(scroller[0].nodeName)) {
                        scroller = $("html,body");
                    }
                    scroller
						.animate(animOptions, options.duration)
						.eq(0) // we want function to be called just once (ref. "html,body")
						.queue(function (next) {
						    $.isFunction(options.complete) && options.complete.call(scroller[0]);
						    next();
						});
                }
                else {
                    // when there's nothing to scroll, just call the "complete" function
                    $.isFunction(options.complete) && options.complete.call(scroller[0]);
                }
            }

            // return set back
            return this;
        }
    });

    var scrollValue = {
        auto: true,
        scroll: true,
        visible: false,
        hidden: false
    };

    $.extend($.expr[":"], {
        scrollable: function (element, index, meta, stack) {
            var direction = converter[typeof (meta[3]) === "string" && meta[3].toLowerCase()] || converter.both;
            var styles = (document.defaultView && document.defaultView.getComputedStyle ? document.defaultView.getComputedStyle(element, null) : element.currentStyle);
            var overflow = {
                x: scrollValue[styles.overflowX.toLowerCase()] || false,
                y: scrollValue[styles.overflowY.toLowerCase()] || false,
                isRoot: rootrx.test(element.nodeName)
            };

            // check if completely unscrollable (exclude HTML element because it's special)
            if (!overflow.x && !overflow.y && !overflow.isRoot) {
                return false;
            }

            var size = {
                height: {
                    scroll: element.scrollHeight,
                    client: element.clientHeight
                },
                width: {
                    scroll: element.scrollWidth,
                    client: element.clientWidth
                },
                // check overflow.x/y because iPad (and possibly other tablets) don't dislay scrollbars
                scrollableX: function () {
                    return (overflow.x || overflow.isRoot) && this.width.scroll > this.width.client;
                },
                scrollableY: function () {
                    return (overflow.y || overflow.isRoot) && this.height.scroll > this.height.client;
                }
            };
            return direction.y && size.scrollableY() || direction.x && size.scrollableX();
        }
    });
})(jQuery);


var loadGoogleMaps = (function ($) {
	var now = $.now(), promise;
	return function (version, apiKey, language, region) {
		if (!language) {
			if ($("body").hasClass('_ltr')) language = 'en';
			else language = 'iw';
		}
		if (promise) { return promise; }
		var deferred = $.Deferred(),
			resolve = function () {
				deferred.resolve(window.google && google.maps ? google.maps : false);
			},
			callbackName = "loadGoogleMaps_" + (now++),
			params = $.extend(
				{ 'sensor': false }
				, apiKey ? { "key": apiKey } : {}
				, language ? { "language": language } : {}
				, region ? { "region": region } : {}
			);

		if (window.google && google.maps) {
			resolve();
		} else if (window.google && google.load) {
			google.load("maps", version || 3, { "other_params": $.param(params), "callback": resolve });
		} else {
			params = $.extend(params, {
				'v': version || 3,
				'callback': callbackName
			});
			window[callbackName] = function () {
				resolve();
				setTimeout(function () {
					try {
						delete window[callbackName];
					} catch (e) { }
				}, 20);
			};
			$.ajax({
				dataType: 'script',
				data: params,
				url: 'https://maps.google.com/maps/api/js?libraries=geometry,places'
			});
		}
		promise = deferred.promise();
		return promise;
	};
}(jQuery));

'use strict';


angular.module('app', [
    'config',
    'ngAnimate',
    'ngCookies',
    'ngResource',
    'ngSanitize',
    'ngTouch',
    'ngStorage',
    'ui.router',
    'ui.bootstrap',
    'ui.load',
    'ui.jq',
    'oc.lazyLoad',
    'pascalprecht.translate',
    'blockUI',
    'angularMoment',
    'sweetDialog',
    'duScroll',
    'tmh.dynamicLocale',
    'angulartics', 
    'angulartics.google.analytics',
    'signature',
    'monospaced.qrcode'
]);
﻿'use strict';

var app = angular.module('app')
    .factory('fbInit', function (ENV, MetaService) {
        try{
            firebase.initializeApp(ENV.fbConfig);
            window.SERVERDATEDIF = MetaService.$storage.SERVERDATEDIF || 0;
            firebase.database().ref('/.info/serverTimeOffset')
              .once('value')
              .then(function stv(data) {
                  window.FIREBASE_ACTIVE = true;
                  window.SERVERDATEDIF = MetaService.$storage.SERVERDATEDIF = data.val() || 0;
                  console.log('Date Diff:' + window.SERVERDATEDIF);
                  var d = new Date().valueOf() + window.SERVERDATEDIF;
                  d = new Date(d);
                  _.extend(MetaService, {
                      today: d,
                      currentYear: d.getFullYear(),
                      currentMonth: d.getMonth() + 1
                  });

              }, function (err) {
                  return err;
              });        
        }catch(e){
            window.SERVERDATEDIF = 0;
            var d = new Date();
            _.extend(MetaService, {
                today: d,
                currentYear: d.getFullYear(),
                currentMonth: d.getMonth() + 1
            });
        }
        return {}
    });
// config

var app =
angular.module('app')
  .config(
    ['$controllerProvider', '$compileProvider', '$filterProvider', '$provide',
    function ($controllerProvider, $compileProvider, $filterProvider, $provide) {

        // lazy controller, directive and service
        app.controller = $controllerProvider.register;
        app.directive = $compileProvider.directive;
        app.filter = $filterProvider.register;
        app.factory = $provide.factory;
        app.service = $provide.service;
        app.constant = $provide.constant;
        app.value = $provide.value;
    }
    ]).config(function (tmhDynamicLocaleProvider) {
        tmhDynamicLocaleProvider.localeLocationPattern('l10n/angular-locale_{{locale}}.js');
    })
  .config(['$translateProvider', function ($translateProvider) {
      // Register a loader for the static files
      // So, the module will search missing translation tables under the specified urls.
      // Those urls are [prefix][langKey][suffix].
      $translateProvider.useStaticFilesLoader({
          prefix: 'l10n/',
          suffix: '.json'
      });

      $translateProvider.useSanitizeValueStrategy(null);


      // Tell the module what language to use by default
      $translateProvider.preferredLanguage('he-IL');     //hebrew-remark
      //$translateProvider.preferredLanguage('en');
      //$translateProvider.preferredLanguage('he-IL');  //hebrew-unremark

      // Tell the module to store the language in the local storage
      //$translateProvider.useLocalStorage();
      //$translateProvider.useSanitizeValueStrategy('sanitize');
  }])




/// <reference path="../vendor/modules/angular-qr/qrcode.min.js" />
// lazyload config

angular.module('app')
    /**
   * jQuery plugin config use ui-jq directive , config the js and css files that required
   * key: function name of the jQuery plugin
   * value: array of the css js file located
   */
  .constant('JQ_CONFIG', {
        
      }
  )
  // oclazyload config
  .config(['$ocLazyLoadProvider', function($ocLazyLoadProvider) {
      $ocLazyLoadProvider.config({
          debug:  false,
          events: true,
          modules: [
            {
                name: 'monospaced.qrcode',
                module: true,
                files: [
                    'vendor/modules/angular-qr/qrcode.min.js',
                    'vendor/modules/angular-qr/angular-qrcode',
                ]
            }
          ]
      });
  }])
;
//'use strict';

/**
 * Config for the router
 */
angular.module('app')
    .run(
        ['$rootScope', '$state', '$stateParams', '$uibModalStack', 'PDialog',
			function ($rootScope, $state, $stateParams, $uibModalStack, PDialog) {
                $rootScope.$state = $state;
                $rootScope.$stateParams = $stateParams;
                $rootScope.PDialog = PDialog;
                // set toastr default position
                toastr.options = {positionClass: 'toast-top-center',}


                function checkSize() {
                    var w = $(window).innerWidth();
                    if (w >= 1024) return 'desktop';
                    if (w > 600) return 'tablet';
                    else return 'cell';
                }

                $rootScope.mediaSize = checkSize();
                $(window).resize(function () {
                    var newSize = checkSize();
                    if (newSize != $rootScope.mediaSize) {
                        $rootScope.mediaSize = newSize;
                        $rootScope.$apply();
                    }
                });
				let wasState = false;

				$rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
					if (!wasState && toState.name == 'error') {
						$state.go("start");
						event.preventDefault();
						return;
					}
					wasState = true;
                    if (toState.name != "app.order.menus") {
                        $("html, body").scrollTop(0);
                    }

                    toastr.clear();
                    $uibModalStack.dismissAll();

                    var isfromOrder = fromState && fromState.name.indexOf('app.order') == 0;
                    var isToCheckout = toState.name == "app.checkout.pay";// || toState.name == "app.checkout.partial";
                    if (isToCheckout && isfromOrder) {
                        $state.transitionTo(fromState);
                        event.preventDefault();
                    }


                });
            }
        ]
    )
    .config(
        ['$stateProvider', '$urlRouterProvider',
            function ($stateProvider, $urlRouterProvider) {
                $urlRouterProvider.otherwise('/start');
                $stateProvider
                    .state('receipt', {
                        url: '/receipt',
                        controller: 'receipt',
                        templateUrl: window.ISDESKTOP ? 'modules/receipt/receipt_desktop.html' : 'modules/receipt/receipt_mobile.html',
                    })
                    .state('error', {
                        url: '/error',
                        //abstract: true,
                        controller: 'error_controller',
                        templateUrl: 'modules/app/error.html'
                    })
                    .state('resetpass', {
                        url: '/resetpass',
                        //abstract: true,
                        controller: 'resetpassword_controller',
                        templateUrl: 'modules/app/reset_password.html'
                    })
                    .state('start', {
                        url: '/start',
                        //abstract: true,
                        controller: 'start_controller',
                        templateUrl: window.ISDESKTOP ? 'modules/start/start_desktop.html' : 'modules/start/start_mobile.html'
                    })
                    .state('end', {
                        url: '/end',
                        //abstract: true,
                        controller: 'end_controller',
                        templateUrl: window.ISDESKTOP ? 'modules/end/end_desktop.html' : 'modules/end/end_mobile.html'
                        //templateUrl: 'modules/order/end.html'
                    })

                    .state('app', {
                        url: '/app',
                        abstract: true,
                        controller: 'app_controller',
                        templateUrl: window.ISDESKTOP ? 'modules/app/app_desktop.html' : 'modules/app/app_mobile.html'
                    })
                    .state("app.order", {
                        url: '/order',
                        controller: 'order_controller',
                        templateUrl: 'modules/order/order.html',
                    })
                    .state("app.order.menus", {
                        url: '/menus',
                        controller: 'order_menus',
                        templateUrl: window.ISDESKTOP ? 'modules/order/order_menus_desktop.html' : 'modules/order/order_menus_mobile.html',
                        data: {
                            showBarBack: false
                        },
                    })
                    .state("app.order.fixed", {
                        url: '/fixed',
                        controller: 'order_fixed',
                        templateUrl: 'modules/order/order_fixed.html',
                        data: {
                            showBarBack: false
                        },
                        params: {
                            isFromCheckout: false
                        }
                    })
                    .state("app.order.submenu", {
                        url: '/submenu/?level&cat',
                        controller: 'order_submenu',
                        templateUrl: window.ISDESKTOP ? 'modules/order/order_submenu_desktop.html' : 'modules/order/order_submenu_mobile.html',
                        data: {
                            showBarBack: true
                        },
                    })
                    .state("app.order.additem", {
                        url: '/additem/?level&item',
                        controller: 'order_additem',
                        templateUrl: window.ISDESKTOP ? 'modules/order/order_additem_desktop.html' : 'modules/order/order_additem_mobile.html',
                        data: {
                            showBarBack: true,
                            sectionStep: 'item'
                        },
                    })
                    .state("app.order.edititem", {
                        url: '/edititem/?bid',
                        controller: 'order_additem',
                        templateUrl: window.ISDESKTOP ? 'modules/order/order_additem_desktop.html' : 'modules/order/order_additem_mobile.html',
                        data: {
                            showBarBack: true,
                            sectionStep: 'item'
                        },
                    })

                    .state("app.checkout", {
                        url: '/checkout',
                        controller: 'checkout_controller',
						templateUrl: window.ISDESKTOP ? 'modules/checkout/checkout-desktop.html' : 'modules/checkout/checkout-mobile.html',
						resolve: {
							resources: function (EntityService) {
								return EntityService.checkBeforeCheckout();
							}
						}

                    })
                    .state("app.checkout.extra", {
                        url: '/extra',
                        controller: 'checkout_extra',
                        templateUrl: window.ISDESKTOP ? 'modules/checkout/checkout_extra_desktop.html' : 'modules/checkout/checkout_extra_mobile.html',
                        data: {
                            showBarBack: true
                        },
					})
                    .state("app.checkout.contact", {
                        url: '/contact',
                        controller: 'checkout_contact',
                        templateUrl: window.ISDESKTOP ? 'modules/checkout/checkout_contact_desktop.html' : 'modules/checkout/checkout_contact_mobile.html',
                        data: {
                            showBarBack: true
                        }
                    })
                    .state("app.checkout.gratuity", {
                        url: '/gratuity',
                        controller: 'checkout_gratuity',
                        templateUrl: window.ISDESKTOP ? 'modules/checkout/checkout_gratuity_desktop.html' : 'modules/checkout/checkout_gratuity_mobile.html',
                        data: {
                            showBarBack: true
                        },
                        resolve: {
                            Order: function (EntityService) {
                                return EntityService.preCreateOrder();
                            }
                        }
                    })
                    .state("app.checkout.pay", {
                        url: '/pay',
                        controller: 'checkout_pay',
                        templateUrl: window.ISDESKTOP ? 'modules/checkout/checkout_pay_desktop.html' : 'modules/checkout/checkout_pay_mobile.html',
                        data: {
                            showBarBack: true
                        },
                        resolve: {
                            Order: function (EntityService) {
                                return EntityService.preCreateOrder();
                            }
                        }
                    })
            }
        ]
);

(function () {
	angular.module('app')
		.config(config)
		.factory('TokenInterceptor', TokenInterceptor);


	function config($httpProvider) {
		$httpProvider.interceptors.push('TokenInterceptor');
	}


	function TokenInterceptor($q, $injector, ENV) {
		return {
			request: function (config) {
				return config;
			},
			responseError: function (response) {
				if (response.status == 401) {

				} else {
					try {
						var metaService = $injector.get('MetaService');
						metaService.logError(response);
					} catch (err) {
						console.error("Interceptor Error");
					}
				}

				return $q.reject(response);
			}
		};
	};


}());



'use strict';

/* Filters */
// need load the moment.js to use this filter. 
angular.module('app')
  .filter('fromNow', function () {
      return function (date) {
          return moment(date).fromNow();
      }
  }).filter('mapList', function () {
      /**
       * Transform any "foreign key" value to its string representation,
       * similar to the way ng-options works for a <select> element,
       *
       * Example:
       *   Let's say we have an item object with a userID property (item.userID = 5)
       *   This ID translates to a 'username' from a 'users' object, that looks
       *   something like this: users = [ {id: 6, name: 'john'}, ... ].
       *
       *   To use in a template, just write out the params
       *     {[{ item.userID | valueField:users:'id':'name' }]}
       */
      return function (native, options, matchField, valueField) {
          if (!matchField) matchField = "value";
          if (!valueField) valueField = "text";
          if (options) {
              for (var i = 0; i < options.length; i++) {
                  if (options[i][matchField] == native) {
                      return options[i][valueField];
                  }
              }
          }
          return '';
      }
  }).filter('propsFilter', function () {
      return function (items, props) {
          var out = [];

          if (angular.isArray(items)) {
              items.forEach(function (item) {
                  var itemMatches = false;

                  var keys = Object.keys(props);
                  for (var i = 0; i < keys.length; i++) {
                      var prop = keys[i];
                      var text = props[prop].toLowerCase();
                      if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                          itemMatches = true;
                          break;
                      }
                  }

                  if (itemMatches) {
                      out.push(item);
                  }
              });
          } else {
              // Let the output be the input untouched
              out = items;
          }

          return out;
      };
  }).filter('range', function () {
      var filter =
        function (arr, lower, upper) {
            for (var i = lower; i <= upper; i++) arr.push(i)
            return arr
        }
      return filter
  }).filter('money', function ($filter, MetaService) {
	  return function (text, decimals) {
		  if (isNaN(text)) return '';
        decimals = decimals === 0 ? 0 : MetaService.decimals || 2;
        return MetaService.currecySymbol + $filter('number')(text, decimals);
    };
  }).filter('amount', function ($filter, MetaService) {
    return function (text, decimals, abs) {
        decimals = decimals === 0 ? 0 : decimals || 2;
        if (!isNaN(text)){
            text /= 100;
            if (abs) text = Math.abs(text);
        }        
        return MetaService.currecySymbol + $filter('number')(text, decimals);
    };
}).filter('mtime', function ($translate) {
      return function (text) {
          if (!isNaN(text)){
              text = _.padStart(text + '', 2, '0');
              return $translate.instant('MINUTES_VAL', {m:text});
          }
      };
  }).filter('mtime_h', function (MetaService) {
      return function (text) {
          var m = Number(text);
          var mm = m % 60;
          var hh = (m - mm) / 60;
          return _.padStart((hh + ''), 2, '0') + ":" + _.padStart((mm + ''), 2, '0');
      };
  }).filter('mtime_away', function (MetaService) {
      return function (text, sEnd) {
          var m = Number(text);
          var mom = moment(sEnd);
          return mom.add(m, 'minutes').calendar();
      };
  }).filter('ccnum', function ($translate) {
      return function (ccinfo) {
          if (ccinfo.paymentMethod == 'CASH') return $translate.instant("CASH");
          var num = ccinfo.number;
          var arr = [];
          if (ccinfo.paymentMethod != 'CREDIT'){
            arr.push($translate.instant(ccinfo.paymentMethod));
          };
          arr.push(num);
          return arr.join(" ");
      };
  }).filter('visual_number', function ($filter, MetaService) {
    return function (text, decimals) {
        decimals = decimals === 0 ? 0 : MetaService.decimals || 2;
        var isNegative = (text < 0);
        if(isNegative) text = text * -1;
        if(MetaService.local.isRTL)
            return $filter('number')(text, decimals) + (isNegative ? '-' : '');
        else
            return (isNegative ? '-' : '') + $filter('number')(text, decimals);
    };
  }).filter('tel', function () {
    return function (tel) {
        if (!tel) { return ''; }

        var value = tel.toString().trim().replace(/^\+/, '');

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number;

        switch (value.length) {
            case 10: // +1PPP####### -> C (PPP) ###-####
                country = 1;
                city = value.slice(0, 3);
                number = value.slice(3);
                break;

            case 11: // +CPPP####### -> CCC (PP) ###-####
                country = value[0];
                city = value.slice(1, 4);
                number = value.slice(4);
                break;

            case 12: // +CCCPP####### -> CCC (PP) ###-####
                country = value.slice(0, 3);
                city = value.slice(3, 5);
                number = value.slice(5);
                break;

            default:
                return tel;
        }

        if (country == 1) {
            country = "";
        }

        number = number.slice(0, 3) + '-' + number.slice(3);

        return (country + " (" + city + ") " + number).trim();
    };
}).filter('nl2br', function () {
    return function (str) {
        if (!str) return '';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
    };
});







/* Controllers */

angular.module('app')
	.controller('AppCtrl', function ($rootScope, $scope, $translate, $window, MetaService, MarkettingService, EntityService, $q, $uibModal, ENV) {
      
      $scope.$storage = MetaService.$storage;
      $scope.MS = MetaService;
      $scope.ES = EntityService;
      MarkettingService.reloadMarketting();


      var _body = angular.element($window.document.body);
      var isIE = !!navigator.userAgent.match(/MSIE/i);
      isIE && _body.addClass('ie');

      if (!window.ISDESKTOP) {
          _body.addClass("_mobile");
          $rootScope.device = "mobile";
      } else {
          _body.addClass("_desktop");
          $rootScope.device = "desktop";
          $rootScope.DESKTOP = true;
      };

      // config
      $scope.app = {
          name: 'Orders',
          version: '1.0.0',
          settings: {
              //isRTL: $translate.proposedLanguage() == 'he-IL', //hebrew-unremark /set to true
              container: false,
          }
      }
      if (!ENV.gAPIKey) ENV.gAPIKey = "AIzaSyDIAmykASRLZ9f265LHYUVp6cpQi6dBeeA";

      $scope.loadingR = true;
      $rootScope.$on('$translateChangeEnd', function (event, message) {
		  $scope.loadingR = false;
		  MetaService.startOrderTimer();
      });

      // ---------------------------------------------------------------------------------------------->
      // side nav
      // ---------------------------------------------------------------------------------------------->

      $scope.sideNav = {
          openNav: function () {
              $scope.ES.accessGlobalOptions()
              $("body").addClass('sidenav-open');
              //$(".sidenav").addClass('open');
          },
          closeNav: function () {
              $("body").removeClass('sidenav-open');
              //$(".sidenav").removeClass('open');
          }
      };


      // ---------------------------------------------------------------------------------------------->
      // actions
      // ---------------------------------------------------------------------------------------------->

      $scope.goBackToSite = function (_site) {
          window.open(_site);
      };
      $scope.doBarBack = function () {
          $rootScope.$broadcast("doBack", {});
      };
      $scope.resetOrder = function () {
          MetaService.resetStorage();
          //$state.go("start");
      }
      $scope.handleCriticalError = function (err) {
          $scope.$storage.error = err;
          $scope.$state.go("error");

          var info = {};
          info.route = document.location.href;

          //log error
          window.setTimeout(function () {
			  info.errorSource = err.source;
			  info.error = err;
			  MetaService.logTemplate('criticalError', info);

			  //var ref = firebase.database().ref('/tologs/').remove(function (error) {
				 // if (error) {
					//  console.error('error deletting firebase logs', error);
				 // } else {
					//  console.log('deleted firebase logs');
				 // }
			  //});
			  /*
              err.date = new Date();
              var data = angular.fromJson(angular.toJson(err));
              var newData = ref.push();
              data._id = newData.getKey(),
              newData.set(data, function (error) {
                  if (error) console.error('log error failed');
              });
			  */
          }, 0);
      };
      $scope.isEnabled = function (item) {
		  if (item.disabled) return false;
		  let isODelay = $scope.$storage.order.forceDelay;
		  if (item.orderTime) {			  
			  switch (item.orderTime) {
				  case "sameDay": if (isODelay) return false; break;
				  case "future": if (!isODelay) return false; break;
			  }
		  }
		  if (item.schedule && $scope.$storage.activeTimeslots[item.schedule] === false) return false;//!isODelay && 
          if (item.services && item.services.length && item.services.indexOf($scope.$storage.order.mode) == -1) return false;
          return true;
      };
      $scope.isMenuMessageEnabled = function (item) {
          if (item.placement != "inMenu" || item.displayType == 'image') return false;
		  if (item.scope && item.scope != 'general' && item.scope != $scope.$storage.order.mode) return false;

          return true;
      };

      $scope.showMessages = function () {
          var deferred = $q.defer();
          var modalMessages = $uibModal.open({
              templateUrl: 'modules/app/modals/modal_messages.html',
              controller: 'modal_messages_controller',
              windowClass: "modal-default",
              size: "sm",
              resolve: {
                  modalParams: function () {
                      return {
                          $storage: $scope.$storage
                      };
                  }
              }
          });
          modalMessages.result.then(function (result) {
              deferred.resolve();
          });
          return deferred.promise;
      };

		
      $scope.signIn = function (args) {
		  //return $scope.signIn_old();

          var deferred = $q.defer();
		  if ($scope.$storage.user) return;

		  if ($scope.$storage.disableLogin) {
			  $scope.PDialog.info({ text: $translate.instant("MESSAGES.SIGNIN_SISABLED") });
			  return;
		  }

          var modalSignin = $uibModal.open({
              templateUrl: 'modules/auth/auth.modal.html',
              controller: 'modal_login_controller',
              windowClass: "modal-default",
              size: $scope.DESKTOP ? '' : "sm",
              resolve: {
				  modalParams: {
					  $storage: $scope.$storage,
					  args: args || {}
				  }
              }
          });
          modalSignin.result.then(function (result) {
              deferred.resolve();
          }, function () {

          });
          return deferred.promise;
      }

      $scope.register = function (ccInfo) {
          var deferred = $q.defer();
          if ($scope.$storage.user) {
              return;
          };
          var modalRegister = $uibModal.open({
              templateUrl: 'modules/app/modals/modal_register.html',
              controller: 'modal_register_controller',
              windowClass: "modal-default",
              backdrop: "static",
              size: $scope.DESKTOP ? '' : "sm",
              resolve: {
                  modalParams: {
					  $storage: $scope.$storage,
					  ccInfo: ccInfo
                  }
              }
          });
          modalRegister.result.then(function (result) {
              deferred.resolve();
          }, function () {

          });
          return deferred.promise;
      }

      $scope.editOrderHistory = function () {
          var deferred = $q.defer();
          var modalOrderHistory = $uibModal.open({
              templateUrl: 'modules/app/modals/modal_order_history.html',
              controller: 'modal_order_history_controller',
              windowClass: "modal-default",
              resolve: {
                  modalParams: function () {
                      return {
                          $storage: $scope.$storage,
                      };
                  }
              }
          });
          modalOrderHistory.result.then(function (result) {
              $scope.ES.
              deferred.resolve();
          }, function () {

          });
          return deferred.promise;
      }

      $scope.editAccountDetails = function () {
          var deferred = $q.defer();
		  if (!$scope.$storage.user || !$scope.$storage.lUser) {
              return;
          };
          var modalSignin = $uibModal.open({
              templateUrl: 'modules/auth/modal_account.html',
              controller: 'modal_account_controller',
              windowClass: "modal-default",
              resolve: {
                  modalParams: function () {
                      return {
                          $storage: $scope.$storage
                      };
                  }
              }
          });
          modalSignin.result.then(function (result) {
              deferred.resolve();
          }, function () {

          });
          return deferred.promise;
      }

      $scope.signOut = function () {
          var deferred = $q.defer();
          $scope.ES.signOut();
          deferred.resolve();
          return deferred.promise;
      };


		$scope.benefitsLogin = function () {
			var deferred = $q.defer();
			var modalBenefitsLogin = $uibModal.open({
				templateUrl: 'modules/auth/benefits_login_modal.html',
				controller: 'benefits_login_controller',
				windowClass: "modal-default",
				backdrop: "static",
				resolve: {
					modalParams: function () {
						return {
							$storage: $scope.$storage
						};
					}
				}
			});
			modalBenefitsLogin.result.then(function (result) {
				
			}, function () {

			});
			return deferred.promise;
		}

  });

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// modal_messages_controller
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('modal_messages_controller', function ($scope, $uibModalInstance, modalParams, $timeout, $translate) {
    $scope.$storage = modalParams.$storage;
    $scope.maxHeight = ($(window).height() - 147) + 'px';

    $scope.approve = function () {
        $uibModalInstance.close({ approve: true });
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

});


// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// modal_signin_controller
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('modal_signin_controller', function ($scope, $uibModalInstance, EntityService, modalParams, $timeout, $translate) {
    $scope.$storage = modalParams.$storage;

    $scope.ui = {
        mode: "signin"
    };
    $scope.setMode = function (mode) {
        $scope.signinForm.submitAttempt = false;
        $scope.ui.mode = mode;
        $scope.signin = {};
    };

    $scope.ES = EntityService;

    $scope.signinData = { rememberMe: true };
    $scope.$watch('signinData', function () {
        $scope.errMSG = null;
    }, true);

    $scope.save = function (data) {
        if ($scope.signinForm.$valid) {
            switch ($scope.ui.mode) {
                case "signin": $scope.signIn(); break;
                case "forgot": $scope.forgotPass(); break;
            }
        }
    };
    $scope.signIn = function () {
        $scope.ES.signIn($scope.signinData).then(
           function (ret) {
               $scope.PDialog.success({ text: $translate.instant("MESSAGES.SIGNIN_SUCCESS", { name: $scope.$storage.user.contact.name }) }).then(
                   function () {
                       $uibModalInstance.close({ mode: 'continue' });
                   }
               );
           },
           function (err) {
               $scope.errMSG = err && err.message ? err.message : null;
           }
        );
    };
    $scope.forgotPass = function () {
        $scope.ES.forgotPass($scope.signinData).then(
           function (ret) {
               $scope.PDialog.success({ text: $translate.instant("MESSAGES.FORGOTPASS_SUCCESS") }).then(
                   function () {
                       $uibModalInstance.close({ mode: 'continue' });
                   }
               );
           },
           function (err) {
               $scope.errMSG = err && err.message ? err.message : null;
           }
        );
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };


})


// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// modal_register_controller
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('modal_register_controller', function ($scope, $uibModalInstance, EntityService, MetaService, modalParams, $timeout, $translate, $uibModal) {
    $scope.$storage = modalParams.$storage;
    $scope.ES = EntityService;
    $scope.MS = MetaService;
    $scope.ui = {};


    $scope.contact = angular.copy($scope.$storage.order.contact) || {};

    $scope.register = {};
    if ($scope.$storage.order.$ccinfo){
        $scope.register.$savePaymentInfo = true;
    }

    $scope.doRegister = function () {
        if ($scope.dForm.$valid) {

            if (!$scope.register.contractApprove && !$scope.$storage.user) {
                $scope.PDialog.info(
                    {
                        "text": $translate.instant("MESSAGES.DO_YOU_APPROVE_CONTRACT"),
                        showCancelButton: true,
                        confirmButtonText: $translate.instant("YES"),
                        cancelButtonText: $translate.instant("NO"),
                    }
                ).then(function () {
                    $scope.register.contractApprove = true;
                    doRegister_cont();
                });
            } else {
                doRegister_cont();
            }

            function doRegister_cont() {

                $scope.register.email = $scope.contact.email;
                var args = angular.copy($scope.register);
                if (args.$savePaymentInfo && $scope.$storage.order.$ccinfo) {
                    args.ccinfo = angular.copy($scope.$storage.order.$ccinfo);
                };
                if ($scope.$storage.order.mode == 'delivery') {
                    args.address = angular.copy($scope.$storage.order.address);
                };
                args.contact = angular.copy($scope.contact);
                $scope.ES.register(args).then(handleRegisterSuccess, handleRegisterFail);
            }

            function handleRegisterSuccess() {
                $scope.PDialog.success({ text: $translate.instant("MESSAGES.REGISTER_SUCCESS", { name: $scope.$storage.user.contact.name }) }).then(
                    function () {
                        $uibModalInstance.close();
                    }
               );
            };
            function handleRegisterFail(args) {
                $scope.PDialog.error({ text: args.message });
            }

        };
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
    $scope.doReadContract = function (isPrivacy) {
        var modalContract = $uibModal.open({
            templateUrl: 'modules/app/modals/modal_contract.html',
            controller: 'modal_contract_controller',
            windowClass: "modal-default",
            resolve: {
                modalParams:  {
                    $storage: $scope.$storage,
                    isPrivacy: isPrivacy
                }
            }
        });
        modalContract.result.then(function (result) {
            $scope.contact.contractApprove = true;
        });
    }

})

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// modal_account_controller
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('modal_account_controller', function ($scope, $q, $uibModalInstance, EntityService, MetaService, LoyaltyService, modalParams, $timeout, $translate) {
    $scope.$storage = modalParams.$storage;
    $scope.maxHeight = ($(window).height() - 147) + 'px';
    $scope.MS = MetaService;

    $scope.ui = {
		fixedPaymentMethods: MetaService.paymentMethods,
		enablePMComment: true
    };


    $scope.ES = EntityService;

    $scope.user = _.extend({ wallet: { paymentMethods: [] } }, angular.copy($scope.$storage.user));
    $scope.wallet = $scope.user.wallet;
	
    if ($scope.user.ccinfo) {
        $scope.wallet.paymentMethods.push($scope.user.ccinfo);
    }
    if (!$scope.wallet.defaultPaymentMethod && $scope.wallet.paymentMethods[0]) {
        $scope.wallet.defaultPaymentMethod = $scope.wallet.paymentMethods[0]._id;
    }

	$scope.customer = _.pick($scope.$storage.lUser, ['FirstName', 'LastName', 'Email']);



    $scope.contact = $scope.user.contact;
    $scope.signin = $scope.user.signin ? angular.copy($scope.user.signin) : {};
    
    
    $scope.setPaymentMethod = function(ccinfo, pm){
        ccinfo.paymentMethod = pm; 
        //ccinfo.account = pm._id;
    };
    $scope.ccinfo = {
        paymentMethod: "CREDIT",
        type: undefined
    };


    $scope.$watch('user', function () {
        $scope.errMSG = null;
    }, true);
    $scope.$watch('signin', function () {
        $scope.errMSG = null;
    }, true);

    $scope.deleteCCinfo = function (index) {
        $scope.PDialog.warning(
            {
                "text": $translate.instant("MESSAGES.ARE_YOU_SURE"),
                showCancelButton: true,
                confirmButtonText: $translate.instant("REMOVE")
            }
        ).then(function () {
            var ccInfo = angular.copy($scope.wallet.paymentMethods[index]);
            $scope.wallet.paymentMethods.splice(index, 1);
            if (!$scope.wallet.ccinfoRemoved) $scope.wallet.ccinfoRemoved = [];
            $scope.wallet.ccinfoRemoved.push(ccInfo._id);
            if (ccInfo._id == $scope.wallet.defaultPaymentMethod) {
                delete $scope.wallet.defaultPaymentMethod;
                if ($scope.wallet.paymentMethods[0]) {
                    $scope.wallet.defaultPaymentMethod = $scope.wallet.paymentMethods[0]._id;
                };
            };
        });
    };
    $scope.addCCInfo = function () {
        if ($scope.dForm.$valid) {
            $scope.ES.addAccountPaymentMethod($scope.ccinfo).then(function (ret) {
                $scope.dForm.submitAttempt = false;
                $scope.wallet.paymentMethods.push(ret);
                $scope.ccinfo = { paymentMethod: "CREDIT", type: undefined };
                delete $scope.ui.mode;
                if (!$scope.wallet.defaultPaymentMethod) {
                    $scope.wallet.defaultPaymentMethod = $scope.wallet.paymentMethods[0]._id;
                };
            });
        };
    };
    $scope.save = function (data) {
		if ($scope.dForm.$valid) {
			var wasWalletChange = $scope.wallet.ccinfoRemoved;
			$q.resolve().then(() => {
				if ($scope.wallet.ccinfoRemoved) return $scope.ES.removePaymentMethodsFromWallet($scope.wallet.ccinfoRemoved);
				else return $q.resolve();
			}).then(o => {
				return LoyaltyService.updateCustomer$($scope.customer).then(res => {
					if (wasWalletChange) return $scope.ES.refreshWalletInformation();
					else return $q.resolve();
				})
			}).then(o => {
				$scope.PDialog.success({ text: $translate.instant("MESSAGES.ACCOUNT_UPDATE_SUCCESS") }).then(() => {
					$uibModalInstance.close({ mode: 'continue' });
				});
			}).catch(err => {
				switch (err.type) {
					case "EMAIL_IN_USE":
						delete $scope.signin.email;
						break;
				}
				if (err.message) { $timeout(function () { $scope.errMSG = err.message; }) }
			});
        }
    };
    $scope.cancel = function () {
        if ($scope.ui.mode == 'addPaymentInfo') {
            $scope.dForm.submitAttempt = false;
            delete $scope.ui.mode;
        } else {
            $uibModalInstance.dismiss('cancel');
        }
    };
})

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// modal_order_history_controller
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('modal_order_history_controller', function ($scope, $uibModalInstance, EntityService, MetaService, modalParams, $timeout, $translate) {
    $scope.$storage = modalParams.$storage;
    $scope.orders = angular.copy($scope.$storage.orders);
    $scope.maxHeight = ($(window).height() - 147) + 'px';
    $scope.MS = MetaService;

    $scope.ui = {
        paymentMethods: MetaService.paymentMethods
    };

    $scope.deleteOrder = function (_order, index) {
        $scope.PDialog.warning(
            {
                "text": $translate.instant("Q_REMOVE_ORDER"),
                showCancelButton: true,
                confirmButtonText: $translate.instant("REMOVE")
            }
        ).then(function () {
            $scope.$storage.orders.splice(index, 1);
            $scope.ES.updateOrder(_order, true);
        });
    };
    $scope.onRating = function (order, b) {
        $scope.ES.updateOrder(order);
    };
    $scope.onTag = function (order, b) {
        order.tags = b;
        $scope.ES.updateOrder(order);
    };
    $scope.ES = EntityService;

    $scope.save = function () {
        $uibModalInstance.close($scope.orders);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
})


angular.module('app').factory('MetaService', function ($translate, $rootScope, tmhDynamicLocale, ENV, $q, $http, $sessionStorage, $locale, blockUI, $timeout, PDialog, $injector, $window) {
    var service = {
        locals: {
            "he-IL": {
                angularLocal: "he-il",
                momentLocal: "he",
                isRTL: true,
                transLang: "he-IL",
                baseLang: "he-IL",
                currecySymbol: "\u20aa",
                mapSearchLocalComponents: "country:IL|street_address",
                mapLanguage: "iw",
				decimals: 2,
				timeFormat: 'HH:mm'
            },
            "en-US": {
                angularLocal: "en-us",
                momentLocal: "en",
                isRTL: false,
                transLang: "en",
                baseLang: "en",
                currecySymbol: "$",
                mapSearchLocalComponents: "country:US|street_address",
                mapLanguage: "en",
				decimals: 2,
				timeFormat: 'h:mm a'
            },
            "en-GB": {
                angularLocal: "en-gb",
                momentLocal: "en",
                isRTL: false,
                transLang: "en",
                baseLang: "en",
                currecySymbol: "\u00a3",
                mapSearchLocalComponents: "country:GB|street_address",
                mapLanguage: "en",
				decimals: 2,
				timeFormat: 'h:mm a'
            },
            "en-ZA": {
                angularLocal: "en-za",
                momentLocal: "en",
                isRTL: false,
                transLang: "en",
                baseLang: "en",
                currecySymbol: "R",
                mapSearchLocalComponents: "country:ZA|street_address",
                mapLanguage: "en",
				decimals: 2,
				timeFormat: 'h:mm a'
            }
        },
        translations: [
			{ id: "he-IL", transLang: "he-IL", name: "עברית", caption: "עב", isRTL: true, momentLocal: "he", enabled:true },
			{ id: "en-US", transLang: "en", name: "English", caption: "EN", isRTL: false, momentLocal: "en", enabled: true },
			{ id: "ar-IL", transLang: "ar-IL", name: "عربى", short: "AR", momentLocal: "en", isRTL: true },
			{ id: "ru-RU", transLang: "ru-RU", name: "Pусский", caption: "RU", momentLocal: "en", isRTL: false },
			{ id: "fr-FR", transLang: "fr-FR", name: "Français", caption: "FR", momentLocal: "en", isRTL: false }
		],
		usStates: [
			{"t": "Alabama","v": "AL"},{"t": "Alaska","v": "AK"},{"t": "American Samoa","v": "AS"},{"t": "Arizona","v": "AZ"},{"t": "Arkansas","v": "AR"},{"t": "California","v": "CA"},{"t": "Colorado","v": "CO"},{"t": "Connecticut","v": "CT"},{"t": "Delaware","v": "DE"},{"t": "District Of Columbia","v": "DC"},{"t": "Federated States Of Micronesia","v": "FM"},{"t": "Florida","v": "FL"},{"t": "Georgia","v": "GA"},{"t": "Guam","v": "GU"},{"t": "Hawaii","v": "HI"},{"t": "Idaho","v": "ID"},
			{"t": "Illinois","v": "IL"},{"t": "Indiana","v": "IN"},{"t": "Iowa","v": "IA"},{"t": "Kansas","v": "KS"},{"t": "Kentucky","v": "KY"},{"t": "Louisiana","v": "LA"},{"t": "Maine","v": "ME"},{"t": "Marshall Islands","v": "MH"},{"t": "Maryland","v": "MD"},{"t": "Massachusetts","v": "MA"},{"t": "Michigan","v": "MI"},{"t": "Minnesota","v": "MN"},{"t": "Mississippi","v": "MS"},{"t": "Missouri","v": "MO"},{"t": "Montana","v": "MT"},{"t": "Nebraska","v": "NE"},{"t": "Nevada","v": "NV"},{"t": "New Hampshire","v": "NH"},{"t": "New Jersey","v": "NJ"},{"t": "New Mexico","v": "NM"},{"t": "New York","v": "NY"},{"t": "North Carolina","v": "NC"}, { "t": "North Dakota", "v": "ND" },
			{ "t": "Northern Mariana Islands", "v": "MP" }, { "t": "Ohio", "v": "OH" }, { "t": "Oklahoma", "v": "OK" }, { "t": "Oregon", "v": "OR" }, { "t": "Palau", "v": "PW" }, { "t": "Pennsylvania", "v": "PA" }, { "t": "Puerto Rico", "v": "PR" }, { "t": "Rhode Island", "v": "RI" }, { "t": "South Carolina", "v": "SC" }, { "t": "South Dakota", "v": "SD" }, { "t": "Tennessee", "v": "TN" }, { "t": "Texas", "v": "TX" }, { "t": "Utah", "v": "UT" }, { "t": "Vermont", "v": "VT" }, { "t": "Virgin Islands", "v": "VI" }, { "t": "Virginia", "v": "VA" }, { "t": "Washington", "v": "WA" }, { "t": "West Virginia", "v": "WV" }, { "t": "Wisconsin", "v": "WI" }, { "t": "Wyoming", "v": "WY" }
		],
        today: new Date(),
        currentYear: new Date().getFullYear(),
        currentMonth: new Date().getMonth() + 1,
        cards: {
            "mastercard": { "name": "Mastercard", "icon": "images/card_mastercard.svg" },
            "visa": { "name": "Visa", "icon": "images/card_visa.svg" },
            "discover": { "name": "Discover", "icon": "images/card_discover.svg" },
            "amex": { "name": "Amex", "icon": "images/card_amex.svg" }
        },
        paymentMethods: ["creditCard", "Cibus", "10bis"],
        gratuities: [10, 12, 15, 20],
        courierGratuities: [10, 15, 20, 25],
        cache: {},
        largeAmountThreshhold: 5000,
        defaultStorage: {
            basket: [],
            order: { step: 'start' },
            meta: null,
            activeTimeslots: {},
            rosConfig: {currencySettings:{}},
            clientInitialDate: new Date(),
            title: 'TabitOrder'
		},
        _keys: {},
        initOrderExternalResources: initOrderExternalResources
    }

    // ------------------------------------------------------------------------------------->

	var _keys = service._keys = UIUtils.getURLParams();
    var mainStorage = $sessionStorage.$default({});
    if (_keys.hosted) {
        window.sessionStorage.clear();
        mainStorage = {};
    }
    

	$window.onbeforeunload = function (e) {
		var benfitsService = $injector.get('BenefitsService');
		if (location.href.indexOf('index_test.html') == -1) benfitsService.signOut();
		//benfitsService.signOut();
		sessionStorage.setItem('ngStorage-' + storageKey, JSON.stringify(service.$storage));
	};


    var storageKey = _keys.site || _keys.org || _keys.siteName || _keys.orgName;
    if (_keys.oid) storageKey += "_" + _keys.oid;
    
    if (_keys.hosted || _keys.oid || !mainStorage[storageKey]) {
        mainStorage[storageKey] = service.defaultStorage;
    };
    service.$storageKey = storageKey;
    service.$storage = mainStorage[storageKey];
	if (!_.get(service.$storage, 'startLoaded')) {
		service.$storage = mainStorage[storageKey] = service.defaultStorage
	}
	service.resetStorage = function (silent) {
		let tpOrder = service.$storage.$tpOrder;
		service.removeOrderTimer();
		var catalogTrans = service.$storage.catalogTrans;
		var benfitsService = $injector.get('BenefitsService');
		benfitsService.signOut();
		service.$storage = null;
        sessionStorage.removeItem('ngStorage-' + storageKey);

        var href = UIUtils.getCleanURL();

        if (href.indexOf('oid=') != -1) {
            href = href.replace(/&?oid=([^&]$|[^&]*)/i, "");
        };
        
		if (catalogTrans) href += `&local=${catalogTrans}`;
		if (_keys.training === 'false') href += `&training=false`;

		if (tpOrder) {
			if (!ENV.tabitPayURL) ENV.tabitPayURL = "https://il-pay.tabit-int.com/";
			const url = `${ENV.tabitPayURL}?oid=${tpOrder._id}&site=${tpOrder.organization}`;
			document.location.replace(url);
			return;
		}
        if (silent) return;
		blockUI.start();
		
        window.setTimeout(function(){
            sessionStorage.removeItem('ngStorage-' + storageKey);
            document.location.replace(href);
		}, 0);

		var deferred = $q.defer();
		return deferred.promise;
    };

    // ------------------------------------------------------------------------------------->

    service.locals["he-il"] = service.locals["he-IL"];
    service.locals["he_il"] = service.locals["he-IL"];
    service.locals["en"] = service.locals["en-US"];

    service.validatePhone = function (val) {
		if (service.local == service.locals["he-IL"]) return !val || val.indexOf('05') == 0;
        else return true;
    };

	service.validateName = function (val) {
		if (!val || !val.length) return true;
		if (/^[a-zA-Z\u0590-\u05FF\- ]*$/.test(val)) return true;
		return false;
	}

    service.maxPhoneNumber = function () {
        return (service.local == service.locals["he-IL"]) ? 10 : 10;
    };

    service.calcServerDateDiff = function(clientDate, serverDate){
        if (serverDate){
            window.SERVERDATEDIF = new Date(serverDate).valueOf() - clientDate.valueOf();
        }
    };
    window.GETREALDATE = service.getRealDate = function (isMoment) {
        var dif = window.SERVERDATEDIF || 0
        var d = new Date().valueOf() + dif;
        var m = moment(d);

        if (service.$storage.timeZone) {
            m = m.clone().tz(service.$storage.timeZone);
        }

        if (isMoment) return m;
        return m.toDate();
    };

    service.selectTranslation = function (member) {
        service.$storage.selectedTranslation = member;
        service.local.transLang = member.transLang;
        service.local.isRTL = member.isRTL;
        let catalogTrans = member.transLang != service.local.baseLang ? member.transLang : null;
        service.$storage.catalogTrans = catalogTrans == 'en' ? 'en-US' : catalogTrans;
		translateBaseSetup();
		if (member.momentLocal) moment.locale(member.momentLocal);
        $translate.use(member.transLang);
        setUIDirection();
    }

    function setUIDirection() {
        $("body").removeClass("_ltr _rtl");
        if (service.local.isRTL) {
            $("body").addClass("_rtl").prop("dir", "rtl");
        } else {
            $("body").addClass("_ltr").prop("dir", "ltr");
        }
    }

    service.setLocal = function () {
        var _local = service.$storage.local;
        var _brand = service.$storage.brandSkin;
        var _brandObj = service.$storage.brand;

        if (!_local) _local = "en-US";
        var local = service.locals[_local];
        var oldLocal = service._local;

        if (!service.$storage.selectedTranslation) {            
            var translations = service.$storage.availTranslations;
            if (translations && translations.length) {
                var chkLocal = service.$storage.preferredLocal || _local;
                var selectedTranslation;
                var parsedTranslations = _.filter(service.translations, function (o) {
					if (o.enabled && translations.indexOf(o.id) != -1) {
                        if (o.id == chkLocal) selectedTranslation = o;
                        return true;
                    }                    
                });
                if (selectedTranslation) {
                    service.$storage.parsedTranslations = parsedTranslations;
                    service.$storage.selectedTranslation = selectedTranslation;
                }
            }
        } 

        if (service.$storage.selectedTranslation) {
            local.transLang = service.$storage.selectedTranslation.transLang;
            local.isRTL = service.$storage.selectedTranslation.isRTL;
            let catalogTrans = local.transLang != local.baseLang ? local.transLang : null;
            service.$storage.catalogTrans = catalogTrans == 'en' ? 'en-US' : catalogTrans;
        }
        translateBaseSetup();

        if (_local != oldLocal) {
            if ($translate.proposedLanguage() || $translate.use() != local.transLang) {
                $translate.use(local.transLang);
            }
            service.local = local;
            service.currecySymbol = local.currecySymbol;
            tmhDynamicLocale.set(local.angularLocal);
            moment.locale(local.momentLocal);
        }
        setUIDirection();        

        service.months = $locale.DATETIME_FORMATS.MONTH;
        if (_brandObj && _brandObj.brandMethod != 'preDefined' && _brandObj._id && _brandObj.templates  && _brandObj.templates.css){
            if (service.brand != _brandObj._id){
                
                service.brand = _brandObj._id;
                $('#css_brand, #stl_brand').remove();

                var cssText = _brandObj.templates.css;
                var css = document.createElement("style");
                $("<style id='stl_brand'>" + cssText + "</style>").appendTo("body");
            }
        }else{        
            if (!_brand) _brand = '_tabit';
            if (service.brand) {
                if (service.brand != _brand) {
                    delete service.brand;
                    $('#css_brand, #stl_brand').remove();
                    if (_brand) setLocalBrand(_brand);
                }
            } else if (_brand) {
                setLocalBrand(_brand);
            };
        };        

        function setLocalBrand(_brand) {
        	if (window.FIXEDBRAND) return;
            if (!_brand)_brand = '_tabit';
            service.brand = _brand;
            var bpath = 'brand/' + _brand + '/style.css';
            service.getBrandPath().then(function (path) {
                bpath = (path || 'brand/') + _brand + '/style.css';
                console.log('bpath:', bpath);
                $('body').append('<link href="' + bpath + '" rel="stylesheet" id="css_brand" />');
            })
        }
    }
    
    service.getBrandPath = function () {
        var deferred = $q.defer();
        deferred.resolve(ENV.brandPath);
        return deferred.promise;
    }

    function translateBaseSetup() {
        let source = _.get(service.$storage, "organization.branches[0]", {});
        let base = source.settings || {};
        let arr = [
			['eatinCaption', 'eatin'],
			['takeawayCaption', 'takeaway'],
			['deliveryCaption', 'delivery'],

			['selectTableCaption', 'SELECT_TABLE'],
			['tableNumberCaption', 'TABLE_NO'],
			['orderDelayCaption', '_DELAYED.service_caption'],
			['supplyTimeDisclaimer', null],
            ['extraOffersCaption', 'ADDITIONAL_OFFERS'],
			['extraOffersDesc', 'SELECT_ADDITIONAL_OFFERS'],

			['loyaltyHeaderCaption', null],
			['loyaltyHeaderSubCaption', null],
			['loyaltyFieldCaption', null],
			['benefitsCommentCaption', null],
			['loyaltyClubsCaption', '_LOYALTY.select_club']
        ]

		_.each(arr, o => {
            var prop = o[0];
            let val = base[prop];
            if (val) val = service.getTranslation(source, prop, val);
            else val = o[1];
            if (prop === 'eatinCaption') prop = 'eatInCaption';
            service.$storage[prop] = val;
        });
    }

    service.getAppCaption = function () {
        let catalogTrans = service.$storage.catalogTrans;
        return _.get(service.$storage, `translations.${catalogTrans}.${'name'}`, service.$storage.caption);
    }

    service.getTranslation = function (source, att, _default) {
        if (!_default) _default = source[att];
        let catalogTrans = service.$storage.catalogTrans;
        return _.get(source, `translations.${catalogTrans}.${att}`, _default);
    }

	service.translateMember = function (member) {
		let trans = _.get(member, `translations[${service.$storage.catalogTrans}]`);
		if (trans) {
			_.each(trans, (val, key) => {
				if (key == 'name') {
					member.$$translated = true;
					member.realName = member[key];
				}
				member[key] = val;
			});
		}
	}

	service.translateValList = function (arr) {
		if (arr.length == 1) return arr[0];
		let op = $translate.instant("arr_join");
		if (arr.length == 2) return arr.join(op);
		let last = arr.pop();
		return `${arr.join(", ")}${op}${last}`;

	}


    // ------------------------------------------------------------------------------------->

    var orderTimer;
    service.removeOrderTimer = function(){
    
    }
    service.startOrderTimer = function(){
        $timeout.cancel(orderTimer);
        if (isOrderTimerActive()){
			let m = ENV.isDev ? 180 : 30;
			var threshhold = 1000 * 60 * m;
			
            var timeOut = window.GETREALDATE(true).diff(service.$storage.started);
            if (timeOut > threshhold){
				service.displayOrderTimer();
				return false;
            }else{
				orderTimer = $timeout(service.startOrderTimer, threshhold - timeOut);
				return true;
            }            
		}
		return true;
    }

	service.checkOrderTimer = function(){
		delete service.$storage.payInProgress;
		return service.startOrderTimer();
	}

	service.displayOrderTimer = function (msg) {
		let isTpOrder = service.$storage.$tpOrder != null;
		if (isTpOrder) msg = "MESSAGES.ORDER_TIMEOUT_TP";
		else if (!msg) msg = "MESSAGES.ORDER_TIMEOUT";
        PDialog.info({
            "text": $translate.instant(msg),
		}).then(function () {
            service.resetStorage();
        }, function () {
			service.resetStorage();
        });
    };

    function isOrderTimerActive(){
		return service.$storage.started && !service.$storage.orderClosed && service.$storage.catalog && !service.$storage.payInProgress ;
    }
    

	service.redirectToTo2 = function (args) {
		let deferred = $q.defer(), url;
		let addStr = '';
		_.each(_keys, (val, key) => {
			switch (key) {
				case "service": ; case "table": ; case "training":
					addStr = `${addStr}&${key}=${val}`;
					break;
			}
		})
		switch (args.topic) {
			case "site":
				if (_keys.siteName) {
					url = `${ENV.TO2Url}tabit-order?siteName=${_keys.siteName}${addStr}&step=enter`;
				} else {
					url = `${ENV.TO2Url}tabit-order?site=${args.siteId}${addStr}&step=enter`;
				}				
				break;
			case "chain":
				if (_keys.orgName) {
					url = `${ENV.TO2Url}tabit-order?orgName=${_keys.orgName}&step=enter`;
				} else {
					url = `${ENV.TO2Url}tabit-order?org=${args.chainId}&step=enter`;
				}
				break;
		}
		
		document.location.replace(url);
		return deferred.promise;
	}

    // ------------------------------------------------------------------------------------->
	// start external
	// ------------------------------------------------------------------------------------->

	function initOrderExternalResources() {
		let types = _.get(service.$storage, 'pmAccountTypes', {});
		if (types.HeartLand) {
			$.when(
				$.getScript('https://api2.heartlandportico.com/SecureSubmit.v1/token/2.1/securesubmit.js'),
			).done(function () {
				return true;
			});
		}
		if (types.LeumiPay) {
			let args = {//paycheckoutuat
				js: 'https://paycheckout.creditguard.co.il/sdk/web/pay-checkout/pay-checkout.js',
				css: 'https://paycheckout.creditguard.co.il/sdk/web/pay-checkout/bundle.css'
			}
			if (ENV.leumiPaySdkURL && ENV.leumiPayCssURL) {
				args.js = ENV.leumiPaySdkURL;
				args.css = ENV.leumiPayCssURL;
			}
			$.getScript(args.js).then(o => {});
			$('head').append(`<link rel="stylesheet" type="text/css" href="${args.css}">`);
		}
		if (types.Braintree) {
			$.when(
				$.getScript('https://js.braintreegateway.com/web/3.63.0/js/client.min.js'),
				$.getScript('https://js.braintreegateway.com/web/3.63.0/js/data-collector.min.js'),
				$.getScript('https://js.braintreegateway.com/web/dropin/1.23.0/js/dropin.min.js'),
			).done(function () {
				return true;
			});
		}
		if (types.Stripe) {
			if (!ENV.stripeClientKey) ENV.stripeClientKey = 'pk_test_51M2Zf9CwcLVxIMhaQwjBw183FsOqh8ao4JRF6RUgqqEOu2zYktPKscmeNKujqPxWR4rKlBSCXeTpDpzi26mmjppr002NCnO7YM';
			$.when(
				$.getScript('https://js.stripe.com/v3/'),
			).done(function () {
				return true;
			});
		}
	}
	initOrderExternalResources();

    // ------------------------------------------------------------------------------------->
	// start logz
	// ------------------------------------------------------------------------------------->

	service.logError = function (err, actionType, message) {
		let url = _.get(err, 'config.url');
		if (url.indexOf(ENV.logsAPI) != -1) {
			return;
		}
		if (!actionType) actionType = 'errorInterceptor';
		if (!message) {
			let errorURL = _.get(err, 'config.url', '').replace(ENV.apiEndpoint + '', '');
			message = `${actionType}: ${errorURL}`;
		}
		service.logTemplate(actionType, { error: err }, message);
	}

	service.logTemplate = function (actionType, data, message) {
		var localDate = new Date(), timeStamp = GETREALDATE();

		if (!message) message = actionType;
		switch (actionType) {
			case "errorInterceptor":
				if (!message) message = "Error Interceptor";
				break;
		}

		let _order = _.get(service.$storage, 'order', {})
		let _branch = _order.branch || {};
		var base = {
			"actionType": actionType,
			"serverUrl": ENV.apiEndpoint,
			"userAgent": navigator.userAgent,
			"platform": navigator.platform,
			"route": document.location.href,
			"localDate": localDate,
			"timestamp": timeStamp,
			"isDesktopMode": window.ISDESKTOP,

			organization: _branch._id,
			organizationName: _branch.name,

			service: _order.mode,
			orderMode: _order.mode,
			isFuture: _order.forceDelay === true,
			order: service.$storage.order_id,
			itemsTotal: _order.itemsTotal,
			itemsDiscount: _order.itemsTotal,
			benefitsTotal: service.$storage.benefitsTotal || 0,
			giftCardsTotal: _order.giftCardsTotal,
			gratuity: _order.gratuityAmount,
			grandTotal: _order.grandTotalPlusTip,
			serverTax: _order.tax,
			//fees: _order.fees
		}
		if (_.get(_order.address, 'point')) {
			base.formattedAddress = _order.address.formatted_address;
			base.location = `${_order.address.point.lat}, ${_order.address.point.lng}`;
		}

		var props = ['defaultSiteId', 'region', 'local', 'startLoaded', 'catalogLoaded', 'brandSkin'];
		_.each(props, function (prop) {
			base[prop] = service.$storage[prop] || null;
		});

		service.serverLog({
			"tabitOrder": _.assignIn(base, data, service.$storage.urlParams),
			"@timestamp": timeStamp,
			"type": actionType,
			"message": message,
		});
	}

	service.logHandOff = function (order, isPartialError) {
		try {
			if (!order) order = {}
			let _order = _.get(service.$storage, 'order', {});
			service.logTemplate("handoff", {}, 'Handoff');
		} catch (e) { }
	}

	service.serverLog = function (payload) {
		console.log("log", payload.type, payload);
		let logsAPI = ENV.logsAPI;
		if (!logsAPI) {
			console.error('missing logz end point');
		} else {
			$http.post(logsAPI, payload).catch(err => {
				console.error('error logging', err);
			});			
		}
	}
	// ------------------------------------------------------------------------------------->

	service.toggleAccessibility = function () {
		service.accesibilityScope.toggleActive();
	}		

	// ------------------------------------------------------------------------------------->
    return service;
});


angular.module('app').factory('MarkettingService', function ($state, MetaService, ENV) {
	var service = {
		$storage: MetaService.$storage,
		wasMarketting: false
	}

	
	service.setMarketting = function (reffsite, exMarketing) {
		if (service.wasMarketting || service.$storage.marketting) return;
		service.wasMarketting = true;

		var base = {
			pixelConversionEventName: 'Purchase'
		}
		var mObj = _.extend(base, reffsite.marketting, exMarketing);
		service.$storage.marketting = mObj;
		loadScripts(mObj, true)
	}

	service.reloadMarketting = function () {
		if (service.wasMarketting) return;
		var mObj = service.$storage.marketting;
		if (mObj) {
			service.wasMarketting = true;
			loadScripts(mObj);
		}
	}

	function loadScripts(mObj, isInitial) {
		var wasGManagerTag = false;

		if (ENV.googleTagsManagerId) {
			if (!window.dataLayer) window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
			$.getScript('https://www.googletagmanager.com/gtm.js?id=' + ENV.googleTagsManagerId);
			wasGManagerTag = true;
		} else {
			console.log("Missing: googleTagsManagerId")
		}

		if (mObj.googleTagsManagerId) {
			if (!wasGManagerTag) {
				if (!window.dataLayer) window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
			}
			$.getScript('https://www.googletagmanager.com/gtm.js?id=' + mObj.googleTagsManagerId);
		}
		else if (mObj.googleTagsId) {
			$.getScript("https://www.googletagmanager.com/gtag/js?id=" + mObj.googleTagsId)
				.done(function (script, textStatus) {

					if (!window.dataLayer)window.dataLayer = window.dataLayer || [];
					window.gtag = function () {
						dataLayer.push(arguments);
					}
					gtag('js', new Date());
					gtag('config', mObj.googleTagsId);
				});
		}

		if (mObj.pixelId) {
			!function (f, b, e, v, n, t, s) {
				if (f.fbq) return;
				n = f.fbq = function () {
					n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
				};
				if (!f._fbq) f._fbq = n;
				n.push = n;
				n.loaded = !0;
				n.version = '2.0';
				n.queue = [];
				t = b.createElement(e);
				t.async = !0;
				t.src = v;
				s = b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t, s)
			}(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

			fbq('init', mObj.pixelId); // Insert your pixel ID here.
			fbq('track', 'PageView');
		}
	}

	service.trackConversion = function (value) {
		var mObj = service.$storage.marketting || {};
		if (window.fbq) {
			try {
				window.fbq('track', mObj.pixelConversionEventName, {
					value: value,
					currency: 'ILS'
				});
			} catch (e) { }
		}
		if (window.gtag && mObj.googleTagsConversionId) {
			try {
				window.gtag('event', 'conversion', {
					'send_to': mObj.googleTagsConversionId,
					value: value,
					currency: 'ILS'
				});
			} catch (e) { }
		}
	}

	//--------------------------------------------------------------------------------------->

	service.pushDataLayer = function (dataLayer, type, moreInfo) {
		//if (!window['dataLayer']) return;
		let $storage = MetaService.$storage;
		let region = $storage.region, currencyCode;
		switch (region) {
			case "US": currencyCode = "USD"; break;
			default: currencyCode = "ILS"; break;
		}

		let layer = {};
		//"currencyCode": "ILS", "USD"
		switch (type) {
			case "add_cart":
				layer = {
					event: 'addToCart',
					ecommerce: {
						currencyCode: currencyCode,
						add: {
							products: this.getProducts(dataLayer)
						}
					}
				}
				break;
			case "remove_cart":
				layer = {
					event: 'removeFromCart',
					ecommerce: {
						currencyCode: currencyCode,
						add: {
							products: this.getProducts(dataLayer)
						}
					}
				}
				break;
			case "purchase":
				layer = {
					event: 'purchase',
					ecommerce: {
						purchase: {
							actionField: {
								id: $storage.order_id,  // Transaction ID. Required for purchases and refunds.
								affiliation: _.get($storage.order, 'branch.name'),
								revenue: _.get($storage.order, 'grandTotalPlusTip', 0),
								tax: _.get(moreInfo, 'totals.totalVatAmount', 0) / 100,
								shipping: !$storage.order.freeDelivery ? _.get($storage.order, 'region.deliveryPrice', '') : '',
								coupon: _.get($storage.order, 'TTPromotion.name', ''),
							},
							products: this.getProducts(dataLayer)
						}						
					}
				}
				break;
		}
		if (layer) this.sendDataLayer(layer);
	}

	service.sendDataLayer = function(layer) {
		if(!layer) return;
		if (!window['dataLayer']) {
			console.log('Missing datalayer:', layer);
			return;
		}
		window['dataLayer'].push(layer);
		console.log('Data Layer Service > pushed data layer:', layer);
	}

	service.getProducts = function(layer) {
        let products = [];
        let product;
        if (_.isArray(layer) && layer.length) {
            layer.forEach(offer => {
                product = this.setProduct(offer);
                if (product) products.push(product);
            })
        } else {
            product = this.setProduct(layer);
            if (product) products.push(product);
        }

        return products;
    }

	service.setProduct = function(layer) {
        if (!layer) return;
        let product;
        product = {
            name: layer.name,
            id: layer._id,
            price: layer.price,
			brand: _.get(layer, '_offer.sectionName'),
			category: _.get(layer, '_item.category.name'),
			variant: _.get(layer, '_item.description'),
            quantity: layer.quantity
        }
        return product;
    }

	service.setOption = function (step) {
		if ($state.includes('app.checkout.contact')) {
			return 'contact';
		} else if ($state.includes('app.checkout.pay')) {
			return 'pay';
		} else if ($state.includes('app.checkout')) {
			return 'checkout';
		}
		return '';
    }

	return service;
});


angular.module('app').factory('EntityService', function (fbInit, $q, ENV, blockUI, $translate,
                                                         $localStorage, MetaService, MarkettingService, $uibModal,
                                                         PDialog, ExternalDeliveryService, LoyaltyService, 
														 ServiceAgent, ServiceCommon, OrderService,	$rootScope, $filter, $state, $http) {

        var service = {
            ENV: ENV,
            storageToken_orders: "tabit_co_orders",
            storageToken_addresses: "tabit_co_addresses",
            $storage: MetaService.$storage,

            taxRegions: ["US"],
            order_tags: ["BREAKFAST", "LUNCH", "DINNER", "WORK"],
            orderMethods: {
                "takeaway": {
                    name: $translate.instant("TAKE_AWAY"),
                    icon: 'images/ta.svg',
                    icon_dark: 'images/ta_dark.svg'
                },
                "eatin": {
                    name: $translate.instant("EAT_IN"),
                    icon: 'images/eatin.svg',
                    icon_dark: 'images/eatin_dark.svg'
                },
                "delivery": {
                    name: $translate.instant("HOME_DELIVERY"),
                    icon: 'images/delivery.svg',
                    icon_dark: 'images/delivery_dark.svg'
				},
				"viewMenu": {
					name: $translate.instant("VIEW_MENU"),
                    //icon: 'images/delivery.svg',
                    //icon_dark: 'images/delivery_dark.svg'
				}
            }
        };

        angular.extend(service, OrderService);

        service.setLocal = function (o) {
            if (o) {
                //if (!o.local) o.local = "he-IL";
				//service.$storage.local = o.local;

				switch (service.$storage.region) {
					case "IL": service.$storage.local = "he-IL"; break;
					default: service.$storage.local = "en-US"; break;
				}
                
                service.$storage.brandSkin = o.brandSkin;
                service.$storage.brand = o.brand;
                service.$storage.availTranslations = o.availTranslations;
            }
			MetaService.setLocal();
        };

		service.setLocal();



        //----------------------------------------------------------------------------------------->
        // methods
        //----------------------------------------------------------------------------------------->

        service.loadOrganization = () => {
            let urlParams = UIUtils.getURLParams();
            service.$storage.defaultSiteId = urlParams.site;
        };

        service.loadEnterprise = function () {
            return loadEnterprise();
        };

        service.loadCatalog = function () {
            blockUI.start($translate.instant("LOADING_MENU"));
            var site_id = service.$storage.order.branch._id;
            return $q.all({
                catalog: loadCatalog(),
                inventory: service.getSite_inventory(site_id),
                configFromRos: service.loadConfigFromRos()
			}).then(function (ret) {
				ServiceCommon.getClientOrderId();
				let mode = service.$storage.order.mode;
				MetaService.logTemplate("menuLoaded", { service: mode, isFuture: service.$storage.order.forceDelay === true }, 'Menu Loaded');
				if (mode != 'delivery') {
					delete service.$storage.order.region;
				}
				if (mode != 'VIEW_MENU' && !service.$storage.$tpOrder) {
					let modeAtt = service.$storage.order.forceDelay ? 'futureActive' : 'active';
					let sMode = ret.configFromRos[mode];
					if (!sMode || !sMode[modeAtt] || !sMode.enabled) {
						blockUI.stop();
						MetaService.displayOrderTimer("MESSAGES.SERVICE_UNAVAILABLE");
						return $q.defer().promise;
					}					
				}
				
				ret.inventory.hideInventory = _.get(ret.configFromRos, 'settings.outOfStockDisplay', 'hide') == 'hide';
				service.$storage.catalog.inventory = ret.inventory;
				prepareExtraOffers();

                return $q.resolve();
            }).catch(function(err){
                return $q.reject(err);
            }).finally(function(){
                blockUI.stop();
            });
        };


        service.loadConfigFromRos = function () {

			let promises = {
				config: ServiceAgent.get({ url: "online-shopper/configuration" }, true),
				exDeliveries: $q.resolve(), //ExternalDeliveryService.getExternalDeliveryProviders()
			}
			return $q.all(promises).then(function (res) {
				let config = res.config;

                service.$storage.rosConfig = angular.merge(config || {}, {
                    payments: {},
                    messaging: {
                        hub: {}
                    }
                });

				if (config.config) {
					/*
					let deliveryProvider = _.get(config.config, 'delivery.deliveryProvider');
					if (deliveryProvider) {
						let providerConfig = _.get(res.exDeliveries, deliveryProvider.id);
						if (providerConfig && deliveryProvider.enabled) {
							service.$storage.deliveryProvider = providerConfig;
						} else {
							_.set(config.config, 'delivery.enabled', false);
							console.log('Deliveries disabled because external provider is disabled');
						}
					}
					*/

					config.config.orderThrottling = config.orderThrottling;
                    service.$storage.requireTax = service.taxRegions.indexOf(config.region)  != -1;
					service.$storage.region = config.region;
					service.$storage.disablePaymentSplit = service.$storage.walletDisabled = config.region == 'US';
					service.$storage.requierePhoneCashConfirm = false;
					service.setRegionCong(config.config);
					config.config.takeawayToBeSentAs = config.takeawayToBeSentAs || 'TD';
					return config.config;
                }
                
            }).catch(function (err) {
                console.log('failed to get configuration from ros', err);
                throw(err)
            })
        };
		service.setRegionCong = function(config){
			var region = service.$storage.region;
			if (region == 'US') {
				service.$storage.gratuityDecimals = 2;
				service.$storage.amountPattern = "";
				service.$storage.defaultAddressType = "house";
				service.$storage.timeZone = _.get(config, 'timezone', "America/Chicago");
				service.$storage.gMapLanguage = 'en';
				service.$storage.gMapRegion = 'US';
				service.$storage.gSearchReplace = ", USA";
				service.$storage.disableLogin = true;				
			} else {
				service.$storage.amountPattern = "[0-9]*";
				service.$storage.timeZone = "Asia/Jerusalem";
				service.$storage.gMapLanguage = 'iw';
				service.$storage.gMapRegion = 'il';
				service.$storage.gSearchRegion = 'IL';
				service.$storage.gSearchComponents = { country: 'il' };
				service.$storage.gSearchReplace = ", ישראל";
			}
			service.$storage.defaultTimeZone = service.$storage.timeZone;
			service.$storage.gSearchPlaceAdd = ''
		}
		service.setSiteTimeZone = function (config) {
			service.$storage.timeZone = _.get(config, 'timezone', service.$storage.defaultTimeZone);
		}

		service.getSite_inventory = function (site_id) {
			let _storage = service.$storage,sURL = "online-shopper/inventory";

			if (_storage.order.forceDelay) {
				const delayedOrderDate = _.get(_storage.delayedOrder, 'slot.date');
				if (delayedOrderDate) sURL += `?eta=${new Date(delayedOrderDate).toISOString()}`;
			}
			return ServiceAgent.get({ url: sURL }).then(res => {
				return _.keyBy(_.filter(res, { quantity: 0 }), 'item');
			}).catch(err => {
				return {}
			});
	    };

        service.beginCheckout = function (phase) {
            console.log('beginCheckout:' + phase);
            if (ServiceCommon.didUserTokenExpired(service.$storage.user))
                service.$storage.user = null;
        };

        service.accessGlobalOptions = function () {
            if (ServiceCommon.didUserTokenExpired(service.$storage.user))
                service.$storage.user = null;
        };

        service.payAnonymous = function (args) {
            return execProcessPayment(args);
        };

        service.payRegistered = function (args) {
            return execProcessPayment(args);
        };


        function execProcessPayment(args) {
            return service.processPayment(args)
                .then(function () {
                    service.saveUsersOrder();
                })
		}

	service.processNoAmountOrder = function () {
			if (!MetaService.checkOrderTimer()) return;
			var _storage = service.$storage;
			var wh = _.get(_storage, "order.branch.wh", {});

			var args = { gratuityAmount: 0, noAmount: true };
			args = _.extend(args, service.getSupplyTime());
			if (!_storage.delayedOrder.active) {
				if (wh.slotMax && GETREALDATE(true).isAfter(wh.slotMax)) {
					PDialog.warning({ "text": $translate.instant("MESSAGES.BRANCH_DISABLED_NOW", { t: wh.text }) });
					return;
				};
			}

			ServiceCommon.getClientOrderId();

			
			if (_storage.user) {

			} else {
				args.register = { email: _storage.order.contact.email }
			}
			service.processPayment(args).then(handlePaySuccess, handlePayFail);

			function handlePaySuccess() {
				MarkettingService.trackConversion(_.get(_storage, 'order.grandTotalPlusTip'));
				_storage.orderClosed = true;
				_storage.dEnd = GETREALDATE(true).format();
				if (args.eta) _storage.order.supplyTime = args.eta;
				$state.go("end");
			}

			function handlePayFail(args) {
				if (args.phone) {
					service.showMessageWithPhone(args.message, args.phone).then(function () {
						if (args.orderClosed) {
							handlePaySuccess();
						} else {
							MetaService.checkOrderTimer();
						}
					});
				} else {
					PDialog.warning({ text: args.message }).then(function () {
						if (args.orderClosed) {
							handlePaySuccess();
						} else {
							MetaService.checkOrderTimer();
						}
					});
				}
			}

		}

        //----------------------------------------------------------------------------------------->
        // messages
        //----------------------------------------------------------------------------------------->


        service.loadSiteMessages = function (site) {
            service.$storage.messsages = null;
            var arr = [];
            _.each(site.messages, function (message) {
                if (message.active && (!message.schedule || service.$storage.activeTimeslots[message.schedule] !== false)) {
                    arr.push(message);
                };
            });
            service.$storage.messsages = arr;
            return $q.resolve();
        }

        service.popMessages = function (placement, scope, session, timing, extend) {
			let iMessages = [];
			toastr.remove();
            _.each(service.$storage.messsages, function (message) {
				if (session && message.session == session) return;
				if (timing && message.orderTime && timing != message.orderTime) return;
                if (message.active && message.placement == placement) {
                    if (!scope || message.scope == scope || message.scope == 'general') {
                        if (session)message.session = session;
                        var stype = "success";
                        switch (message.type) {
                            case "alert":
                                stype = "error";
                                break;
                            case "warning":
                                stype = "warning";
                                break;
                            case "info":
                                stype = "info";
								break;
							case "promo":
								stype = "promo";
								break;
                        }

                        var _message = message.message;
                        var catalogTrans = service.$storage.catalogTrans;
                        if (catalogTrans) {
                            _message = _.get(message, `translations.${catalogTrans}.message`, _message);
						}
						if (message.displayType == 'image') iMessages.push(message);
						else {
							toastr[stype](_message, null, _.assignIn({
								timeOut: 0,
								positionClass: "toast-top-right",
								"closeButton": true,
							}, extend));
						}
                    }
                }
			});
			if (iMessages.length) showImageMessage(iMessages, 0);
        }

		function showImageMessage(iMessages, index) {
			let message = iMessages[index];
			if (!message) return;
			let url = window.ISDESKTOP ? _.get(message, 'desktopImage.url') : _.get(message, 'mobileImage.url');
			if (!url) return;
			let modal = $uibModal.open({
				templateUrl: 'modules/app/modals/modal_image_message.html',
				controller: function ($scope, $uibModalInstance, modalParams) {
					$scope.args = modalParams;
				},
				windowClass: "modal-image",
				resolve: {
					modalParams: () => {return { url: url };}
				}
			});
			modal.result.then(result => {
				showImageMessage(iMessages, ++index);
			}, () => {
				showImageMessage(iMessages, ++index);
			});
		}

        function isMessageRelevant(message) {
            if (service.$storage.orderClosed) return false;
            return message && message.active && (message.scope == 'general' || message.scope == service.$storage.order.mode)
        };

        //----------------------------------------------------------------------------------------->
        // save load previous orders
        //----------------------------------------------------------------------------------------->

        service.maxSaveBuffer = 5;
        function getUserStorageKey() {
            if (service.$storage.user) {
                return service.storageToken_orders;// + '_' + service.$storage.user.id;
            } else {
                return service.storageToken_orders;
            }
        }

        service.getUsersOrders = function () {
            var deferred = $q.defer();
			var o = $localStorage[getUserStorageKey()] || [];
			deferred.resolve(o);
            return deferred.promise;
        };

        service.getRelevantUserOrders = function () {
            var deferred = $q.defer();
            service.getUsersOrders().then(function (arr) {
                var _orders = [];
                _.each(service.$storage.organization.branches, function (site) {
                    var _match = _.filter(arr, { site_id: site._id });
                    if (_match.length) {
                        _orders = _orders.concat(_match);
                    }
                });
                service.$storage.orders = _orders;
                deferred.resolve();
            });
            return deferred.promise;
        };

        service.getRelevantUserOrders_site = function (site_id) {
            if (service.$storage.orders) {
                service.$storage.orders = _.filter(arr, { site_id: site_id });
            }
        };

        service.updateOrder = function (order, _delete) {
            service.getUsersOrders().then(function (arr) {
                var index = _.findIndex(arr, { _id: order._id });
                if (_delete) {
                    if (index != -1) arr.splice(index, 1);
                } else {
                    if (index != -1) arr[index] = order;
                }
                $localStorage[getUserStorageKey()] = arr;
            });
        };

        service.saveUsersOrder = function () {
            var deferred = $q.defer();
            var order = service.$storage.order;
            var orderForSave = ServiceCommon.getPreparedOrder(true);
            if (orderForSave.order_id) {
                // disable saving fixed orders
                deferred.resolve();
            } else {
                orderForSave._id = new Date().valueOf();
                orderForSave.site_id = order.branch._id;
                orderForSave.site_name = order.branch.name;

                orderForSave.address = order.address;
                orderForSave.contact = order.contact;
                delete orderForSave.orderer;
                delete orderForSave.user;

                var $offers = [];
                _.each(orderForSave.orderedOffers, function (offer) {
                    if ($offers.indexOf(offer.name) == -1) $offers.push(offer.name);
                });

                orderForSave._offers = $offers.join(', ');
                orderForSave._type = order.mode;
                orderForSave.saved = window.GETREALDATE();

                service.getUsersOrders().then(function (arr) {
                    arr.unshift(orderForSave);
                    arr.splice(service.maxSaveBuffer, arr.length);
                    $localStorage[getUserStorageKey()] = arr;

                    service.$storage.savedOrder = orderForSave;
                    service.$storage.orders.unshift(orderForSave);
                    service.$storage.orders.splice(service.maxSaveBuffer, arr.length);

                    deferred.resolve(true);
                });
            }
            return deferred.promise;
		};

		service.getCachedAddresses = function () {
			let cachedAddresses = [];
			try {
				cachedAddresses = $localStorage[service.storageToken_addresses] || [];
			} catch (e) {}

			return cachedAddresses;
		}

		service.setDefaultCachedAddress = function (addresses, address, index) {
			for (let i = 0; i < addresses.length; i++) {
				if (i == index) addresses[i].isDefault = true;
				else addresses[i].isDefault = false;
			}
			addresses = _.cloneDeep(addresses);
			addresses.splice(index, 1);
			addresses.unshift(address);
			$localStorage[service.storageToken_addresses] = addresses;
		}

		service.saveCachedAddresses = function (addresses, address) {
			try {
				if (address && address.formatted_address) {
					address.V = 1;
					var index = _.findIndex(addresses, { formatted_address: address.formatted_address });
					if (index != -1) {
						if (addresses[index].isDefault) address.isDefault = true;
						addresses[index] = address;
					} else {
						addresses.splice(4, addresses.length);
						addresses.push(address);
					}
					$localStorage[service.storageToken_addresses] = addresses;
				}
			} catch (e) { }
			return addresses
		}

        //----------------------------------------------------------------------------------------->
        // 
        //----------------------------------------------------------------------------------------->


        function loadEnterprise() {
            var deferred = $q.defer();
            service.getURLParams().then(function (urlParams) {
                service.$storage.preferredLocal = urlParams.local;

				service.$storage.urlParams = urlParams;
            	var siteId = urlParams.org != null || urlParams.orgName != null ? "53eb1ee2e6c77111203d8501" : urlParams.site;
				service.$storage.defaultSiteId = siteId;

				if (urlParams.hosted) {
					$("body").addClass("cordova-hosted");
					service.$storage.isAppHosted = true;
					return loadFromExteranl(urlParams, deferred);
				}
				if (urlParams.opened) {
					return openFromExteranl(urlParams, deferred);
				}

				return LoyaltyService.restoreSignIn(urlParams.org != null).then(function(){
					if (urlParams.oid) loadFromOrder(urlParams, deferred);
					else if (urlParams.site) loadFromSite(urlParams, deferred);
                    else loadFromChain(urlParams, deferred);                
                });
            }).catch(function(err){
                deferred.reject(err);
            })
            return deferred.promise;
        };

	function loadFromOrder(urlParams, deferred) {
		const orderId = urlParams.oid;
		let url = `online-shopper/bills?`
		if (orderId.length === 6) url = `${url}shortId=${orderId.toUpperCase()}`; //params.shortId = orderId.toUpperCase();
		else url = `${url}orderId=${orderId}`; //params.orderId = orderId;

		ServiceAgent.get({ url: url }, true).then(function (config) {
			let order = _.get(config, '[0].order');
			if (order) return _loadSiteConfig().then(() => {
				let site = _.get(service.$storage, 'organization.branches[0]');
				_.set(site, 'settings.enableOrderDelay', false);
				if (!site.tabitpay || !site.tabitpay.enableAddItems) throw ({});
				service.$storage.$tpOrder = _.pick(order, ["_id", "orderer", "organization", "orderType", "serviceType"]);

				deferred.resolve();
			}).catch(err => {
				deferred.reject();
			});
		}).catch(err => {
			deferred.reject();
		})

		function _loadSiteConfig() {
			let _deferred = $q.defer();
			loadFromSite(urlParams, _deferred);
			return _deferred.promise;
		}
	}

	service.addItemsToTPOrder = function () {
		blockUI.start($translate.instant("CREATING_ORDER"));
		let args = {}
		let clientOrder = service.$storage.order;
		if (!clientOrder.contact) {
			clientOrder.contact = {}
		}
		const parsedOrder = ServiceCommon.getPreparedOrder(false, args);
		let payload = {
			orderedOffers: parsedOrder.orderedOffers,
			orderedItems: []
		}
		payload.orderedOffers.forEach(oo => {
			if (oo.orderedItems) {
				oo.orderedItems.forEach(oi => {
					oi._id = ServiceCommon.generateMongoObjectId();
					oi.offer = oo.offer;
					oi.menu = oo.menu;
					payload.orderedItems.push(oi);
				});
				oo.orderedItems = oo.orderedItems.map(oooi => oooi._id);
			}
		});
		return ServiceAgent.create({
			url: `online-shopper/orders/${service.$storage.$tpOrder._id}/order`,
			data: payload,
			loadingCaption: 'CREATING_ORDER'
		}).then(function (order) {
			returnToTabitPay();
			return order;
		}).catch(function (err) {
			let message;
			let data = _.get(err, 'data.data', {});
			if (data.name === "ItemInventoryOutOfStock") {
				let minStock = service.$storage.rosConfig.outOfStockWhenUnder;
				let items = data.inventory.map(o => {
					let name = (service.$storage.catalog.items.find(i => i._id === o.item) || {}).name;
					let required = Math.max(payload.orderedItems.filter(oi => oi.item == o.item).length, 1);
					let qty = required - Math.max(o.quantity - minStock, 0);
					return `[ ${qty} x ${name} ]`;
				}).join('\n');
				message = $translate.instant('MESSAGES.OUT_OF_STOCK', { items: items });
			}
			if (!message) message = $translate.instant("MESSAGES.SERVER_ERROR");
			
			PDialog.error({ text: message });
			throw err;
		}).finally(function () {
			blockUI.stop();
		});
	}
	
	function returnToTabitPay() {
		MetaService.resetStorage(true);
	}
	service.returnToTabitPay = returnToTabitPay;


	function openFromExteranl(urlParams, deferred) {
		window.EXAUTH = {
			token_type: 'Bearer',
			access_token: urlParams.to
		}

		return service.retrieveCurrentCustomerInformation()
			.then(function () {
				loadFromSite(urlParams, deferred);
			})
			.catch(function (err) {
				service.$storage.user = null;
				deferred.reject();
			});
	}
	function loadFromExteranl(urlParams, deferred) {
			if (window.parent) window.parent.postMessage({ source: 'tabit', action: 'loaded' }, "*");
			window.addEventListener("message", function (o) {
                if (_.isObject(o.data) && o.data.source == 'tabit') {
                    console.log('child', o && o.data);
					switch (o.data.action) {
                        case "load":
                            if (service.isLoadingExteranal) return;
                            service.isLoadingExteranal = true;

                            window.EXAUTH = {
                                token_type: 'Bearer',
                                access_token: o.data.token
                            }

                            return service.retrieveCurrentCustomerInformation()
                                .then(function () {
                                    loadFromSite(urlParams, deferred);
                                })
                                .catch(function (err) {
                                    service.$storage.user = null;
                                    service.closeHosted();
                                    deferred.reject();
                                });
                            break;
                    }
                }                
            }, false);
        }

        service.closeHosted = function () {
            MetaService.resetStorage();
        }

        function loadTdSetup(site) {
            var site_id = site;
            
            return service.loadConfigFromRos()
				.then(function (siteSettings) {
					if (siteSettings.redirectToTo2 && ENV.TO2Url && location.search.indexOf("&oid=") == -1) {
						return MetaService.redirectToTo2({ topic: 'site', siteId: site_id})
					}
                    siteSettings._id = site_id;
                    service.$storage.organization = {
                        branches: [siteSettings]
                    };
                    service.$storage.activeTimeslots = service.calculateTimeSlots(siteSettings.timeslots);
                    $rootScope.$broadcast('configLoadedEvent');


                    return service.loadSiteMessages(siteSettings).then(function () {
                        return siteSettings
                    });
                }).catch(err => {
                    return null
                });
        }

        function loadFromSite(urlParams, deferred) {
            var globalServices = ["takeaway", "delivery", "eatin"];
            loadTdSetup(urlParams.site).then(function (reffSite) {
                if (reffSite) {
                    var data = {
                        branches: []
					};
					let showDelayedServiceButton = reffSite.futureOrder2 ? reffSite.futureOrder2.showServiceButton : _.get(reffSite.futureOrder, 'showServiceButton');
					let _delayedServices = [];
					let _services = reffSite._services = [];
					let siteEnableFuture = (() => { let o = _.get(reffSite, 'futureOrder2.modules', 'TD'); return !o || o == 'TD' })();
					_.each(globalServices, function (service) {
						if (reffSite[service].enabled) {// && reffSite[service].active
							let _enabled = reffSite[service].active;
							let _delayedEnabled = siteEnableFuture && reffSite[service].futureActive == true;
							if (_delayedEnabled && showDelayedServiceButton) {
								_delayedServices.push({ id: service });
							}
							let _service = {
								id: service,
								enabled: _enabled,
								delayedEnabled: _delayedEnabled && !showDelayedServiceButton,
								canDelay: _delayedEnabled,
								visible: true
							}
							if (!_enabled && _delayedEnabled && showDelayedServiceButton) {
								_service.visible = false;
							}
							_services.push(_service);
						}
					});

					if (_delayedServices.length) {
						_services.push({
							id: 'delay', members: _delayedServices, visible: true });
					}

					if (reffSite.settings.enableMenuView) {
						_services.push({ id: 'VIEW_MENU', visible: true });
					}

					if (_services.length || true) {
						service.setLocal(reffSite);
						MarkettingService.setMarketting(reffSite);

                        if (!reffSite.region) {
                            reffSite.region = $translate.instant("BRANCHES");
						}
						data._services = _services;
                        data.branches.push(reffSite);
                        data.regions = [reffSite.region];
                        service.$storage.backToSite = reffSite.backToSite;
                        service.$storage.organization = data;
                        service.$storage.enableSelectTable = service.$storage.local !== 'he-IL';
                        if (service.$storage.organization)
                            service.$storage.title = $translate.instant('TITLE', { name: service.$storage.organization.branches[0].name });
                        service.$storage.translations = reffSite.translations;
                        service.$storage.caption = reffSite.name;

                        deferred.resolve();
                    } else {
                        deferred.reject({
                            type: 'ERROR',
                            message: $translate.instant("SERVER_MESSAGES.NO_ACTIVE_SERVICES")
                        });
                    }
                } else {
                    deferred.reject({
                        type: 'ERROR',
                        message: $translate.instant("SERVER_MESSAGES.MISSING_SITE")
                    });
                }
            });
        }
        
        
        function getChain_messages(urlParams, deferred){
            var deferred = $q.defer();
            return deferred.promise;
        }
        
        service.loadSite_ConfigFromRos = function (siteId) {
            if (true) return ServiceAgent.get({ url: "online-shopper/configuration" }, true, null, siteId).then(function (config) {
                service.$storage.region = config.region;
                var siteId = config && config.organization;
                var ret = config && config.config || {};
                if (!ret._id) ret._id = siteId;
                return ret;
            }).catch(function (err) {
                console.log('failed to get configuration from ros', err);
            })
            
            return firebase.database().ref('/td/td_setup/' + siteId).once('value').then(function(snapshot){
                var val = snapshot.val();
                return val || null;
            }).catch(function(err){
                return null;
            });
        };

	function loadFromChain(urlParams, deferred, _ORGID) {
		var globalServices = ["takeaway", "delivery", "eatin"];

		let sURL = urlParams.orgName ? `${ENV.tabitBridge}/configuration/chains/get-by-name/${urlParams.orgName}` : `${ENV.tabitBridge}/configuration/chains/${urlParams.org}`;
		$.get(sURL, function (chain) {
			if (chain.disabled) return loadFromChain_error({ type: 'CHAIN_DISABLED', message: $translate.instant("SERVER_MESSAGES.CHAIN_DISABLED") }, deferred);
			let reffSites = _.map(chain.sites, site => {
				let config = site.config;
				let siteId = site._id;
				service.$storage.region = config.region;
				//var siteId = config && config.organization;
				let ret = config && config.config || {};
				ret.takeawayToBeSentAs = config.takeawayToBeSentAs || 'TD';
				if (!ret._id) ret._id = siteId;
				return ret;
			});
			if (chain.redirectToTo2 && ENV.TO2Url) {
				return MetaService.redirectToTo2({ topic: 'chain', chainId: chain.id })
			}


			var sites = [];
			var chainRegions = [];
			var messages = [];

			let showDelayedServiceButton = reffSites[0].futureOrder2 ? reffSites[0].futureOrder2.showServiceButton : _.get(reffSites[0].futureOrder, 'showServiceButton');
			var chainServices = [];

			_.each(reffSites, reffSite => {
				if (!reffSite || reffSite.disabled) return true;
				let siteEnableFuture = (() => { let o = _.get(reffSite, 'futureOrder2.modules', 'TD'); return !o || o == 'TD' })();
				//if (!reffSite.futureOrder) reffSite.futureOrder = {};
				let _services = reffSite._services = [], _delayedServices = [];
				_.each(globalServices, function (service) {

					if (reffSite[service].enabled) {
						let _enabled = reffSite[service].active;
						let _delayedEnabled = siteEnableFuture && reffSite[service].futureActive == true;
						if (_delayedEnabled && showDelayedServiceButton) {
							_delayedServices.push({ id: service });
						}
						let _service = {
							id: service,
							enabled: _enabled,
							delayedEnabled: _delayedEnabled && !showDelayedServiceButton,
							canDelay: _delayedEnabled,
							visible: true
						}
						if (!_enabled && _delayedEnabled && showDelayedServiceButton) {
							_service.visible = false;
						}
						_services.push(_service);

						//prepare chain ------------------------------>
						let cService = _.find(chainServices, { id: _service.id });
						if (!cService) {
							cService = _.cloneDeep(_service);
							chainServices.push(_service);
						} else {
							_.each(['enabled', 'delayedEnabled', 'canDelay', 'visible'], att => {
								if (_service[att]) cService[att] = true;
							})
						}
					}
				});

				messages = _.concat(messages, _.filter(reffSite.messages, message => {
					if (!message.active || !message.dosplayOnOrgLevel) return false;
					let sch = message.schedule;
					if (sch) {
						let timeSlot = _.find(reffSite.timeslots, { _id: sch });
						if (!timeSlot || !service.isTimeslotsActive(timeSlot)) return false;
					}
					return true;
				}));

				if (_delayedServices.length) {
					_services.push({
						id: 'delay', members: _delayedServices, visible: true
					});

					//prepare chain ------------------------------>
					let cService = _.find(chainServices, { id: 'delay' });
					if (!cService) {
						chainServices.push({
							id: 'delay', members: _delayedServices, visible: true
						});
					} else {
						_.each(_delayedServices, member => {
							if (!_.find(cService.members, { id: member.id })) {
								cService.members.push(member);
							}
						});
					}
				}

				if (reffSite.settings.enableMenuView) {
					_services.push({ id: 'VIEW_MENU', visible: true });
					//prepare chain ------------------------------>
					let cService = _.find(chainServices, { id: 'VIEW_MENU' });
					if (!cService) chainServices.push({ id: 'VIEW_MENU', visible: true });
				}

				if (_services.length) {
					if (!reffSite.region) {
						reffSite.region = $translate.instant("BRANCHES");
					}
					if (chainRegions.indexOf(reffSite.region) == -1) chainRegions.push(reffSite.region);
					sites.push(reffSite);
					deferred.resolve();
				}
			});

			if (!sites.length) {
				deferred.reject({
					type: 'NOSITES',
					message: $translate.instant("SERVER_MESSAGES.NO_SITES_IN_CHAIN")
				});
			} else if (!chainServices.length) {
				deferred.reject({
					type: 'NOSITES',
					message: $translate.instant("SERVER_MESSAGES.NO_ACTIVE_SITES")
				});
			} else {
				if (!chain.brandSkin && !chain.brand) {
					chain.brandSkin = sites[0].brandSkin;
					chain.brand = sites[0].brand;
				}
				service.setRegionCong(sites[0]);
				MarkettingService.setMarketting(sites[0], chain.marketing);

				chain._services = chainServices;
				chain.isChain = sites.length > 1;
				chain.branches = sites;
				chain.regions = chainRegions;

				service.$storage.organization = chain;
				service.$storage.isOrganization = true;
				if (service.$storage.organization)
					service.$storage.title = $translate.instant('TITLE', { name: service.$storage.organization.name });
				service.setLocal(chain);
				service.$storage.caption = chain.name;
				service.$storage.messsages = messages;
			}


		}, 'json').fail(function () {
			return loadFromChain_error({ type: 'MISSING_CHAIN', message: $translate.instant("SERVER_MESSAGES.MISSING_CHAIN") }, deferred)
		});

		function loadFromChain_error(err, deferred) {
			console.log(err);
			deferred.reject(err);
		}
	}

        function loadCatalog() {
            var deferred = $q.defer();
			var _storage = service.$storage;
			var branch = _storage.order.branch;
			
			let clubMethod = _.get(branch, 'settings.clubMethod', null);
			let promises = {
				catalog: ServiceAgent.get({ url: "online-shopper/tdcatalogs/" + branch._id })
			}

			if (clubMethod) {
				let excludedClubServices = _.get(branch, 'settings.excludeLoyaltyServices', []);
				if (excludedClubServices.indexOf(_storage.order.mode) == -1) {
					promises.clubs = _storage.$tpOrder ? $q.resolve([]) : ServiceAgent.get({ url: "online-shopper/clubs/?loyaltyProvider=5cff99288dc6d4e785abc0bf" }).then(res => {
						return _.filter(res, club => {
							//if (ENV.isDev && club._id != '5fa3a34e676b7680a82c5f3c') return false;
							if (club.accountGuid != null) {

								club.clubMethod = clubMethod;
								switch (clubMethod) {
									case "voucher":
										club.greeting = '_LOYALTY.voucher_gtreeting';
										club.inlineGreeting = '_LOYALTY.voucher_inline_gtreeting';
										club.greetingInfo = '_LOYALTY.voucher_gtreeting_info';
										club.placeholder = '_LOYALTY.voucher_placeholder';
										club.benefitsTitle = '_LOYALTY.voucher_benefits_title';
										club.signinError = '_LOYALTY.voucher_signin_error';
										break;
									case "club":
										club.greeting = '_LOYALTY.club_gtreeting';
										club.inlineGreeting = '_LOYALTY.club_inline_gtreeting';
										club.greetingInfo = '_LOYALTY.club_gtreeting_info';
										club.placeholder = '_LOYALTY.club_placeholder';
										club.benefitsTitle = '_LOYALTY.club_benefits_title';
										club.signinError = '_LOYALTY.club_signin_error';
										break;
								}
								if (_storage.loyaltyHeaderCaption) {
									club.greeting = _storage.loyaltyHeaderCaption;
									club.inlineGreeting = _storage.loyaltyHeaderCaption;
								}
								if (_storage.loyaltyHeaderSubCaption) {
									club.greetingInfo = _storage.loyaltyHeaderSubCaption;
								}
								if (_storage.loyaltyFieldCaption) {
									club.placeholder = _storage.loyaltyFieldCaption;
								}
								let disableReg = _.get(club, 'providerData.systemBlockedForNewCustomers');
								if (disableReg && disableReg.indexOf("82C4F1A1-9F1F-43F2-A42B-26F3C885734C") != -1) {
									club.disableRegistration = true;
								}

								return true;
							}
						})
					}).catch(err => null);
				}				
			}

			$q.all(promises).then(res => {
				if (res.clubs && res.clubs[0]) {
					_storage.loyaltyClubs = res.clubs;
					_storage.loyaltyClub = res.clubs[0];
				}

				let catalog = res.catalog;
                if (catalog){
                    _storage.settings = branch.settings || {};
                    _loadCatalog_success({
                        catalog:catalog,
                        messages: branch.messages
                    });
                }else{
                    _loadCatalog_fail({
                        type: 'ERROR',
                        message: $translate.instant("SERVER_MESSAGES.MISSING_CATALOG")
                    });
                }                
            }).catch(function (err) {
                console.log(err);
                _loadCatalog_fail({
                    type: 'ERROR',
                    message: $translate.instant("SERVER_MESSAGES.ERROR_LOADING_CATALOG")
                });
            })
			return deferred.promise;

			function fixCatalogRegressions(config) {
				//fix fratuity, in us deliveryGratuity used to affect all services
				const region = service.$storage.region;
				let deliveryGratuity = _.get(config, 'settings.enableDeliveryGratuity');
				if (!_.isUndefined(deliveryGratuity)) {
					delete config.settings.enableDeliveryGratuity;
					_.set(config.delivery, 'enableGratuity', deliveryGratuity);
					switch (region) {
						case "US":
							_.set(config.takeaway, 'enableGratuity', deliveryGratuity);
							_.set(config.eatin, 'enableGratuity', deliveryGratuity);
							break;
					}
				}
				//set tip option in US if not exist
				if (region == 'US' && !config.takeaway.tipOptions) {
					const tipOptions = {
						values: [10, 15, 20],
						valueType: "percent",
						defaultValue: 15
					}
					_.each(['takeaway', 'delivery', 'eatin'], opt => {
						let service = config[opt];
						service.tipOptions = _.cloneDeep(tipOptions);
					})
				}

				if (region == 'US' && _.get(config, 'settings.leaveDeliveryOutside') == undefined) {
					_.set(config, 'settings.leaveDeliveryOutside', true);
				}
			}

            function _loadCatalog_success(ret) {
                _storage.catalogLoaded = true;
				var site = _storage.order.branch;
				fixCatalogRegressions(site);
                if (!site.settings)site.settings = {};

                var catalogTrans = _storage.catalogTrans;

                if (catalogTrans) {
                    //translate catalog
                    site.name = _.get(site, `translations.${catalogTrans}.name`, site.name);
                    site.address = _.get(site, `translations.${catalogTrans}.address`, site.address);

                    let arr = ["offers", "items", "itemGroups", "modifierGroups", "view"];
                    _.each(arr, attList => {
                        _.each(ret.catalog[attList], member => {
                            if (member.translations) {
                                let trans = member.translations[catalogTrans];
                                if (trans) {
                                    _.each(trans, (val, key) => {
                                        if (key == 'name') {
                                            member.$$translated = true;
                                            member.realName = member[key];
                                        }
                                        member[key] = val;
                                    });
                                }
                            }
                            if (member.modifiers) {
                                _.each(member.modifiers, modifier => {
                                    var trans = modifier.translations && modifier.translations[catalogTrans];
                                    if (trans) {
                                        _.each(trans, (val, key) => {
                                            modifier[key] = val;
                                        });
                                    }
                                });
                            }
                        })
                    });
                }
				_storage.catalog = ret.catalog;
				_storage.referenceTime = service.getOrderReferenceTime();

				if (_storage.order.mode == "VIEW_MENU" && site.settings.menuViewSlot) {
					_storage.activeTimeslots = {}
					_storage.activeTimeslots[site.settings.menuViewSlot] = true;
					_storage.catalog.view = _.filter(_storage.catalog.view, oView => oView.schedule == site.settings.menuViewSlot);
				} else {
					_storage.activeTimeslots = service.calculateTimeSlots(site.timeslots, _.get(service.$storage, 'order.orderDelay.moment',_storage.referenceTime), _storage.allDayReffTime);
				}
                
                var arr = [];
				let isODelay = _storage.order.forceDelay;
                _.each(ret.messages, function (message) {
                    if (message.active) {
                        if (message.placement == "inMenu" && catalogTrans) {
                            var _message = message.message;
                            message.message = _.get(message, `translations.${catalogTrans}.message`, _message);
						}
						switch (message.orderTime) {
							case "sameDay": if (isODelay) return; break;
							case "future":
								if (!isODelay) return;
								//delete message.schedule
								break;
						}
						if (!message.schedule || _storage.activeTimeslots[message.schedule] !== false) {
							arr.push(message);
						}                        
                    };
				});

                _storage.messsages = arr;
				_storage.order.TTPromotion = _storage.$tpOrder ? null : getSiteTTPromotion(site);

                var mode = _storage.order.mode;
                var orderSetup = site[mode] || {};

				_storage.enableGratuity = orderSetup.enableGratuity;

                //fix preparation time
                if (!_.get(site,'takeaway.$$preparationTimeFixed')){
                    _.set(site,'takeaway.$$preparationTimeFixed', true);
                    var prepTime = _.get(site,'takeaway.preparationTime',0);
                    prepTime = isNaN(prepTime) ? 0 : Number(prepTime);
                    var prepTime_add = _.get(site,'timesOffsetSetup.prepTimeOffset',0);
                    prepTime_add = isNaN(prepTime_add) ? 0 : Number(prepTime_add);
                    _.set(site,'takeaway.preparationTime',prepTime + prepTime_add);
                }

				_storage.enableGratuityCreditOnly = _.get(site, 'settings.enableGratuityCreditOnly', false);
				if (!_storage.delayedOrder) _storage.delayedOrder = {};

                var minOrderDelay = mode == "takeaway" ? orderSetup.preparationTime : (_storage.order.region && _storage.order.region.deliveryTime  || orderSetup.deliveryTime);
                _storage.minOrderDelay = isNaN(minOrderDelay) ? 30 : Number(minOrderDelay);

				if (_storage.order.forceDelay) {

				} 			

				service.setOrderTimeOffsets(site);

				//weight system
				let weightSystem = _storage.catalog.weightSystem;
				if (weightSystem) {
					let weightMatrix = [
						{ base: "kg", use: "kg", ratio: 1, increment: 0.5, default: 0.5, decimals: 1, min: 0.5, round: 0.1 },
						{ base: "kg", use: "gr", ratio: 1000, increment: 100, default: 100, decimals: 0, min: 100, round: 50, checkMin: true },
						{ base: "gr", use: "kg", ratio: 0.001, increment: 0.1, default: 0.1, decimals: 2, min: 0.05, round: 0.05 },
						{ base: "lb", use: "lb", ratio: 1, increment: 0.5, default: 0.5, decimals: 1, min: 0.5, round: 0.1 },
						{ base: "lb", use: "oz", ratio: 16, increment: 1, default: 1, decimals: 0, min: 1, round: 0.1 },
						{ base: "oz", use: "lb", ratio: 0.0625, increment: 0.0625, default: 0.0625, decimals: 3, min: 0.0625, round: 0.0625 },
						{ base: "l", use: "ml", ratio: 1000, increment: 100, default: 100, decimals: 0, min: 50, round: 50 },
						{ base: "ml", use: "l", ratio: 0.001, increment: 0.1, default: 0.1, decimals: 2, min: 0.05, round: 0.05 },
					];
					let weightParams = _storage.weightParams = _.find(weightMatrix, { base: weightSystem.basicUom, use: weightSystem.defaultUom });
					if (!weightParams) {
						console.error("Missing Weight Parameters");
					} else {
						weightParams.baseTxt = $translate.instant(`_WEIGHT.${weightParams.base}`);
						weightParams.useTxt = $translate.instant(`_WEIGHT.${weightParams.use}`);
					}
				}

                // remove disabled top view elements
                _.remove(_storage.catalog.view, function (cat) {
                    return cat.disabled;
                });

				let pms = _.get(branch, 'payments.paymentMethods', {}), pmTypes = {};
				let giftCardAccounts = [], brainTreeAccount;

				branch.paymentMethods = _.filter(branch.paymentAccounts, function (pa) {
					let pm = _.find(pms, { accountId: pa._id }) || {};
					if (!pa.services) pa.services = [];
					if (pa.paymentType == "10bis" && !site.settings.enableTenbis) return false;
                    if (_storage.order.forceDelay && !pm.delayedOrderActive) return false;
                    if (!_storage.order.forceDelay && !pa.tabitOrderActive) return false;
					if (!pa.services.length || pa.services.indexOf(mode) != -1) {
						if (catalogTrans) {
							if (pa.paymentType) pa.name = $translate.instant(pa.paymentType);
						}
						if (pa.name) {
							if (catalogTrans) {
								pa.name = _.get(pa, `translations.${catalogTrans}.name`, pa.name);
							}
						}

						if (pa.paymentType == 'giftCard') {
							giftCardAccounts.push(pa);
							return false;
						}

						if (pa.paymentType == "Braintree") {
							if (brainTreeAccount) return false;
							pa.paymentType = "Braintree";
							brainTreeAccount = pa;
						}

						if (pa.paymentType == "CreditGuard") {
							pa.$$isCreditGuard = true;
							pa.realPaymentType = "CreditGuard";
							if (!site.settings.showCreditGuardWindow) {
								pa.paymentType = "creditCard";
							}
						}

						if (pa.paymentType == 'LeumiPay') {
							pa.name = "PAY.";
							pa.isSpecial = true;
							pa.isExternal = true;
							pa.cssClass = 'leumi-pay-btn';
						}

						if (pa.paymentType == 'Meshulam') {
							pa.isExternal = true;
							pa.cssClass = 'bit-pay-btn';
							return false;//temp disable bit payments
						}

						if (pa.paymentType == 'CardPoint') {
							if (!pa.iframeUrl) {
								console.error("Missing cardpoint iframeUr")
								//pa.iframeUr = "https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html";
								return false;
							}
						}
						pmTypes[pa.paymentType] = true;
						return true;
					}
				});
				_storage.pmAccountTypes = pmTypes;

				if (brainTreeAccount) {
					_storage.hasBraintreeAccount = true;
				}

				if (giftCardAccounts.length) {
					_storage.giftCardAccounts = giftCardAccounts;
				}
				MetaService.initOrderExternalResources();

                _storage.created = window.GETREALDATE();
                _storage.started = window.GETREALDATE();

                MetaService.startOrderTimer();
                _storage.orderValid = true;
                deferred.resolve();
            };

            function _loadCatalog_fail(err) {
                // on error you need to return error type + translate message
                deferred.reject(err);
            }
        }

		function prepareExtraOffers() {
			let _storage = service.$storage;
			//let mode = _storage.order.mode;
			//if (mode == "eatin") return;
			let catalog = _storage.catalog;
			let inventory = catalog.inventory;
			let catalogTrans = _storage.catalogTrans;

			//extra questions ------------------------------------>
			if (_.get(catalog, 'extraQuestions[0]')) {
				_.remove(catalog.extraQuestions, (question) => {
					let _offer = question.offer;
					if (_offer) {
						if (!_offer.active || !checkExtraOfferInventory(_offer)) return true;
					}
					if (!question.enableSelection) {
						question.requiereSelection = false;
						question.active = true;
						question.disabled = true;
					}
					question.displayName = question.name;
					if (catalogTrans) {
						question.displayName = _.get(question, `translations.${catalogTrans}.name`, question.displayName);
					}
				});
				if (catalog.extraQuestions.length) {
					_storage.extraQuestions = catalog.extraQuestions;
				}
			}

			//single extra offers ------------------------------------>
			if (_.get(catalog, 'extraOffers[0]')) {
				_storage.extraOffersMethod = "single";
				let extraOffers = [];
				_.each(catalog.extraOffers, (_offer) => {
					if (!_offer.active || !checkExtraOfferInventory(_offer)) return;
					if (!_offer.maxCount) _offer.maxCount = 5;

					if (_offer.menus) {
						if (!_offer.subGroups) _offer.subGroups = [];
						_offer.subGroups.unshift({
							menus: _offer.menus,
							menuMultiplier: _offer.menuMultiplier
						});
						delete _offer.menus;
						delete _offer.menuMultiplier;
					}

					_offer.count = 0;
					_offer.displayName = _offer.name;
					if (_offer.customName && _offer.customName.length) {
						_offer.displayName = _offer.customName;
					}
					if (catalogTrans) {
						_offer.displayName = _.get(_offer, `translations.${catalogTrans}.name`, _offer.displayName);
					}

					if (_offer.maxCount && _offer.maxCount > 1) {
						_storage.extraOffersMethod = "multi";
					} else {
						_offer.maxCount == 1;
					}
					extraOffers.push(_offer);
				});
				if (extraOffers.length) {
					_storage.extraOffers = extraOffers;
				}
			}
			//multi extra offers ------------------------------------>
			if (_.get(catalog, 'extraOffersGroups[0]')) {
				let groups = [];
				_.each(catalog.extraOffersGroups, (group, index) => {
					if (!_.get(group, 'offers[0]')) return;
					_.remove(group.offers, (_offer) => {
						if (!_offer.active || !checkExtraOfferInventory(_offer)) return true;
						_offer.count = 0;
						_offer.displayName = _offer.name;
						if (_offer.customName && _offer.customName.length) {
							_offer.displayName = _offer.customName;
						}
						if (catalogTrans) {
							_offer.displayName = _.get(_offer, `translations.${catalogTrans}.name`, _offer.displayName);
						}
					});
					if (group.offers.length) {
						group._id = `A${index}`;
						group.count = 0;
						if (!group.maxCount) group.maxCount = 5;
						if (catalogTrans) {
							group.name = _.get(group, `translations.${catalogTrans}.name`, group.name);
						}
						groups.push(group);
					}
				});
				if (groups.length) {
					_storage.extraOffersGroups = groups;
				}
			}

			function checkExtraOfferInventory(offer) {
				let _ret = true;
				_.each(offer.orderedItems, item => {
					if (inventory[item.item]) {
						_ret = false;
						return false;
					}
				});
				return _ret;
			}

		}

        function getSiteTTPromotion(site) {

			let isODelay = service.$storage.order.forceDelay, orderMode = service.$storage.order.mode;
            if (site.promotions) {
                for (var i = 0; i < site.promotions.length; i++) {
                    var pr = site.promotions[i];
                    var sr = pr.services || [];

					if (pr.disabled) continue;
					if (pr.type != 'totalTicket') continue;
					switch (pr.orderTime) {
						case "sameDay": if (isODelay) continue; break;
						case "future": if (!isODelay) continue; break;
					}

					if (pr.schedule) {
						let timeSlot = _.find(service.$storage.order.branch.timeslots, { _id: pr.schedule });
						if (timeSlot && !service.isTimeslotsActive(timeSlot, service.$storage.referenceTime)) continue;
					}
					if (sr.length && sr.indexOf(orderMode) == -1) continue;
					if (orderMode == 'delivery' && pr.deliveryRegion) {
						if (_.get(service.$storage.order, "region.id") != pr.deliveryRegion) continue;
					}

                    return translateRow(pr);

                }
            }
        }

        function translateRow(member) {
            var catalogTrans = service.$storage.catalogTrans;
            if (catalogTrans) {
                if (member.translations) {
                    let trans = member.translations[catalogTrans];
                    if (trans) {
                        _.each(trans, (val, key) => {
                            member[key] = val;
                        });
                    }
                }
            }
            return member;
        }

        //----------------------------------------------------------------------------------------->
        // offer summary utilities
        //----------------------------------------------------------------------------------------->
        
        service.prepareOfferSummary = function (offer, prepareItems) {
            if (prepareItems) service.prepareItemSummary(offer._item);
            var selectionSummary = [];
            _.each(offer._offer.selectionGroups, function (sG) {
				let arr = [];
				_.each(sG.items, function (item) {
					if (item._selected) {
						that.prepareItemSummary(item._item);
						arr.push(item);
					}
				});
				if (arr.length) {
					selectionSummary.push({
						_id: sG._id,
						name: sG.name,
						items: arr,
						_visible: sG._visible !== false
					});
				}
            });
            delete offer.selectionSummary;
            if (selectionSummary.length) offer.selectionSummary = selectionSummary;
        }
        service.prepareItemSummary = function (item) {
			if (!item) return;

			if (item.$summary) {
				item._summary = { text: item.$summary }
				return;
			}

            var _decisions = [], _with = [], _without = [];
            var stWithout = $translate.instant("MOD_WITHOUT");
            _.each(item.modifierGroups, function (group) {
                
                if (group.singleSelection) {
                    var mod = _.find(group.modifiers, { '_selected': true });
                    if (mod && !mod._isDefault) _decisions.push(group.name + ": " + mod.name);
                } else {
                    _.each(group.modifiers, function (mod) {
                        if (mod.formationUnit) {
                            var modGroups = generateModifierGroupsSummary(mod);
                            if (mod.formationUnit != mod.defaultFormationUnit || modGroups) {
                                var modVal;
                                if (mod.price) {
                                    modVal = mod.name + " " + $filter('money')(mod.price);
                                } else {
                                    modVal = mod.name;
                                }
                                if (modGroups) modVal += (" " + modGroups);
                                _with.push(modVal);
                            }
                        } else if (mod.defaultFormationUnit) {
                            _without.push(stWithout + " " + mod.name);
                        }
                    });
                }
            });
            var verbs = _decisions.concat(_with, _without);

            delete item._summary;
            if (verbs.length) {
                item._summary = { text: verbs.join(", ") }
            }

            function generateModifierGroupsSummary(mod) {
                if (!mod.groups) return;
                var modVerbs = [];
                _.each(mod.groups, function (group) {
                    if (group.value && group.value != group._default) {
                        //modVerbs.push(group.name + ": " + group.valueName);
                        modVerbs.push(group.valueName);
                    }
                });
                if (modVerbs.length) {
                    return "[" + modVerbs.join(",") + "]";
                }
            }
        }

        //----------------------------------------------------------------------------------------->
        // pre create order
        //----------------------------------------------------------------------------------------->
        
        service.clearPreCreatedOrder = function(){
			delete service.$storage.order.tax;
			delete service.$storage.order.fees;
            delete service.$storage.preCreatedOrder;
            service.calculateBasketTotal();
        }
        service.preCreateOrder = function(args, reset){
			if (!reset) {
				if (!service.$storage.requireTax || service.$storage.preCreatedOrder) {
					return $q.resolve()
				}
			}
			if (!args) args = {}
			args = _.extend(args, service.getSupplyTime());

            blockUI.start();
            var parsedOrder = ServiceCommon.getPreparedOrder(false, args);
            return ServiceAgent.create({
				url: "online-shopper/orders?virtual=true ",
                    data: parsedOrder,
                    loadingCaption: 'CREATING_ORDER'
                }).then(function(order){
					service.$storage.order.tax = _.get(order, 'totals.totalExcludedTax', 0) / 100;
					service.$storage.order.fees = _.get(order, 'totals.totalFees', 0) / 100;
					MetaService.logTemplate("taxCalculated", {}, "tax Calculated");
                    service.calculateBasketTotal();
                    service.$storage.preCreatedOrder = order;
                    return order;
				}).catch(function (err) {
					let message = $translate.instant("MESSAGES.SERVER_ERROR");
					let _err = OrderService.formatOrderError(err);
					if (_err.type && _err.message) message = _err.message;
					PDialog.error({ text: message });
					throw err;
                }).finally(function(){
                    blockUI.stop();
                });
        }

        //----------------------------------------------------------------------------------------->
        // calculation utilities
        //----------------------------------------------------------------------------------------->

		service.clearLoyaltyPromotions = function () {
			service.$storage.benefitsTotal = 0;
			let data = service.$storage.loyaltyData
			if (data) {
				delete data.reference;
				delete data.hasPoints;
				delete data.pointsUsed;
				delete data.hasPrepaid;
			}
			service.calculateBasketTotal();
		}

        service.calculateBasketTotal = function () {
            var order = service.$storage.order;

            //calculate items total
            var total = 0;
            var q = 0;
            _.each(service.$storage.basket, function (offer) {
                if (!offer.quantity) offer.quantity = 1;
                q += offer.quantity;
                total += (offer.total * offer.quantity);
            });
            var itemsTotal = total;
            service.$storage.order.itemsTotal = itemsTotal;
            
            //calculate total ticket promotion
			service.$storage.order.itemsDiscount = 0;
			let benefitsTotal = service.$storage.benefitsTotal || 0;

			if (order.TTPromotion) {
				var discount = service.calculateTTPromotionFromPercentage(order.TTPromotion, itemsTotal - _.get(service.$storage.loyaltyData, 'totalBenefits', 0));
                if (!discount) discount = 0;
                service.$storage.order.itemsDiscount = discount;
                total -= discount;
            }
            service.$storage.order.total = total;
            //calculate delivery price
            var dp = 0;
            var region = order.region;
            if (region) {
                dp = (region.deliveryPrice) || 0; //dilivery price
                if (dp > 0) {
                    var freeDeliveryFrom = region.freeDeliveryFrom;
                    if (freeDeliveryFrom && itemsTotal >= freeDeliveryFrom) {
                        dp = 0;
                    }
                }
            }
			order.grandTotalCleanBeforeTax = total + dp;

            var tax = order.tax || 0;
			var fees = order.fees || 0;

            order.freeDelivery = dp === 0;
			order.quantity = q;

			order.grandTotalClean = total + dp + tax + fees;

			order.giftCardsTotal = 0;
			_.each(service.$storage.giftCards, card => {
				order.giftCardsTotal += card.amount;
			});

			order.grandTotal = order.grandTotalClean - benefitsTotal;// - order.giftCardsTotal;

			service.fixGratuity();

			order.grandTotalForPay = order.grandTotalPlusTip - order.giftCardsTotal;
        }


        //----------------------------------------------------------------------------------------->
        // general utilities
        //----------------------------------------------------------------------------------------->

        service.showMessageWithPhone = function (translatedMessage, phone, call_translate_key) {
            var deferred = $q.defer();
            if (!call_translate_key) call_translate_key = "CALL_RESTAURANT";
            PDialog.info({
                "text": translatedMessage,
                showCancelButton: true,
                cancelButtonText: $translate.instant("CLOSE"),
                confirmButtonText: $translate.instant(call_translate_key),
            }).then(function () {
                window.location.href = "tel:" + phone;
                //window.open("tel:" + phone);
                deferred.resolve(true);
            }, function () {
                deferred.resolve(false);
            });
            return deferred.promise;
        };

        service.checkForSignature = function (ccinfo) {
            var deferred = $q.defer();
            if (!service.$storage.requiereCreditSign || ccinfo.paymentMethod != 'CREDIT') {
                deferred.resolve();
            } else {
                service.getSignature().then(function (signature) {
                    ccinfo.signature = signature;
                    deferred.resolve();
                });
            }
            ;
            return deferred.promise;
        };

        service.checkForIdCard_split = function(args){
            if (!service.$storage.user)  return $q.resolve();
            var walletPM = _.find(args.splitPayments, function(o){return o._id});
            if (!walletPM)  return $q.resolve();
            return service.checkForIdCard(walletPM);
        }

	service.checkForIdCard = function (ccinfo) {
			if (ccinfo.idCard || !_.get(ccinfo,'$$account.requireIdPhoneTrans') || ccinfo.paymentMethod != 'CREDIT') return $q.resolve();
            var deferred = $q.defer();

            ServiceCommon.getToken({
                title: 'ENTER_IDCARD',
                message: $translate.instant('ENTER_IDCARD_MESSAGE', { card: ccinfo.number }),
                caption: 'ENTER_IDCARD',
				askForSave: true,
				type: 'idCard',
				timeOut: 5
            }).then(function(ret){
                if (ret){
                    ccinfo.idCard = ret.token;
                    ccinfo.updatePaymentInfo = ret.updatePaymentInfo;
                    deferred.resolve();
                }else{
                    deferred.reject();
                };
            }).catch(function(){
                deferred.reject();
            });
            return deferred.promise;
        };

        service.getSignature = function (_args) {
            var args = _.extend({
                title: 'MESSAGES.SIGN_FOR_PAYMENT_CONFIRM',
            }, _args);
            var deferred = $q.defer();
            var modalM = $uibModal.open({
                templateUrl: 'modules/app/modals/modal_signature.html',
                controller: function ($scope, $uibModalInstance, modalParams) {
                    $scope.args = args;
                    $scope.apply = function () {
                        var signature = $scope.accept();
                        if (signature.isEmpty) {
                            PDialog.info({ text: $translate.instant("MESSAGES.SIGNATURE_MANDATORY") });
                        } else {
                            $uibModalInstance.close(signature.dataUrl);
                        }
                    };
                    $scope.cancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                },
                windowClass: "modal-default",
                //size: "sm",
                resolve: {
                    modalParams: function () {
                        return {
                            args: args
                        };
                    }
                }
            });
            modalM.result.then(function (result) {
                deferred.resolve(result);
            }, function () {
                PDialog.info({ text: $translate.instant("MESSAGES.SIGNATURE_MANDATORY") });
                deferred.reject();
            });
            return deferred.promise;
        };

        service.clickTimeout = null;
        service.clickProtection = function () {
            var deferred = $q.defer();
            window.clearTimeout(service.clickTimeout);
            service.clickTimeout = window.setTimeout(function () {
                deferred.resolve();
            }, 10);
            return deferred.promise;
        };

        service.getURLParams = function () {
            var deferred = $q.defer();
			let urlParams = UIUtils.getURLParams();
            if (urlParams.siteName) {
                ServiceAgent.get({
                    url: 'online-shopper/organizations?publicUrlLabel=' + urlParams.siteName + '&includeUnlisted=1'
                }).then(function (orgs) {
                    if (orgs.length)
                        urlParams.site = orgs[0]._id;
                    deferred.resolve(urlParams);
                });
            }
            else
                deferred.resolve(urlParams);

            return deferred.promise;
        }

        service.getKeypadValue = function (_args) {
            var args = _.extend({
                title: 'MESSAGES.SIGN_FOR_PAYMENT_CONFIRM',
            }, _args);
            var deferred = $q.defer();
            var modalM = $uibModal.open({
                templateUrl: 'modules/app/modals/modal_keypad.html',
				controller: function ($scope, $uibModalInstance, modalParams) {
					$scope.args = modalParams;
					$scope.keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, '.', 0, 'back'];
					$scope.wasFirstClick = false;
					$scope.response = args.response || {};

					if (!args.enableDecimals) {
						$scope.keys[9] = null;
					}

					$scope.setAmaountKey = function(key) {
						var newVal = $scope.response.amountText;
						let hasDecimal = newVal.indexOf(".") != -1;
						let precision = hasDecimal && newVal.split(".")[1].length || 0;

						switch (key) {
							case 'back':
								if (!newVal.length) return;
								newVal = newVal.slice(0, -1);
								break;
							case '.':
								if (hasDecimal) return;
								newVal += key;
								break;
							default:
								if (!$scope.wasFirstClick) newVal = key + "";
								else {
									if (precision == 2) return;
									newVal += key;
								}
								break;
						}
						$scope.wasFirstClick = true;
						$scope.response.amountText = newVal;
						delete $scope.response.invalid;
						if (Number(newVal) > $scope.args.maxVal) {
							$scope.response.invalid = true;
						} else {
							$scope.response.amount = Number(newVal);
						}
						$scope.response.wasChanged = true;
					}

                    $scope.apply = function () {
						var ret = $scope.response.wasChanged ? $scope.response : null;
						$uibModalInstance.close(ret);
                    };
                    $scope.cancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                },
                windowClass: "modal-pad-center",
                //size: "sm",
                resolve: {
                    modalParams: args
                }
            });
            modalM.result.then(function (result) {
                deferred.resolve(result);
            });
            return deferred.promise;
        };

        //----------------------------------------------------------------------------------------->
        // load from external backup
        //----------------------------------------------------------------------------------------->
        
        service.loadFromExternalProvider = function (params) {
            service.authoriseExternalPayment(service.$storage.cacheOrder, params).then(function (ret) {
                handlePaySuccess(ret);
            }).catch(function (args) {
                if (_.get(args,'serverError.data.code') == '409121') $state.go("app.checkout.pay");
                else{
                    PDialog.warning({ text: args.message }).then(function () {
                        $state.go("app.checkout.pay");
                    });
                }
                
            }).finally(function(){
				delete service.$storage.cacheOrder;
				blockUI.stop();
            })

            function handlePaySuccess(ret) {
                var _storage = service.$storage;

				var args = service.getSupplyTime();
				if (args.eta) _storage.order.supplyTime = args.eta;
				_storage.orderClosed = true;
                if (_.get(ret,'type') == 'PARTIAL_PAYMENT_ERROR'){
                    _storage.order.haveErrors = true;
                }
                _storage.dEnd = GETREALDATE(true).format();
				$state.go("end");
				blockUI.stop();
            };
        }


        //----------------------------------------------------------------------------------------->
        // timeslots functions
        //----------------------------------------------------------------------------------------->

        service.getWorkDay = function () {
            var md = window.GETREALDATE(true);
            var startThreshhold = 5 * 60;
            var d = md.day();
            var cmd = (md.hours() * 60) + md.minutes();
            if (cmd < startThreshhold) {
                d -= 1;
                if (d < 0) d = 6;
            }
            return d;
        };

        service.calculateTimeSlots = function (timeslots, md, allDayReffTime) {
            var activeSlots = {};
            _.each(timeslots, function (timeslot) {
				var match = service.isTimeslotsActive(timeslot, md, allDayReffTime);
                if (match) activeSlots[timeslot._id] = true;
                else activeSlots[timeslot._id] = false;
            });
            return activeSlots;
        };

		service.isTimeslotsActive = function (timeslot, md, allDayReffTime) {
			if (!md) {
				md = _.get(service.$storage, 'order.orderDelay.moment', _.get(service.$storage, 'realStartTime', GETREALDATE(true)));
			}
			if (!service.isTimeslotRangeActive(timeslot, md)) return false;
            var d = md.day();

            var dayM = 24 * 60;
            var startThreshhold = 5 * 60;
            var cmd = getMinutes(md);
            if (cmd < startThreshhold) {
                d -= 1;
                if (d < 0) d = 6;
                cmd += dayM;
            }

            var day = _.find(timeslot.days, { day: d });
            if (!day.active) return null;
            if (day.type == "allday") return {};
            if (day.type == "default") day = _.find(timeslot.days, { day: -1 });

            day.tslots = [];
            var slotFound;
            for (var i = 0; i < day.slots.length; i++) {
                var slot = day.slots[i];

                if (_.isString(slot.from)) slot.from = moment(slot.from);
                if (_.isString(slot.to)) slot.to = moment(slot.to);

				slot.tfrom = slot.from.format(MetaService.local.timeFormat);
				slot.tto = slot.to.format(MetaService.local.timeFormat);

                slot.mfrom = getMinutes(slot.from);
                if (slot.from.date() == 2) slot.mfrom += dayM;
                slot.mto = getMinutes(slot.to);
                if (slot.to.date() == 2) slot.mto += dayM;

                day.tslots.push(slot.tfrom + " - " + slot.tto);

				if (allDayReffTime || (cmd >= slot.mfrom && cmd < slot.mto)) {
                    slotFound = slot;
                    break;
                }
            }
            return slotFound;
            function getMinutes(m) {
                return (m.hours() * 60) + m.minutes();
            }
        }

		service.isTimeslotRangeActive = function (slot, md) {
			if (!slot.limitToDate || !slot.dateRange) return true;
			if (!md) md = moment();
			if (slot.dateRange.startDate && md.isBefore(slot.dateRange.startDate)) return false;
			if (slot.dateRange.endDate && md.isAfter(moment(slot.dateRange.endDate).endOf('day'))) return false;
			return true;
		}

		service.getOrderReferenceTime = function () {
			var _storage = service.$storage;
			let delayedOrder = _storage.delayedOrder;
			if (service.$storage.order.orderDelay) {
				delayedOrder = service.$storage.order.orderDelay;
			}

			let now = GETREALDATE(true);
			let nowM = (now.hours() * 60) + now.minutes();
			if (delayedOrder && delayedOrder.active) {
				switch (delayedOrder.method) {
					case "today": return moment(delayedOrder.todaySlot.date);
					case "delayed": {
						if (delayedOrder.slot.id == 'ASAP') service.$storage.allDayReffTime = true;
						return moment(delayedOrder.slot.date);
					}
				}
			}
			// normal order ----------------------->

			if (service.$storage.preOrderM) {
				var diff = service.$storage.preOrderM - nowM;
				if (diff > 0) {
					now.add(diff, 'minutes');
				}
			}
			return now;
		}

        service.getSupplyTime = function () {
			var _storage = service.$storage, mode = _storage.order.mode, site = _storage.order.branch;
            var args = {
                clientInitialDate: service.$storage.clientInitialDate,
                orderStartDate:service.$storage.started,
                serverDateDiff: window.SERVERDATEDIF
			}
			let delayedOrder = _storage.delayedOrder;
			let now = GETREALDATE(true);
			let nowM = (now.hours() * 60) + now.minutes();

			if (delayedOrder.active) {
				switch (delayedOrder.method) {
					case "today":
						//cancel preorder fire
						if (delayedOrder.preOrderM && nowM >= delayedOrder.preOrderM) {
							delayedOrder.active = false;
						} else {
							return _.extend(args, {
								delayedOrder: true,
								eta: delayedOrder.todaySlot.date
							})
						}
						break;
					case "delayed":
						return _.extend(args, {
							delayedOrder: true,
							eta: delayedOrder.slot.date,
							etaMax: delayedOrder.slot.dateTo,
							asapOnSupplyDay: delayedOrder.slot.id == "ASAP" ? true : undefined
						})
						break;
				}
            }
			// normal order ----------------------->
			
			if (service.$storage.preOrderM) {
				var diff = service.$storage.preOrderM - nowM;
				if (diff > 0) {
					now.add(diff, 'minutes');
				}
			}

            if (mode == 'delivery') {
                var mdiff = _.get(_storage, "order.region.deliveryTime", 60);
				return _.extend(args, { eta: now.add(mdiff, 'minutes').toDate()});
			} else {//if (mode == 'takeaway')
                var mdiff = _.get(site, `${mode}.preparationTime`, 30);
                return _.extend(args, {eta: now.add(mdiff, 'minutes').toDate()});
            }            
		}

		service.setOrderTimeOffsets = function (site) {
			let _storage = service.$storage;
			if (!site) site = _storage.order.branch;
			_storage.addedTimeOffset = service.calculateOrderThrottling(site) || 0;
		}
		service.calculateOrderThrottling = function(site) {
			let _storage = service.$storage;
			

			if (_storage.order.orderDelay || !_.get(site, 'orderThrottling.enabled') || !_.get(site.orderThrottling, 'slots[0]')) return 0;

			let offset = 0;
			switch (service.$storage.order.mode) {
				case "delivery":
					offset = _.get(service.$storage, 'order.region.deliveryTime', 0);
					break;
				case "takeaway":
					offset = _.get(service.$storage, 'order.branch.takeaway.preparationTime', 0);
					break;
			}
			let _now = GETREALDATE(true).add(offset, 'minutes');
			let _day = _now.toDate().getDay();
			let _mmtStart = _now.clone().startOf('day');
			let _nowM = _now.diff(_mmtStart, 'minutes');
			let slot = _.find(site.orderThrottling.slots, slot => {
				if (slot.days.indexOf(_day) != -1 && _nowM >= slot.fromTime && _nowM < slot.toTime) {
					return true;
				}
			});
			if (slot) {
				let _addTime = site.orderThrottling.timeStep;
				if (_addTime) {
					return _addTime;
				}
			}
			return 0;
		}


        //----------------------------------------------------------------------------------------->
        // geo
        //----------------------------------------------------------------------------------------->

		service.getAddresses_alt = function (query) {
			return $q.all({
				g_address: service.placeSearch(query),
				c_address: service.getAddresses_custom(query, false, null),
			}).then(function (ret) {
				if (!ret.c_address) return ret.g_address;
				if (!ret.g_address) return ret.c_address;
				return ret.c_address.concat(ret.g_address);
			})
		};


	var googleSearchService, googleSessionToken, currentPosition,
		wasCurrentPosition = true;

	service.placeSearch = function (term) {
		if (!term || term.length < 5) return $q.resolve([]);
		var deferred = $q.defer();

		if (!wasCurrentPosition) {
			wasCurrentPosition = true;
			try {
				navigator.geolocation.getCurrentPosition(function (location) {
					var lat = location.coords.latitude;
					var lng = location.coords.longitude;
					if (lat && lng) {
						currentPosition = new google.maps.LatLng(lat, lng);
					}
				}, function () { });
			} catch (e) {}
		}
		
		//if (!googleSearchService) googleSearchService = new google.maps.places.AutocompleteService();
		if (!googleSessionToken) {
			//googleSessionToken = new google.maps.places.AutocompleteSessionToken();
			googleSessionToken = new Date().getTime();
			console.log('=== Google Session Token:', googleSessionToken)
		}

		var args = {
			input: term,
			sessionToken: googleSessionToken,
			types: ["address"],
		}
		if (currentPosition) {
			args.location = currentPosition;
			args.radius = 100;
		}
		MetaService.logTemplate("placeAutoComplete", {}, "place Auto Complete");
		let payload = `input=${term}&location=${currentPosition}&language=${service.$storage.gMapLanguage}&types=address&key=${ENV.gMapKey}`;
		if (service.$storage.region != "IL") payload += `&sessiontoken=${googleSessionToken}`;

		$.get(`${ENV.tabitBridge}/maps-google-apis/maps/api/place/autocomplete/json?${payload}`, function(response) {
			var addresses = [];
			if (response && response.status == 'OK' && response.predictions) {
				_.each(response.predictions, function (prediction) {
					//if (prediction.types.indexOf("street_address") != -1) {
						addresses.push({
							description: prediction.description.replace(service.$storage.gSearchReplace, ""),
							label: prediction.structured_formatting.main_text,
							place_id: prediction.place_id,
							isPlace: true
						});
					//}
				});
			}
			deferred.resolve(addresses);
		}, 'json');

		return deferred.promise;
	}

	service.geoCodePlace = function (place) {
		var placeDescription = place.description;
		var deferred = $q.defer();

		let sURL, payload;
		if (service.$storage.region == "IL") {
			payload = `place_id=${place.place_id}&language=${service.$storage.gMapLanguage}&fields=geometry,formatted_address,address_component&key=${ENV.gMapKey}`;
			sURL = `${ENV.tabitBridge}/maps-google-apis/maps/api/geocode/json?${payload}`;
		} else {
			payload = `place_id=${place.place_id}&language=${service.$storage.gMapLanguage}&fields=geometry,formatted_address,address_component&key=${ENV.gMapKey}&sessiontoken=${googleSessionToken}`;
			sURL = `${ENV.tabitBridge}/maps-google-apis/maps/api/place/details/json?${payload}`;
		}

		$.get(sURL, function (response) {
			if (!response || response.status != 'OK') {
				deferred.reject();
				return;
			}
			let place = response.result || response.results[0];
			place.formatted_address = place.formatted_address.replace(service.$storage.gSearchReplace, "");
			var address = { formatted_address: UIUtils.fixSpecialChars(place.formatted_address) };
			_.each(place.address_components, function (comp) {
				let val = UIUtils.fixSpecialChars(comp.long_name);
				switch (comp.types[0]) {
					case "street_number":
						address.streetNumber = val;
						break;
					case "route":
						address.street = val;
						break;
					case "locality":
						address.locality = val;
						break;
					case "postal_code":
						address.postalCode = val;
						break;
					case "administrative_area_level_1":
						address.state = comp.short_name;
						break;
				}
			});
			address.point = {
				lat: place.geometry.location.lat,
				lng: place.geometry.location.lng
			}
			let _url = `https://maps.google.com/?q=${address.point.lat},${address.point.lng}`;

			if (!address.streetNumber) {
				var matches = placeDescription.match(/(\d+)/);
				if (address.street && matches && matches[0]) {
					address.streetNumber = matches[0];
					address.formatted_address = `${address.street} ${address.streetNumber}, ${address.locality}`;
				} else {
					address.partial = true;
				}
			}
			deferred.resolve(address);

		}, 'json');

		return deferred.promise;
	}

        service.getAddresses_custom = function (query, isLocality, locality) {

			if (!window.ADDRESS_CACHE) {
				window.ADDRESS_CACHE = {
					matchHouse: /\d+/g,
					
					partialModel: {
						"force_valid": true,
						"address_components": [
							{
								"types": ["locality", "political"]
							}
						],
						"geometry": {},
						"is_partial_address": true,
					},
					model: {
						"force_valid": true,
						"address_components": [
							{
								"types": ["route"]
							}, {
								"types": ["locality", "political"]
							}
						],
						"geometry": {},
						"partial_match": true,
					},
					houseModel: {
						"types": ["street_number"]
					}
				}
			}

			if (!ENV.tabitBridge) {
				ENV.tabitBridge = "https://il-bridge.tabit-int.com";
				console.error(`missing tabit bridge api, defaulting to https://il-bridge.tabit-int.com`);
				return $q.resolve(null);
			}

			var lquery = locality ? query + " " + locality : query;
			return $http.post(`${ENV.tabitBridge}/configuration/addresses-query`, { query: query }).then(res => {
				if (_.get(res, 'data[0]')) return getAddresses_custom_fromCache(res.data);
			}).catch(err => {
				return null;
			});



            function getAddresses_custom_fromCache(rows) {
                var retArr = [];
                var onlyPartial = lquery.length < 9;
                var matchCount = 0;
                _.each(rows, function (c_address) {
					if (c_address.enablePartialAddress){
                        if (locality) var matchCity = c_address.city == locality; 
						else var matchCity = c_address.city_keys.some(function (rx) {
                            return query.indexOf(rx) !== -1
                        });
                        if (!matchCity) return true;

                        var result = _.cloneDeep(window.ADDRESS_CACHE.partialModel);
                        result.locality = c_address.city;
                        result.geometry.location = c_address.location;
                        result.formatted_address = c_address.formatted_address;
                    }else{
                        if (onlyPartial) return true;
                        if (matchCount > 2) return true;
						if (locality){
							var matchCity = c_address.city == locality; 
							if (!matchCity) return true;

							var matchStreet = c_address.street_keys.some(function (rx) {
								return query.indexOf(rx) !== -1
							});

						}else{
							var matchCity = c_address.city_keys.some(function (rx) {
								return query.indexOf(rx) !== -1
							});
							if (!matchCity) return true;						

							var matchStreet = c_address.street_keys.some(function (rx) {
								return query.indexOf(rx) !== -1
							});
						}
                        
                        if (!matchStreet) return true;
                        ++matchCount;
                        var houseNum = query.match(window.ADDRESS_CACHE.matchHouse);
                        var result = _.cloneDeep(window.ADDRESS_CACHE.model);
                        result.address_components[0].long_name = c_address.street;
                        result.address_components[1].long_name = c_address.city;
                        result.geometry.location = c_address.location;

                        if (getAddresses_custom_checkhouse(c_address, houseNum)){
                            var house_num = houseNum[0];
                            var house_comp = _.cloneDeep(window.ADDRESS_CACHE.houseModel);
                            house_comp.long_name = houseNum[0];
                            result.address_components.unshift(house_comp);
                            result.partial_match = false;
                            result.formatted_address = c_address.pattern.replace("???",house_num);
                        }else{
                            result.formatted_address = c_address.formatted_address;
                        }
                    }
                    retArr.push(result);
                    if (retArr.length > 2) return false;
                });

                return(retArr);
            };

            function getAddresses_custom_checkhouse(c_address, houseNum){
                if (!houseNum || !houseNum[0]) return;
                houseNum = Number(houseNum);
                if (c_address.house_from && houseNum < c_address.house_from) return;
                if (c_address.house_to && houseNum > c_address.house_to) return;
                return true;
            }
        }

        service.goToGeo = function (site) {
            var address = _.get(site, "location.formatted_address");
            if (address){
                var sURL = "http://maps.google.com/?saddr=" + address;
                window.open(sURL);            
            }
        };

        //----------------------------------------------------------------------------------------->
        // utils
        //----------------------------------------------------------------------------------------->

		service.checkMaxOfferCount = function (offer, q) {
			if (!q) q = 0;

			let maxOrderOffers = _.get(service.$storage.settings, 'maxOfferCount');
			let maxCatId = offer.maxCatId;
			let maxCatCount = offer.maxCatCount;
			let maxOffers = offer.maxOfferCount;
			
			let countOrder = q, countCat = q, countOffer = q;
			
			_.each(service.$storage.basket, _offer => {
				let qOP = _offer.quantity ? _offer.quantity : 1;
				countOrder += qOP;
				if (maxCatId && _offer.maxCatId == offer.maxCatId) countCat += qOP;
				if (maxOffers && _offer.offer == offer.offer) countOffer += qOP;
			});

			if (maxOrderOffers && countOrder > maxOrderOffers) {
				PDialog.warning({ "text": $translate.instant("MESSAGES.MAX_ORDER_OFFERS_CONT", { max: maxOrderOffers }) });
				return false;
			}
			if (maxCatId && countCat > maxCatCount) {
				PDialog.warning({ "text": $translate.instant("MESSAGES.MAX_CAT_OFFERS_CONT", { max: maxCatCount, target: offer.maxCatName }) });
				return false;
			}
			if (maxOffers && countOffer > maxOffers) {
				PDialog.warning({ "text": $translate.instant("MESSAGES.MAX_SAME_DISH_COUNT", { max: maxOffers, target: offer.name }) });
				return false;
			}
			return true;
		}

	    service.prepareGroupValidationMessage = function(min, max){
        if (!min && !max){
            return;
        } else if (min && !max ){//Choose at least X items
            var message = min == 1 ? "SELECT_ATLEAST_ONE" : "SELECT_ATLEAST_SOME";
        } else if (!min && max ){//Choose up to X items
            var message = max == 1 ? "SELECT_UPTO_ONE" : "SELECT_UPTO_SOME";
        } else if (min == 1 && max == 1){//Choose 1 item
            var message = "SELECT_ONE"
        } else if (min == max){//Choose X items
            var message = "SELECT_SOME";
        } else if (min == 1 && max > min ){//Choose at least 1 item
            var message = "SELECT_ATLEAST_ONE";
        } else if (min > 1 && max > min){//Choose between X to Y items
            var message = "SELECT_BETWEEN";
        }else{
            return;
        }        
        return $translate.instant("GROUP_VALIDATION." + message, {min:min, max:max})
    }


        //----------------------------------------------------------------------------------------->

	service.checkBeforeCheckout = function () {
			return $q.resolve();
		}

        return service;
    }
);

angular.module('app').factory('CustomerService', function ($q, ENV, blockUI, $translate, $localStorage,
                                                           MetaService, EntityServiceSim, CONSTS, ServiceAgent, ServiceCommon) {

    var __SIMULATED = window.__SIMULATED;
    var service = {
        simService: EntityServiceSim,
        $storage: MetaService.$storage
    };

    angular.extend(service, ServiceAgent);

    service.initResetPass = function (data) {
        if (__SIMULATED) {
            return service.simService.simulate_initResetPass(data)
        } //simulation
        else {

            var deferred = $q.defer();
            //serialize url parameters to object
            var urlParams = {};
            var sSearch = location.search.substr(1).split("&");
            _.each(sSearch, function (ss) {
                var _ss = ss.split("=");
                urlParams[_ss[0]] = _ss[1];
            });


            //if local exists as parameter set the local according to the parameters
            var _local = urlParams.local;
            var _brand = urlParams.brand;

            if (_local || _brand) {
                MetaService.setLocal(_local, _brand);
            }

            console.log('initResetPass', urlParams);

            if (!urlParams.token) {
                deferred.reject($translate.instant("SOME VALIDATION MESSAGE (MISSING TOKEN)"));
            } else {
                blockUI.start();
                window.setTimeout(function () {
                    ServiceAgent.get({ url: 'online-shopper/customers/' + urlParams.token }).then(function (ret) {
                        blockUI.stop();

                        //you will get back to the "resetPass" what ever you return here + the new password
                        deferred.resolve({
                            token: urlParams.token,
                            local: urlParams.local,
                            brand: urlParams.brand,
                            site: urlParams.site,
                            email: ret.email
                        });
                    }, function (err) {
                        blockUI.stop();
                        console.log(err);
                        deferred.reject($translate.instant("YOUR ERROR RESPONSE MESSAGE HERE"));
                    })
                }, 1500);
            }
            return deferred.promise;

        }
    };

    service.resetPass = function (data) {
        if (__SIMULATED) {
            return service.simService.simulate_resetPass(data)
        }
        else {
            console.log('resetPass', data);

            var deferred = $q.defer();
            blockUI.start();
            window.setTimeout(function () {
                if (data.password !== data.confirmpass) {
                    deferred.reject({ type: 'G', message: $translate.instant("YOUR ERROR RESPONSE MESSAGE HERE") });
                } else {
                    var dToken = decodeURIComponent(data.token);
                    ServiceAgent.update({
                        url: 'online-shopper/customers/' + data.email + '/password',
                        data: { token: dToken, newPassword: data.password }
                    }).then(function () {
                        blockUI.stop();
                        var resolvePayload = {
                            message: $translate.instant("MESSAGES.sPASSWORD_RESET_SUCCESS"),
                            redirect: '/?site=' + data.site
                        };
                        deferred.resolve(resolvePayload);
                    }, function (err) {
                        blockUI.stop();
                        console.log(err);
                        // on error you need to return error type + translate message
                        // supported types:
                        // 'C': critical error : process will be terminated
                        // 'G': user will be alerted
                        deferred.reject({ type: 'G', message: $translate.instant("YOUR ERROR RESPONSE MESSAGE HERE") });
                    })
                }
            }, 1000);
            return deferred.promise;
        }
    };

    service.signIn = function (data) {
		var deferred = $q.defer();
		var errorInfo = {};
		var cred = { username: data.email, password: data.password, rememberMe: data.rememberMe };
		ServiceAgent.getEndpoint().then(function () {
			return ServiceAgent.authenticateWithRos(ENV.apiEndpoint, errorInfo, cred)
				.then(function (auth) {
					if (errorInfo.data)
						throw {
							type: 'ERROR',
							message: $translate.instant("SERVER_MESSAGES.INVALID_USER_ORPASS")
						};
					service.$storage.user = { auth: auth };
					return service.retrieveCurrentCustomerInformation(data.rememberMe);
				})
		}).then(function () {
			deferred.resolve();
		}).catch(function (err) {
			if (err && err.type)
				deferred.reject(err);
			else
				deferred.reject({
					type: 'ERROR',
					message: $translate.instant("SERVER_MESSAGES.INVALID_USER_ORPASS")
				});
		});
		return deferred.promise;
    };

    service.retrieveCurrentCustomerInformation = function () {
        return ServiceAgent.get({ url: "online-shopper/customers/current" })
			.then(function (customer) {
                service.$storage.user = extendRosCustomerToIncludeContact(customer, service.$storage.user);
                if(service.$storage.order){
                    service.$storage.order.contact = service.$storage.user.contact;
                }
            }).then(function () {
                return service.refreshWalletInformation();
            }).catch(function (err) {
                console.log(err);
                throw {
                    type: 'ERROR',
                    message: $translate.instant("SERVER_MESSAGES.INVALID_USER_ORPASS")
                };
            })
    };

    service.refreshWalletInformation = function () {
        var errorInfo = {};
        return ServiceAgent.get({ url: "online-shopper/customers/current/wallet" },
            false, errorInfo).then(function (wallet) {
            if (errorInfo.data) {
                console.log('ERROR RETRIEVE WALLET', errorInfo.data);
            }
            setWalletInformation(service.$storage.user, wallet);
        });
    };

    function setWalletInformation(user, wallet) {
        if (service.$storage.rosConfig && service.$storage.rosConfig.payments &&
            service.$storage.rosConfig.payments.walletDisabled) return;
        if (user && wallet && wallet.payments && wallet.payments.length) {
            var singlePayment = wallet.payments[wallet.payments.length - 1];
            user.wallet = {
                defaultPaymentMethod: (wallet.payments.find(function (p) {
                    return p.isDefault
                }) || wallet.payments[0])._id,
                paymentMethods: wallet.payments.map(function (p) {
                	var _p = walletPaymentToCCInfo(p);
                	if (_p.cardExpiration) {
                		if (moment(_p.cardExpiration).isBefore(window.GETREALDATE(true))) {
                			_p.expired = true;
                		}
                	}
                	return _p;
                })
            };
        }

    }

    service.restoreSignIn = function (isChain) {
        console.log('restore sign in started');
        var deferred = $q.defer();

        if (service.$storage.user) {
            console.log('user exist, no auth');
            deferred.resolve(true);
        } else {
            var restoreToken = $localStorage[CONSTS.storageToken];
            if (restoreToken && restoreToken.refresh_token) {
                console.log('restoreToken exist: will auth');
                service.$storage.user = restoreToken.refresh_token ? {} : { auth: restoreToken };
                return service.retrieveCurrentCustomerInformation()
                    .then(function () {
                        deferred.resolve(true);
                    })
                    .catch(function (err) {
                        service.$storage.user = null;
                        $localStorage[CONSTS.storageToken] = null;
                        console.log('user removed', err);
                        deferred.reject();
                    });

            } else {
                console.log('restoreToken do not exist');
                deferred.resolve(false);
            }
        }
        return deferred.promise;

    };

	service.signOut = function () {
        ServiceAgent.remove({ url: "oauth2/token" })
            .catch(function (e) {
				console.log('remove remote token failed', e);
            })
			.then(function () {
				delete service.$storage.lUser;
				localStorage.removeItem('loyaltyToken');
				localStorage.removeItem('loyaltyRefreshToken');
                delete service.$storage.user;
				$localStorage[CONSTS.storageToken] = null;
				MetaService.logTemplate("signOut", {}, "sign out");
            })
    };

    service.forgotPass = function (data) {
        if (__SIMULATED) {
            return service.simService.simulate_forgotPass(data)
        } //simulation
        else {
            //forgot password logic goes here
            blockUI.start($translate.instant("AUTHENTICATING"));
            var deferred = $q.defer();

            //add local
            /*
             data.site = service.$storage.order && service.$storage.order.branch && service.$storage.order.branch._id;
             data.brand = service.$storage.brandSkin;
             */
            data.local = service.$storage.local;


            ServiceAgent.remove({
                url: 'online-shopper/customers/' + data.email + '/password',
                data: { local: data.local }
            }, false).then(
                function () {
                    blockUI.stop();
                    deferred.resolve();
                }, function (err) {
                    blockUI.stop();
                    console.log(err);
                    // on error you need to return error type + translate message
                    deferred.reject({ type: 'ERROR', message: $translate.instant("SERVER_MESSAGES.EMAIL_NOT_FOUND") });
                }
            );
            return deferred.promise;
        }
    };

    service.addAccountPaymentMethod = function (ccinfo) {
		if (__SIMULATED) return service.simService.addAccountPaymentMethod(ccinfo);
		var savePayment = ServiceCommon.ccInfoToWalletPayment(ccinfo);
        return service.addPaymentMethodToWallet(savePayment, ccinfo.cvv);
    };



    service.updateAccount = function (data) {
        if (__SIMULATED) {
            return service.simService.updateAccount(data)
        } //simulation
        else {
            blockUI.start($translate.instant("UPDATING_ACCOUNT"));
            var deferred = $q.defer();
            var signin = data.signin || {};
            var contact = (data.user && data.user.contact) || {};
            var customerData = {
                email: signin.email, oldPassword: signin.oldPassword,
                newPassword: signin.newPassword, name: contact.name,
                phone: contact.cell
            };
            console.log('update account details', customerData);
            var defaultWalletPayment;
            if (data.user.wallet && service.$storage.user.wallet &&
                (data.user.wallet.defaultPaymentMethod !== service.$storage.user.wallet.defaultPaymentMethod))
                defaultWalletPayment = data.user.wallet.defaultPaymentMethod;
            ServiceAgent.update({ url: 'online-shopper/customers/current', data: customerData }).then(
                function (result) {
                    blockUI.stop();

                    data = angular.copy(data);
                    var user = extendRosCustomerToIncludeContact(result, service.$storage.user);
                    if (data.ccinfo) user.ccinfo = data.ccinfo;
                    if (data.signin) user.signin = { email: result.email };

                    service.$storage.user = user;
                }).then(function () {
                if (defaultWalletPayment)
                    return ServiceAgent.create({
                        url: 'online-shopper/customers/current/wallet/payments/' + defaultWalletPayment + '/default'
                    });
            }).then(function () {
                if (data.user.wallet && data.user.wallet.ccinfoRemoved)
                    return service.removePaymentMethodsFromWallet(data.user.wallet.ccinfoRemoved);
            }).then(function () {
                return service.retrieveCurrentCustomerInformation();
            }).then(function () {
                deferred.resolve();
            }).catch(function (err) {
                blockUI.stop();
                console.log(err);
                // on error you need to return error type + translate messagess
                if (data.signin) {
                    // silmulate change signin data error
                    deferred.reject({
                        type: 'EMAIL_IN_USE',
                        message: $translate.instant("SERVER_MESSAGES.EMAIL_IN_USE")
                    });
                } else {
                    // simulate general error
                    deferred.reject({
                        type: 'ERROR',
                        message: $translate.instant("SERVER_MESSAGES.UPDATE_ACCOUNT_ERROR")
                    });
                }
            });

            return deferred.promise;
        }
    };

    service.register = function (args) {
        if (__SIMULATED) {
            return service.simService.simulate_register(args)
        } //simulation
        else {
            blockUI.start($translate.instant("REGISTERING"));
			var deferred = $q.defer();
			var registrationData = ServiceCommon.getCustomer(args);
            var savePayment = ServiceCommon.ccInfoToWalletPayment(args.ccinfo);
            registrationData.email = args.email;
            registrationData.password = args.password;

            var saveInfo = args.saveInfo;

            window.setTimeout(function () {
                var errorInfo = {};
                ServiceAgent.create({
                    url: "online-shopper/customers",
                    data: registrationData
                }, false, errorInfo)
                    .then(function () {
                        blockUI.stop();

                        if (errorInfo.data) {
                            throw {
                                type: 'SERVER_ERROR',
                                message: ServiceCommon.translateServerError(errorInfo.data, "Customer")
                            };
                        }
                        service.$storage.user = null;
                        $localStorage[CONSTS.storageToken] = null;
                        registrationData.rememberMe = saveInfo;
                        return service.signIn(registrationData);
                    }).then(function () {

                    if (savePayment) {
                        return service.addPaymentMethodToWallet(savePayment, args.ccinfo.cvv);
                    }
                }).then(function () {
                    deferred.resolve();
                }).catch(function (err) {
                    blockUI.stop();
                    if (err.type)
                        deferred.reject(err);
                    else
                        deferred.reject({ type: 'G', message: $translate.instant("SERVER_MESSAGES.ERROR_REGISTEING") });
                })
            }, function (err) {
                console.log(err);
                blockUI.stop();
                deferred.reject({ type: 'G', message: $translate.instant("SERVER_MESSAGES.ERROR_REGISTEING") });
            }, 600);

            return deferred.promise;
        }
    };

    service.addPaymentMethodToWallet = function (savePayment, cvv, doNotRefresh) {
        if (_.get(service.$storage, 'rosConfig.payments.walletDisabled', false)) return $q.defer().resolve();
        var resultPayment;
        return ServiceAgent.create({
            url: "online-shopper/customers/current/wallet/payments",
            data: savePayment
        }).then(function (paymentInWallet) {
            if (cvv) {
                ServiceCommon.cacheKEY('wpv_' + paymentInWallet._id, cvv);
            }
            resultPayment = paymentInWallet;
            if (!doNotRefresh)
                return service.refreshWalletInformation();
        }).then(function () {
            return walletPaymentToCCInfo(resultPayment);
        });
    };

    service.removePaymentMethodsFromWallet = function (walletPaymentIdList) {
        if (!walletPaymentIdList || !walletPaymentIdList.length) return $q.resolve();
        return _.reduce(walletPaymentIdList, function (promise, paymentToRemove) {
            return promise.then(function () {
                return service.removeOnePaymentMethodFromWallet(paymentToRemove);
            })
        }, $q.resolve());

    };

    service.removeOnePaymentMethodFromWallet = function (existingWalletPayment) {
        var deferred = $q.defer();
        var errorInfo = {};
        ServiceAgent.remove({
            url: "online-shopper/customers/current/wallet/payments/" + existingWalletPayment
        }, false, errorInfo)
            .then(function () {
                if (errorInfo.data) {
                    return deferred.reject({
                        type: 'SERVER_ERROR',
                        message: $translate.instant("MESSAGES.SERVER_ERROR")
                    });
                }
                return deferred.resolve();
            });
        return deferred.promise;
    };


    service.verifyPhoneNumber = function () {
        var deferred = $q.defer();
        var errorInfo = {};
        ServiceAgent.create({
            url: 'online-shopper/customers/current/verification',
            data: { pendingVerification: 'PhoneNumber' }
        }, false, errorInfo).then(function () {
            if (errorInfo.data) throw {
                type: 'SERVER_ERROR',
                message: $translate.instant("MESSAGES.SERVER_ERROR")
            };
            return ServiceCommon.getToken({}).then(
                function (ret) {
                    console.log("Challenge response: " + ret);
                    if (!ret) throw {
                        type: 'SERVER_ERROR',
                        message: $translate.instant("MESSAGES.SERVER_ERROR")
                    };
                    return ServiceAgent.update({
                        url: 'online-shopper/customers/current/verification',
                        data: {
                            pendingVerification: 'PhoneNumber',
                            verificationCode: ret
                        }
                    }, false, errorInfo).then(function () {
                        if (errorInfo.data) throw {
                            type: 'SERVER_ERROR',
                            message: ServiceCommon.translateServerError(errorInfo.data,
                                "Customer", "MESSAGES.PHONE_VERIFICATION_FAILED")
                        };
                    })
                });
        }).then(function () {
            deferred.resolve();
        }).catch(function (err) {
            if (err.type)
                deferred.reject(err);
            else
                deferred.reject({
                    type: 'SERVER_ERROR',
                    message: $translate.instant("MESSAGES.SERVER_ERROR")
                })
        });
    };


	function walletPaymentToCCInfo(walletPayment) {
		var ccinfo = {
			number: walletPayment.displayPan,
			type: walletPayment.brand,
			name: walletPayment.ownerName,
			paymentType: walletPayment.paymentType,
			_id: walletPayment._id,
			walletPayment: walletPayment._id,
			comment: walletPayment.comment
		};
		if (walletPayment.expMonth && walletPayment.expYear) { 
			ccinfo.cardExpiration = moment({ y: walletPayment.expYear, M: walletPayment.expMonth, d: 1 }).toDate();
			ccinfo.$$expText = `${_.padStart(walletPayment.expMonth, 2, '0')}-${String(walletPayment.expYear).substring(2)}`;
		}
        switch (walletPayment.paymentType) {
            case "creditCard":
                ccinfo.paymentMethod = "CREDIT";
                break;
            case "Cibus":
                ccinfo.paymentMethod = "CIBUS";
                break;
            case "10bis":
                ccinfo.paymentMethod = "TENBIS";
                break;
        }
        return ccinfo;
    }



	function extendRosCustomerToIncludeContact(customer, userToReplace) {
		var lUser = service.$storage.lUser || {};
		customer.name = lUser.FullName;
		customer.firstName = lUser.FirstName;
		customer.lastName = lUser.LastName;
		customer.email = lUser.Email;
		customer.cell = lUser.Mobile;

        customer.contact = {
			name: customer.name,
			firstName: customer.firstName,
			lastName: customer.lastName,
            email: customer.email,
			cell: customer.cell,//customer.phones[0].national.replace(/\D/g, ''),
            contractApprove: true
        };
        customer.signin = { email: customer.email };
        if (userToReplace)
            customer.auth = userToReplace.auth;
        return customer;
    }


    return service;

});

angular.module('app').factory('OrderService', function ($q, blockUI, $translate,
                                                        PDialog, CONSTS, MetaService,
                                                        $state, EntityServiceSim,
                                                        ServiceAgent, ServiceCommon, $rootScope,
                                                        $timeout, CustomerService, ExternalDeliveryService, BenefitsService, ENV, $uibModal, $filter, $injector, MarkettingService) {

    var service = {
        $storage: MetaService.$storage,
        ENV: ENV
    }

    angular.extend(service, CustomerService);

    service.selectTable = function (args) {
        var deferred = $q.defer();
        blockUI.start();

        var table = parseInt(args.table);

        ServiceAgent.get({ url: 'online-shopper/tables' })
            .then(function (tables) {
                if (!tables.some(function (t) {
                    return t.number === table
                })) {
                    throw new {
                        type: 'ERROR',
                        message: $translate.instant("MESSAGES.INVALID_TABLE")
                    };
                }
                blockUI.stop();
                service.$storage.order.table = table;
                deferred.resolve();
            })
            .catch(function (err) {
                blockUI.stop();
                console.log('set table failed', err);
                deferred.reject({
                    type: 'ERROR',
                    message: $translate.instant("MESSAGES.INVALID_TABLE")
                });
            });

        return deferred.promise;
    }

    service.callOrderVerification = function (orderId, defaultPhone) {
        var deferred = $q.defer();
        var errorInfo = {};

        if (service.$storage.requierePhoneCashConfirm) {
            ServiceCommon.getToken({
                title: 'CONFIRM_CELL',
                message: $translate.instant('CONFIRM_CELL_MESSAGE', { phone: defaultPhone }),
                caption: 'CONFIRM_CELL',
                type: 'confirm_phone',
                phone: defaultPhone,
                retResponse: true,
                response: {
                    useDefaultPhone: true
                }
            }).then(function (response) {
                callOrderVerification_cont(!response.useDefaultPhone && response.token);
            }).catch(function (e) {
                deferred.reject();
            });
        } else {
            callOrderVerification_cont();
        }

        function callOrderVerification_cont(phone) {
            ServiceAgent.create({
                url: 'online-shopper/orders/' + orderId + '/verification',
                data: { pendingVerification: 'DelayedPayment', channel: 'sms', 'phone': phone },
                loadingCaption: 'PAYMENT_IN_PROGRESS'
            }, false, errorInfo).then(function () {
                if (errorInfo.data) {
					deferred.reject(ServiceCommon.serverErrorToAppError(errorInfo.data) || {
						type: 'SERVER_ERROR',
						message: $translate.instant("MESSAGES.SERVER_ERROR")
					});
                } else {
                    deferred.resolve();
                }
            });
        }

        return deferred.promise;
    };

    service.resolveOrderVerification = function (orderId, verificationCode, updateData, releaseOrder) {
        var deferred = $q.defer();
        var errorInfo = {};
        var unlock = releaseOrder ? '1' : '0';
        ServiceAgent.update({
            url: 'online-shopper/orders/' + orderId + '/verification?unlock=' + unlock,
            data: {
                pendingVerification: 'DelayedPayment',
                verificationCode: verificationCode,
                orderUpdate: updateData
            },
            loadingCaption: 'PAYMENT_IN_PROGRESS'
        }, false, errorInfo).then(function () {
            if (errorInfo.data) {
                deferred.reject({
                    type: 'SERVER_ERROR',
                    message: ServiceCommon.translateServerError(errorInfo.data,
                        "Customer", "MESSAGES.PHONE_VERIFICATION_FAILED")
                })
            } else {
                deferred.resolve();
            }
        });
        return deferred.promise;
    };

    service.verifyCashPayment = function (args) {
        var deferred = $q.defer();
        if (args.order.orderType == 'Seated') {
            deferred.resolve();
		} else {
			let _message = 'MESSAGES.PAYMENT_CHALLENGE';
			let phone = args.order.orderer.mainPhone.national
			if (args._type == 'order') {
				_message = 'MESSAGES.ORDER_CHALLENGE';
			}
            service.callOrderVerification(args.order._id, phone)
				.then(function () {
                    var message = $translate.instant(_message, { phone: phone });
                    return ServiceCommon.getToken({ message: message, timeOut:5 }).then(function (ret) {

                            if (!ret) throw {
                                type: 'SERVER_ERROR',
                                message: $translate.instant("MESSAGES.SERVER_ERROR")
                            };
                            return service.resolveOrderVerification(args.order._id, ret, args.updateData, args.releaseOrder);
                        });
                }).then(function () {
                deferred.resolve();
				}).catch(function (err) {
					if (!err) deferred.reject({ CANCELED: true });
					else if (err.type)
						deferred.reject(err);
					else
						deferred.reject({
							type: 'SERVER_ERROR',
							message: $translate.instant("MESSAGES.SERVER_ERROR")
						});
            });
        }
        return deferred.promise;
    };

	// ------------------------------------------------------------------------------------->
	// vantiv
	// ------------------------------------------------------------------------------------->

	function processVantivPayment(parsedPayment) {
		return ServiceAgent.create({
			url: `online-shopper/finance/CreditAccounts/${parsedPayment.account}/setup`,
			data: _.assign({}, parsedPayment, {
				source: 'online',
				returnUrl: document.location.origin + "/expayment_ret.html"
			})
		}).then(res => {
			return showVantivDialog(parsedPayment, res);
		}).catch(err => {
			throw (err);
		});
	}
	function showVantivDialog(parsedPayment, res) {
		var deferred = $q.defer();
		blockUI.stop();
		$uibModal.open({
			templateUrl: 'modules/checkout/modals/modal_ex_credit_guard.html',
			controller: function ($scope, $uibModalInstance, $sce, modalParams, $timeout, EntityService, blockUI, $interval) {
				$scope._location = modalParams.location;
				$scope.exPaySRC = $sce.trustAsResourceUrl(modalParams.location);
				$timeout(function () {
					blockUI.stop();
				}, 500);

				setCloseInterval();
				function setCloseInterval() {
					let end = new Date().valueOf() + (60000 * 10);
					$scope.closeInterval = $interval(() => {
						let diff = Math.floor((end - new Date().valueOf()) / 1000);
						if (diff < 0) {
							$interval.cancel($scope.strInterval)
							$scope.cancel();
						} else {
							$scope.strInterval = `(${_.padStart(Math.floor(diff / 60), 2, '0')}:${_.padStart(Math.floor(diff % 60), 2, '0')})`;
						}
					}, 1000);
				}
				$scope.$on("$destroy", function (event) {
					$interval.cancel($scope.strInterval)
				});


				$scope.onFrameLoaded = function (contentLocation) {
					if (contentLocation && contentLocation.href.indexOf($scope._location) == -1) {
						let params = UIUtils.getURLParams(contentLocation);
						$uibModalInstance.close(params);
					}
				}
				$scope.cancel = function () {
					$uibModalInstance.close({ mode: "cancel" });
				}
			},
			backdrop: "static",
			windowClass: "modal-default",
			resolve: {
				modalParams: {
					$storage: service.$storage,
					location: res.redirectUrl,
					blockUI: blockUI
				}
			}
		}).result.then((result) => {
			if (result != undefined) {
				if (_.get(result, 'HostedPaymentStatus') == 'Cancelled') deferred.reject({ CANCELED: true });
				else {
					blockUI.start('PAYMENT_IN_PROGRESS');
					deferred.resolve(_.assignIn(result, res));
				}
			} else {
				deferred.reject({ CANCELED: true });
			}
		});
		return deferred.promise;
	}


	// ------------------------------------------------------------------------------------->
	// credit guard
	// ------------------------------------------------------------------------------------->

	function processCreditGuardPayment(parsedPayment) {
		let simulated = false;

		let baseURL = document.location.origin + "/expayment_ret.html?r="
		//baseURL = "https://www.tabit.cloud/?r="

		return ServiceAgent.create({
			url: `online-shopper/finance/CreditGuardAccounts/${parsedPayment.account}/setup`,
			data: {
				amount: parsedPayment.amount,
				//account: parsedPayment.account,
				redirectUrlOnSuccess: `${baseURL}success`,
				redirectUrlOnError: `${baseURL}error`,
				redirectUrlOnCancel: `${ baseURL }cancel`
			}
		}).then(res => {
			return showCreditGuardDialog(parsedPayment, res);
		}).catch(err => {
			if (simulated) return showCreditGuardDialog(parsedPayment, {
				paymentUrl: document.location.origin + "/credit_test.html"
			});
			throw (err);
		});
	}

	function showCreditGuardDialog(parsedPayment, res) {
		let args = UIUtils.getURLParams({ search: `?${res.paymentUrl.split('?')[1]}` });
		var deferred = $q.defer();
		blockUI.stop();
		$uibModal.open({
			templateUrl: 'modules/checkout/modals/modal_ex_credit_guard.html',
			controller: function ($scope, $uibModalInstance, $sce, modalParams, $timeout, EntityService, blockUI, $interval) {
				$scope._location = modalParams.location;
				$scope.exPaySRC = $sce.trustAsResourceUrl(modalParams.location);
				$timeout(function () {
					blockUI.stop();
				}, 500);

				setCloseInterval();
				function setCloseInterval() {
					let end = new Date().valueOf() + (60000 * 10);
					$scope.closeInterval = $interval(() => {
						let diff = Math.floor((end - new Date().valueOf()) / 1000);
						if (diff < 0) {
							$interval.cancel($scope.strInterval)
							$scope.cancel();
						} else {
							$scope.strInterval = `(${_.padStart(Math.floor(diff / 60), 2, '0')}:${_.padStart(Math.floor(diff % 60), 2, '0')})`;
						}
					}, 1000);
				}
				$scope.$on("$destroy", function (event) {
					$interval.cancel($scope.strInterval)
				});


				$scope.onFrameLoaded = function (contentLocation) {
					if (contentLocation && contentLocation.href.indexOf($scope._location) == -1) {
						let params = UIUtils.getURLParams(contentLocation);
						switch (params.r) {
							case "success":
								$uibModalInstance.close({ mode: "success", transactionId: args.txId });
								break;
							case "error":
								$uibModalInstance.close({ mode: "error" });
								break;
							case "cancel":
								$scope.cancel();
								break;
						}
					}
				}
				$scope.cancel = function () {
					$uibModalInstance.close({ mode: "cancel" });
				}
			},
			backdrop: "static",
			windowClass: "modal-default",
			resolve: {
				modalParams: {
					$storage: service.$storage,
					location: res.paymentUrl,
					blockUI: blockUI
				}
			}
		}).result.then((result) => {
			let mode = _.get(result, 'mode');
			switch (mode) {
				case "cancel":
					deferred.reject({ CANCELED: true });
					break;
				case "success":
					blockUI.start('PAYMENT_IN_PROGRESS');
					deferred.resolve(result);
					break;
				case "error":
					deferred.reject({
						type: 'PAYMENT_VALIDATION_ERROR',
						message: $translate.instant("SERVER_MESSAGES.CREDIT_GUARD_EX_ERROR"),
						serverError: {}
					});
					break;
			}
		});
		return deferred.promise;
	}

	// ------------------------------------------------------------------------------------->
	// meshulam
	// ------------------------------------------------------------------------------------->

	function processMeshulamPayment(parsedPayment, resultOrder) {
		let simulated = false;

		let baseURL = document.location.origin + "/expayment_ret.html?r="
		//baseURL = "https://www.tabit.cloud/?r="

		return ServiceAgent.create({
			url: `online-shopper/finance/MeshulamAccounts/${parsedPayment.account}/setup`,
			data: {
				amount: parsedPayment.amount,
				//account: parsedPayment.account,
				redirectUrlOnSuccess: `${baseURL}success`,
				redirectUrlOnError: `${baseURL}error`,
				redirectUrlOnCancel: `${baseURL}cancel`,
				email: _.get(resultOrder, 'orderer.email'),
				phone: _.get(resultOrder, 'orderer.phone'),
				fullName: _.get(resultOrder, 'orderer.name'),
				description: resultOrder.number
			}
		}).then(res => {
			return showMeshulamDialog(parsedPayment, res);
		}).catch(err => {
			if (simulated) return showMeshulamDialog(parsedPayment, {
				paymentUrl: document.location.origin + "/credit_test.html"
			});
			throw (err);
		});
	}

	function showMeshulamDialog(parsedPayment, res) {
		let args = UIUtils.getURLParams({ search: `?${res.paymentUrl.split('?')[1]}` });
		var deferred = $q.defer();
		blockUI.stop();
		$uibModal.open({
			templateUrl: 'modules/checkout/modals/modal_ex_credit_guard.html',
			controller: function ($scope, $uibModalInstance, $sce, modalParams, $timeout, EntityService, blockUI) {
				$scope._location = modalParams.location;
				$scope.exPaySRC = $sce.trustAsResourceUrl(modalParams.location);
				$timeout(function () {
					blockUI.stop();
				}, 500);

				$scope.onFrameLoaded = function (contentLocation) {
					if (contentLocation && contentLocation.href.indexOf($scope._location) == -1) {
						let params = UIUtils.getURLParams(contentLocation);
						switch (params.r) {
							case "success":
								$uibModalInstance.close({ mode: "success", transactionId: args.txId });
								break;
							case "error":
								$uibModalInstance.close({ mode: "error" });
								break;
							case "cancel":
								$scope.cancel();
								break;
						}
					}
				}
				$scope.cancel = function () {
					$uibModalInstance.close({ mode: "cancel" });
				}
			},
			backdrop: "static",
			windowClass: "modal-default-no-frame",
			resolve: {
				modalParams: {
					$storage: service.$storage,
					location: res.paymentUrl,
					blockUI: blockUI,
				}
			}
		}).result.then((result) => {
			let mode = _.get(result, 'mode');
			switch (mode) {
				case "cancel":
					deferred.reject({ CANCELED: true });
					break;
				case "success":
					blockUI.start('PAYMENT_IN_PROGRESS');
					deferred.resolve(res);
					break;
				case "error":
					deferred.reject({
						type: 'PAYMENT_VALIDATION_ERROR',
						message: $translate.instant("SERVER_MESSAGES.CREDIT_GUARD_EX_ERROR"),
						serverError: {}
					});
					break;
			}
		});
		return deferred.promise;
	}

	// ------------------------------------------------------------------------------------->
	// leumi pay
	// ------------------------------------------------------------------------------------->

	function processLeumiPayPayment(parsedPayment) {

		window.LeumiPayDeferred = $q.defer();
		if (!window.LEUMIPAY) {

			//$("body").on("click", "#pay-close-icon-img", function () {
			//	window.leumiNewTxn.destroyPopup_ref();
			//	window.LeumiPayDeferred.reject({ CANCELED: true });
			//});

			window.LEUMIPAY = new paySDK.PayCheckout(parsedPayment.merchantNumber);
			window.LEUMIPAY.on('Pending', function (contextId) {
				window.LeumiPayDeferred.resolve(contextId);
				window.leumiNewTxn.destroyPopup_ref();
			})
			window.LEUMIPAY.on('Failed', function (contextId) {
				window.LeumiPayDeferred.reject({
					type: 'PAYMENT_VALIDATION_ERROR',
					message: $translate.instant("_LEUMIPAY.payment_error"),
					serverError: {}
				});
				window.leumiNewTxn.destroyPopup_ref();
			})
			window.LEUMIPAY.on('Canceled', function (contextId) {
				window.LeumiPayDeferred.reject({ CANCELED: true });
			});

			$("<div id='LeumiPayContainer'></div>").appendTo('body');
			let components = window.LEUMIPAY.components();
			var btn = components.create('button')
			btn.mount('LeumiPayContainer');
		}
		window.leumiNewTxn = window.LEUMIPAY.txn(parsedPayment.amount, 'ILS');
		window.setTimeout(() => {
			let $btn = $("#LeumiPayContainer a")[0];
			if (!$btn) $btn = $("#LeumiPayContainer button")[0];
			if ($btn) {
				$btn.click();
			} else {
				window.LeumiPayDeferred.reject({
					type: 'PAYMENT_VALIDATION_ERROR',
					message: $translate.instant("_LEUMIPAY.payment_error"),
					serverError: {}
				});
			}
		}, 500);
		return window.LeumiPayDeferred.promise;
	}

	// ------------------------------------------------------------------------------------->
	// braintree
	// ------------------------------------------------------------------------------------->

	function processBraintreePayment(parsedPayment) {
		return ServiceAgent.create({
			url: `online-shopper/finance/BraintreeAccounts/${parsedPayment.account}/sdk-configuration`,
			data: { amount: $filter('number')(parsedPayment.amount / 100, 2), account: parsedPayment.account }
		}).then(res => {
			return showBraintreeDialog(parsedPayment, res);
		});
	}

	function showBraintreeDialog(parsedPayment, config) {
		var deferred = $q.defer();
		blockUI.stop();
		$uibModal.open({
			templateUrl: 'modules/app/modals/modal_braintree.html',
			controller: function ($scope, $uibModalInstance, modalParams, $q, PDialog) {
				$scope.args = modalParams;
				$scope.loading = true;

				function afterInit() {
					$scope.loading = true;
					loadDropin().then(instance => {
						$scope.instance = instance;

						instance["on"]('paymentOptionSelected', (event) => {
							$scope.$apply(() => {
								$scope.args.paymentOption = event.paymentOption;
							});
						});

						instance["on"]('paymentMethodRequestable', function (event) {
							$scope.$apply(() => {
								if ($scope.args.paymentOption == 'card') return;
								$scope.apply();
							});
						});

						instance["on"]('noPaymentMethodRequestable', function () {
							$scope.$apply(() => {
								let o = 1;
							});				
						});

					}).catch(err => {
						PDialog.info({ text: err.message }).then(() => {
							$scope.cancel();
						});
					}).then(o => {
						$scope.loading = false;
					});
				}

				function loadDropin() {
					var deferred = $q.defer();
					window.setTimeout(() => {
						window["braintree"].dropin.create(modalParams.config.clientConfiguration, (createErr, instance) => {
							if (createErr) {
								deferred.reject(createErr)
							} else {
								deferred.resolve(instance);
							}
						});
					}, 300);
					return deferred.promise;
				}

				function getPaymentMethod() {
					var deferred = $q.defer();
 					$scope.instance.requestPaymentMethod(function (err, payload) {
						if (err) {
							deferred.reject(err);
						} else {
							getDeviceData().then(deviceData => {
								payload.deviceData = deviceData;
								deferred.resolve(payload);
							}).catch(err => {
								if (payload.type == "Venmo") {
									deferred.reject(err)
								} else {
									deferred.resolve(payload);
								}
							});
						}
					});
					return deferred.promise;
				}

				function getDeviceData() {
					var deferred = $q.defer();
					braintree.client.create({
						authorization: modalParams.config.clientToken
					}, (err, clientInstance) => {
						if (err) {
							deferred.reject();
							return;
						}
						braintree.dataCollector.create({
							client: clientInstance,
							kount: true
						}, (err, dataCollectorInstance) => {
							if (err) {
								reject();
								return;
							}
							deferred.resolve(dataCollectorInstance.deviceData);
						});
					});
					return deferred.promise;
				}

				$scope.$on("$destroy", function (event) {
					$scope.instance.teardown((err) => {
						if (err) { console.error('An error occurred during teardown:', err); }
					});
				});

				$scope.apply = function() {
					if (!$scope.instance) return;
					getPaymentMethod().then(res => {
						$uibModalInstance.close(res);
					}).catch(err => {
						console.error(err);
					});
				}

				$scope.cancel = function () {
					$uibModalInstance.dismiss('cancel');
				}
				afterInit();
			},
			backdrop: "static",
			windowClass: "modal-default",
			resolve: {
				modalParams: {
					$storage: service.$storage,
					parsedPayment: parsedPayment,
					config: config
				}
			}
		}).result.then((result) => {
			blockUI.start('PAYMENT_IN_PROGRESS');
			deferred.resolve(result);
		}).catch(err => {
			blockUI.start('PAYMENT_IN_PROGRESS');
			deferred.reject({ CANCELED: true });
		});

		return deferred.promise;
	}

	// ------------------------------------------------------------------------------------->
	// cardPoint
	// ------------------------------------------------------------------------------------->

	function processCardPointPayment(parsedPayment) {
		let paymentUrl = parsedPayment.iframeUrl + '?useexpiry=true&usecvv=true&invalidcreditcardevent=true&invalidcvvevent=true&invalidexpiryevent=true&css=';
		paymentUrl += encodeURI(`body, input, select {font-family: sans-serif; box-sizing:border-box}.error{color:red;}input, select{font-size:16px;height:30px;margin: 5px 0 15px;}input{}select{width:83px}form {margin: 0 auto; width: 200px; padding-top: 25px;}`);
		if (!window.ISDESKTOP) {
			paymentUrl += '&tokenizewheninactive=true&inactivityto=2000';
		}
		return showCardPointDialog({
			paymentUrl: paymentUrl
		});
	}

	function showCardPointDialog(res) {
		var deferred = $q.defer();
		blockUI.stop();
		$uibModal.open({
			templateUrl: 'modules/checkout/modals/modal_ex_card_point.html',
			controller: function ($scope, $uibModalInstance, $sce, modalParams, $timeout, PDialog, blockUI, $interval) {
				$scope._location = modalParams.location;
				$scope.data = {}
				$scope.exPaySRC = $sce.trustAsResourceUrl(modalParams.location);
				$timeout(function () {
					blockUI.stop();
				}, 500);

				window.addEventListener('message', handleFrameMessage, false);

				setCloseInterval();
				function setCloseInterval() {
					let end = new Date().valueOf() + (60000 * 10);
					$scope.closeInterval = $interval(() => {
						let diff = Math.floor((end - new Date().valueOf()) / 1000);
						if (diff < 0) {
							$interval.cancel($scope.strInterval)
							$scope.cancel();
						} else {
							$scope.strInterval = `(${_.padStart(Math.floor(diff / 60), 2, '0')}:${_.padStart(Math.floor(diff % 60), 2, '0')})`;
						}
					}, 1000);
				}
				$scope.$on("$destroy", function (event) {
					$interval.cancel($scope.strInterval);
					window.removeEventListener("message", handleFrameMessage, false); 
				});

				function handleFrameMessage(event) {
					$scope.$apply(function () {
						var token = JSON.parse(event.data);
						$scope.data.token = token;
						console.log(token)
					});
					
				}

				let applyTimer;
				$scope.applyCardPoint = function () {
					$timeout.cancel(applyTimer)
					applyTimer = $timeout(() => {
						let token = $scope.data.token;
						if (!token) return;
						if (token.validationError) {
							return PDialog.warning({ "text": token.validationError });
						}
						if (token.message.length) {
							$uibModalInstance.close({ mode: "success", data: { token: token.message, expirey: token.expiry, expiry: token.expiry } });
						}
					}, 1500);
				}

				$scope.onFrameLoaded = function (contentLocation) {
					if (contentLocation && contentLocation.href.indexOf($scope._location) == -1) {
						let params = UIUtils.getURLParams(contentLocation);
						switch (params.r) {
							case "success": $uibModalInstance.close({ mode: "success"}); break;
							case "error": $uibModalInstance.close({ mode: "error" }); break;
							case "cancel": $scope.cancel(); break;
						}
					}
				}
				$scope.cancel = function () {
					$uibModalInstance.close({ mode: "cancel" });
				}
			},
			backdrop: "static",
			size: 'sm',
			windowClass: "modal-default ",
			resolve: {
				modalParams: {
					$storage: service.$storage,
					location: res.paymentUrl,
					blockUI: blockUI
				}
			}
		}).result.then((result) => {
			let mode = _.get(result, 'mode');
			switch (mode) {
				case "cancel":
					deferred.reject({ CANCELED: true });
					break;
				case "success":
					blockUI.start('PAYMENT_IN_PROGRESS');
					deferred.resolve(result.data);
					break;
				case "error":
					deferred.reject({
						type: 'PAYMENT_VALIDATION_ERROR',
						message: $translate.instant("SERVER_MESSAGES.CREDIT_GUARD_EX_ERROR"),
						serverError: {}
					});
					break;
			}
		});
		return deferred.promise;
	}

	// ------------------------------------------------------------------------------------->
	// Heartland
	// ------------------------------------------------------------------------------------->

	function processHeartLandPayment(parsedPayment) {
		var deferred = $q.defer();
		blockUI.stop();
		$uibModal.open({
			templateUrl: 'modules/checkout/modals/modal_ex_heartland.html',
			controller: 'heartland_payment_dialog',
			backdrop: "static",
			size: 'sm',
			windowClass: "modal-default _larger",
			resolve: {
				modalParams: {
					$storage: service.$storage,
					merchantNumber: parsedPayment.merchantNumber,
					blockUI: blockUI
				}
			}
		}).result.then((result) => {
			let mode = _.get(result, 'mode');
			switch (mode) {
				case "cancel":
					deferred.reject({ CANCELED: true });
					break;
				case "success":
					blockUI.start('PAYMENT_IN_PROGRESS');
					deferred.resolve(result.data);
					break;
				case "error":
					deferred.reject({
						type: 'PAYMENT_VALIDATION_ERROR',
						message: $translate.instant("SERVER_MESSAGES.CREDIT_GUARD_EX_ERROR"),
						serverError: {}
					});
					break;
			}
		});
		return deferred.promise;
	}


	// ------------------------------------------------------------------------------------->
	// Stripe
	// ------------------------------------------------------------------------------------->

	function processStripePayment(parsedPayment) {
		return ServiceAgent.create({
			url: `online-shopper/finance/StripeAccounts/${parsedPayment.account}/setup`,
			data: {
				amount: parsedPayment.amount,
			}
		}).then(res => {
			return showStripeDialog(parsedPayment, res);
		}).catch(err => {
			throw (err);
		});
	}

	function showStripeDialog(parsedPayment, args) {
		var deferred = $q.defer();
		blockUI.stop();
		$uibModal.open({
			templateUrl: 'modules/checkout/modals/modal_ex_card_stripe.html',
			controller: function ($scope, $uibModalInstance, $sce, modalParams, $timeout, ENV, blockUI, $interval) {
				$scope.requireAVS = modalParams.account.paymentVerificationStrategy == "fullAddress";
				let stripe, elements;
				$timeout(() => {
					let accountId = modalParams.account.providerAccountId;
					stripe = Stripe(ENV.stripeClientKey, { stripeAccount: accountId});
					document.querySelector("#payment-form").addEventListener("submit", handleSubmit);
					elements = stripe.elements({
						appearance: { theme: 'stripe' },
						clientSecret: modalParams.secret
					});
					let paymentElement = elements.create("payment");
					paymentElement.mount("#payment-element");
					if ($scope.requireAVS) {
						let addressElement = elements.create('address', { mode: 'billing' });
						addressElement.mount('#stripe-address-element');
						$('#stripe-address-element').addClass('m-t')
					}
				}, 100);

				function handleSubmit(e) {
					e.preventDefault();
					$scope.setLoading(true);
					$('#stripe-payment-message').addClass('hidden');
					stripe.confirmPayment({
						elements,
						confirmParams: {
							//return_url: "http://localhost:4242/checkout.html",
						},
						redirect: "if_required"
					}).then(res => {
						let error = res.error;
						if (error) {
							if (error.type === "card_error" || error.type === "validation_error") {
								$('#stripe-payment-message').html(error.message).removeClass('hidden');
								$scope.setLoading();
								return;
							} else {
								$uibModalInstance.close({ mode: "error" });
							}
						}
						$uibModalInstance.close({ mode: "success", transactionId: res.paymentIntent.id  });
					}).catch(err => {
						$uibModalInstance.close({ mode: "error" });
					}).finally(() => {
						blockUI.stop();
					});
				}
				
				$scope.cancel = function () {
					$uibModalInstance.close({ mode: "cancel" });
				}

				$scope.setLoading = function(show) {
					if (show) {
						$("#stripe-Modal-inner").addClass('_load-active');
						document.querySelector("#stripe-submit").disabled = true;
					} else {
						$("#stripe-Modal-inner").removeClass('_load-active');
						document.querySelector("#stripe-submit").disabled = false;
					}
				}

				// modal expiration ------------------------------------------>
				$timeout(() => {
					$scope.setLoading();
				}, 2000);
				setCloseInterval();
				function setCloseInterval() {
					let end = new Date().valueOf() + (60000 * 10);
					$scope.closeInterval = $interval(() => {
						let diff = Math.floor((end - new Date().valueOf()) / 1000);
						if (diff < 0) {
							$interval.cancel($scope.strInterval)
							$scope.cancel();
						} else {
							$scope.strInterval = `(${_.padStart(Math.floor(diff / 60), 2, '0')}:${_.padStart(Math.floor(diff % 60), 2, '0')})`;
						}
					}, 1000);
				}
				$scope.$on("$destroy", function (event) {
					$interval.cancel($scope.strInterval);
				});
			},
			backdrop: "static",
			windowClass: "modal-default",
			resolve: {
				modalParams: {
					$storage: service.$storage,
					account: service.$storage.order.branch.paymentAccounts.find(acct => acct._id == parsedPayment.account),
					secret: args.providerTransactionSecret,
					blockUI: blockUI
				}
			}
		}).result.then((result) => {
			let mode = _.get(result, 'mode');
			switch (mode) {
				case "cancel":
					deferred.reject({ CANCELED: true });
					break;
				case "success":
					blockUI.start('PAYMENT_IN_PROGRESS');
					deferred.resolve(result);
					break;
				case "error":
					deferred.reject({
						type: 'PAYMENT_VALIDATION_ERROR',
						message: $translate.instant("SERVER_MESSAGES.CREDIT_GUARD_EX_ERROR"),
						serverError: {}
					});
					break;
			}
		});
		return deferred.promise;
	}

	// ------------------------------------------------------------------------------------->

	service.processNotCashPayment = function (resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, retryCount) {
		switch (parsedPayment.paymentType) {
			case "CreditGuard":
				MetaService.logTemplate("creditGuardBefore", {}, "Credit Guard Before");
				return processCreditGuardPayment(parsedPayment).then(res => {
					parsedPayment.providerInfo = {
						"providerTransactionId": res.transactionId
					}
					MetaService.logTemplate("creditGuardAfter", {}, "Credit Guard After");
					return processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, retryCount);
				}).catch(err => {
					throw (err);
				});
			case "Stripe":
				MetaService.logTemplate("StripeBefore", {}, "Stripe Before");
				return processStripePayment(parsedPayment).then(res => {
					parsedPayment.providerTransactionId = res.transactionId;
					MetaService.logTemplate("StripeAfter", {}, "Stripe After");
					return processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, retryCount);
				}).catch(err => {
					throw (err);
				});
			case "Meshulam":
				MetaService.logTemplate("MeshulamBefore", {}, "Meshulam Before");
				return processMeshulamPayment(parsedPayment, resultOrder).then(res => {
					parsedPayment.providerInfo = {
						"processToken": res.processToken,
						"processId": res.processId
					}
					MetaService.logTemplate("MeshulamAfter", {}, "Meshulam After");
					return processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, retryCount);
				}).catch(err => {
					throw (err);
				});

			case "LeumiPay":
				MetaService.logTemplate("leumiPayBefore", {}, "Leumi Pay Before");
				return processLeumiPayPayment(parsedPayment).then(contextId => {
					parsedPayment.providerTransactionId = contextId;
					//parsedPayment.paymentType = 'payLeumi';
					MetaService.logTemplate("leumiPayAfter", {}, "Leumi Pay After");
					return processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, retryCount);
				}).catch(err => {
					throw (err);
				});

            case "CardPoint": return processCardPointPayment(parsedPayment, resultOrder).then(res => {
                parsedPayment.providerInfo = res
                return processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, retryCount);
                }).catch(err => {
                    throw (err);
				});

			case "HeartLand": return processHeartLandPayment(parsedPayment, resultOrder).then(res => {
                parsedPayment.providerInfo = res
                return processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, retryCount);
                }).catch(err => {
                    throw (err);
				});

			case "Braintree":
				MetaService.logTemplate("BraintreeBefore", {}, "Braintree Before");
				return processBraintreePayment(parsedPayment).then(res => {
					parsedPayment.providerInfo = {
						nonce: res.nonce,
						deviceData: res.deviceData
					}
					MetaService.logTemplate("Braintree After", {}, "Braintree After");
					return processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, retryCount);
				});
				break;
			default:
				if (parsedPayment.$$isExternal && service.$storage.region == 'US') {
					MetaService.logTemplate("VantivBefore", {}, "Vantiv Before");
					return processVantivPayment(parsedPayment).then(res => {
						parsedPayment.providerTransactionToken = res.providerTransactionToken;
						parsedPayment.disableValidate = true;
						MetaService.logTemplate("Vantiv After", {}, "Vantiv After");
						return processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, retryCount);
					});
				}
				return processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, retryCount)
		}
	}

	function processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, retryCount) {
		if (!retryCount) retryCount = 0;
		var unlock = releaseOrder ? '1' : '0';
		parsedPayment.source = resultOrder.source || 'online';

		if (parsedPayment.idCard === 'MISSING') {
			delete parsedPayment.idCard;
		}

		var deferred = $q.defer();
		let sURL = `online-shopper/orders/${resultOrder._id}/payments?unlock=${unlock}`;
		if (parsedPayment.providerTransactionToken) {
			sURL = `online-shopper/orders/${resultOrder._id}/payments/V2?unlock=${unlock}`;
		}
		
		ServiceAgent.create({
			url: sURL,
			data: parsedPayment,
			loadingCaption: 'PAYMENT_IN_PROGRESS',
			//throwError: { data: { code: '409098' } }
		}).then(function (addedPayment) {
			if (addedPayment.redirectUrlXX) {
				var providerTransactionToken = addedPayment.providerTransactionToken;
				if (!providerTransactionToken) {
					deferred.reject({
						type: 'PAYMENT_VALIDATION_ERROR',
						message: $translate.instant("MESSAGES.EX_PAYMENT_ERROR"),
						serverError: {}
					})
				} else {
					MetaService.logTemplate("externalPayStart", {}, "External Pay Start");
					service.$storage.cacheOrder = {
						resultOrder: resultOrder,
						orderId: resultOrder._id,
						providerTransactionToken: providerTransactionToken,
                        paymentId: addedPayment.payment._id,
                        giftCards: _.cloneDeep(service.$storage.giftCards)
					}
					if (window.ISDESKTOP) {
						$uibModal.open({
							templateUrl: 'modules/checkout/modals/modal_ex_payment.html',
							controller: 'modal_excheck',
							backdrop: "static",
							windowClass: "modal-default",
							resolve: {
								modalParams: {
									$storage: service.$storage,
									location: addedPayment.redirectUrl,
									blockUI: blockUI
								}
							}
						}).result.then(function (result) {

						});
					} else {
						window.location = addedPayment.redirectUrl;
					}
				}
			} else {
				paymentsAddedOnThisCall.push(addedPayment);
				deferred.resolve(resultOrder);
			}
		}).catch(function (srvErr) {

			let err = srvErr.data || {}
			if (err.code == '409100' && retryCount < 2) {
				blockUI.start('PAYMENT_IN_PROGRESS');
				window.setTimeout(() => {
					service.processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, ++retryCount).then(res => {
						deferred.resolve(res);
					}).catch(err => {
						deferred.reject(err);
					});
				}, 4000);
				return;
			} else {
				var appError = ServiceCommon.serverErrorToAppError(srvErr) ||
					{
						type: 'PAYMENT_VALIDATION_ERROR',
						message: $translate.instant("MESSAGES.PAYMENT_ERROR"),
						serverError: srvErr
					};
				deferred.reject(appError);
			}
		});
		return deferred.promise;
	};

    function processExternalGiftCards(resultOrder, giftCards){
        let processedPayments = [];
        let failedPayments = [];
        let paymentsAddedOnThisCall = [];

        let promises = _.map(giftCards, onePayment => {
            return service.processSinglePayment(onePayment, resultOrder, paymentsAddedOnThisCall).then(processedOrder => {
                processedPayments.push(onePayment);
                return processedOrder;
            }).catch(expPay => {
                failedPayments.push({ payment: onePayment, error: expPay })
            });
        });
        return $q.all(promises).then(oRet => {
            if (failedPayments.length > 0) {
                return getPartialPaymentFailError(resultOrder);
            }
            return resultOrder;
        });

    }

    service.authoriseExternalPayment = function (args, params) {
        var cacheOrder = args;
        var payload = {
            "paymentId": cacheOrder.paymentId,
            "providerTransactionToken": cacheOrder.providerTransactionToken,
            "params": params
        }
        var resultOrder = cacheOrder.resultOrder;
		var sURL = "online-shopper/orders/" + cacheOrder.orderId + "/authorize ";
        return ServiceAgent.create({
            url: sURL,
            data: payload,
            loadingCaption: 'PAYMENT_IN_PROGRESS'
		}).then((ret) => {
			MetaService.logTemplate("externalPayAuthorize", {}, "External Pay Authorize");
            let giftCards = service.$storage.cacheOrder.giftCards;
            if (giftCards && _.get(giftCards,'[0]')){
                return processExternalGiftCards(resultOrder, giftCards).then(retG => {
                    return retG;
                });
            }
            return ret;
        }).then((retH) => {
            var handoffData = { status: 'ready' };
            if (_.get(retH,'type') == 'PARTIAL_PAYMENT_ERROR'){
                handoffData.status = 'attendanceRequired';
			}
			return handoffOrder(resultOrder._id, handoffData).then(() => {
				MetaService.logHandOff(resultOrder);
				MarkettingService.pushDataLayer(service.$storage.basket, "purchase", resultOrder);
				service.$storage.disableLoyaltyCancel = true;
                service.$storage.orderClosed = true;
                service.$storage.order.reference = resultOrder.number;
                return retH;
            });
        }).catch(function (srvErr) {
            var appError = ServiceCommon.serverErrorToAppError(srvErr) ||
                {
                    type: 'PAYMENT_AUTHRIZATION_ERROR',
                    message: $translate.instant("MESSAGES.EX_PAYMENT_AUTHRIZATION_ERROR"),
                    serverError: srvErr
                };
            throw(appError);
        })
    }

    service.processMultiplePayments = function (splitPayments, resultOrder, addPaymentToWallet, paymentsAddedOnThisCall) {
        let cashPayments = splitPayments.filter(function (p) {
            return p.paymentMethod === 'CASH'
        }).reduce(function (prev, current) {
            if (!prev.length)
                return [angular.copy(current)];
            prev[0].amount = current.amount + prev[0].amount;
            return prev;
        }, []);
        let nonCashPayments = splitPayments.filter(function (p) {
            return p.paymentMethod !== 'CASH'
        });
        if (nonCashPayments.length > 0) {
            nonCashPayments[0].addPaymentToWallet = addPaymentToWallet;
        }
        let allPayments = cashPayments.concat(nonCashPayments);
        let processedPayments = [];
		let failedPayments = [];
        return _.reduce(allPayments, function (promise, onePayment) {
            return promise.then(function () {
                return service.processSinglePayment(onePayment, resultOrder, paymentsAddedOnThisCall)
                    .then(function (processedOrder) {
                        processedPayments.push(onePayment);
                        return processedOrder;
					}).catch(function (expPay) {
                        if ((expPay.type === 'PAYMENT_VALIDATION_ERROR') && (nonCashPayments.length > 1)) {
                            failedPayments.push({ payment: onePayment, error: expPay })
                        } else {
                            throw expPay;
                        }
                    });
            })
        }, $q.resolve())
            .then(function () {
                if (failedPayments.length > 0) {
                    if (processedPayments.filter(pp => !pp.isCash).length > 0)
                        throwPartialPaymentFailError(resultOrder);
                    else
                        throw failedPayments[0].error;
                }
                return resultOrder;
            });
    };

    function throwPartialPaymentFailError(resultOrder) {
        throw getPartialPaymentFailError(resultOrder);
    }

    function getPartialPaymentFailError(resultOrder) {
		let phone = _.get(service.$storage.order.branch, 'phone', '911');

        let message = resultOrder.orderType !== 'Seated' ?
            $translate.instant("MESSAGES.SOME_OF_MULTIPLE_PAYMENTS_FAILED", { phone: phone }) :
            $translate.instant("MESSAGES.SOME_OF_MULTIPLE_PAYMENTS_FAILED_LOCALLY", { phone: phone });
        return {
            type: 'PARTIAL_PAYMENT_ERROR',
            message: message,
            order: resultOrder
            //phone: phone - we do not support call from dialog for now
        }
    }

    service.processSinglePayment = function (paymentInfo, resultOrder, paymentsAddedOnThisCall) {
        let savePayment = null;
        return $q.resolve().then(function () {
            if (paymentInfo.walletPayment) paymentInfo.cvv = ServiceCommon.restoreKEY('wpv_' + paymentInfo.walletPayment);
            let releaseOrder = paymentInfo.releaseOrder;
            let parsedPayment = ServiceCommon.getPreparedPayment(paymentInfo);
            if (!parsedPayment.$$isExternal && (parsedPayment.paymentType !== 'cash') &&
                paymentInfo.addPaymentToWallet && service.$storage.user && service.$storage.user.auth) {
                savePayment = ServiceCommon.ccInfoToWalletPayment(paymentInfo);
                savePayment.cvv = paymentInfo.cvv;
            }
            if (paymentInfo.$$isExternal && paymentInfo.giftCards){
                parsedPayment.giftCards = paymentInfo.giftCards;
            }
            delete paymentInfo.addPaymentToWallet;
            console.log("payment payment info: ", JSON.stringify(parsedPayment));

            let cashPaymentBehaviour = "paymentIntent";
            if (resultOrder.orderType === 'Delivery')
                cashPaymentBehaviour = _.get(service.$storage, 'rosConfig.payments.cashBehavior', "paymentIntent");
            if (service.$storage.orderMode !== "fixedorder")
                parsedPayment.trackPaymentFailure = true;
			if (parsedPayment.paymentType === 'cash') {
				if (!_.get(service.$storage.settings, 'requiereSMSonOrder')) {
					let updateData = cashPaymentBehaviour === "paymentIntent" ? { paymentIntent: { resolveBalanceWithCash: true } } : {};
					return service.verifyCashPayment({
						_type: 'cash',
						order: resultOrder,
						updateData: updateData,
						releaseOrder: releaseOrder
					}).then(function () {
						if (cashPaymentBehaviour !== "paymentIntent") {
							return service.processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall);
						}
						return resultOrder;
					});
				}
				if (cashPaymentBehaviour !== "paymentIntent") {
					return service.processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall);
				}
				return resultOrder;
            } else {
                if (parsedPayment.cvv === 'MISSING') {
                    return ServiceCommon.getCVV(parsedPayment.number).then(function (cvv) {
                        parsedPayment.cvv = cvv.toString();
                        return service.processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall)
                            .then(function (resultOrder) {
                                ServiceCommon.cacheKEY('wpv_' + paymentInfo.walletPayment, cvv);
                                return resultOrder;
                            });
                    })
                }
                return service.processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall);
            }
        }).then(function (resultOrder) {
            if (savePayment) {
                let cvvToSave = savePayment.cvv;
                delete savePayment.cvv;
                return service.addPaymentMethodToWallet(savePayment, cvvToSave)
                    .then(function () {
                        return resultOrder
                    });
            }
            return resultOrder;
        })
    };

    service.processPayment = function (args = {}) {
		blockUI.start($translate.instant(args.noAmount ? "CREATING_ORDER" : "PERFORMING_PAYMENT"));

		var deferred = $q.defer();

        if (service.$storage.orderClosed) {
            blockUI.stop();
            deferred.reject({
                type: 'SERVER_ERROR',
                message: $translate.instant("MESSAGES.SERVER_ERROR", { phone: phone })
            });

            return deferred.promise;
		}

		if (!args.noAmount) {

			// gift card handling ------------------------------------------------------------>
			let havePayments = args.ccinfo || (args.splitPayments && args.splitPayments.length);
			let giftCards = service.$storage.giftCards;
			if (giftCards && !giftCards[0]) giftCards = null;
			if (giftCards) {
				if (args.splitPayments && args.splitPayments.length) {
					args.splitPayments = args.splitPayments.concat(giftCards);
					havePayments = true;
				} else {
					let remainingAmount = service.$storage.order.grandTotalForPay;
					args.splitPayments = [].concat(giftCards);
					if (remainingAmount) {
						if (!args.ccinfo) havePayments = false;
						else {
                            let ccinfo = _.cloneDeep(args.ccinfo);
							ccinfo.amount = remainingAmount;

							args.splitPayments.unshift(ccinfo);
							havePayments = true;

							/* the following is no longer relevant as vantiv no longer implement full redirect
                            if (ccinfo.$$isExternal && service.$storage.region == 'US'){
                                ccinfo.amount = ccinfo.remainingAmount = remainingAmount;
                                ccinfo.giftCards = giftCards;
                                args.ccinfo = ccinfo;
                                delete args.splitPayments;
                            }else{
                                args.splitPayments.unshift(ccinfo);
                                havePayments = true;
                            }
							*/
						}
					} else {
						havePayments = true;
						service.$storage.order.$ccinfo = null;
						delete args.ccinfo;
					}
				}
			}
			
			if (!havePayments) {
				blockUI.stop();
				let phone = service.$storage.order.branch.phone;
				deferred.reject({
					type: 'SERVER_ERROR',
					message: $translate.instant("MESSAGES.PROBLEM_IN_PAYMENT_INPUT", { phone: phone })
				});

				return deferred.promise;
			}
		}

		if (_.get(MetaService, '_keys.training') === 'false') {}
		else if (service.$storage.rosConfig.trainingMode) {
			var phone = _.get(service.$storage.order.branch, 'phone', '911');
            blockUI.stop();
            deferred.reject({
                type: 'SERVER_ERROR',
                message: $translate.instant("MESSAGES.CANNOT_PAY_TRAINING_MODE", { phone: phone })
            });

            return deferred.promise;
        }

        if (!args.ccinfo && args.splitPayments && args.splitPayments.length === 1)
            args.ccinfo = args.splitPayments[0];

        // contains the site/organization/branch
        var siteId = service.$storage.order.branch._id;

        var parsedOrder = ServiceCommon.getPreparedOrder(false, args);
        service.$storage.order.haveErrors = false;

        var addPaymentToWallet = args.updatePaymentInfo;
        var paymentsAddedOnThisCall = [];

		var paymentsToProcess = args.splitPayments, requireOrderValidation = false;
		if (!args.noAmount) {
			if (!paymentsToProcess) {
				if (!args.ccinfo.amount) args.ccinfo.amount = service.$storage.order.grandTotal + args.gratuityAmount;
				paymentsToProcess = [args.ccinfo];
			}
			if (_.get(service.$storage.settings, 'requiereSMSonOrder')) {
				if (paymentsToProcess.length > 1) requireOrderValidation = true;
				switch (paymentsToProcess[0].paymentMethod) {
					case "LeumiPay": break;
					case "bitiPay": break;
					default: requireOrderValidation = true;
				}
			}
			let cashAmount = 0;
			_.each(paymentsToProcess, (pm) => {
				if (pm.paymentMethod == 'cash') {
					if (pm.amount) cashAmount += pm.amount;
				}
			});

			let limitCashAbove = _.get(service.$storage.order.branch, 'settings.limitCashAbove');
			if (service.$storage.region == 'IL') {
				if (!limitCashAbove) limitCashAbove = 6000;
				else limitCashAbove = Math.min(limitCashAbove, 6000);
			}
			if (limitCashAbove && cashAmount > limitCashAbove) {
				blockUI.stop();
				return $q.reject({
					type: 'CASH_ABOVE_6000',
					message: $translate.instant("MESSAGES.LIMIT_CASH_ABOVE_MSG", { val: $filter('money')(limitCashAbove) })
				});
			}
		}

		service.$storage.payInProgress = true;
		createOrLoadOrder(parsedOrder)
			.then((resultOrder) => {
				service.$storage.hanoffETA = _.get(resultOrder.deliveryInfo, 'ETA');
				if (requireOrderValidation) {
					let phone = _.get(resultOrder, 'orderer.mainPhone.national');
					let updateData = { phoneNumberVerified: phone}, chashPayment = _.find(paymentsToProcess, { paymentMethod: 'cash' });
					if (chashPayment) {
						let cashPaymentBehaviour = "paymentIntent";
						if (parsedOrder.orderType === 'Delivery') {
							cashPaymentBehaviour = _.get(service.$storage, 'rosConfig.payments.cashBehavior', "paymentIntent");
						}
						if (cashPaymentBehaviour == "paymentIntent") {
							updateData.paymentIntent = { resolveBalanceWithCash: true } 
						}
					}
					return service.verifyCashPayment({
						_type: 'order',
						updateData: updateData,
						order: resultOrder
					}).then(function () {
						return resultOrder;
					});
				}
				return resultOrder;
			}).then((resultOrder) => {
				return ExternalDeliveryService.validateExDelivery(resultOrder);
			}).then((resultOrder) => {
				if (args.noAmount) return resultOrder;

				addTipToPayments(paymentsToProcess, args.gratuityAmount);
				paymentsToProcess = paymentsToProcess.map(function (ptp) {
					ptp = angular.copy(ptp);
					ptp.customerDetails = _.assignIn({}, parsedOrder.orderer, ptp.customerDetails);
					return ptp;
				});

				return service.processMultiplePayments(paymentsToProcess, resultOrder, addPaymentToWallet, paymentsAddedOnThisCall);
		}).then(function (resultOrder) {
			return handoffOrder(resultOrder._id, { status: 'ready' }).then(_res => {
				MetaService.logHandOff(resultOrder);
				MarkettingService.pushDataLayer(service.$storage.basket, "purchase", resultOrder);
				service.$storage.disableLoyaltyCancel = true;
				return resultOrder
			});
		}).then(function (resultOrder) {
            afterHandoff(service, resultOrder, args);
        }).then(function () {
            blockUI.stop();
            deferred.resolve();
        }).catch(function (err) {
            console.log('err', err);
			if (err.CANCELED) {
				blockUI.stop();
				deferred.reject(err);
				return;
			}
            if (err && err.type) {
                handlePartialPaymentsError(err)
					.then(function (revisedErr) {
						service.$storage.disableLoyaltyCancel = true;
                        blockUI.stop();
                        deferred.reject(revisedErr);
                    });
            }
			else {
				let errorCode = _.get(err, 'data.code');
				if (errorCode == 409207) {
					blockUI.stop();
					PDialog.warning({ "text": $translate.instant("MESSAGES.LOYALTY_BENEFIT_CONFLICT_ERROR") });
					$state.go("app.order.menus");
				} else if (errorCode === 409903) {
					blockUI.stop();
					PDialog.warning({ "text": $translate.instant("MESSAGES.LOYALTY_BENEFIT_TIMEOUT_ERROR") }).then(() => {
						BenefitsService.getBenefits().then(res => {
							BenefitsService.showPromotions().then(o => {
								let entityService = $injector.get('EntityService');
								entityService.calculateBasketTotal();
							});
						});
					});
				} else if (errorCode === 409014 || errorCode == 409136 || errorCode == 409167) { // OnlineOrderAlreadyHandedOff
                    return ServiceAgent.get({
                        url: `online-shopper/orders/${parsedOrder._id}`,
                        loadingCaption: 'LOADING_ORDER'
                    }).then(handedOffOrder => {
                        if (getOrderAmount(parsedOrder) !== getOrderAmount(handedOffOrder)) {
                            blockUI.stop();
                            deferred.reject({
                                type: 'ORDER_CHANGED_AFTER_HANDOFF',
                                message: $translate.instant("MESSAGES.ORDER_CHANGED_AFTER_HANDOFF", {
									phone: _.get(service.$storage.order.branch, 'phone', '911')
                                })
                            });
                        } else {
                            let hasFailedPayments = !!_.get(handedOffOrder, 'failedPayments', []).length;
                            if (hasFailedPayments) {
                                handlePartialPaymentsError(getPartialPaymentFailError(handedOffOrder), false)
                                    .then(function (revisedErr) {
                                        blockUI.stop();
                                        deferred.reject(revisedErr);
                                    });
                            } else {
                                afterHandoff(service, handedOffOrder, args);
                                blockUI.stop();
                                deferred.resolve();
                            }
                        }
                    });
                } else {
                    blockUI.stop();
                    var formattedError = formatError(err);
                    deferred.reject(formattedError);
                }
            }
        });
        return deferred.promise;
    }


	function handoffOrder(orderId, handoffData, retryCount) {
		return handoffOrder_ros(orderId, handoffData, retryCount).then((handoffResult) => {
			return ExternalDeliveryService.handoffDelivery(handoffResult).then(res => {
				return handoffResult;
			}).catch(err => {
				err.orderClosed = true;
				service.$storage.orderClosed = true;
				service.$storage.order.haveErrors = true;
				return err;
			});
		})
	}

	function handoffOrder_ros(orderId, handoffData, retryCount) {
		if (!retryCount) retryCount = 0;
		var deferred = $q.defer();
		let url = 'online-shopper/orders/' + orderId+ '/handoff';

		ServiceAgent.create({
			url: url,
			data: handoffData,
			loadingCaption: 'PAYMENT_IN_PROGRESS'
		}).then((res) => {
			deferred.resolve(res);
		}).catch(err => {
			let errorCode = _.get(err, 'data.code');
			if (!errorCode && retryCount < 2) {
				blockUI.start('PAYMENT_IN_PROGRESS');
				window.setTimeout(() => {
					handoffOrder_ros(orderId, handoffData, ++retryCount).then(res => {
						deferred.resolve(res);
					}).catch(err => {
						deferred.reject(err);
					});
				}, 1500);
				return;
			}
		});

		return deferred.promise;
	}

	service.formatOrderError = formatError;
    function formatError(err) {
        if (_.get(err, 'data.data.name') === "ItemInventoryOutOfStock")
            return formatInventoryOutOfStockError(err.data.data.outOfStock, service.$storage);
        return {
            type: 'SERVER_ERROR',
            message: $translate.instant("MESSAGES.SERVER_ERROR")
        }
    }

    function getOrderAmount(order) {
        return _.get(order, 'orderedOffers', []).reduce((amount, orderedOffer) => {
            return amount + (orderedOffer.amount || 0);
        }, 0);
    }

    function formatInventoryOutOfStockError(outOfStockItems, _storage) {
        let minStock = _storage.rosConfig.outOfStockWhenUnder;
        let items = outOfStockItems.map(o => {
            let name = (_storage.catalog.items.find(i => i._id === o.item) || {}).name;
            let qty = o.required - Math.max(o.stockCount - minStock, 0);
            return `[ ${qty} x ${name} ]`
        }).join('\n');
        return { type: 'OUT_OF_STOCK', message: $translate.instant('MESSAGES.OUT_OF_STOCK', { items: items }) };
    }

    function addTipToPayments(paymentsToProcess, gratuityAmount) {
        if (paymentsToProcess.length === 1) {
            paymentsToProcess[0].tip = gratuityAmount;
        } else if (paymentsToProcess.length > 1) {
            let paymentsTotal = paymentsToProcess.reduce((sum, payment) => sum + payment.amount, 0);
            let totalTip = 0;
            for (var i = 0; i < paymentsToProcess.length - 1; i++) {
                var payment = paymentsToProcess[i];
                var tip = gratuityAmount * (payment.amount / paymentsTotal);
                tip = service.roundGratuity(tip);
                totalTip += tip;
                payment.tip = tip;
            }
            paymentsToProcess[paymentsToProcess.length - 1].tip = Math.max(gratuityAmount - totalTip, 0);
        }
    }

	function createOrLoadOrder(parsedOrder) {
        if (parsedOrder.order_id) {
            return ServiceAgent.get({
                url: "online-shopper/orders/" + parsedOrder.order_id,
                loadingCaption: 'LOADING_ORDER'
            }).then(function (loadedOrder) {
                if (loadedOrder.balance !== parsedOrder.balance) {
                    throw {
                        type: 'ORDER_RELOAD_NEEDED',
                        message: $translate.instant("MESSAGES.ORDER_RELOAD_NEEDED"),
                        order: loadedOrder
                    };
                }
                loadedOrder.source = 'tabitPay';
                return loadedOrder;
            });
        } else if (service.$storage.preCreatedOrder) {
            // handle precreted order need to update order's deliveryInfo
            var deliveryInfo = parsedOrder.deliveryInfo;
            return ServiceAgent.create({
                url: "online-shopper/orders",
                data: parsedOrder,
                loadingCaption: 'CREATING_ORDER'
            });
        } else {
            return ServiceAgent.create({
                url: "online-shopper/orders",
                data: parsedOrder,
                loadingCaption: 'CREATING_ORDER'
            });
        }
    }

    function handlePartialPaymentsError(err, handoff = true) {
        let deferred = $q.defer();
        if (err.type === 'PARTIAL_PAYMENT_ERROR') {
			if (handoff) {
				handoffOrder(err.order._id, { status: 'attendanceRequired' }).then((res) => {
					MetaService.logHandOff(res, true);
					MarkettingService.pushDataLayer(service.$storage.basket, "purchase", res);
                    resolvePartialPaymentError(err, deferred);
                }).catch(function (tmpErr) {
                    console.log("Error on release bad order", tmpErr);
                    resolvePartialPaymentError(err, deferred);
                });
            } else {
                resolvePartialPaymentError(err, deferred);
            }
        }
        else
            deferred.resolve(err);

        return deferred.promise;
    }

    function resolvePartialPaymentError(err, deferred) {
        err.orderClosed = true;
		service.$storage.orderClosed = true;
        service.$storage.order.reference = err.order.number;
        service.$storage.order.haveErrors = true;
        deferred.resolve(err);
    }

	service.fixGratuity = function () {
		var gratuity = service.$storage.order.gratuity;
		var total = service.$storage.order.grandTotal;
		var grandTotalPlusTip = total;
		var gratuityAmount = 0;
		if (gratuity) {
			if (gratuity.type == 'p') {
				var pVal = gratuity.percent || 0;
				gratuity.amount = service.calculateGratuityFromPercentage(pVal);// _.round(total * (pVal / 100), MetaService.local.decimals);
				grandTotalPlusTip += gratuity.amount;
				gratuityAmount = gratuity.amount;
			} else if (gratuity.type == 'm' || gratuity.type == 'mm') {
				if (!gratuity.amount || isNaN(gratuity.amount)) {
					gratuity.amount = 0;
					gratuity.percent = 0;
				} else {
					gratuityAmount = _.round(gratuity.amount, MetaService.local.decimals);
					grandTotalPlusTip += gratuity.amount;
					gratuity.percent = gratuity.amount / total * 100
				}
			}
		}
		service.$storage.order.gratuityAmount = gratuityAmount;
		service.$storage.order.grandTotalPlusTip = grandTotalPlusTip;
	}

    service.calculateGratuityFromPercentage = function (pVal, totalAmount) {
        var total = totalAmount || service.$storage.order.grandTotal;
        var unroundedGratuity = total * (pVal / 100);
        return service.roundGratuity(unroundedGratuity);
    };

	service.roundGratuity = function (unroundedGratuity) {
		var defaultTipDenomination = service.$storage.region == 'US' ? 1 : 100;
		var minDenom = _.get(service.$storage.rosConfig, 'currencySettings.minimalTipDenomination', defaultTipDenomination);
        minDenom = minDenom / 100;

        const roundedGratuity = _.round(unroundedGratuity / minDenom) / (1 / minDenom);
        return _.round(roundedGratuity, 2);
    };

	service.calculateTTPromotionFromPercentage = function (promotion, total) {
		if (promotion.minOrderPrice && total < promotion.minOrderPrice) return 0;
		let promotionValue = promotion.value;
		let promotionType = promotion.valueType;
		//let total = _total || service.$storage.order.itemsTotal;
		let discount;
		switch (promotionType) {
			case "percent":
				discount = total * (promotionValue / 100);
				break;
			case "amount":
				discount = Math.min(total, promotionValue);
				break;
		}
		return service.roundTTPromotion(discount);
	}

    service.roundTTPromotion = function (unroundedDiscount) {
        var minDenom = (service.$storage.rosConfig.currencySettings &&
                        service.$storage.rosConfig.currencySettings.minimalDiscountDenomination) || 100;
        minDenom = minDenom / 100;

        var roundedDiscount = _.round(unroundedDiscount / minDenom) / (1 / minDenom);
        return _.round(roundedDiscount, 2);
    };


    return service;

});

function afterHandoff(service, resultOrder, args) {
	service.$storage.orderClosed = true;
    service.$storage.order.reference = resultOrder.number;
}



angular.module('app').factory('EntityServiceSim', function ($http, $q, ENV, blockUI, $translate, $sessionStorage,
                                                            $localStorage, $location, $uibModal, fbInit, $timeout, MetaService, ServiceCommon) {

    /*------------------------------------------------------------------------------------------*/

    return {};
});

angular.module('app').factory('ServiceAgent', function ($http, $q, ENV, blockUI, $translate,
                                                        $localStorage, MetaService, CONSTS) {

    let $storage = MetaService.$storage;

    let service = {
        //----------------------------------------------------------------------------------------->
        // shared functions
        //----------------------------------------------------------------------------------------->

        getEndpoint() {
            let deferred = $q.defer();
            deferred.resolve(ENV.apiEndpoint);
            return deferred.promise;
        },

        getPublicTokenData(organization){
            let authData = {
                grant_type: 'client_credentials',
                client_id: ENV.clientId,
                scope: organization ? `online-account organization/${organization}` : 'online-account'
            };
            return $http.post(`${ENV.apiEndpoint}/oauth2/token`, authData).then(function (res) {
                return res.data;
            })
        },

        getPublicToken(organization){
            return this.getPublicTokenData(organization).then(function(ret){
                return ret && ret.access_token;
            })
        }


    };

    service.get = function (params, dontBlock, errorInfo, forSite) {
        if (!dontBlock) blockUI.start(params.loadingCaption);
        if (!params.url) params.url = params.type;

        return service.execHttpCall('GET', params.url, null, errorInfo, forSite)
            .then(function (result) {
                if (!dontBlock) blockUI.stop();
                return result;
            });
    };

    service.update = function (params, dontBlock, errorInfo) {
        if (!dontBlock) blockUI.start(params.loadingCaption);
        if (!params.url) params.url = params.type;

        return service.execHttpCall('PUT', params.url, params.data, errorInfo)
            .then(function (result) {
                if (!dontBlock) blockUI.stop();
                return result;
            });
    };


	service.create = function (params, dontBlock, errorInfo) {
		if (params && params.throwError) return $q.reject(params.throwError);
        if (!dontBlock) blockUI.start(params.loadingCaption);
        if (!params.url) params.url = params.type;

        return service.execHttpCall('POST', params.url, params.data, errorInfo)
            .then(function (result) {
                if (!dontBlock) blockUI.stop();
                return result;
            });
    };

    service.remove = function (params, dontBlock, errorInfo) {
        if (!dontBlock) blockUI.start(params.loadingCaption);
        if (!params.url) params.url = params.type;

        return service.execHttpCall('DELETE', params.url, null, errorInfo)
            .then(function (result) {
                if (!dontBlock) blockUI.stop();
                return result;
            });
    };

    service.upsert = function (params, dontBlock) {
        if (!dontBlock) blockUI.start(params.loadingCaption);
        if (!params.url) params.url = params.type;

        return service.execHttpCall('PATCH', params.url, params.data)
            .then(function (result) {
                if (!dontBlock) blockUI.stop();
                return result;
            });
    };

    service.execHttpCall = function (method, path, data, errorInfo, forSite) {
        var throwErr = !errorInfo;

        function handleErr(err) {
            console.log('err', err);
            if (throwErr)
                throw err;
            errorInfo.data = err;
        }

        errorInfo = errorInfo || {};
        
        return service.getEndpoint().then(function () {
            if ($storage.user) {
                return $storage.user.auth;
            }
            if (!$storage.auth)
                return service.authenticateWithRos(ENV.apiEndpoint, errorInfo);
            else
                return $storage.auth;
        }).then(function (auth) {
            if (auth) {
                return service.execCallWithAuthAsync(ENV.apiEndpoint, auth, method, path, data, forSite);
            } else if ($storage.user) {
                throw { status: 401 };
            } else {
                throw (errorInfo.data || 'authentication failed');
            }
        }).then(function (res1) {
            return res1.data;
        }, function (err) {
            if (err.status === 401) {
                errorInfo = {};
                if ($storage.user) {
                    var restoreCred = $localStorage[CONSTS.storageToken];
					if (restoreCred && restoreCred.refresh_token) {
						restoreCred.rememberMe = true;
					} else if ($storage.user.auth) {
						restoreCred = $storage.user.auth;
					}

					if (restoreCred && restoreCred.refresh_token) {
                        return service.authenticateWithRos(ENV.apiEndpoint, errorInfo, restoreCred).then(function (auth2) {
                            if (errorInfo.data) throw errorInfo.data;
                            $storage.user.auth = auth2;
                            return service.execCallWithAuthAsync(ENV.apiEndpoint, auth2, method, path, data, forSite)
                        }).then(function (res2) {
                            return res2.data
                        }).catch(function (err) {
                            handleErr(err);
                        })
                    } else {
                        handleErr(err);
                    }
                } else {
                    return service.authenticateWithRos(ENV.apiEndpoint, errorInfo).then(function (auth2) {
                        return service.execCallWithAuthAsync(ENV.apiEndpoint, auth2, method, path, data, forSite)
                            .then(
                                function (res2) {
                                    return res2.data
                                },
                                function (err) {
                                    handleErr(err);
                                });
                    }, function (err) {
                        handleErr(err);
                    })
                }
            } else {
                handleErr(err);
            }
        })
    };

    service.getSiteId = function () {
        var siteId = $storage.order && $storage.order.branch
            && $storage.order.branch._id;
        if (!siteId) siteId = $storage.defaultSiteId;
        if (!siteId && $storage.organization && $storage.organization.branches &&
            $storage.organization.branches[0] && $storage.organization.branches[0]._id)
            siteId = $storage.organization.branches[0]._id;

        return siteId;
    };

    service.execCallWithAuthAsync = function (apiEndpoint, auth, method, path, data, forSite) {
        var req = buildHeader(auth, forSite);
        req.method = method.toUpperCase();
        req.url = apiEndpoint + '/' + path;
        req.data = data;
        return $http(req);
    };

    service.authenticateWithRos = function (apiEndpoint, errorInfo, cred) {
        var authData = {
            grant_type: 'client_credentials',
            client_id: ENV.clientId,
            scope: "online-account"
        };
        var restoreTokenStorage = false;
        if (cred) {
            authData.scope = "online-account";
            if (cred.refresh_token) {
                restoreTokenStorage = true;
                authData.grant_type = "refresh_token";
                authData.refresh_token = cred.refresh_token;
            } else {
                authData.grant_type = "password";
                authData.username = cred.username;
                authData.password = cred.password;
            }
        } else {

        }
        /*
        REMOVED
        if (siteId) {
            authData.scope = ((authData.scope || '') + ' organization/' + siteId).trim();
        }
        */
        return $http.post(apiEndpoint + '/oauth2/token', authData).then(function (res) {
            res.data.timestamp = GETREALDATE();
            //res.data.org = siteId;
            if (cred) {
                if (cred.rememberMe || restoreTokenStorage)
                    $localStorage[CONSTS.storageToken] = angular.copy(res.data);
                //delete res.data.refresh_token;
            }
            else
                $storage.auth = res.data;
            return res.data;
        }, function (err) {
            if (errorInfo)
                errorInfo.data = err;
            console.log('err', err);
        });
    };

    service.getMulti = function (arrEntities) {
        var deferred = $q.defer();
        var httpArr = [];
        var ret = {};
        $.each(arrEntities, function (i, ent) {
            var key = ent.name;
            httpArr.push(
                ServiceAgent.get(ent).then(function (response) {
                    ret[key] = response;
                    deferred.notify(key);
                }, function () {
                    ret[key] = "Error";
                    deferred.notify(key);
                })
            );
        });
        $q.all(httpArr).then(function () {
            deferred.resolve(ret);
            blockUI.stop();
        }, function (errorValue) {
            deferred.reject(errorValue);
            blockUI.stop();
        });
        return deferred.promise;
    };


    function buildHeader(auth, forSite) {
        var config = {};
        config.headers = {
			'Authorization': (auth.token_type || 'Bearer') + ' ' + auth.access_token,
		}
		config.headers['ros-organization'] = forSite || service.getSiteId();
		switch ($storage.catalogTrans || $storage.local) {
			case "he-IL": config.headers['Accept-Language'] = "he-IL"; break;
			case "en-US": config.headers['Accept-Language'] = "en-US"; break;
		}
        return config;
    }

    service.currentToken = function () {
        if ($storage.user) {
            return $storage.user.auth && $storage.user.auth.access_token;
        }
        return $storage.auth && $storage.auth.access_token;
    };

    /*------------------------------------------------------------------------------------------*/
    return service;
});


angular.module('app')
    .constant('CONSTS', {
        storageToken: "tabit_co" ,
        storageToken_keys: "tabit_co_keys",
        tdTypeMap: {
            "takeaway": "TA",
            "eatin": "OTC",
            "delivery": "Delivery"
        },
        tdServiceTypeMap: {
            "eatin": "seated"
        },
        tdOrderTypeMap: {
            "Seated" : "eatin",
            "Delivery": "delivery",
            "TA": "takeaway"
        },
        tdTenderMap: {
            "CASH": "cash",
            "CREDIT": "creditCard",
            "CIBUS": "Cibus",
            "TENBIS": "10bis"
        },
        AUTH_EXP_THRESHOLD_MS: 60000
});
angular.module('app').factory('ServiceCommon', function ($q, $translate, MetaService, CONSTS, $localStorage,
                                                         $uibModal, blockUI, BenefitsService, $filter) {
    var service = {
        $storage: MetaService.$storage
    };

    service.ccInfoToWalletPayment = function (ccinfo) {
        if (ccinfo && !ccinfo.$$isExternal) {
            var parsedPayment = service.getPreparedPayment(ccinfo);
            return {
                paymentType: parsedPayment.paymentType,
                pan: parsedPayment.cardNum.toString(),
                expMonth: parsedPayment.expMonth,
                expYear: parsedPayment.expYear,
                brand: ccinfo.type,
				ownerName: parsedPayment.name,
				comment: ccinfo.comment
            }
        }
    }

    service.getPreparedPayment = function (pinfo) {
        //var order = service.$storage.order;
        var amount = Math.round(pinfo.amount * 100);// || order.balanceDue || order.grandTotal) * 100);
        var tip = null;
        if (pinfo.tip) {
            //amount = Math.round(amount + (pinfo.tip * 100));
            tip = { amount: Math.round(pinfo.tip * 100) }
        }

        if (pinfo.walletPayment) {
            return {
                paymentType: pinfo.paymentMethod,
                walletPayment: pinfo.walletPayment,
                amount: amount,
                tip: tip,
                customerDetails: pinfo.customerDetails,
                cvv: pinfo.cvv ? pinfo.cvv.toString() : (pinfo.paymentType === 'creditCard' ? 'MISSING' : null),
				idCard: pinfo.idCard ? pinfo.idCard.toString() : (pinfo.paymentType === 'creditCard' ? 'MISSING' : null),
				account: pinfo.account,
				merchantNumber: pinfo.merchantNumber
            }
        } else {
            if (window.ISDESKTOP){
                var retURL = document.location.origin + "/expayment_ret.html"
            }else{
                var retURL = UIUtils.getCleanURL();
            }
			var base = {
				account: pinfo.account,
                paymentType: pinfo.paymentMethod,
				cardNum: pinfo.cardNum ? pinfo.cardNum : pinfo.number && pinfo.number.toString(),
                amount: amount,
                customerDetails: pinfo.customerDetails,
                tip: tip,
                returnUrl: retURL,
				$$isExternal: pinfo.$$isExternal,
				iframeUrl: pinfo.iframeUrl,
				merchantNumber: pinfo.merchantNumber,
				isDesktop: window.ISDESKTOP,
				verificationDetails: {
					zipCode: pinfo.zip,
					address1: pinfo.line1,
					city: pinfo.city,
					state: pinfo.state,
				}
            }

            if (pinfo.$$isExternal) return base;

            switch (pinfo.paymentMethod) {
                case "creditCard":
                    var d = moment(pinfo.cardExpiration);
                    return _.extend(base, {
                        expMonth: d.format("MM"),
                        expYear: d.format("YYYY"),
                        name: pinfo.name,
                        cardNum: pinfo.number.toString(),
                        cvv: pinfo.cvv.toString(),
                        idCard: pinfo.idCard && pinfo.idCard.toString(),
					});
				case "giftCard":
					return _.extend(base, {
						cvv: pinfo.cvv,
						cardNum: pinfo.cardNum
					});					
					break;
                case "cash":
                    return _.extend(base, {});
                default:
                    return _.extend(base, {});
            }
        }

    };

    service.didUserTokenExpired = function (user) {
        if (user && user.auth) {
            return service.timeToExpiration(user.auth) < CONSTS.AUTH_EXP_THRESHOLD_MS;
        }
    };

    service.timeToExpiration = function (auth) {
        if (!auth.timestamp || !auth.expires_in) return CONSTS.AUTH_EXP_THRESHOLD_MS * 2;
        return moment(auth.timestamp).add(moment.duration(auth.expires_in, 'seconds')).diff(GETREALDATE());
    };

    service.translateServerError = function (errorData, entity, defaultKey) {
        var errName = errorData.data && errorData.data.data && errorData.data.data.name;
        var key = defaultKey || "MESSAGES.SERVER_ERROR";
        if (errName) {
            key = ("MESSAGES." + (entity ? entity + "_" + errName : errName)).toUpperCase();
        }
        var translation = $translate.instant(key);
        return translation == key ? $translate.instant(defaultKey) : translation;
    };

    service.restoreKEY = function (key) {
        if (!$localStorage[CONSTS.storageToken_keys]) {
            $localStorage[CONSTS.storageToken_keys] = {};
        }
        return $localStorage[CONSTS.storageToken_keys][key];
    };
    service.cacheKEY = function (key, val) {
        if (!$localStorage[CONSTS.storageToken_keys]) {
            $localStorage[CONSTS.storageToken_keys] = {};
        }
        $localStorage[CONSTS.storageToken_keys][key] = val;
    };

    service.getCustomer = function (args, includeOrderInstructions) {
        var contact = args.contact;
        var phoneNumber = contact.cell && contact.cell.trim();

        //fit phone for international US/IL use
        // - replace an actual international phone solution on the UI
        if (phoneNumber && (phoneNumber.length > 10) && (phoneNumber[0] !== '+')) {
            if ((phoneNumber[0] === '1') || (phoneNumber.substr(0, 3) === '972'))
                phoneNumber = '+' + phoneNumber;
        }
        //***

        var customer = {
			name: contact.name,
			firstName: contact.firstName,
			lastName: contact.lastName,
            phone: phoneNumber,
            email: contact.email
        };
        if (includeOrderInstructions) {
            customer.dinerCount = contact.dinerCount;
            customer.includeCutlery = contact.includeCutlery;
        }
		var address = args.address;

		if (!address && service.$storage.order.$$curbsidePickup && service.$storage.order.$$curbsidePickupDesc) {
			customer.deliveryAddress = {
				notes: `${$translate.instant("CURBSIDE_PICKUP")}: ${service.$storage.order.$$curbsidePickupDesc}`
			}
		}

		if (address) {
			let addressNotes = address.remarks;
			if (service.$storage.order.$$leaveOutside) {
				if (!addressNotes) addressNotes = $translate.instant("LEAVE_ORDER_OUTSIDE_COMMENT");
				else addressNotes += ', ' + $translate.instant("LEAVE_ORDER_OUTSIDE_COMMENT");
			}

            customer.deliveryAddress = {
                city: address.locality,
                street: address.street,
                house: address.streetNumber,
                floor: address.floor && address.floor.toString(),
                apartment: address.appartment && address.appartment.toString(),
                entrance: address.entrance,
				location: address.point,
				state: address.state,
				postalCode: address.postalCode,
				notes: addressNotes
            }
            var region = args.region;
            if (region){
                customer.deliveryAddress.regionId = region.id;
                customer.deliveryAddress.regionName = region.name;
			}

			if (address.isMapAddress) {
				customer.deliveryAddress.isMapAddress = true;
				customer.deliveryAddress.formatted_address = address.formatted_address;
			}
        }
        return customer;
    };

	service.getClientOrderId = function() {
		if (!service.$storage.order_id) {
			service.$storage.order_id = service.generateMongoObjectId();
		}
		return service.$storage.order_id;
	}

	service.getPreparedOrder = function (_clean, options) {
		if (!options) options = {}
        var order = service.$storage.order;
		var site = service.$storage.order.branch;

		var ret = {
			deliveryInfo: {
				address: _.cloneDeep(order.address),
				version: "1.45.2",
				"userAgent": navigator.userAgent,
				"platform": navigator.platform
			},
			onlineSource: {
				name: 'Tabit Order v1'
			},
            orderType: CONSTS.tdTypeMap[order.mode],
            serviceType: CONSTS.tdServiceTypeMap[order.mode],
            orderer: service.getCustomer(order, true),
            balanceDue: order.grandTotal,
            balance: Math.round(order.grandTotal * 100),
			table: order.table
		}
		if (order.mode == 'takeaway' && order.branch.takeawayToBeSentAs == 'OTC') {
			ret.orderType = 'OTC';
			ret.serviceType = 'takeaway';
		}


		if (order.tableNumber) {
			let addressNotes = _.get(ret.orderer, 'deliveryAddress.notes');
			let tnCaption = $translate.instant(service.$storage.tableNumberCaption);
			if (addressNotes) addressNotes += `. ${tnCaption}: ${order.tableNumber}`;
			else addressNotes = `${tnCaption}: ${order.tableNumber}`;
			_.set(ret.orderer, 'deliveryAddress.notes', addressNotes);
		}

		let orderNotes = _.get(order, 'notes', null);
		if (orderNotes) {
			ret.orderTags = [orderNotes];
        }

		let delayedOrder = service.$storage.delayedOrder;
		if (delayedOrder.active) {
			var suppliedRequestTime;

			let deliveryEstimate = _.get(service.$storage.order, 'branch.exDeliveryProvider.estimate');
			if (deliveryEstimate) {
				let prepTime = _.get(site, 'takeaway.preparationTime', 20);
				ret.deliveryInfo.toBePreparedOn = moment(deliveryEstimate.pickupTime).subtract(prepTime, 'minutes').toDate();
				ret.deliveryInfo.toBeSuppliedOn = new Date(deliveryEstimate.deliveryTime);
			} else {
				switch (delayedOrder.method) {
					case "today":
						suppliedRequestTime = delayedOrder.todaySlot.date;
						break;
					case "delayed":
						suppliedRequestTime = delayedOrder.slot.date;
						ret.deliveryInfo.toBeSuppliedMax = delayedOrder.slot.dateTo;
						if (options.asapOnSupplyDay) {
							ret.deliveryInfo.asapOnSupplyDay = true;
						}
						break;
				}
				var prepareTime = moment(suppliedRequestTime);
				var minOrderDelay = service.$storage.minOrderDelay;

				if (ret.orderType === "Delivery") {
					var mdiff = minOrderDelay || 60;
					prepareTime.add(mdiff * -1, 'minutes')
				} else if (ret.orderType === "TA") {
					var mdiff = minOrderDelay || 30;
					prepareTime.add(mdiff * -1, 'minutes')
				}
				ret.deliveryInfo.toBePreparedOn = prepareTime.toDate();
				ret.deliveryInfo.toBeSuppliedOn = suppliedRequestTime;
			}
		}
		if (options.eta) {
			ret.deliveryInfo.ETA = options.eta;
			if (options.clientInitialDate) ret.deliveryInfo.initialDate = options.clientInitialDate;
			if (options.orderStartDate) ret.deliveryInfo.orderStartDate = options.orderStartDate;
			if (options.serverDateDiff) ret.deliveryInfo.serverDateDiff = options.serverDateDiff;
		};

        if (!service.$storage.user) ret.orderer.unknown = true;

        if (service.$storage.orderMode == 'fixedorder') {//fixed order should be processesed by order_id
            ret.order_id = service.$storage.order.order_id;
            ret.__v = service.$storage.order.__v;
            ret.balanceDue = service.$storage.order.balanceDue;
            ret.balance = Math.round(service.$storage.order.balanceDue * 100);
		} else {
			ret._id = service.getClientOrderId();
            ret.orderedOffers = [];
            _.each(service.$storage.basket, function (offer) {
                var parsedOffer = {
                    "amount": Math.round(offer.total * 100),
                    "menu": offer.menu,
                    "price": Math.round(offer.price * 100),
                    "offer": offer.offer,
                    "name": offer.realName || _.get(offer, "_offer.name", offer.name),
                    "orderedItems": []
                };

                var note = offer.offerRemrks;
                // main offer item
                if (offer._item) {
					var pitem = prepareBasketOfferItem(offer._item, note, offer._item.total);
					if (offer.isWeight) {
						pitem.uom = offer.uom;
						pitem.units = offer.baseUnits;
					}
                    if (pitem) {
                        parsedOffer.orderedItems.push(pitem);
                        note = null;
                    }
                }
                // basket items
                _.each(offer.selectionSummary, function (selectionGroup) {
					_.each(selectionGroup.items, function (item) {
						let count = item.count || 1;
						for (let i = 0; i < count; i++) {
							var pitem = prepareBasketOfferItem(item._item, note, item.price);
							if (pitem) {
								parsedOffer.orderedItems.push(pitem);
								note = null;
							}
						}
                    });
                });
                var q = offer.quantity || 1;
                for (var i = 0; i < q; i++) {
                    ret.orderedOffers.push(angular.copy(parsedOffer));
                }


                function prepareBasketOfferItem(item, note, itemPrice) {
                    var parsedItem = {
                        "category": item.category._id,
                        "menu": offer.menu,
                        "offer": offer.offer,
                        "item": item._id,
						"name": item.realName || item.name,
                        "price": Math.round(itemPrice * 100),
                        "itemGroup": item.itemGroup
                    };
                    if (note)
                        parsedItem.note = note;
                    var itemAmount = parsedItem.price || 0;
                    var modifiers = [];
                    var removedModifiers = [];
                    _.each(item.modifierGroups, function (group) {
                        if (group.singleSelection) {
                            var mod = _.find(group.modifiers, { '_selected': true });
                            if (mod.price) itemAmount = itemAmount + Math.round(mod.price * 100);
                            modifiers.push({ 
                                "modifier": mod._id, 
                                "price": mod.price ? Math.round(mod.price * 100) : 0,
								"name": mod.realName || mod.name,
								item: (mod.item && mod.item._id) || mod.item,
								modifierGroup: group._id,
                                modifierGroupName: group.name,
                                "isDefault": mod._isDefault
                            });
                        } else {
                            var isGroupCheckbox = group.modifierBehavior == "checkbox";
                            _.each(group.modifiers, function (mod) {
                                if (mod.formationUnit) {
                                    if (mod.price) itemAmount = itemAmount + Math.round(mod.price * 100);
                                    modifiers.push({
                                        modifier: mod._id,
                                        formationUnit: mod.formationUnit,
                                        price: Math.round(mod.price * 100),
										name: mod.realName || mod.name,
										item: (mod.item && mod.item._id) || mod.item,
										modifierGroup: group._id,
                                        modifierGroupName: group.name,
                                        "isDefault": mod.defaultFormationUnit != null
                                    });

                                } else if (mod.defaultFormationUnit) {
                                    removedModifiers.push({
                                        modifier: mod._id,
                                        formationUnit: mod.defaultFormationUnit,
										name: mod.realName || mod.name,
										item: (mod.item && mod.item._id) || mod.item,
										modifierGroup: group._id,
                                        modifierGroupName: group.name
                                    });
                                }
                            });
                        }

                    });
                    parsedItem.selectedModifiers = modifiers;
                    parsedItem.removedModifiers = removedModifiers;
                    parsedItem.amount = Math.round(itemAmount);
                    return parsedItem;
                }
            });

			if (!_clean) {
				// extra questions
				_.each(service.$storage.extraQuestions, (question) => {
					if (question.active) {
						if (!ret.deliveryInfo.declerations) ret.deliveryInfo.declerations = [];
						ret.deliveryInfo.declerations.push(question.name);
						if (question.offer) {
							let newOffer = angular.copy(question.offer);
							delete newOffer.limitBy;
							delete newOffer.organization;
							delete newOffer.active;
							ret.orderedOffers.push(newOffer);
						}
					}
				});

				// add extra offers
                _.each(service.$storage.order.extraOffers, (offer) => {
					let count = offer.count || 0;
					if (count) {
						let newOffer = angular.copy(offer);
						delete newOffer.active;
						delete newOffer.count;
						delete newOffer.maxCount;
						for (var i = 0; i < count; i++) {
							ret.orderedOffers.push(newOffer);
						}
					}
				});
				// add extra offers groups
				_.each(service.$storage.order.extraOffersGroups, (group) => {
					_.each(group.offers, (offer) => {
						let count = offer.count || 0;
						if (count) {
							let newOffer = angular.copy(offer);
							delete newOffer.active;
							delete newOffer.count;
							delete newOffer.maxCount;
							for (var i = 0; i < count; i++) {
								ret.orderedOffers.push(newOffer);
							}
						}
					});
				});
            }

            // add delivery offer
            if (!_clean && !order.freeDelivery) {
                if (order.mode == 'delivery' && order.region && order.region.deliveryOffer) {
                    console.log("adding delivery offer to order");
                    var deliveryOffer = angular.copy(order.region.deliveryOffer);
                    delete deliveryOffer._id;
                    deliveryOffer.amount = deliveryOffer.price;
                    deliveryOffer.orderedItems[0].price = null;
                    deliveryOffer.isDeliveryFee = true;
                    ret.orderedOffers.push(deliveryOffer);
                }
            }
			ret.orderedDiscounts = [];
            if (order.itemsDiscount && order.TTPromotion) {
                var discount = {
                    discountType: 'amount',
                    value: Math.round(order.itemsDiscount * 100),
                    onlinePromoId: order.TTPromotion._id,
                    comment: order.TTPromotion.name,
                    valueType: order.TTPromotion.valueType,
                    reason: order.TTPromotion.reason,
                };
                ret.orderedDiscounts.push(discount);
            }
		}

		BenefitsService.prepareRosOrder(ret);
        return ret;
	};

	service.hasBasketAlcohol = function () {
		let hasAlcohol = false;
		_.each(service.$storage.basket, (offer) => {
			checkItem(offer._item);
			if (hasAlcohol) return false;
			//if (hasAlcohol) return;

			_.each(offer.selectionSummary, (selectionGroup) => {
				_.each(selectionGroup.items, (item) => {
					checkItem(item._item);
					if (hasAlcohol) return false;
				});
			});
		});

		if (!hasAlcohol) {
			_.each(service.$storage.order.extraOffers, (offer) => {
				let count = offer.count || 0;
				if (count) {
					_.each(offer.orderedItems, oi => {
						if (checkModItem(oi.item)) {
							hasAlcohol = true;
							return false;
						};
					})
				}
			});
		}

		return hasAlcohol;

		function checkItem(item) {
			if (!item) return;
			if (item.alcoholQuantity) {
				hasAlcohol = true;
				return false;
			}
			_.each(item.modifierGroups, (group) => {
				if (hasAlcohol) return false;
				if (group.singleSelection) {
					let mod = _.find(group.modifiers, { '_selected': true });
					if (mod) {
						if (checkModItem((mod.item && mod.item._id) || mod.item)) {
							hasAlcohol = true;
							return false;
						};
					}
				} else {
					_.each(group.modifiers, (mod) => {
						if (mod.formationUnit) {
							if (checkModItem((mod.item && mod.item._id) || mod.item)) {
								hasAlcohol = true;
								return false;
							};
						}
					});
				}
			});
		}

		function checkModItem(itemId) {
			if (!itemId) return;
			let item = _.find(service.$storage.catalog.items, { "_id": itemId });
			if (item && item.alcoholQuantity) return true;
		}
	}

	service.generateMongoObjectId = function () {
		var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
		return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
			return (Math.random() * 16 | 0).toString(16);
		}).toLowerCase();
	}

    service.getCVV = function (card) {
        return service.getToken({
            title: 'ENTER_CVV',
            message: $translate.instant('ENTER_CVV_MESSAGE', { card: card }),
			caption: 'ENTER_CVV',
			timeOut: 5 
		}).catch(err => {
			throw({ CANCELED: true });
		});
    };

	service.getToken = function (_args) {
		var args = _.extend({
			title: 'CHALLENGE_CODE',
			message: 'MESSAGES.SIGNIN_CHALLENGE',
			caption: 'CHALLENGE_CODE',
			type: 'default'
		}, _args);
		var blockUiState = angular.copy(blockUI.state());
		var deferred = $q.defer();
		var modalM = $uibModal.open({
			templateUrl: 'modules/app/modals/modal_phonechallenge.html',
			controller: function ($scope, $uibModalInstance, modalParams, blockUI, MetaService, $interval) {
				$scope.MS = MetaService;
				$scope.args = args;
				if (args.timeOut) {
					setCloseInterval(); 
				}

				function setCloseInterval() {
					let end = new Date().valueOf() + (60000 * args.timeOut);
					$scope.closeInterval = $interval(() => {
						let diff = Math.floor((end - new Date().valueOf()) / 1000);
						if (diff < 0) {
							$interval.cancel($scope.strInterval)
							$scope.cancel();
						} else {
							$scope.strInterval = `(${_.padStart(Math.floor(diff / 60), 2, '0')}:${_.padStart(Math.floor(diff % 60), 2, '0')})`;
						}
					}, 1000);
				}
				$scope.$on("$destroy", function (event) {
					$interval.cancel($scope.strInterval)
				});

				$scope.response = _.extend({}, args.response);
				blockUI.stop();
				$scope.save = function () {
					if ($scope.challengeForm.$valid) {
						if ($scope.args.askForSave || $scope.args.retResponse) {
							$uibModalInstance.close($scope.response);
						} else {
							$uibModalInstance.close($scope.response.token);
						}
					}
				};
				$scope.cancel = function () {
					$uibModalInstance.dismiss('cancel');
				};
			},
			windowClass: "modal-default",
			//size: "sm",
			resolve: {
				modalParams: function () {
					return {
						args: args
					};
				}
			}
		});
		modalM.result.then(function (result) {
			if (blockUiState.blocking)
				blockUI.start(blockUiState.message);
			deferred.resolve(result);
		}, function () {
			if (blockUiState.blocking)
				blockUI.start(blockUiState.message);
			deferred.reject();
		});
		return deferred.promise;
	}

    service.serverErrorToAppError = function (serverError) {
		if (!serverError) return;
		let errCode = _.get(serverError, 'data.code');
		if (errCode) errCode = String(errCode);
		switch (errCode) {
			case "409001":
				return {
					type: 'SERVER_ERROR',
					message: $translate.instant("MESSAGES.ORDER_HELD_BY_SERVER"),
					serverError: serverError
				}
			case "409098":
				return {
					type: 'PAYMENT_VALIDATION_ERROR',
					message: $translate.instant("SERVER_MESSAGES.SHVA_OFFLINE"),
					serverError: serverError
				}
			case "409188":
				return {
					type: 'PAYMENT_VALIDATION_ERROR',
					message: $translate.instant("SERVER_MESSAGES.CARD_VALIDATION_MISMATCH"),
					serverError: serverError
				}
			case "409110":
				return {
					type: 'SERVER_ERROR',
					message: $translate.instant("SERVER_MESSAGES.SMS_PHONE_ERROR"),
					serverError: serverError
				}
			case "409031":
				let balance = _.get(serverError, 'data.data.providerResponse.budget');
				if (balance) {
					if (!isNaN(balance)) balance = $filter('money')(balance)
					return {
						type: 'PAYMENT_VALIDATION_ERROR',
						message: $translate.instant("SERVER_MESSAGES.PAYMENT_QUOTA_EXCEEDED", { v: balance } ),
						serverError: serverError
					}
				}				
		}
    };

    return service;
});

angular.module('app').factory('ExternalDeliveryService', function ($q, $http, blockUI,PDialog, MetaService,	ServiceAgent, ServiceCommon,ENV, $uibModal, $translate) {

	var service = {
		simulated: true,
		$storage: MetaService.$storage,
		prepareSiteForDelivery: prepareSiteForDelivery,
		estimateExDeliveryBeforeMenu: estimateExDeliveryBeforeMenu,
		estimateExDeliveryBeforePay: estimateExDeliveryBeforePay,
		validateExDelivery: validateExDelivery,
		handoffDelivery: handoffDelivery,
		knownErrors: ['OUT_OF_DELIVERY_SERVICE_TIME', 'NO_ADDRESS', 'ADDRESS_OUTSIDE_OF_DELIVERY_AREA', 'INVALID_PHONE', 'PHONE_NO_PSTN', 'ALCOHOL_NOT_DELIVERABLE']
	}
	return service;

	function prepareSiteForDelivery(site, address) {
		if (site.noDeliveryProviders || service.$storage.region != 'US') return $q.resolve();
		if (site.exDeliveryProvider) {
			setDeliveryAddress(site, address);
			return $q.resolve();
		} 
		let useDeliveryProvider = _.get(site, 'delivery.useDeliveryProvider');
		if (!useDeliveryProvider) {
			site.noDeliveryProviders = true;
			return $q.resolve();
		}

		return getExternalDeliveryProvider(site._id).then(provider => {
			if (!provider) {
				site.noDeliveryProviders = true;
				return;
			}
			let base = {
				"provider": provider.id,
				"organization": {
					"name": site.name,
					"address": site.location.formatted_address, // Hebrew good too
					"tel": site.phone
				},
				"organizationCoordinates": {
					"lat": site.location.geometry.location.lat,
					"lng": site.location.geometry.location.lng,
				},
				"orders": [
					{
						"_id": service.$storage.order_id,
						"number": undefined,
						"created": new Date(),
						"deliveryInfo": {},
						"orderer": {
							mainPhone: {
								pstn: parsePhone(site.phone)
							}
						},
						"payments": {},
						"totals": {
							"totalAmount": 0
						},
						"containsAlcohol": false
					}
				]
			}
			site.exDeliveryProvider = _.assignIn(provider, { base: base });
			setDeliveryAddress(site, address);
		}).catch(err => {
			let message = _.get(err, 'message', 'Error loading provider configurations');
			PDialog.warning({ text: message });
			throw (err);
		})
	}

	function estimateExDeliveryBeforeMenu(order) {
		let site = order.branch;
		if (!site.exDeliveryProvider) return $q.resolve();

		let base = site.exDeliveryProvider.base;
		let providerOrder = base.orders[0];
		setDeliveryAddress(site, order.address);

		delete service.orderDelayEta;

		let futureOrder = service.$storage.delayedOrder, reffDate;
		let orderDelay = service.$storage.order.orderDelay;
		if (futureOrder && futureOrder.active) {
			providerOrder.deliveryInfo.ETA = futureOrder.slot.date;
			service.orderDelayEta = reffDate = providerOrder.deliveryInfo.ETA;
		} else if (orderDelay && orderDelay.active) {
			providerOrder.deliveryInfo.ETA = orderDelay.todaySlot.date;
			service.orderDelayEta = reffDate = providerOrder.deliveryInfo.ETA;
		} else {
			let preparationTime = _.get(site, 'takeaway.preparationTime', 20);
			base.pickupAt = GETREALDATE(true).add(preparationTime + 5, 'minutes').toDate();
			reffDate = base.pickupAt;
		}

		blockUI.start();
		return callHTTP('POST', '/deliveries/external/online-shopper/pre?type=estimate', base).then(res => {
			blockUI.stop();

			let estimate = _.get(res, 'orders[0]');
			if (!estimate) throw ({});
			prepareEstimate(estimate);
			site.exDeliveryProvider.estimate = estimate;

			let diffD = 10;
			if (service.orderDelayEta) {
				diffD = Math.abs(moment(estimate.deliveryTime).diff(service.orderDelayEta, 'minutes'));
			}
			if (diffD > 5) {
				return showExDeliveryEstimate(estimate).then(res => {
					site.exDeliveryProvider.estimate = estimate;
					setEstimate(estimate);
				});
			} else {
				site.exDeliveryProvider.estimate = estimate;
				setEstimate(estimate);
			}
		}).catch(err => {
			blockUI.stop();
			if (_.get(err, 'type') != 'CANCEL') {
				let errCode = _.get(err, 'data.code');
				if (!service.knownErrors.includes(errCode)) errCode = 'UNKNOWN_ERROR';
				let message = $translate.instant(`_EXTERNAL_DELIVERY.${errCode}`, { address: order.address.formatted_address });
				PDialog.warning({ text: message });
			}
			throw (err);
		});
	}

	function estimateExDeliveryBeforePay() {
		if (service.$storage.orderClosed) return $q.resolve();
		let order = service.$storage.order;
		let site = order.branch;
		if (!site.exDeliveryProvider) return $q.resolve();

		let base = site.exDeliveryProvider.base;
		let providerOrder = base.orders[0];

		providerOrder.orderer = ServiceCommon.getCustomer(order, false);
		_.set(providerOrder.orderer, 'mainPhone.pstn', parsePhone(providerOrder.orderer.phone));
		delete providerOrder.orderer.phone;

		providerOrder.containsAlcohol = ServiceCommon.hasBasketAlcohol();
		providerOrder.totals.totalAmount = Math.round(order.grandTotalForPay * 100);

		
		let orderDelay = service.$storage.delayedOrder, reffDate;
		if (orderDelay && orderDelay.active) {
			delete base.pickupAt;
			reffDate = _.get(orderDelay, 'slot.date');
			if (!reffDate) reffDate = orderDelay.todaySlot.date;
			providerOrder.deliveryInfo.ETA = service.orderDelayEta = reffDate;
		} else {
			delete service.orderDelayEta;
			delete providerOrder.deliveryInfo.ETA;
			let preparationTime = _.get(site, 'takeaway.preparationTime', 20);
			base.pickupAt = GETREALDATE(true).add(preparationTime + 5, 'minutes').toDate();
			reffDate = base.pickupAt;
		}

		blockUI.start();
		return callHTTP('POST', '/deliveries/external/online-shopper/pre?type=estimate', base).then(res => {
			blockUI.stop();

			let estimate = _.get(res, 'orders[0]');
			if (!estimate) throw ({});
			prepareEstimate(estimate);

			let oldEstimate = site.exDeliveryProvider.estimate;
			let diffMinutes = moment(estimate.deliveryTime).diff(oldEstimate.deliveryTime, 'minutes');
			if (service.orderDelayEta) {
				site.exDeliveryProvider.estimate = estimate;
			}else if (diffMinutes > 5) {
				return showExDeliveryEstimate(estimate).then(res => {
					site.exDeliveryProvider.estimate = estimate;
					setEstimate(estimate);
				});
			}
			
		}).catch(err => {
			blockUI.stop();
			if (_.get(err, 'type') != 'CANCEL') {
				let errCode = _.get(err, 'data.code');
				if (!service.knownErrors.includes(errCode)) errCode = 'UNKNOWN_ERROR';
				let message = $translate.instant(`_EXTERNAL_DELIVERY.${errCode}`);
				PDialog.warning({ text: message });
			}
			throw (err);
		});
	}

	function validateExDelivery(resultOrder) {
		return $q.resolve(resultOrder)
	}

	function handoffDelivery(handoffResult) {
		let order = service.$storage.order;
		let site = order.branch;
		if (!site.exDeliveryProvider) return $q.resolve();

		return ServiceAgent.get({
			url: `online-shopper/orders/${service.$storage.order_id}`,
			loadingCaption: 'LOADING_ORDER'
		}).then(resultOrder => {

			let base = site.exDeliveryProvider.base;
			let exOrder = _.get(base, 'orders[0]');

			exOrder._id = resultOrder._id;
			exOrder.created = resultOrder.created;
			exOrder.number = resultOrder.number;
			exOrder.payments = resultOrder.payments;
			exOrder.totals = resultOrder.totals;
			exOrder.orderer = resultOrder.orderer;
			exOrder.deliveryInfo = resultOrder.deliveryInfo;
			return callHTTP('POST', '/deliveries/external/online-shopper', base).then(delivery => {
				let resuleOrder = _.get(delivery, 'orders[0]', {});
				delivery.order = resuleOrder;
				delete delivery.orders;
				service.$storage.delivery = delivery;
			});
		}).catch(err => {
			if (!err.message) err.message = _.get(err, 'message', _.get(err, 'data.error', 'Error creating external delivery'));
			throw (err);
		})

	}

	function showExDeliveryEstimate(estResponse) {
		var deferred = $q.defer();
		let modal = $uibModal.open({
			templateUrl: 'modules/app/modals/modal_external_delivery.html',
			controller: ($scope, $uibModalInstance, modalParams) => {
				$scope.args = modalParams;
				$scope.apply = function () {
					$uibModalInstance.close({});
				}
				$scope.cancel = function () {
					$uibModalInstance.dismiss('cancel');
				}
			},
			windowClass: "modal-center _dark",
			backdrop: 'static',
			resolve: {
				modalParams: {
					mode: 'estimate',
					data: estResponse
				}
			}
		});
		modal.result.then(result => {
			if (result) {
				deferred.resolve();
			}
			else deferred.reject({ type: 'CANCEL' });
		}, () => {
			deferred.reject({ type: 'CANCEL' });
		});
		return deferred.promise;
	}

	//---------------------------------------------------------------------------------------------->
	//---------------------------------------------------------------------------------------------->
	//---------------------------------------------------------------------------------------------->

	function setEstimate(estimate) {
		//_.set(service.$storage.order.region, 'deliveryPrice', estimate.price);
		_.set(service.$storage.order.region, 'deliveryTime', estimate.deliveryTimeMinutes);
		//delete service.$storage.order.region.freeDeliveryFrom;
	}

	function prepareEstimate(estimate) {
		estimate.price = estimate.delivery_provider_estimation_fee / 100;
		estimate.deliveryTime = estimate.delivery_provider_dropoff_eta
		estimate.pickupTime = estimate.delivery_provider_pickup_eta;
		estimate.deliveryTimeParsed = `${moment(estimate.deliveryTime).diff(GETREALDATE(true), 'minutes')} min. (${moment(estimate.deliveryTime).format(MetaService.local.timeFormat)})`;
		estimate.pickupTimeParsed = moment(estimate.pickupTime).format(MetaService.local.timeFormat);
		estimate.deliveryTimeMinutes = moment(estimate.delivery_provider_dropoff_eta).diff(GETREALDATE(true), 'minutes');
	}

	function parsePhone(phoneNumber) {
		if (phoneNumber && (phoneNumber.length > 10) && (phoneNumber[0] !== '+')) {
			if ((phoneNumber[0] === '1') || (phoneNumber.substr(0, 3) === '972'))
				phoneNumber = '+' + phoneNumber;
		} else {
			switch (service.$storage.region) {
				case "US": phoneNumber = `+1${phoneNumber}`; break;
				case "IL": phoneNumber = `+972${phoneNumber}`; break;
			}			
		}
		return phoneNumber;
	}

	function setDeliveryAddress(site, address) {
		let formattedAddress = {
			city: address.locality,
			street: address.street,
			house: address.streetNumber,
			floor: address.floor && address.floor.toString(),
			apartment: address.appartment && address.appartment.toString(),
			entrance: address.entrance,
			location: address.point,
			state: address.state,
			postalCode: address.postalCode,
			notes: address.remarks
		}
		_.set(site.exDeliveryProvider.base, 'orders[0].orderer.deliveryAddress', formattedAddress);
		_.set(site.exDeliveryProvider.base, 'orders[0].deliveryInfo.address.postalCode', address.postalCode);
	}

	function getExternalDeliveryProvider(siteId) {
		return callHTTP(
			"get",
			"/deliveries/external/online-shopper/available-providers",
			null,
			siteId
		).then(res => {
			let providerId = _.get(res, 'providers[0]');
			if (providerId) {
				let provider = _.get(res.details, providerId);
				if (provider) {
					provider.id = providerId;
					return provider;
				}
			}
		}).catch(err => {
			return null;
		});
	}

	function callHTTP(method, url, data, siteId) {
		if (!ENV.deliveriesEndpoint) ENV.deliveriesEndpoint = "https://us-delivery-service.tabit-int.com";

		let auth = service.$storage.user ? service.$storage.user.auth : service.$storage.auth;
		let config = {
			url: `${ENV.deliveriesEndpoint}${url}`,
			method: method.toUpperCase(),
			data: data,
			headers: {
				'ros-organization': siteId || ServiceAgent.getSiteId(),
				'Authorization': auth ? `Bearer ${auth.access_token}` : undefined,
			}
		}
		if (data) {
			if (method == 'get' || method == 'delete') {
				config.params = data;
			} else {
				config.data = data;
			}
		}
		return $http(config).then(res => {
			return res.data;
		}).catch(error => {
			throw (error);
		});
	}
});




app.controller('heartland_payment_dialog', function ($scope, $uibModalInstance, $translate, modalParams, $timeout, PDialog, blockUI, $interval) {

	setCloseInterval();
	function setCloseInterval() {
		let end = new Date().valueOf() + (60000 * 10);
		$scope.closeInterval = $interval(() => {
			let diff = Math.floor((end - new Date().valueOf()) / 1000);
			if (diff < 0) {
				$interval.cancel($scope.strInterval)
				$scope.cancel();
			} else {
				$scope.strInterval = `(${_.padStart(Math.floor(diff / 60), 2, '0')}:${_.padStart(Math.floor(diff % 60), 2, '0')})`;
			}
		}, 1000);
	}

	$scope.$onDestroy = function () {
		$interval.cancel($scope.strInterval);
		if ($scope.hps) $scope.hps.dispose();
	};

	$scope.status = {}
	$timeout(() => {
		$scope.hps = new Heartland.HPS({
			publicKey: modalParams.merchantNumber,
			type: 'iframe',
			// Configure the iframe fields to tell the library where
			// the iframe should be inserted into the DOM and some
			// basic options
			fields: {
				cardNumber: {
					target: 'iframesCardNumber',
					placeholder: '•••• •••• •••• ••••'
				},
				cardExpiration: {
					target: 'iframesCardExpiration',
					placeholder: 'MM / YYYY'
				},
				cardCvv: {
					target: 'iframesCardCvv',
					placeholder: 'CVV'
				},
				submit: {
					value: $translate.instant("PAY"),
					target: 'iframesSubmit'
				}
			},
			style: {
				'input': {
					'background': '#fff',
					'border': '1px solid',
					'border-color': '#bbb3b9 #c7c1c6 #c7c1c6',
					'box-sizing': 'border-box',
					'font-family': 'serif',
					'font-size': '16px',
					'line-height': '1',
					'margin': '0 .5em 0 0',
					'max-width': '100%',
					'outline': '0',
					'padding': '0.5278em',
					'vertical-align': 'baseline',
					'height': '50px',
					'width': '100% !important'
				},
				'#heartland-field': {
					'font-family': 'sans-serif',
					'box-sizing': 'border-box',
					'display': 'block',
					'height': '50px',
					'padding': '6px 12px',
					'font-size': '14px',
					'line-height': '1.42857143',
					'color': '#555',
					'background-color': '#fff',
					'border': '1px solid #ccc',
					'border-radius': '0px',
					'-webkit-box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075)',
					'box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075)',
					'-webkit-transition': 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
					'-o-transition': 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
					'transition': 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
					'width': '100%'
				},
				'#heartland-field[name=submit]': {
					'background-color': '#01BCD4',
					'font-family': 'sans-serif',
					'font-size': '20px',
					'text-transform': 'uppercase',
					'color': '#ffffff',
					'border': '0px solid transparent'
				},
				'#heartland-field[name=submit]:focus': {
					'color': '#ffffff',
					'background-color': '#01BCD4',
					'outline': 'none'
				},
				'#heartland-field[name=submit]:hover': {
					'background-color': '#01BCD4',
				},
				'#heartland-field-wrapper #heartland-field:focus': {
					'border': '1px solid #3989e3',
					'outline': 'none',
					'box-shadow': 'none',
					'height': '50px'
				},
				'heartland-field-wrapper #heartland-field': {
					'height': '50px'
				},
				'input[type=submit]': {
					'box-sizing': 'border-box',
					'display': 'inline-block',
					'padding': '6px 12px',
					'margin-bottom': '0',
					'font-size': '14px',
					'font-weight': '400',
					'line-height': '1.42857143',
					'text-align': 'center',
					'white-space': 'nowrap',
					'vertical-align': 'middle',
					'-ms-touch-action': 'manipulation',
					'touch-action': 'manipulation',
					'cursor': 'pointer',
					'-webkit-user-select': 'none',
					'-moz-user-select': 'none',
					'-ms-user-select': 'none',
					'user-select': 'none',
					'background-image': 'none',
					'border': '1px solid transparent',
					'border-radius': '4px',
					'color': '#fff',
					'background-color': '#337ab7',
					'border-color': '#2e6da4'
				},
				'#heartland-field[placeholder]': {
					'letter-spacing': '3px'
				},
				'#heartland-field[name=cardCvv]': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/cvv1.png?raw=true) no-repeat right',
					'background-size': '63px 40px',
				},
				'input#heartland-field[name=cardNumber]': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-inputcard-blank@2x.png?raw=true) no-repeat right',
					'background-size': '55px 35px'
				},
				'#heartland-field.invalid.card-type-visa': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right',
					'background-size': '83px 88px',
					'background-position-y': '-44px'
				},
				'#heartland-field.valid.card-type-visa': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-visa@2x.png?raw=true) no-repeat right top',
					'background-size': '82px 86px'
				},
				'#heartland-field.invalid.card-type-discover': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right',
					'background-size': '85px 90px',
					'background-position-y': '-44px'
				},
				'#heartland-field.valid.card-type-discover': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-discover@2x.png?raw=true) no-repeat right',
					'background-size': '85px 90px',
					'background-position-y': '1px'
				},
				'#heartland-field.invalid.card-type-amex': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right',
					'background-size': '50px 90px',
					'background-position-y': '-44px'
				},
				'#heartland-field.valid.card-type-amex': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-savedcards-amex@2x.png?raw=true) no-repeat right top',
					'background-size': '50px 90px'
				},
				'#heartland-field.invalid.card-type-mastercard': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right',
					'background-size': '62px 105px',
					'background-position-y': '-52px'
				},
				'#heartland-field.valid.card-type-mastercard': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-mastercard.png?raw=true) no-repeat right',
					'background-size': '62px 105px',
					'background-position-y': '-1px'
				},
				'#heartland-field.invalid.card-type-jcb': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right',
					'background-size': '55px 94px',
					'background-position-y': '-44px'
				},
				'#heartland-field.valid.card-type-jcb': {
					'background': 'transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-saved-jcb@2x.png?raw=true) no-repeat right top',
					'background-size': '55px 94px',
					'background-position-y': '2px'
				},
				'input#heartland-field[name=cardNumber]::-ms-clear': {
					'display': 'none'
				}
			},
			// Callback when a token is received from the service
			onTokenSuccess: function (resp) {
				let expMonth = _.trim(resp.exp_month), expYear = _.trim(resp.exp_year);
				if (!expMonth || expMonth.length != 2) expMonth = null;
				else if (!expYear || expYear.length != 4) expYear = null;


				let err = [];
				if (!$scope.status.cardCvv) err.push("Invalid Card CVV");
				if (!expMonth || !expYear) err.push("Invalid Card Expiration");
				//if (!$scope.status.cardNumber) err.push("Invalid Card Number");
				if (err.length) {
					return PDialog.warning({ "text": err.join("\n") });
				}
				$uibModalInstance.close({ mode: "success", data: { token: resp.token_value, expMonth: expMonth, expYear: expYear } });
			},
			// Callback when an error is received from the service
			onTokenError: function (resp) {
				PDialog.warning({ "text": resp.error.message });
			},
			// Callback when an event is fired within an iFrame
			onEvent: function (ev) {
				console.log(ev);
				$scope.status[ev.source] = _.get(ev.classes, '[0]') == "valid";
			}
	});
	}, 10);


	$scope.cancel = function () {
		$uibModalInstance.close({ mode: "cancel" });
	}
})



angular.module('app').directive("timeAgo", function (dateFilter) {
    return {
        restrict: "A",
        require: 'ngModel',
        scope: {
            model: '=ngModel'
        },
        link: function (scope, element, attr) {
            var format;

            function updateTime() {
                element.html(moment().diff(moment(scope.model), 'minutes'));
                //element.html(moment(scope.model).fromNow());
            }

            function updateLater() {
                setTimeout(function () {
                    updateTime(); // update DOM
                    updateLater(); // schedule another update
                }, 1000);
            }

            updateLater();

            scope.$watch("model", function () {
                updateTime();
            }, true);

        }
    }
});

angular.module('app').directive('ngRightClick', function ($parse) {
    return function (scope, element, attrs) {
        var fn = $parse(attrs.ngRightClick);
        element.bind('contextmenu', function (event) {
            scope.$apply(function () {
                event.preventDefault();
                fn(scope, { $event: event });
            });
        });
    };
});

angular.module('app').directive("contractFormatter", function () {
    return {
        link: function (scope, element, attr) {
            var olRoot = $(element).children("ol");
            var NN = 0;
            olRoot.children("li").each(function () {
                ++NN;
                var TN = 0;
                var $liRoot = $(this);
                var $liRoot = $(this).prepend('<span class="counter">' + NN + '</span>');
                $($liRoot).children("ol").children("li").each(function () {
                    ++TN;
                    var $li = $(this).prepend('<span class="counter">' + NN + "." + TN + '</span>');
                });
            });
        }
    }
});


angular.module('app').directive('creditCardType', function (ENV) {
    var directive =
      {
          require: 'ngModel'
      , link: function (scope, elm, attrs, ctrl) {
          ctrl.$parsers.unshift(function (value) {
             
              var val = value + '', n = val.length;
              var valid = true;
              var short = n == 8 || n == 9;
              if (!short && (n < 12 || n > 19)){
                valid = false;
              }
              if (!ENV.disableCreditCardValidation){
                  if (!short && !UIUtils.checkCreditCardDigit(value)){
                      valid = false;
                  }
              }

              ctrl.$setValidity('invalid', valid)
              return value;
          })
      }
      }
    return directive
});

angular.module('app').directive('validIdCard', function (MetaService, ENV) {
    var directive =
      {
          require: 'ngModel'
      , link: function (scope, elm, attrs, ctrl) {
          ctrl.$parsers.unshift(function (value) {
              var valid = true;
              if (!ENV.disableIdCardValidation){
                  var valid = UIUtils.isValidIsraelIdCard(value);
                  ctrl.$setValidity('valid', valid)
              }
              return value;
          })
      }
      }
    return directive
});

angular.module('app').directive('validZip', function (MetaService, ENV) {
	var directive =
	{
		require: 'ngModel'
		, link: function (scope, elm, attrs, ctrl) {
			ctrl.$parsers.unshift(function (value) {
				let valid = true, val = value;
				if (!value || value.length < 5) valid = false;
				else if (isNaN(val)) {
					let aval = val.split('-');
					if (aval.length != 2 || aval[0].length != 5 && aval[1].length != 4) {
						valid = false;
					}
				}
				else if (val.length == 5) { valid = true; }
				else if (val.length == 9) {
					parsedVal = `${val.substring(0, 5)}-${val.substring(5, 9)}`;
					elm.val(parsedVal);
					val = parsedVal;
				}
				else valid = false;
				ctrl.$setValidity('valid', valid);
				return valid ? val : value;
			})
		}
	}
	return directive
});

angular.module('app').directive('cardExpiration', function (MetaService) {
    var directive =
      {
          require: 'ngModel'
      , link: function (scope, elm, attrs, ctrl) {
          scope.$watch('[ccinfo.month,ccinfo.year]', function (value) {
              ctrl.$setValidity('invalid', true);
              var valid = true;
              if (scope.ccinfo.year == MetaService.currentYear && scope.ccinfo.month < MetaService.currentMonth) {
                  ctrl.$setValidity('invalid', false);
                  var valid = false;
              };
              if (valid && scope.ccinfo.year && scope.ccinfo.month) {
                  var year = Number(scope.ccinfo.year);
                  var month = Number(scope.ccinfo.month - 1);
                  var d = new Date(year, month, 2);
                  scope.ccinfo.cardExpiration = d;
                  //console.log(scope.ccinfo.cardExpiration);
              };

              return value
          }, true)
      }
      }
    return directive
});


angular.module('app').directive('uiValidate', function () {

    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var validateFn, validators = {},
                validateExpr = scope.$eval(attrs.uiValidate);

            if (!validateExpr) { return; }

            if (angular.isString(validateExpr)) {
                validateExpr = { validator: validateExpr };
            }

            angular.forEach(validateExpr, function (exprssn, key) {
                validateFn = function (valueToValidate) {
                    var expression = scope.$eval(exprssn, { '$value': valueToValidate });
                    if (angular.isObject(expression) && angular.isFunction(expression.then)) {
                        // expression is a promise
                        expression.then(function () {
                            ctrl.$setValidity(key, true);
                        }, function () {
                            ctrl.$setValidity(key, false);
                        });
                        return valueToValidate;
                    } else if (expression) {
                        // expression is true
                        ctrl.$setValidity(key, true);
                        return valueToValidate;
                    } else {
                        // expression is false
                        ctrl.$setValidity(key, false);
                        return valueToValidate;
                    }
                };
                validators[key] = validateFn;
                ctrl.$formatters.push(validateFn);
                ctrl.$parsers.push(validateFn);
            });

            function apply_watch(watch) {
                //string - update all validators on expression change
                if (angular.isString(watch)) {
                    scope.$watch(watch, function () {
                        angular.forEach(validators, function (validatorFn) {
                            validatorFn(ctrl.$modelValue);
                        });
                    });
                    return;
                }

                //array - update all validators on change of any expression
                if (angular.isArray(watch)) {
                    angular.forEach(watch, function (expression) {
                        scope.$watch(expression, function () {
                            angular.forEach(validators, function (validatorFn) {
                                validatorFn(ctrl.$modelValue);
                            });
                        });
                    });
                    return;
                }

                //object - update appropriate validator
                if (angular.isObject(watch)) {
                    angular.forEach(watch, function (expression, validatorKey) {
                        //value is string - look after one expression
                        if (angular.isString(expression)) {
                            scope.$watch(expression, function () {
                                validators[validatorKey](ctrl.$modelValue);
                            });
                        }

                        //value is array - look after all expressions in array
                        if (angular.isArray(expression)) {
                            angular.forEach(expression, function (intExpression) {
                                scope.$watch(intExpression, function () {
                                    validators[validatorKey](ctrl.$modelValue);
                                });
                            });
                        }
                    });
                }
            }
            // Support for ui-validate-watch
            if (attrs.uiValidateWatch) {
                apply_watch(scope.$eval(attrs.uiValidateWatch));
            }
        }
    };
});

angular.module('app').directive('onErrorSrc', function () {
    return {
        link: function (scope, element, attrs) {
            element.bind('error', function () {
                if (attrs.src != attrs.onErrorSrc) {
                    attrs.$set('src', attrs.onErrorSrc);
                }
            });
        }
    }
});


angular.module('app').directive('bgImage', function () {
    return {
        require: 'ngModel',
        scope: {
            model: '=ngModel',
            bgImage: '='
        },
        link: function (scope, element, attr) {
            var bgImage = scope.bgImage;
            var defImage = attr.defImage || 'images/meal-icon.svg';
            if (!bgImage || scope.model.$$noimage) {
                element.css("background-image", "url(" + defImage + ")");
                element.addClass("no-image");
                scope.model.$$noimage = true;
            } else {
                var image = new Image();
                image.src = bgImage;
                if (bgImage == 'images/meal-icon.svg') {
                    element.css("background-image", "url(" + bgImage + ")");
                    element.addClass("no-image");
                    return;
                }
                image.onload = function () {
                    //Image loaded- set the background image to it
                    element.css("background-image", "url(" + bgImage + ")");
                };
                image.onerror = function () {
                    //Image failed to load- use default
                    element.css("background-image", "url(" + defImage + ")");
                    element.addClass("no-image");
                    scope.model.$$noimage = true;
                };
            }
        }
    };
});

angular.module('app').directive('posFixedRlt', function () {
    return {
        link: function (scope, element, attrs) {
            var $element = $(element);
            var $parent = $element.parent();
            var pos = attrs["posFixedRlt"];
            var isTRL = $("body").hasClass("_rtl");
            if (isTRL) pos = pos == "far" ? "left" : "right"
            else pos = pos == "far" ? "right" : "left";

            function setPosition() {
                if (pos == "left") $element.css("left", $parent.offset().left + "px");
                else $element.css("right", $parent.offset().left + "px");
            };

            setPosition();
            window.setTimeout(setPosition, 100);
            $(window).resize(setPosition);
        }
    }
});

angular.module('app').directive('heightContainer', function () {
    return {
        link: function (scope, element, attrs) {
            var $element = $(element);
            var hasMaxTarget, maxHTarget, maxHOffset;
            var offset = attrs.heightContainer;
            offset = isNaN(offset) ? 0 : Number(offset);
            var attr = attrs.useMaxHeight ? "max-height" : "height";
            var fixedSize = attrs.fixedDite == "1"
            var _hoffset = attrs.hOffset;

            if (attr === "max-height"){
                $element.on('mousewheel', function(ev){
                    var $this = $(this),
                    scrollHeight = this.scrollHeight;                    
                    if (scrollHeight <= $this.outerHeight()) return;

                    var height = $this.innerHeight(),
                    scrollTop = this.scrollTop,
                    delta = (ev.type == 'DOMMouseScroll' ?
                        ev.originalEvent.detail * -40 :
                        ev.originalEvent.wheelDelta),
                    up = delta > 0;

                    if (up){
                        $this.scrollTop(Math.max(0, scrollTop - 40));
                    }else{
                        $this.scrollTop(Math.max(0, scrollTop + 40));
                    }
                    var newScrollTop = Math.max(scrollTop - delta, 0);
                    newScrollTop = Math.min(newScrollTop, scrollTop - height);

                    ev.stopPropagation();
                    ev.preventDefault();
                    ev.returnValue = false;
                    return false;

                });
            };

            function setPosition() {
                if (fixedSize) {
                    var viewportOffset = $element[0].getBoundingClientRect();
                    var top = viewportOffset.top + offset;
                } else {
                    //var top = $element.offset().top;
                    //top -= $(document).scrollTop();
                    //top = offset;
                    var top = offset;
                }
                top += $(_hoffset).outerHeight() || 0;

                var wh = $(window).height();
                $element.css(attr, (wh - top) + "px");
                UIUtils.deleyFocusBasket(window.BLOCATIONHASH,300);  
            };

            setPosition();
            window.setTimeout(setPosition, 100);
            $(window).resize(setPosition);
        }
    }
});

angular.module('app').directive('heightWatch', function ($window, $document, $timeout) {
	return {
		link: function (scope, element, attrs) {
			var target = attrs.heightWatch;
			var offset = Number(attrs.offset);

			window.setTimeout(function(){
				if (isNaN(offset)) offset = 0;
				$(element).css('min-height', ($(target).outerHeight() + offset) + "px");
			},100);
		}
	}
});

angular.module('app').directive('catNav', function ($window, $document, $timeout) {
    return {
        link: function (scope, element, attrs) {
            var posInfo, offset = 150;
                $timeout(function () {
                    var reposition = function () {
                        posInfo = {
                            offsetHeight: element.prop('offsetHeight'),
                            windowHeight: $window.outerHeight,
                            scrollTop: $document.scrollTop(),
                            top: 250
                        };
                        var translateY = 0;
                        var maxOffset = (posInfo.top + posInfo.offsetHeight) - posInfo.windowHeight;
                        if (maxOffset > 0){
                            translateY = Math.min(maxOffset,posInfo.scrollTop);
                            if (translateY) translateY *= -1;
                        }
                        //console.log(translateY, maxOffset, posInfo)
                        element.css("transform",'translateY(' + translateY + 'px)')
                    };

                    $(window).on("resize scroll",reposition);
                    element.on('$destroy', function () {
                        $(window).off("resize scroll",reposition);
                    });
                }, 0);
        }
    }
});

angular.module('app').directive('ngOnload', function () {
        return {
            restrict: "A",
            scope: {
                callback: "&ngOnload"
            },
            link: function link(scope, element, attrs) {
                // hooking up the onload event - calling the callback on load event
                element.on("load", function (_) {
                    var contentLocation = undefined;
                    try{
                        var contentLocation = element.length > 0 && element[0].contentWindow ? element[0].contentWindow.location : undefined;
                        if (!contentLocation.href) contentLocation = undefined;
                    }catch(e){
                        contentLocation = undefined;
                    }                    
                    scope.callback({
                        contentLocation: contentLocation
                    });
                });
            }
        };
    });

angular.module('app').directive("toFix", function () {
	return {
		require: "ngModel",
		link: postLink
	}

	function postLink(scope, elem, attrs, ngModel) {
		ngModel.$render = function () {
			elem.val(parseFloat(ngModel.$viewValue).toFixed(attrs.toFix));
		}
		ngModel.$parsers = [(_ => parseFloat(_))];
		ngModel.$formatters = [(_ => parseFloat(_).toFixed(attrs.toFix))];
	}
})

/*
 ------------------------------------------------------------------------------------------
 directive tgmParamsTree
 ------------------------------------------------------------------------------------------
 */

angular.module('app').directive('accessibilityMenu', function ($rootScope, ENV, MetaService) {
    return {
        templateUrl: 'modules/_blocks/accessibility_menu.html',
        scope: {
            tree: '='
        },
        link: function (scope, element, attr) {
            var $element = $(element);
			var $body = $("body");
			scope.meta = MetaService;
			switch (ENV.loyaltyEnv) {
				case "il": scope.declerationURL = 'https://s3.eu-west-1.amazonaws.com/files.tabit.cloud/tabit-app/general-app/accessibility-statement.pdf'; break;
			}
			MetaService.accesibilityScope = scope;

			scope.device = $rootScope.device;
            scope.services = [
                {"id":"monochrom", "icon":"icaccess-monochrome", "class":"ac-gray"},
                {"id":"light-contrast", "icon":"icaccess-contrust-light", "class":"ac-contrast-light"},
                {"id":"dark-contrast", "icon":"icaccess-contrust-dark", "class":"ac-contrast-dark"},
                {"id":"readable-font", "icon":"icaccess-font", "class":"ac-font"},
                {"id":"big-white-curser", "icon":"icaccess-curosr-light", "class":"ac-pointer-white", desktopOnly: true},
				{ "id": "big-black-curser", "icon": "icaccess-curosr-dark", "class": "ac-pointer-black", desktopOnly: true},
                {"id":"enlarge-frame", "icon":"icaccess-zoom-window", "class":"ac-zoom"},
                {"id":"highlight-links", "icon":"icaccess-link", "class":"ac-links"},
                {"id":"highlight-headers", "icon":"icaccess-heading", last:true},
                {"id":"image-info", "icon":"icaccess-picture", last:true}
                //{"id":"enlarge-font", "icon":"ma-icon-4"},
                //{"id":"reduce-font", "icon":"ma-icon-5"},
			].filter(prop => {
				return !prop.desktopOnly || scope.device == 'desktop';
			});
            scope.menuActive = false;

            scope.toggleActive = function(){
                scope.menuActive = !scope.menuActive;
                $(element).toggleClass('active');
            }

            scope.removeAccess = function(){
                _.each(scope.services, function(o){
                    if (o.active) removeFeatureClass(o);
                });
                scope.hasAccess = false;
                $(window).trigger("resize");
            }

            scope.toggleFeature = function(feature){
                if (feature.active){
                    removeFeatureClass(feature);
                    scope.hasAccess = _.find(scope.services, {active:true}) != null;
                }else{
                    switch(feature.id){
                        case "big-white-curser":
                            removeFeatureClass("big-black-curser");
                        break;
                        case "big-black-curser":
                            removeFeatureClass("big-white-curser");
                        break;
                        case "monochrom":
                            removeFeatureClass("dark-contrast");
                            removeFeatureClass("light-contrast");
                        break;
                        case "light-contrast":
                            removeFeatureClass("dark-contrast");
                            removeFeatureClass("monochrom");
                        break;
                        case "dark-contrast":
                            removeFeatureClass("light-contrast");
                            removeFeatureClass("monochrom");
                        break;                        
                    }
                    setFeatureClass(feature);
                    scope.hasAccess = true;
                }
                $(window).trigger("resize");
            }
        
            function setFeatureClass(o){
                if (_.isString(o)) o = _.find(scope.services, {id:o})
                o.active = true;
                if (o.class)$body.addClass(o.class);
            }
            function removeFeatureClass(o){
                if (_.isString(o)) o = _.find(scope.services, {id:o})
                o.active = false;
                if (o.class)$body.removeClass(o.class);
            }
        
        
        }
    }
});


angular.module('app').directive('uiEvent', ['$parse',
	function ($parse) {
		'use strict';

		return function ($scope, elm, attrs) {
			var events = $scope.$eval(attrs.uiEvent);
			angular.forEach(events, function (uiEvent, eventName) {
				var fn = $parse(uiEvent);
				elm.bind(eventName, function (evt) {
					var params = Array.prototype.slice.call(arguments);
					//Take out first paramater (event object);
					params = params.splice(1);
					fn($scope, { $event: evt, $params: params });
					if (!$scope.$$phase) {
						$scope.$apply();
					}
				});
			});
		};
	}]);
'use strict';

(function () {
	var app = angular.module('app');

	//Setup map events from a google map object to trigger on a given element too,
	//then we just use ui-event to catch events from an element
	function bindMapEvents(scope, eventsStr, googleObject, element) {
		angular.forEach(eventsStr.split(' '), function (eventName) {
			//Prefix all googlemap events with 'map-', so eg 'click'
			//for the googlemap doesn't interfere with a normal 'click' event
			window.google.maps.event.addListener(googleObject, eventName, function (event) {
				element.triggerHandler('map-' + eventName, event);
				//We create an $apply if it isn't happening. we need better support for this
				//We don't want to use timeout because tons of these events fire at once,
				//and we only need one $apply
				if (!scope.$$phase) { scope.$apply(); }
			});
		});
	}

	app.value('uiMapConfig', {}).directive('uiMap',
		['uiMapConfig', '$parse', function (uiMapConfig, $parse) {

			var mapEvents = 'bounds_changed center_changed click dblclick drag dragend ' +
				'dragstart heading_changed idle maptypeid_changed mousemove mouseout ' +
				'mouseover projection_changed resize rightclick tilesloaded tilt_changed ' +
				'zoom_changed';
			var options = uiMapConfig || {};

			return {
				restrict: 'A',
				//doesn't work as E for unknown reason
				link: function (scope, elm, attrs) {
					var opts = angular.extend({}, options, scope.$eval(attrs.uiOptions));
					var map = new window.google.maps.Map(elm[0], opts);
					var model = $parse(attrs.uiMap);

					//Set scope variable for the map
					model.assign(scope, map);

					bindMapEvents(scope, mapEvents, map, elm);
				}
			};
		}]);

	app.value('uiMapInfoWindowConfig', {}).directive('uiMapInfoWindow',
		['uiMapInfoWindowConfig', '$parse', '$compile', function (uiMapInfoWindowConfig, $parse, $compile) {

			var infoWindowEvents = 'closeclick content_change domready ' +
				'position_changed zindex_changed';
			var options = uiMapInfoWindowConfig || {};

			return {
				link: function (scope, elm, attrs) {
					var opts = angular.extend({}, options, scope.$eval(attrs.uiOptions));
					opts.content = elm[0];
					var model = $parse(attrs.uiMapInfoWindow);
					var infoWindow = model(scope);

					if (!infoWindow) {
						infoWindow = new window.google.maps.InfoWindow(opts);
						model.assign(scope, infoWindow);
					}

					bindMapEvents(scope, infoWindowEvents, infoWindow, elm);

					/* The info window's contents dont' need to be on the dom anymore,
					 google maps has them stored.  So we just replace the infowindow element
					 with an empty div. (we don't just straight remove it from the dom because
					 straight removing things from the dom can mess up angular) */
					elm.replaceWith('<div></div>');

					//Decorate infoWindow.open to $compile contents before opening
					var _open = infoWindow.open;
					var compile = attrs.compile;
					if (compile) $compile(elm.contents())(scope);
					infoWindow.open = function open(a1, a2, a3, a4, a5, a6) {
						_open.call(infoWindow, a1, a2, a3, a4, a5, a6);
					};
				}
			};
		}]);

	/*
	 * Map overlay directives all work the same. Take map marker for example
	 * <ui-map-marker="myMarker"> will $watch 'myMarker' and each time it changes,
	 * it will hook up myMarker's events to the directive dom element.  Then
	 * ui-event will be able to catch all of myMarker's events. Super simple.
	 */
	function mapOverlayDirective(directiveName, events) {
		app.directive(directiveName, [function () {
			return {
				restrict: 'A',
				link: function (scope, elm, attrs) {
					scope.$watch(attrs[directiveName], function (newObject) {
						if (newObject) {
							bindMapEvents(scope, events, newObject, elm);
						}
					});
				}
			};
		}]);
	}

	mapOverlayDirective('uiMapMarker',
		'animation_changed click clickable_changed cursor_changed ' +
		'dblclick drag dragend draggable_changed dragstart flat_changed icon_changed ' +
		'mousedown mouseout mouseover mouseup position_changed rightclick ' +
		'shadow_changed shape_changed title_changed visible_changed zindex_changed');

	mapOverlayDirective('uiMapPolyline',
		'click dblclick mousedown mousemove mouseout mouseover mouseup rightclick');

	mapOverlayDirective('uiMapPolygon',
		'click dblclick mousedown mousemove mouseout mouseover mouseup rightclick');

	mapOverlayDirective('uiMapRectangle',
		'bounds_changed click dblclick mousedown mousemove mouseout mouseover ' +
		'mouseup rightclick');

	mapOverlayDirective('uiMapCircle',
		'center_changed click dblclick mousedown mousemove ' +
		'mouseout mouseover mouseup radius_changed rightclick');

	mapOverlayDirective('uiMapGroundOverlay',
		'click dblclick');

})();






// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// app_controller
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('app_controller', function ($scope, $rootScope, $timeout, $uibModal, $translate, BenefitsService, $q, $sessionStorage, blockUI, MarkettingService) {

    $scope.UIAPPArgs = {
        loading: true,
    };
	$scope.benefitsService = BenefitsService;

    $scope.init = function (qrc, oid, redirect) {
        if ($scope.$storage.orderMode == 'fixedorder') {
            $scope.UIAPPArgs.loading = false;
        } else if (!$scope.$storage.catalog) {
            $scope.$state.go('start');
        } else if ($scope.$storage.orderClosed) {
            $scope.$state.go('end');
        } else {
            $scope.UIAPPArgs.loading = false;
            $scope.catalog = $scope.$storage.catalog;
        }
    };
    $scope.init();

    // ------------------------------------------------------------------------------------------------->

    $scope.promptResetOrder = function () {
        $scope.PDialog.warning(
            {
                "text": $translate.instant("MESSAGES.CONFIRM_RESET_ORDER"),
                showCancelButton: true,
                confirmButtonText: $translate.instant("YES"),
                cancelButtonText: $translate.instant("NO"),
            }
        ).then(function () {
            $scope.resetOrder();
        });
    };

    // ------------------------------------------------------------------------------------------------->

    $scope.updateBasket = function (offer) {
        var index = _.findIndex($scope.$storage.basket, { _bid: offer._bid });
        if (index > -1) {
            $scope.$storage.basket[index] = offer;
            window.history.back();

            if (window.ISDESKTOP) {
                window.BLOCATIONHASH = "#bs_offer_" + offer._bid;
                UIUtils.deleyFocusBasket(window.BLOCATIONHASH);
            }
        } else {
            $scope.addToBasket(offer, true);
        };
        $scope.ES.calculateBasketTotal();
	}

	window._ADDTOBASKET = $scope.addToBasket = function (offer, position) {
        offer._bid = new Date().valueOf() + "";
        var _q = offer.quantity;
        if (!_q) offer.quantity = _q = 1;

        if (!offer._enableEdit) {
            var _offer = _.find($scope.$storage.basket, { offer: offer.offer });
            if (_offer) {
                offer = _offer;
                _offer.quantity += _q;
            } else $scope.$storage.basket.push(offer);
        } else {
            if (_q > 1) {
                offer.quantity = 1;
                for (var i = 0; i < _q; i++) {
                	var newOffer = angular.copy(offer);
                	newOffer._bid += "_" + i;
                	$scope.$storage.basket.push(newOffer);
                };
            } else {
                $scope.$storage.basket.push(offer);
            };
        };
        if (position) window.history.back();
        $scope.ES.calculateBasketTotal();

        if (window.ISDESKTOP) {
            window.BLOCATIONHASH = "#bs_offer_" + offer._bid;
            UIUtils.deleyFocusBasket(window.BLOCATIONHASH);
		};
		MarkettingService.pushDataLayer(offer, "add_cart");
	}
	

    $scope.getBasketOfferQuantity = function (offer) {
        var ret = { q: 0 };
        var _offer = _.find($scope.$storage.basket, { offer: offer.offer });
        if (_offer) {
            ret.offer = _offer;
            ret.q = _offer.quantity;
        }
        return ret;
    }

    $scope.canCheckout = function (silent) {
        var minOrderPrice = $scope.$storage.order.region && $scope.$storage.order.region.minOrderPrice;
        if (minOrderPrice && minOrderPrice > $scope.$storage.order.itemsTotal) {
			if (silent) return false;
			$scope.PDialog.warning(
                {
                    "text": $translate.instant("MESSAGES.MIN-ORDER-PRICE", { t: minOrderPrice }),
                }
            ).then(function () {
                if ($scope.$state.includes('app.checkout')) {
                    $scope.gotoItems();
                }
            });
            return false;
        } else {
            return true;
        };
    }

    $scope.checkout = function () {
        //$scope.UIAPPArgs.menuOpen = false;
        var deferred = $q.defer();
        if (!$scope.$storage.basket.length) {
            $scope.PDialog.warning({ text: $translate.instant("MESSAGES.NO_BASKET_ITEMS") });
            return;
        };
        if ($scope.DESKTOP) {
            if ($scope.canCheckout()) $scope.goToPayment();
            return;
        };

        var modalCheckout = $uibModal.open({
            templateUrl: 'modules/checkout/modals/modal_checkout.html',
            controller: 'modal_checkout_controller',
            windowClass: "modal-default",

            resolve: {
                modalParams: function () {
                    return {
                        isInCheckout: $scope.$state.includes('app.checkout'),
                        $storage: $scope.$storage
                    };
                }
            }
        });
        modalCheckout.result.then(function (result) {
            switch (result.mode) {
                case "checkout":
                    $scope.goToPayment();
                    break;
                case "edit":
                    $scope.$state.go("app.order.edititem", { bid: result.bid })
                    break;
                case "additems":
                    $scope.gotoItems();
                    //$scope.$state.go("app.order.submenu", { level: 0, cat: $scope.$storage.catalog.view[0]._id });
                    break;
                default:
                    deferred.resolve('continue')
            }
        }, function () {
            if ($scope.$state.includes('app.checkout')) {

                if (!$scope.$storage.basket.length) {
                    $scope.gotoItems();
                    return;
                };

                if (!$scope.canCheckout()) return;

            }
            deferred.resolve('continue');

        });
        return deferred.promise;
    }

    $scope.goToPayment = function () {
		if ($scope.$state.includes('app.checkout')) { }
		else {
			$scope.$state.go(($scope.$storage.extraOffers || $scope.$storage.extraOffersGroups) ? 'app.checkout.extra' : 'app.checkout.contact');
		}
	}

    $scope.gotoItems = function () {
        $scope.$state.go("app.order.menus");
    }

    // ------------------------------------------------------------------------------------------------->

    function reloadCheckout() {
        if ($scope.$state.includes('app.checkout')) {
            $scope.$state.reload();
        };
    };

    // ------------------------------------------------------------------------------------------------->


    $scope._signIn = function () {
        //$scope.UIAPPArgs.menuOpen = false;
        $scope.signIn().then(function () {
            reloadCheckout();
        });
    };
    $scope._signOut = function () {
        //$scope.UIAPPArgs.menuOpen = false;
        $scope.signOut().then(function () {
            reloadCheckout();
        });
    };
    $scope._register = function () {
        //$scope.UIAPPArgs.menuOpen = false;
        $scope.register().then(function () {
            reloadCheckout();
        });
    };


    $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
        //$scope.UIAPPArgs.menuOpen = false;
    });

    var listener = $rootScope.$on("doBack", function (o, args) {
		if ($scope.$state.includes('app.order.additem')) return;
		//if ($scope.$state.includes('app.order.edititem')) $scope.$state.go("app.order.menus");
        else window.history.back();
    });
    $scope.$on("$destroy", function (event) {
        listener();
    });

});


// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// modal_checkout_controller
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('modal_checkout_controller', function ($scope, $uibModalInstance, modalParams, $timeout, $translate, EntityService, MarkettingService, BenefitsService) {
    $scope.ES = EntityService;
    $scope.isInCheckout = modalParams.isInCheckout;
    $scope.isClosed = modalParams.isClosed;
    $scope.$storage = modalParams.$storage;

	$scope.checkout = function () {
		if ($scope.$storage.$tpOrder) return EntityService.addItemsToTPOrder();

        $scope.ES.beginCheckout($scope.isInCheckout ? "payment" : "customer");
        var total = $scope.$storage.order.itemsTotal;
        var minOrderPrice = $scope.$storage.order.region && $scope.$storage.order.region.minOrderPrice;
        if (minOrderPrice && minOrderPrice > total) {
            $scope.PDialog.warning(
                {
                    "text": $translate.instant("MESSAGES.MIN-ORDER-PRICE", { t: minOrderPrice }),
                }
            ).then(function () {
                if ($scope.isInCheckout) {
                    $scope.addItems();
                } else $scope.cancel();
            });
        } else {
            $uibModalInstance.close({ mode: 'checkout' });
        };
    };
    $scope.editOffer = function (offer, index) {
        if ($scope.isClosed) return;
        $uibModalInstance.close({ mode: 'edit', bid: offer._bid });
    };
    $scope.continue = function () {
        if ($scope.isClosed) return;
        $uibModalInstance.close({ mode: 'continue' });
    };
    $scope.addItems = function () {
        if ($scope.isClosed) return;
        $uibModalInstance.close({ mode: 'additems' });
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

	checkout_controller_logic($scope, $timeout, $translate, EntityService, MarkettingService, BenefitsService);
});
app.controller('app_checkout_controller', function ($scope, $timeout, $translate, EntityService, MarkettingService, BenefitsService) {

	checkout_controller_logic($scope, $timeout, $translate, EntityService, MarkettingService, BenefitsService);

    $scope.editOffer = function (offer, index) {
        $scope.$state.go("app.order.edititem", { bid: offer._bid });
    };
});


function checkout_controller_logic($scope, $timeout, $translate, EntityService, MarkettingService, BenefitsService) {
    $scope.basket = $scope.$storage.basket;

    $scope.maxHeight = ($(window).height() - 147) + 'px';

    _.each($scope.basket, function (offer) {
        $scope.ES.prepareItemSummary(offer._item); //new summary

        var selectionSummary = [];

        _.each(offer._offer.selectionGroups, function (sG) {
            var arr = [];
            _.each(sG.items, function (item) {
                if (item._selected) {
                    $scope.ES.prepareItemSummary(item._item); //new summary
                    arr.push(item);
                };
            });
            if (arr.length) {
                selectionSummary.push({
                    _id: sG._id,
                    name: sG.name,
                    items: arr,
					_visible: sG._visible !== false
                });
            }
        });
        offer.selectionSummary = selectionSummary;
    });

    EntityService.calculateBasketTotal();

    $scope.duplicateOffer = function (offer, index) {
		if ($scope.isClosed || !$scope.ES.checkMaxOfferCount(offer, 1)) return;
        if (offer._enableEdit && offer.quantity == 1) {
            var newOffer = angular.copy(offer);
            newOffer._bid = new Date().valueOf() + "";
            $scope.basket.splice(index, 0, newOffer);
            newOffer.$$isNew = true;
            $timeout(function () { delete newOffer.$$isNew }, 300);
        } else {
            ++offer.quantity;
		};
		MarkettingService.pushDataLayer(offer, "add_cart");
        EntityService.calculateBasketTotal();
    };
    $scope.deleteOffer = function (offer, index) {
		let message;
		if (offer.isLoyaltyOffer) {
			message = $translate.instant("_LOYALTY_REG.confirmDeleteOffer");
		} else {
			message = $translate.instant("Q_REMOVE_OFFER_FROM_BASKET");
			if ($scope.isClosed) return;
			if (offer.quantity > 1) {
				--offer.quantity;
				EntityService.calculateBasketTotal();
				return;
			}
		}
		$scope.PDialog.warning(
			{
				"text": message,
				showCancelButton: true,
				confirmButtonText: $translate.instant("REMOVE")
			}
		).then(function () {
			$scope.basket.splice(index, 1);
			MarkettingService.pushDataLayer(offer, "remove_cart");
			
			if (offer.isLoyaltyOffer) {
				try { BenefitsService.signOut(_.get($scope.$storage, 'loyaltyData.prevReference')) } catch (e) {}
			}
			EntityService.calculateBasketTotal();
		});
    }

    $scope.getMGroupSingleSelection = function (group) {
        return _.find(group.modifiers, { _selected: true });
    }
};


angular.module('app').factory('LoyaltyService', function ($q, $http, ENV, CONSTS, $translate, $localStorage, MetaService, CustomerService) {
	var service = {
		$storage: MetaService.$storage,
		setHttpHeadersLoyaltyAccessToken: setHttpHeadersLoyaltyAccessToken,
		restoreSignIn: restoreSignIn,
		refreshSignin: refreshSignin,
		callHTTP: callHTTP,
		execHTTP: execHTTP,
		signInByPhone$: signInByPhone$,
		resendPicode$: resendPicode$,
		signInByPhoneVerifyCode$: signInByPhoneVerifyCode$,
		insertCustomer$: insertCustomer$,
		getCustomer$: getCustomer$,
		updateCustomer$: updateCustomer$,
		getROSToken$: getROSToken$,
		signInByEmail$: signInByEmail$
	}

	var headers = {
		'Content-Type': 'application/json',
		'env': ENV.loyaltyEnv,
		'JoinChannelGuid': ENV.loyaltyJoinChannelGUID,
		'siteId': ENV.tabitDefaultOrganizationId,
		'client_id': ENV.tabitClientID
	}

	function setHttpHeadersLoyaltyAccessToken(token, refreshToken) {
		//this.loyaltyHttpOptions.headers = this.loyaltyHttpOptions.headers.set('Authorization', `Bearer ${token}`);
		localStorage.setItem('loyaltyToken', token);
		localStorage.setItem('loyaltyRefreshToken', refreshToken);
	}

	function restoreSignIn() {
		let loyaltyToken = localStorage.getItem('loyaltyToken');
		delete service.$storage.user;
		delete service.$storage.auth;
		localStorage.removeItem(CONSTS.storageToken);
		delete $localStorage[CONSTS.storageToken];
		if (loyaltyToken) {
			return getCustomer$().then(() => {
				return getROSToken$().then(() => {
					return CustomerService.restoreSignIn();
				})
			}).catch(err => {
				clearLoyaltyTokens();
				return CustomerService.restoreSignIn()
			})
		} else {
			return CustomerService.restoreSignIn()
		}
	}

	var restorePromise;
	function refreshSignin() {
		if (restorePromise) return restorePromise;
		let that = this;
		let refresh_token = localStorage.getItem('loyaltyRefreshToken');
		if (refresh_token) {
			restorePromise = refreshLoyaltySignIn().then(response => {
				setHttpHeadersLoyaltyAccessToken(response.ResponseData.Access_token, response.ResponseData.Refresh_token);
				return getROSToken$().then(res => {
					return { success: true }
				}).catch(err => {
					throw (err);
				}).then(o => {
					restorePromise = null;
				})
			}).catch(err => {
				//that.signInAnonimus();
				throw (err);
			});
			return restorePromise;
		}
		return $q.reject();
	}

	function refreshLoyaltySignIn() {
		return callHTTP('post', '/auth/renew', {
			accessToken: localStorage.getItem('loyaltyToken'),
			refreshToken: localStorage.getItem('loyaltyRefreshToken')
		}, true).catch(err => {
			clearLoyaltyTokens();
			throw (err);
		});
	}

	function clearLoyaltyTokens() {
		localStorage.removeItem('loyaltyToken');
		localStorage.removeItem('loyaltyRefreshToken');
	}

	function callHTTP(method, url, data, isRetry) {
		let loyaltyToken = localStorage.getItem('loyaltyToken');

		var config = {
			url: `${ENV.tabitLoyaltyAPI}${url}`,
			method: method.toUpperCase(),
			data: data,
			headers: _.assignIn({
				'Authorization': `Bearer ${loyaltyToken}`
			}, headers)
		}
		if (data) {
			if (method == 'get' || method == 'delete') {
				config.params = data;
			} else {
				config.data = data;
			}
		}
		return execHTTP(config, isRetry);

	}

	function execHTTP(config, isRetry) {
		return $http(config).then(res => {
			return res.data;
		}).catch(error => {
			if (error.status == 401) {
				if (!isRetry) {
					return refreshSignin().then(response => {
						config.headers.Authorization = `Bearer ${localStorage.getItem('loyaltyToken')}`
						return execHTTP(config, true);
					});
				} else {
					throw (error);
				}
			} else {
				throw (error);
			}
		});
	}


	function generateVerificationCodeMessage() {
		return $translate.instant('_AUTH.verification_code_message').replace("{", "{{").replace("}", "}}");
	}

	function signInByPhone$(args) {
		return callHTTP('POST', '/auth/mobile', {
			mobile: args.phone,
			customMessage: generateVerificationCodeMessage(),
		}).then(res => {
			MetaService.logTemplate("signIn", { request: args, response: res }, `sign in: ${_.get(res,'ResponseData.LoginResult','unknown')}`);
			return res;
		});
	}

	function resendPicode$(args) {
		return callHTTP('POST', '/customer/resenddotp', {
			mobile: args.phone,
			customMessage: generateVerificationCodeMessage(),
		});
	}

	function signInByPhoneVerifyCode$(args) {
		return callHTTP('POST', '/auth/mobile/pincode', {
			mobile: args.phone, pinCode: args.code
		});
	}

	function getCustomer$() {
		return callHTTP('get', '/customer').then(result => {
			var customer = result.ResponseData;

			let fn = customer.FirstName;
			let ln = customer.LastName;

			let arr = [];
			if (fn) arr.push(fn);
			if (ln && ln.length > 1) arr.push(ln);
			else delete customer.LastName;

			customer.FullName = arr.join(" ");

			customer.contact = {
				name: customer.FullName,
				firstName: fn,
				lastName: ln,
				email: customer.Email,
				phone: customer.Mobile
			}
			service.$storage.lUser = customer;
			return result;
		})
	}
	function insertCustomer$(args) {
		return callHTTP('POST', '/customer/createandsendotp', {
			Mobile: args.phone,
			FirstName: args.FirstName,
			LastName: args.LastName,
			Email: args.email,
			customMessage: generateVerificationCodeMessage(),
		}).then(res => {
			MetaService.logTemplate("signUp", { request: args }, "sign up");
			return res;
		});
	}

	function updateCustomer$(args) {
		return callHTTP('PUT', '/customer', args).then(res => {
			return getCustomer$().then(loyaltyResponse => {
				var lUser = service.$storage.lUser;

				var customer = service.$storage.user;
				customer.name = lUser.FullName;
				customer.firstName = lUser.FirstName;
				customer.lastName = lUser.LastName;
				customer.email = lUser.Email;
				customer.cell = lUser.Mobile;

				customer.contact = {
					name: customer.name,
					firstName: customer.firstName,
					lastName: customer.lastName,
					email: customer.email,
					cell: customer.cell,//customer.phones[0].national.replace(/\D/g, ''),
					contractApprove: true
				};
				customer.signin = { email: customer.email };

				if (service.$storage.order && service.$storage.order.contact) {
					_.assignIn(service.$storage.order.contact, {
						name: customer.name,
						email: customer.email
					});
				}

				return loyaltyResponse;
			})
		});
	}

	function getROSToken$(isRefresh) {
		return callHTTP('GET', '/auth/rostoken',null, true).then(response => {
			if (response && response.IsSuccess && response.ResponseData && response.ResponseData.Access_token && response.ResponseData.Refresh_token) {
				service.$storage.auth = $localStorage[CONSTS.storageToken] = {
					refresh_token: response.ResponseData.Refresh_token,
					access_token: response.ResponseData.Access_token
				}
				return response;
			}
			console.error("Error generating ros token from loyalty");
			throw ({});
		});
	}

	function signInByEmail$(args) {
		return callHTTP('POST', '/auth/email', {
			Mobile: args.phone,
			UserName: args.email,
			Password: args.password,
		});
	}


	return service;

})

app.controller('modal_login_controller', function ($scope, $q, PDialog, $uibModalInstance, $uibModal, LoyaltyService, CustomerService, ServiceCommon, MetaService, modalParams, blockUI, $translate) {
	var VM = this;
	$scope.$storage = modalParams.$storage;
	$scope.MS = MetaService;

	$scope.ui = {
		step: 'sign',
		ccInfo: modalParams.args.ccInfo
	}
	$scope.ui.savePaymentInfo = modalParams.args.ccInfo != null;

	var stepsMap = {
		'sign': 'sign',
		'email': 'email',
		'register': 'register',
		'confirm': 'confirm',
		'update': 'update'
	}
	var formModel = $scope.formModel = {};
	var mailFormModel = $scope.mailFormModel = {}
	var loyaltySignInResponse;

	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	}

	var contact = _.get($scope.$storage, 'order.contact');
	if (contact) {
		formModel.email = contact.email;
		mailFormModel.email = contact.email;
		formModel.phone = contact.cell;
		var name = contact.name;
		if (name) {
			name = name.split(" ");
			formModel.FirstName = name[0];
			formModel.FirstName = name[0];
		}
	}

	//----------------------------------------------------------------------------------------->
	//----------------------------------------------------------------------------------------->

	//------------------------------------------------------------------------------------------------->
	// signIn
	//------------------------------------------------------------------------------------------------->

	$scope.signIn = function (form) {
		if (!$scope.signinForm.$valid) {
			$scope.signinForm.submitAttempt = true;
			return;
		}
		$scope.signinForm.submitAttempt = false;
		blockUI.start();
		LoyaltyService.signInByPhone$(formModel).then(response => {
			blockUI.stop();
			if (response && response.IsSuccess && response.ResponseData) {
				loyaltySignInResponse = response.ResponseData.LoginResult;
				if (response.ResponseData.LoginResult == 'EmailValidation') {
					$scope.ui.step = stepsMap.email;
				} else if (response.ResponseData.LoginResult == 'NewCustomer') {
					$scope.ui.step = stepsMap.register;
				} else {
					delete $scope.formModel.code;
					$scope.ui.step = stepsMap.confirm;
				}
				if (formModel.phone) {
					//this.customer.phone = formModel.phone;
				}
			}
		}).catch(err => {
			console.log('_onSignInByPhoneSubmit > Error: ', err);
			blockUI.stop();
			PDialog.error({ text: $translate.instant("_AUTH.error_general") });
		})

	}

	//------------------------------------------------------------------------------------------------->
	// register
	//------------------------------------------------------------------------------------------------->

	$scope.register = function (form) {
		if (!$scope.signinForm.$valid) {
			$scope.signinForm.submitAttempt = true;
			return;
		}

		if (!$scope.formModel.contractApprove) {
			PDialog.info(
				{
					"text": $translate.instant("MESSAGES.DO_YOU_APPROVE_CONTRACT"),
					showCancelButton: true,
					confirmButtonText: $translate.instant("YES"),
					cancelButtonText: $translate.instant("NO"),
				}
			).then(function () {
				$scope.formModel.contractApprove = true;
				$scope.register(form);
			});
			return;
		}



		$scope.signinForm.submitAttempt = false;
		blockUI.start();
		let args = _.pick($scope.formModel, ['FirstName', 'LastName', 'email', 'phone']);
		LoyaltyService.insertCustomer$(args).then(response => {
			blockUI.stop();
			if (response && response.IsSuccess) {
				$scope.ui.step = stepsMap.confirm;
			} else {
				PDialog.error({ text: $translate.instant("_AUTH.error_general") });
			}
		}).catch(err => {
			blockUI.stop();
			if (err.status == '409') {
				PDialog.error({ text: $translate.instant("_AUTH.error_invalid_sign_in_email_already_exists") });
			} else {
				PDialog.error({ text: $translate.instant("_AUTH.error_general") });
			}
		});
	}

	//------------------------------------------------------------------------------------------------->
	// by mail
	//------------------------------------------------------------------------------------------------->

	$scope.gotoSignup = function () {
		loyaltySignInResponse = '';
		$scope.ui.step = stepsMap.register;
	}

	$scope.signInByMail = function (form) {
		if (!$scope.signinForm.$valid) {
			$scope.signinForm.submitAttempt = true;
			return;
		}
		$scope.signinForm.submitAttempt = false;
		mailFormModel.phone = formModel.phone;

		blockUI.start();
		LoyaltyService.signInByEmail$(mailFormModel).then(response => {
			blockUI.stop();
			if (response && response.IsSuccess) {
				$scope.ui.step = stepsMap.confirm;
			} else {
				PDialog.warning({ text: $translate.instant('_AUTH.error_invalid_sign_in_credentials') });
			}
		}).catch(err => {
			blockUI.stop();
			PDialog.warning({ text: $translate.instant('_AUTH.error_invalid_sign_in_credentials') });
		});
	}

	//------------------------------------------------------------------------------------------------->
	// confirm phone
	//------------------------------------------------------------------------------------------------->

	$scope.resendPhone = function () {
		blockUI.start();
		LoyaltyService.resendPicode$({ phone: $scope.formModel.phone }).then(response => {
			blockUI.stop();
		}).catch(err => {
			blockUI.stop();
			PDialog.error({ text: $translate.instant('_AUTH.error_general') });
		});
	}

	$scope.confirmPhone = function (form) {
		if (!$scope.signinForm.$valid) {
			$scope.signinForm.submitAttempt = true;
			return;
		}
		$scope.signinForm.submitAttempt = false;
		blockUI.start();
		let formModel = $scope.formModel;
		LoyaltyService.signInByPhoneVerifyCode$(formModel).then(response => {
			_setHeadersAndGetROSToken({ formModel, response });
		}).catch(err => {
			blockUI.stop();
			PDialog.error({ text: $translate.instant('_AUTH.error_invalid_phone_verification_code') });
		});
	}

	function _setHeadersAndGetROSToken({ formModel, response }) {
		if (response && response.IsSuccess && response.ResponseData && response.ResponseData.accessToken && response.ResponseData.refreshToken) {
			LoyaltyService.setHttpHeadersLoyaltyAccessToken(response.ResponseData.accessToken, response.ResponseData.refreshToken);
			LoyaltyService.getROSToken$(formModel).then(response => {
				LoyaltyService.restoreSignIn().then(res => {
					blockUI.stop();
					if (loyaltySignInResponse == 'EmailValidation') {
						$scope.updateFormModel = _.pick($scope.$storage.lUser, ['FirstName', 'LastName', 'Email']);
						$scope.updateFormModel.phone = $scope.formModel.phone;
						$scope.ui.step = stepsMap.update;
					} else {
						onCloseSuccess();
					}
				}).catch(err => {
					blockUI.stop();
					PDialog.error({ text: $translate.instant('_AUTH.error_general') });
				});
			}).catch(err => {
				blockUI.stop();
				PDialog.error({ text: $translate.instant('_AUTH.error_general') });
			});
		} else {
			blockUI.stop();
			PDialog.error({ text: $translate.instant('_AUTH.error_general') });
		}
	}

	//------------------------------------------------------------------------------------------------->
	// update account
	//------------------------------------------------------------------------------------------------->

	$scope.updateFormModel = {}
	$scope.updateAccount = function (form) {
		if (!$scope.signinForm.$valid) {
			$scope.signinForm.submitAttempt = true;
			return;
		}
		$scope.signinForm.submitAttempt = false;
		blockUI.start();
		LoyaltyService.updateCustomer$($scope.updateFormModel).then(response => {
			blockUI.stop();
			if (response && response.IsSuccess) {
				onCloseSuccess();
			} else {
				PDialog.error({ text: $translate.instant('_AUTH.error_general') });
			}
		}).catch(err => {
			blockUI.stop();
			PDialog.error({ text: $translate.instant('_AUTH.error_general') });
		});
	}

	//------------------------------------------------------------------------------------------------->
	// confirm phone
	//------------------------------------------------------------------------------------------------->

	function onCloseSuccess() {
		//var savePayment = ServiceCommon.ccInfoToWalletPayment(args.ccinfo);
		$q.resolve().then(() => {
			if ($scope.ui.savePaymentInfo && $scope.ui.ccInfo) {
				var savePayment = ServiceCommon.ccInfoToWalletPayment($scope.ui.ccInfo);
				return CustomerService.addPaymentMethodToWallet(savePayment, $scope.ui.ccInfo.cvv).then(() => {
					return true;
				}).catch(err => {
					console.error("Error saving ccInfo");
					return true;
				})
			}
			return true;
		}).then(res => {
			try {
				delete $scope.$storage.order.$ccinfo;
			} catch (e) { }
			PDialog.success({ text: $translate.instant("_AUTH.signin_success", { name: $scope.$storage.user.contact.name }) }).then(
				function () {
					$uibModalInstance.close({ mode: 'continue' });
				}
			);
		});
	}

	//------------------------------------------------------------------------------------------------->

	$scope.doReadContract = function (isPrivacy) {
		var modalContract = $uibModal.open({
			templateUrl: 'modules/app/modals/modal_contract.html',
			controller: 'modal_contract_controller',
			windowClass: "modal-default",
			resolve: {
				modalParams: {
					$storage: $scope.$storage,
					isPrivacy: isPrivacy
				}
			}
		});
		modalContract.result.then(function (result) {
			$scope.contact.contractApprove = true;
		});
	}


});



angular.module('app').factory('BenefitsService', function ($rootScope, ENV, CONSTS, $q, $http, $uibModal, PDialog, MetaService, ServiceAgent, blockUI, $state, $translate, $injector, $filter) {
	var service = {
		$storage: MetaService.$storage,
		loyaltyData: null,
		reset: reset,
		init: init,
		showLoyaltyDialog: showLoyaltyDialog,
		clearPromotions: clearPromotions,
		showPromotions: showPromotions,
		getLoyaltyCustomer: getLoyaltyCustomer,
		confirmRegistration: confirmRegistration,
		register: register,
		updateReg: updateReg,
		getBenefits: getBenefits,
		signOut: signOut,
		getPrepared_benifits: getPrepared_benifits,
		calculateTotal: calculateTotal,
		hasPromotions: hasPromotions,
		hasBenefits: hasBenefits,
		prepareRosOrder: prepareRosOrder,
		getPreparedOrder_forloyalty: getPreparedOrder_forloyalty,
		calculateBasketTotal: calculateBasketTotal,

		checkGiftCard: checkGiftCard
	}

	service.$storage.loadingBenefits = false;

	function showLoyaltyDialog(forceAccount) {
		if (service.$storage.orderClosed) return;
		let data = service.$storage.loyaltyData || {};

		let pointsOnly = _.get(service, '$storage.loyaltyClub.pointOnly')
		if (!forceAccount && $state.includes('app.checkout') && data.reference) {
			if (!pointsOnly) showPromotions();
			else showLoyaltyDialog_modal();
		} else {
			showLoyaltyDialog_modal().then(o => {
				delete service.$storage.preCreatedOrder;
				let loyaltyMember = _.get(service.$storage, 'loyaltyMember', {});
				if (loyaltyMember) {
					do {
						let index = _.findIndex(service.$storage.basket, { isLoyaltyOffer: true });
						if (index == -1) break;
						service.$storage.basket.splice(index, 1);
					} while (true);

					if (!_.get(o, 'withoutOffers') && loyaltyMember.requiereOffers) {
						_.each(loyaltyMember.offers, offer => {
							window._ADDTOBASKET(offer);
						});
					}
				}

				if ($state.includes('app.checkout')) {
					getBenefits().then(o => {
						if (pointsOnly && !service.$storage.loyaltyData) calculateBasketTotal();
						if (!pointsOnly && hasPromotions()) showPromotions();
					});
				}
			})
		}
	}

	function showLoyaltyDialog_modal() {
		var deferred = $q.defer();
		var modalMessages = $uibModal.open({
			templateUrl: 'modules/auth/benefits_login_modal.html',
			controller: 'benefits_login_controller',
			windowClass: "modal-center _dark",
			backdrop: "static",
			resolve: {
				modalParams: function () {
					return {}
				}
			}
		});
		modalMessages.result.then(result => {
			deferred.resolve(result);
		}).catch(err => {
			deferred.resolve();
		});
		return deferred.promise;
	}

	function clearPromotions() {
		service.$storage.benefitsTotal = 0;
		do {
			let index = _.findIndex(service.$storage.basket, { isLoyaltyOffer: true });
			if (index == -1) break;
			service.$storage.basket.splice(index, 1);
		} while (true);
		calculateBasketTotal();
		//EntityService.calculateBasketTotal();
	}

	function showPromotions(_args) {
		let deferred = $q.defer();
		let args = _.assignIn({ mode: 'benefits' }, _args);
		let modalMessages = $uibModal.open({
			templateUrl: args.mode == 'benefits' ? 'modules/auth/benefits_promotions_modal.html' : 'modules/auth/benefits_points_modal.html',
			controller: 'benefits_promotions_controller',
			backdrop: 'static',
			windowClass: "modal-center _dark",
			//size: "sm",
			resolve: {
				modalParams: function () {
					return args
				}
			}
		});
		modalMessages.result.then((result) => {
			let mode = _.get(result, 'mode');
			switch (mode) {
				case "approve":
					service.$storage.benefitsTotal = calculateTotal();
					calculateBasketTotal();
					break;
				case "select":
					return showLoyaltyDialog(true);
					break;
			}
			deferred.resolve();
		}).catch(err => {
			deferred.resolve();
		});
		return deferred.promise;
	}	


	function callHTTP(method, url, data, accountGuid) {
		let auth = service.$storage.user ? service.$storage.user.auth : service.$storage.auth;

		var config = {
			url: `${ENV.tabitLoyaltyAPI}${url}`,
			method: method.toUpperCase(),
			data: data,
			headers: {
				'env': service.$storage.region == 'US' ? (ENV.loyaltyEnv || 'us') : 'il',
				'accountGuid': accountGuid ? accountGuid : service.$storage.loyaltyClub.accountGuid,
				'siteId': ServiceAgent.getSiteId(),
				'Authorization': `Bearer ${auth.access_token}`,
				'JoinChannelGuid': ENV.loyaltyJoinChannelGUID,
			}
		}
		if (data) {
			if (method == 'get' || method == 'delete') {
				config.params = data;
			} else {
				config.data = data;
			}
		}
		return $http(config).then(res => {
			return res.data;
		}).catch(error => {
			throw (error);
		});
	}

	function getSimulated(url) {
		var deferred = $q.defer();

		window.setTimeout(() => {
			$http.get(url).then(res => {
				deferred.resolve(res.data);
			});
		}, 500);

		return deferred.promise;
	}

	// ----------------------------------------------------------------------------------------------------------->
	// gift card
	// ----------------------------------------------------------------------------------------------------------->

	function checkGiftCard(pm) {
		blockUI.start();
		return callHTTP('post', '/pos/get-money-balance', { cardNumber: pm.cardNum, cvv: pm.cvv, pincode: pm.otp }, pm.$$loyaltyApiKey).then(res => {
			if (res.IsSuccess) {
				let balance = _.get(res, 'ResponseData.balance', 0);
				if (!balance) throw ({ data: { Key: 'balance' } });
				return balance;
			} else {
				throw ({
					message: res.Message
				});
			}
		}).catch(err => {
			err = _.get(err, 'data', {});
			throw (err);
		}).finally(o => {
			blockUI.stop();
		});
	}

	// ----------------------------------------------------------------------------------------------------------->
	// API
	// ----------------------------------------------------------------------------------------------------------->

	function reset() {
		clearPromotions();
		service.$storage.loyaltyData = service.loyaltyData = service.$storage.loyaltyMember = service.loyaltyMember = null;
	}

	function init(showDialog) {
		reset();
		if (service.$storage.READONLY) return;
		if (showDialog) showLoyaltyDialog();
	}

	function getRegistrationInfo() {
		let club = service.$storage.loyaltyClub, $storage = service.$storage;
		return $q.resolve().then(() => {
			if (club.regInfo) return club.regInfo;
			return callHTTP('get', '/pos/get-registration-info').then(res => {
				res = _.get(res, 'ResponseData');
				if (!res) throw (null);

				_.each(res, (val, key) => {
					if (val == "") res[key] = null;
				});
				_.each(['membershipItems', 'renewItems'], prop => {
					if (!_.get(res, `${prop}[0]`)) return;
					let was = false;
					_.each(res[prop], offerId => {
						let offer = _.find($storage.catalog.offers, { _id: offerId });
						if (!offer) return;
						let price = _.find($storage.catalog.prices, { "offer": offerId, menu: offer.menu });
						if (!price) return;
						res[`${prop}_price`] = (price.price / 100);
						was = true;
						return false;
					});
					switch (prop) {
						case 'membershipItems':
							if (!was) club.disableRegistration = true;
							else {
								res.clubRegMessage = $translate.instant("_LOYALTY_REG.regCostMessage", { price: $filter('money')(res.membershipItems_price) });
								res.clubPromptPriceMessage = $translate.instant("_LOYALTY_REG.regPromptPrice", { price: $filter('money')(res.membershipItems_price) });
							}
							break;
						case 'renewItems':
							if (!was) club.disableRenew = true;
							else {
								res.clubRenewMessage = $translate.instant("_LOYALTY_REG.regRenewMessage", { price: $filter('money')(res.renewItems_price) });
							}
							break;
					}
				});
				club.regInfo = res;
				if (service.$storage.region == 'US') club.pointOnly = true;
			}).catch(err => {
				club.disableRegistration = true;
				club.regInfo = {}
			});
		})
	}

	function getLoyaltyCustomer(query, pincode) {
		if (query) query = query.replace(/\D/g,'');
		if (pincode) pincode = pincode.replace(/\D/g,'');

		return $q.all({
			regInfo: getRegistrationInfo(),
			customer: callHTTP('post', '/pos/get-customer', {
				benefitMoreInfo: true,
				query: query,
				pincode: pincode,
				orderId: service.$storage.order_id
			}).catch(err => {
				return {
					$error: err
				}
			})			
		}).then(res => {	
			if (res.customer.$error) throw (res.customer.$error);
			prepareLoyaltyLoginResponse(res.customer);
		}).catch(err => {
			throw (err.data);
		});
	}

	function confirmRegistration(payload) {
		payload.orderId = getClientOrderId();
		return callHTTP('post', '/pos/otp-manager', payload).then(res => {
			return res;
		}).catch(err => {
			throw (err.data);
		});
	}

	function register(payload) {
		payload = _.cloneDeep(payload);
		payload.orderId = getClientOrderId();
		if (payload.birthDate) { payload.birthDate = moment(payload.birthDate).format('YYYY-MM-DDT00:00:00') }
		if (payload.anniversary) { payload.anniversary = moment(payload.anniversary).format('YYYY-MM-DDT00:00:00') }

		//return callHTTP('post', '/pos/send-invitation', payload).then(res => {
		return callHTTP('post', '/pos/register-customer', payload).then(res => {
			prepareLoyaltyLoginResponse(res, true);
		}).catch(err => {
			console.error(err);
			let message;// = _.get(err, 'error.Message', err.message);
			if (err.type) message = err.message;
			else {
				switch (_.get(err, 'data.Key')) {
					case "InvalidMobileNumber":
						message = $translate.instant("_LOYALTY_REG.inavalid_phone_error");
						break;
				}
			}
			PDialog.info({ text: $translate.instant(message || '_LOYALTY_REG.registration_error') });
			throw (err);
		});
	}

	function updateReg(_payload) {
		let payload = {
			CustomerId: _payload.customerId,
			FirstName: _payload.firstName,
			LastName: _payload.lastName,
			Mobile: _payload.mobile,
			Zehut: _payload.customerId,
			IsConfirmSms: _payload.isConfirmSms ? 1 : 0
		}
		return callHTTP('put', '/ofc/customer', payload).then(res => {
			let c = service.$storage.loyaltyCustomer;
			let data = _.get(res.ResponseData, '[0]', {});
			delete service.$storage.loyaltyMember.$expired;

			_.assignIn(service.$storage.loyaltyCustomer, {
				wasRenewed: true,
				firstName: data.FirstName,
				lastName: data.LastName,
				fullName: data.CustomerName
			});
		}).catch(err => {
			console.error(err);
			PDialog.info({ text: $translate.instant('_LOYALTY_REG.regUpdate_error') });
			return true;
		});
	}

	function prepareLoyaltyLoginResponse(res, isRegister) {
		if (res.IsSuccess) {
			let club = service.$storage.loyaltyClub, $storage = service.$storage;

			//res.ResponseData.balance.points = 30; //SIMULATION ONLY

			getPrepared_benifits(res.ResponseData);
			$storage.pointsCaption = _.get(res.ResponseData, 'configurations.accountSettings.pointsNickName', $translate.instant('_LOYALTY.points'));
			$storage.excludePoints = _.get($storage, 'order.branch.settings.excludeLoyaltyPointsServices', []).indexOf($storage.order.mode) != -1;

			let autoBenefits = [], manualBenefits = [];
			_.each(res.ResponseData.benefits, benefit => {
				if (benefit.isAutoBenefit === false) {
					manualBenefits.push(benefit);
				} else {
					autoBenefits.push(benefit);
				}
			});

			let data = $storage.loyaltyData;
			let loyaltyMember = {
				"workFlow": _.get(data, 'customer.workflow'),
				"loyaltyId": _.get(data, 'customer.cardNumber'),
				"points": _.get(data, 'balance.points', 0),
				"club": club._id,
				"accountGuid": club.accountGuid,
				"identification": {
					"value": _.get(data, 'customer.cardNumber'),
					"method": "cardNum"
				},
				"name": _.get(data, 'customer.fullName'),
				"printMessage": _.get(data, 'customer.PrintMessage'),
				"$$maxManualBenfits": _.get(res.ResponseData, 'configurations.accountSettings.limitManualRedeemBenefit', false),
			}
			if (autoBenefits[0]) loyaltyMember.$$autoBenefits = autoBenefits;
			if (manualBenefits[0]) loyaltyMember.$$manualBenefits = manualBenefits;
			$storage.loyaltyCustomer = data.customer || {};

			/*
			if (true) {//card expired simulation
				loyaltyMember.workFlow = "CustomerExpired";
				_.set(data, 'configurations.renewItems.offers', club.regInfo.renewItems)
			}
			*/

			let offerIds = [], offerMessage;
			switch (loyaltyMember.workFlow) {
				case "MustPayMembershipFee":
					isRegister = true;
					offerMessage = "_LOYALTY_REG.regPaySuccesComment";
					offerIds = _.get(data, 'configurations.membershipItems.offers', []);
					break;
				case "CustomerExpired":
					loyaltyMember.isRenew = true;
					offerMessage = "_LOYALTY_REG.loginPayExpiredComment";
					offerIds = _.get(data, 'configurations.renewItems.offers', []);
					loyaltyMember.$expired = true;
					loyaltyMember.$cardExpired = true;
					break;
				//case "ExpiredSoon": break;
				default:
					if (!$storage.loyaltyCustomer.isLoyaltyCustomer && !club.disableRegistration) {
						$storage.loyaltyCustomer.promptRegistration = true;
						$storage.loyaltyCustomer.promptRegistrationMessage = club.regInfo.joinMessage;
					}
					break;
			}

			if (offerIds.length && $storage.catalog) {
				let members = [], membersPrice = 0;
				loyaltyMember.requiereOffers = true;

				_.each(offerIds, offerId => {
					let offer = _.find($storage.catalog.offers, { _id: offerId });
					if (!offer) return;
					let price = _.find($storage.catalog.prices, { "offer": offerId, menu: offer.menu });
					if (!price) return;
					let member = {
						offer: offerId,
						menu: offer.menu,
						name: offer.name,
						isLoyaltyOffer: true
					}
					prepareViewItem($storage.catalog, member);
					if (member._disabled) return;
					member.total = member.price;
					membersPrice += member.price;
					member.quantity = 1;
					members.push(member);
					return false;
				});
				if (!members.length) throw ({
					type: 'missing_offers',
					message: '_LOYALTY_REG.missing_offers_error'
				});
				loyaltyMember.requiereOffersMessage = $translate.instant(offerMessage, { price: $filter('money')(membersPrice) })
				loyaltyMember.offers = members;
			}
			loyaltyMember.isRegister = isRegister;
			service.$storage.loyaltyMember = service.loyaltyMember = loyaltyMember;
			console.log(service.loyaltyMember);
		} else {
			throw ({
				message: res.Message
			});
		}
	}

	function prepareViewItem (catalog, item) {
		let offer = _.find(catalog.offers, { "_id": item.offer });
		var price = _.find(catalog.prices, { "offer": item.offer, menu: item.menu });
		if (offer && price) {
			item.realName = item.name = offer.name;
			item.description = offer.description;
			item._offer = offer;
			item.price = item.viewPrice = price.price / 100;
			var offerItem = offer.items && offer.items[0];
			if (offerItem) {
				let inv = catalog.inventory;
				if (inv[offerItem]) {
					if (inv.hideInventory) item.disabled = true;
					item.stockDisabled = true;
				}
				var refItem = _.find(catalog.items, { "_id": offerItem });
				if (refItem) {
					item._item = refItem;
					if (!item.description) item.description = refItem.description;
					if (!refItem._proccesd) {
						let _total = 0;
						refItem._hasRequiered = false;
						refItem._addPrice = _total;
					}
				}
			}
		} else {
			item.disabled = true;
		}
	}

	function getBenefits() {
		let data = service.$storage.loyaltyData;
		if (!data) return $q.resolve();

		blockUI.start();
		service.$storage.loadingBenefits = true;
		let order = getPreparedOrder_forloyalty(service.$storage);

		if (false) {
			return getSimulated('/_mock/benifits.json').then(res => {
				getPrepared_benifits(res.ResponseData)
			}).finally(o => { blockUI.stop() });
		}

		let benefitsToRedeem = _.filter(service.$storage.loyaltyMember.$$manualBenefits, _benefit => _benefit.active); 

		return callHTTP('post', '/pos/get-benefits', {
			customer: { cardNumber: _.get(data, 'customer.cardNumber') },
			benefitsToRedeem: _.map(benefitsToRedeem, benefit => { return { externalSaleId: benefit.externalSaleId } }),
			order: order
		}).then(res => {
			if (res.IsSuccess) {
				getPrepared_benifits(res.ResponseData);

				let notRedeemed = [];
				if (benefitsToRedeem.length) {
					let redeemedBenefits = _.get(res.ResponseData, 'orderBenefits.byBenefits', []);
					_.each(benefitsToRedeem, benefit => {
						let redeemed = _.find(redeemedBenefits, { externalSaleId: benefit.externalSaleId });
						if (redeemed) benefit.$$notRedeemed = false;
						else {
							benefit.$$notRedeemed = true;
							notRedeemed.push(benefit);
						}
					})
				}
				if (!notRedeemed[0]) notRedeemed = null;
				service.$storage.loyaltyMember.$$notRedeemed = notRedeemed;
				service.$storage.loyaltyData.prevReference = service.$storage.loyaltyData.reference.loyaltyReferenceId;
				if (hasBenefits()) service.$storage.benefitsTotal = calculateTotal();
				calculateBasketTotal();
			} else {
				throw ({
					message: res.Message
				});
			}
		}).catch(err => {
			console.error(err);
			let message = _.get(err, 'data.Message', err.message)
			PDialog.info({ text: message || $translate.instant('_LOYALTY.member_not_found') });
			throw (err);
		}).finally(o => {
			service.$storage.loadingBenefits = false;
			blockUI.stop();
		});
	}

	function signOut(prevReferenceId, fromCheckout) {
		if (service.$storage.disableLoyaltyCancel) return;
		if ($state.includes('app.checkout') && _.get(service.$storage.order, 'branch.settings.forceLoyaltyServices', []).includes(service.$storage.order.mode)) $state.go("app.order.menus");
		let data = service.$storage.loyaltyData;
		let loyaltyReferenceId = _.get(data, 'reference.loyaltyReferenceId', prevReferenceId);
		let cardNumber = _.get(service.$storage, 'loyaltyMember.loyaltyId');


		reset();
		if (loyaltyReferenceId) {
			return callHTTP('post', `/pos/cancel-order`, {
				loyaltyReferenceId: loyaltyReferenceId,
				orderId: service.$storage.order_id, //check
				cardNumber: cardNumber
			}).catch(err => {
				console.error(err);
			}).then(res => {

			});
		}
	}

	//------------------------------------------------------------------------------->

	function getPrepared_benifits(data) {
		service.$storage.loyaltyData = service.loyaltyData = data;
		if (!data) return;
		_.each(data.benefits, benefit => {
			let key = benefit.programBenefitTypeAlias || 'Other';
			benefit._type = `_${key.toLowerCase()}`;

			benefit.name = _.get(benefit, 'moreInfo.headerForApp');
			if (!benefit.name || benefit.name == "") {
				key = `_BENEFITS.${key}`;
				benefit.name = $translate.instant(key);
				if (benefit.name == key) benefit.name = $translate.instant('_BENEFITS.Other');
			}			
			benefit.description = _.get(benefit, 'moreInfo.headerForAp', benefit.description);
			benefit.terms = _.get(benefit, 'moreInfo.messageForApp');
			let _types = _.get(benefit, 'moreInfo.orderTypes');
			if (_types) {
				let typesMap = {
					order: $translate.instant("delivery"),
					takeaway: $translate.instant("takeaway"),
					seated: $translate.instant("seated")
				}
				_types = _.map(_types, tp => `${typesMap[tp].toLowerCase()}`);
				_types = MetaService.translateValList(_types);
				benefit._types = $translate.instant("_LOYALTY.benefit_types", { val: _types});
			}
			if (benefit.terms) {
				console.log('terms', benefit.terms)
			}
			
		});
		data.pontsBalance = 0;

		//if (window.ISLOCAL) _.set(data, 'balance.points', 100); //SIMULATIO

		let pontsBalance = _.get(data, 'balance.points');
		if (pontsBalance) {
			data.pontsBalance = pontsBalance;
			data.hasPoints = true;
			data.pointsUsed = 0;
		}
		let cardPrepaid = _.get(data, 'balance.prePaid');
		if (cardPrepaid) {
			data.cardPrepaid = cardPrepaid;
			data.hasPrepaid = true;
		}
		data.hasBalance = data.hasPoints || data.hasBalance;
		data.hasBenefits = _.get(data, 'orderBenefits.byBenefits[0]');;

		let compBalance = _.get(data, 'balance.moneyCompensation', 0);
		_.set(data, 'balance.pointsV', service.getFromattedPoints(Math.max(0, pontsBalance - compBalance)));
		data.balance.pointsVReal = data.balance.pointsV;

		if (data.customer.workflow == "CustomerExpired") {
			data.pontsBalance = compBalance;
			data.balance.pointsVReal = service.getFromattedPoints(compBalance);
		}


		data.totalBenefits = 0;
		let totalBenefits = _.get(data, 'orderBenefits.totalDiscount');
		if (totalBenefits) {
			data.totalBenefits = totalBenefits;
			
		}
	}
	service.getFromattedPoints = function (val) {
		if (isNaN(val)) return;
		if (service.$storage.region == 'US') return val.toFixed(2);
		else return val;
	}

	function hasPromotions() {
		let data = service.$storage.loyaltyData;
		return data && (data.hasPoints || data.hasBenefits);
	}

	function hasBenefits() {
		return calculateTotal() > 0;
	}

	function calculateTotal() {
		if (!hasPromotions()) return 0;
		let data = service.$storage.loyaltyData;
		return _.get(data, 'pointsUsed', 0) + _.get(data, 'totalBenefits', 0);
	}

	function prepareRosOrder(order, _storage) {
		let data = service.$storage.loyaltyData;
		if (!hasPromotions() || !service.$storage.benefitsTotal) {
			if (data && data.reference) {
				order.member = _.assignIn(prepareRosOrder_member(), { "reference": data.reference });
			}			
			return;
		}
		let club = service.$storage.loyaltyClub;
		let promotions = [], promotion, rewards = [];
		let balanceDiff = 0, amount = 0, amountReal = 0;
		if (data.hasBenefits) {
			amount = _.get(data, 'totalBenefits', 0);
			amountReal = Math.floor(amount * 100);
			balanceDiff += amount;
			promotion = {
				"_id": getObjectId(),
				"promotion": club.generalPromotion,
				"promotionType": "generalDiscount",
				"club": club._id,
				"pointsUsed": 0,
				"targets": [

				],
				"suggestedAmount": amountReal,
				"providerInfo": {
					"transactionId": _.get(data, 'reference.loyaltyReferenceId')
				}
			}
			promotions.push(promotion);
			rewards.push({
				_type: "AmountOffOrder",
				promotion: promotion._id,
				manual: true,
				discount: {
					amount: amountReal,
					amountOff: amountReal,
				}
			});

		}
		if (data.hasPoints && data.pointsUsed) {
			amount = data.pointsUsed;
			amountReal = Math.floor(amount * 100);
			balanceDiff += amount;
			promotion = {
				"_id": getObjectId(),
				"promotion": club.pointsPromotion,
				"promotionType": "pointsDiscount",
				"club": club._id,
				"pointsUsed": amount,
				"targets": [

				],
				"suggestedAmount": amountReal,
				"providerInfo": {
					"transactionId": _.get(data, 'reference.loyaltyReferenceId')
				}
			}
			rewards.push({
				_type: "AmountOffOrder",
				promotion: promotion._id,
				manual: true,
				discount: {
					amount: amountReal,
					amountOff: amountReal,
				}
			});
			promotions.push(promotion);
		}
		if (promotions.length) {
			order.orderedPromotions = promotions;
			order.member = _.assignIn(prepareRosOrder_member(), { "reference": data.reference });
			let manualBenefits = _.get(service.$storage, 'loyaltyMember.$$manualBenefits'), benefitsToRedeem;
			if (manualBenefits) benefitsToRedeem = _.filter(manualBenefits, _benefit => _benefit.active);
			if (benefitsToRedeem) order.member.benefitsToRedeem = _.map(benefitsToRedeem, benefit => { return { externalSaleId: benefit.externalSaleId } });
			_.set(order, 'clientStatistics.updatedByOperationalInterface', true);
		}
	}

	function prepareRosOrder_member() {
		let json = JSON.stringify(service.$storage.loyaltyMember, function (key, value) {
			if (key.indexOf("$") === 0) {
				return undefined;
			}
			return value;
		});
		return JSON.parse(json);
	}


	function getPreparedOrder_forloyalty(_storage, options) {
		_storage.offerIndex = 0;
		let order = _storage.order;
		options = options || {};

		let tdServiceTypeMap = {
			"eatin": "seated",
			"takeaway": "takeaway"
		}

		let ret = {
			"organization": _storage.rosConfig.organization,
			"orderId": getClientOrderId(),
			"orderNumber": undefined,
			"waiterName": undefined,
			"orderType": CONSTS.tdTypeMap[order.mode],
			"serviceType": CONSTS.tdServiceTypeMap[order.mode],
			"numberOfDiners": undefined,
			"tableNumber": undefined,
			"offers": [],
			"itemsTotal": order.itemsTotal,
			"orderTotal": order.total,
		}
		if (order.itemsDiscount && order.TTPromotion) {
			if (order.TTPromotion.valueType == 'amount'){
				ret.orderDiscountAmount = ret.generalDiscountPrice = order.TTPromotion.value;//order.itemsDiscount;
			}else{
				ret.orderDiscountPercent = ret.generalDiscountPercent = order.TTPromotion.value;
			}
			//ret.orderDiscountValue = order.itemsDiscount;
		}

		let offerIndex = 0;
		if (order.mode == 'delivery' && !order.freeDelivery && order.region && order.region.deliveryOffer) {
			var deliveryOffer = order.region.deliveryOffer;
			ret.offers.push({
				"index": ++_storage.offerIndex,
				"price": deliveryOffer.price / 100,
				"qty": 1,
				"offerId": deliveryOffer.offer,
				"offerName": deliveryOffer.realName || deliveryOffer.name,
				"menuId": deliveryOffer.menu,
				"menuName": undefined,
				"sectionId": deliveryOffer.section,
				"sectionName": deliveryOffer.sectionName,//"המבורגרים",
				"isDeliveryFee": true,
				"items": []
			})
		}

		//start basket
		_.each(_storage.basket, function (offer) {

			delete offer.$startIndex;
			delete offer.$endIndex;

			let offerPrice = offer.price;
			var q = offer.quantity || 1;
			
			let startOfferIndex = _storage.offerIndex + 1;
			for (var i = 0; i < q; i++) {
				let parsedOffer = {
					"index": ++_storage.offerIndex,
					"price": offerPrice,
					"qty": 1,
					"offerId": offer.offer,
					"offerName": offer.realName || offer.name,
					"menuId": offer.menu,
					"menuName": undefined,//"עקריות",
					"sectionId": offer._offer.section,//"5e4c192435598b2cfa0225f2",
					"sectionName": offer._offer.sectionName,//"המבורגרים",
					"items": []
				}

				// main offer item
				if (offer._item) {//offer._item._addPrice
					prepareBasketOfferItem(offer._item, offer._item.total, parsedOffer);
				}
				// basket items
				_.each(offer.selectionSummary, function (selectionGroup) {
					_.each(selectionGroup.items, function (item) {
						let count = item.count || 1
						for (let i = 0; i < count; i++) {
							prepareBasketOfferItem(item._item, item.price, parsedOffer);
						}
					});
				});
				offer.$startIndex = startOfferIndex;
				offer.$endIndex = _storage.offerIndex
				if (offerPrice || parsedOffer.items.length) ret.offers.push(parsedOffer);
			}

			function prepareBasketOfferItem(item, itemPrice, parsedOffer) {
				if (itemPrice) {
					let pitem = {
						"index": ++_storage.offerIndex,
						"price": itemPrice,
						"qty": 1,
						"itemId": item._id,
						"itemName": item.realName || item.name,
						"menuId": parsedOffer.menuId,
						"menuName": parsedOffer.menuName,
						"sectionId": parsedOffer.sectionId,
						"sectionName": parsedOffer.sectionName
					}
					parsedOffer.items.push(pitem);
				}

				_.each(item.modifierGroups, (group) => {
					if (!group.singleSelection) {
						_.each(group.modifiers, (mod) => {
							if (mod.formationUnit && mod.price) {
								let moditem = {
									"index": ++_storage.offerIndex,
									"price": mod.price,
									"qty": 1,
									"itemId": mod.item || undefined,
									"itemName": mod.name,
									"menuId": parsedOffer.menuId,
									"menuName": parsedOffer.menuName,
									"sectionId": parsedOffer.sectionId,
									"sectionName": parsedOffer.sectionName
								}
								parsedOffer.items.push(moditem);
							}
						});
					}
				});
			}
		});
		return ret;
	}

	function getClientOrderId() {
		if (!service.$storage.order_id) {
			service.$storage.order_id = getObjectId();
		}
		return service.$storage.order_id;
	}

	function getObjectId() {
		let timestamp = (new Date().getTime() / 1000 | 0).toString(16);
		return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
			return (Math.random() * 16 | 0).toString(16);
		}).toLowerCase();
	}

	function calculateBasketTotal() {
		var entityService = $injector.get('EntityService');
		entityService.calculateBasketTotal();
		$rootScope.$emit('benefitsChange', true);
	}

	return service;
})


app.directive('benefitsCheckout', function (BenefitsService, $state) {
	return {
		restrict: 'E',
		scope: {
			mode: '=',
			subject: '=',
			iscc: '='
		},
		templateUrl:'modules/auth/benefits_checkout_template.html',
		link: function (scope, element, attr) {
			scope.benefitsService = BenefitsService;
			scope.$storage = BenefitsService.$storage;
			scope.isInCheckout = $state.includes('app.checkout');
		}

	};
});

app.controller('benefits_promotions_controller', function ($scope, PDialog, $uibModalInstance, $translate, MetaService, BenefitsService, modalParams, $state) {

	$scope.BenefitsService = BenefitsService;
	$scope.ui = {
		title: $translate.instant(MetaService.$storage.loyaltyClub.benefitsTitle, { name: MetaService.$storage.loyaltyClub.name }),
	}

	init();
	function init() {
		$scope.mode = modalParams.mode;

		let _storage = MetaService.$storage;
		$scope.$storage = _storage;
		let data = _storage.loyaltyData;
		$scope.order = _storage.order;
		$scope.loyaltyClub = _storage.loyaltyClub;
		$scope.catalog = _storage.catalog;
		$scope.basket = _.cloneDeep(_storage.basket);
		$scope.data = data;
		$scope.ui.pointsUsed = data.pointsUsed || 0;

		$scope.ui.maxPoints = Math.min(data.pontsBalance, $scope.$storage.order.grandTotalCleanBeforeTax - data.totalBenefits);
		if (data.hasBenefits) {
			let benefits = _.get(data.orderBenefits, 'byRows', []);
			_.each($scope.basket, offer => {
				let _benefits = [], totalDiscount = 0;
				for (let i = offer.$startIndex; i <= offer.$endIndex; i++) {
					let _benefit = _.find(benefits, { index: i });
					if (_benefit && _benefit.totalDiscount) {
						totalDiscount += _benefit.totalDiscount;
						_benefits.push(_benefit)
					}
				}
				if (_benefits.length) {
					offer.$totalDiscount = totalDiscount;
					offer.$benefits = _benefits;
				}
			});
		}
		calculateTotal();
		if (!$scope.ui.totalBenefits) {
			$scope.ui.title = $translate.instant('_LOYALTY.attention_title');
		}
	}


	$scope.checkPoints = function () {
		if (isNaN($scope.ui.pointsUsed)) return; //$scope.ui.pointsUsed = 0;
		else $scope.ui.pointsUsed = Number($scope.ui.pointsUsed);

		if ($scope.ui.pointsUsed < 0 || isNaN($scope.ui.pointsUsed)) $scope.ui.pointsUsed = 0;
		else if ($scope.ui.pointsUsed > $scope.ui.maxPoints) $scope.ui.pointsUsed = $scope.ui.maxPoints;

		$scope.ui.pointsUsed = _.floor($scope.ui.pointsUsed, $scope.$storage.region == 'US' ? 2 : 0 ) 

		try {
			if ($scope.ui.pointEvent) {
				$scope.ui.pointEvent.target.value = $scope.ui.pointsUsed;
			}
		} catch (e) { }


		calculateTotal();
	}


	$scope.ui.validatePoints = function (val) {
		if (isNaN(val) || val < 0 || val > $scope.ui.maxPoints) {
			calculateTotal({ points: 0 })
			return false;
		}
		calculateTotal({
			points: _.floor(val, $scope.$storage.region == 'US' ? 2 : 0)
		});
		return true;
	}

	$scope.setPoints = function(op) {
		let data = $scope.data;
		let newPoints = Math.min(data.pontsBalance, Math.max(0, $scope.ui.pointsUsed + op));
		if (newPoints < 0 || newPoints > data.pontsBalance) return;
		let maxPoints = $scope.ui.maxPoints;
		if (newPoints > maxPoints) newPoints = maxPoints;
		$scope.ui.pointsUsed = newPoints;
		calculateTotal();
	}

	$scope.cancelBenefits = function (fromCheckout) {
		BenefitsService.signOut(null,fromCheckout);
		$scope.cancel();
	}

	function calculateTotal(args) {
		let data = $scope.data;
		let _totalBenefits = data.totalBenefits || 0;
		let points = _.get(args, 'points', $scope.ui.pointsUsed);
		if (isNaN(points)) points = 0;
		$scope.ui.totalBenefits = _totalBenefits + points;
		$scope.ui.total = $scope.$storage.order.grandTotalCleanBeforeTax - _totalBenefits - points;// - _.get(data, 'totalBenefits', 0);
	}

	$scope.applyBenefits = function () {
		$scope.data.pointsUsed = $scope.ui.pointsUsed;
		$uibModalInstance.close({ mode:'approve'});
	}

	$scope.applyPoints = function () {
		if (!$scope.pointForm.$valid) return;
		$scope.data.pointsUsed = _.floor($scope.ui.pointsUsed, $scope.$storage.region == 'US' ? 2 : 0);
		$uibModalInstance.close({ mode: 'approve' });
	}

	$scope.updateItems = function () {
		$state.go("app.order.menus");
	}

	$scope.selectBenefits = function () {
		$uibModalInstance.close({ mode: 'select' });
	}

	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	}
})


app.controller('benefits_login_controller', function ($scope, PDialog, $uibModalInstance, $translate, MetaService, BenefitsService, $uibModal, modalParams, blockUI, $state) {
	$scope.MS = MetaService;
	$scope.$storage = MetaService.$storage;
	$scope.BenefitsService = BenefitsService;

	$scope.args = {
		mode: 'login',
		maxBirthdayDate: moment().subtract(10, 'years').toDate(),
		maxAnniversary: moment().subtract(1, 'days').toDate(),
		token: _.get($scope.$storage.user, 'cell','')//document.location.href.indexOf('index_test.html') != -1 ? '0544596700' : ''
	}

	if ($scope.$storage.loyaltyData) {//customer already loaded
		setAccountStep();
	} else if ($scope.$storage.loyaltyClubs[1]) {
		setClubsStep();
	} else {
		setLoginStep();
	}

	function setClubsStep() {
		$scope.args.hasMultyClubs = true;
		$scope.args.mode = "clubs";
		$scope.args.title = $translate.instant($scope.$storage.loyaltyClubsCaption);
	}

	function setLoginStep(club) {
		$scope.args.title = $translate.instant($scope.$storage.loyaltyClub.greeting)
		$scope.args.mode = 'login';
	}

	function setAuthStep() {
		$scope.challengeForm.submitAttempt = false;
		$scope.args.title = $translate.instant('_LOYALTY.auth_title');
		$scope.args.mode = 'auth';
	}

	function setAccountStep() {
		$scope.args.mode = 'account';
		$scope.info = $scope.$storage.loyaltyData;
		$scope.member = $scope.$storage.loyaltyMember;
		var cName = _.get($scope.info, 'customer.fullName');
		if ($scope.member.requiereOffers && !_.get($scope.$storage.loyaltyCustomer, 'wasRenewed')) {
			$scope.args.mode = 'requiered';
			$scope.args.title = $translate.instant('_LOYALTY_REG.loginPayExpiredTitle', { name: cName });
		} else {
			if ($scope.member.$$manualBenefits) {
				$scope.args.title = $translate.instant('_LOYALTY.select_benefits_title', { name: $scope.info.customer.fullName });
			} else if (cName && $scope.$storage.loyaltyClub.clubMethod == 'club') {
				$scope.args.title = $translate.instant('_LOYALTY.member_greeting', { name: $scope.info.customer.fullName });
			} else {
				$scope.args.title = $translate.instant("_LOYALTY.voucher_member_greeting");
			}
		}	
	}

	$scope.selectClub = function (club) {
		$scope.$storage.loyaltyClub = club;
		setLoginStep();
	}

	$scope.login = login;
	function login(authToken, isResend) {
		if (isResend || $scope.challengeForm.$valid) {
			blockUI.start();
			BenefitsService.getLoyaltyCustomer($scope.args.token, authToken).then(res => {
				if ($state.includes('app.checkout') && !$scope.$storage.loyaltyMember.$$manualBenefits) {
					$uibModalInstance.close({});
				} else {
					setAccountStep();
				}
				
			}).catch(err => {
				let phone = $scope.args.token, club = $scope.$storage.loyaltyClub;
				switch (_.get(err, 'Key')) {
					case "ExternalProviderErrror":
						PDialog.info({ text: err.Message });
						break;
					case "auth_otpSent":
						setAuthStep();
						break;
					case "VoucherAlreadyUsed":
						PDialog.info({ text: $translate.instant("_LOYALTY.error_voucher_redeemed") });
						break;
					case "LoyaltyProviderError_InternalGetCustomer": ;
					case "InvalidPinCode":
						if ($scope.args.mode == 'auth') {
							$scope.challengeForm.submitAttempt = false;
							delete $scope.args.auth;
							PDialog.info({ text: $translate.instant('MESSAGES.PHONE_VERIFICATION_FAILED') });
							break;
						}
					case "ActivationIsRequired":
						setRegisterStep(undefined, {
							'cardNumber': phone,
							'$$cardNumber': (() => {
								let n = phone.length;
								if (n < 5) return phone;
								return _.padStart(phone.slice(-4), n -4, 'x')
							})(),
							fromCard: true,
						});
						break;
					case "customerNotFound":
						let isUSRegion = $scope.$storage.region == 'US';
						let isValidPhone = phone.length == 10 && (isUSRegion || phone.indexOf('05') == 0)
						if (!club.disableRegistration && (isValidPhone || isUSRegion)) { //$scope.$storage.order.branch.settings.enableClubRegistration
							let loyaltyRegURL = window.ISDESKTOP ? club.regInfo.joinImageDesktop : club.regInfo.joinImageMobile;
							loyaltyRegURL = null;
							let joinMessage = club.regInfo.joinMessage;
							if (!joinMessage) joinMessage = $translate.instant("_LOYALTY_REG.regDetailsDescription");
							if (loyaltyRegURL || joinMessage) {
								let args = {
									windowClass: loyaltyRegURL ? "modal-image" : "modal-center _dark",
									backdrop: "static",
									resolve: {
										modalParams: {
											isImage: loyaltyRegURL != null,
											regPromptTitle: $translate.instant("_LOYALTY_REG.regPromptTitle", { phone: phone }),
											regPromptPrice: club.regInfo.clubPromptPriceMessage,
											url: loyaltyRegURL,
											message: joinMessage
										}
									}
								}
								$uibModal.open(_.assignIn({
									templateUrl: 'modules/auth/benefits_reg_confirm_modal.html',
									controller: function ($scope, $uibModalInstance, modalParams) {
										$scope.args = modalParams;
										$scope.isImage = modalParams.url != null;
										$scope.apply = function () {
											$uibModalInstance.close({});
										}
									}
								}, args)).result.then(result => {
									if (result || !loyaltyRegURL) checkRegistrationStep();
								}).catch(err => {
									if (loyaltyRegURL) checkRegistrationStep();
								});
							} else {
								checkRegistrationStep();
							}
							break;
						}
					default:
						PDialog.info({ text: $translate.instant($scope.$storage.loyaltyClub.signinError) });
						break;
				}
			}).finally(o => {
				blockUI.stop();
			})
		}
		function login_regOnExeption() {

		}
	}
	

	//------------------------------------------------------------------------------------------------>

	$scope.promptRegister = function () {
		let c = $scope.$storage.loyaltyCustomer;
		setRegisterStep(c.mobile, {
			mobile: c.mobile,
			firstName: c.firstName,
			lastName: c.lastName,
			fromCompensation: true,
		});		
	}

	$scope.openRegContract = function (url) {
		window.open(url);
	}

	$scope.checkRegistrationStep = checkRegistrationStep;
	function checkRegistrationStep(pincode) {
		//return setRegisterStep($scope.args.token); //used for checking registration string without token
		blockUI.start();
		BenefitsService.confirmRegistration({
			mobile: $scope.args.token,//$scope.args.token
			pincode: pincode
		}).then(res => {
			if (pincode) {
				setRegisterStep($scope.args.token);
			} else {
				$scope.challengeForm.submitAttempt = false;
				$scope.args.title = $translate.instant('_LOYALTY.auth_title');
				$scope.args.mode = 'regConfirm';
			}
		}).catch(err => {
			switch (err.Key) {
				case "InvalidPinCode":
					$scope.challengeForm.submitAttempt = false;
					delete $scope.args.auth;
					PDialog.info({ text: $translate.instant('MESSAGES.PHONE_VERIFICATION_FAILED') });
					break;
				default:
					PDialog.info({ text: $translate.instant($scope.$storage.loyaltyClub.signinError) });
					break;
			}
		}).finally(o => {
			blockUI.stop();
		})
	}
	$scope.confirmRegStepResend = function () {
		$scope.challengeForm.submitAttempt = false;
		delete $scope.args.auth;
		checkRegistrationStep();
	}

	function setRegisterStep(phone, args) {
		let regInfo = _.get($scope.$storage.loyaltyClub, 'regInfo', {});
		$scope.challengeForm.submitAttempt = false;
		$scope.reg = _.assignIn({
			mobile: phone,
			isConfirmSms: regInfo.confirmSmsDefaultValue == true
		}, args)
		$scope.args.mode = 'regDetails';
		$scope.args.title = $translate.instant("_LOYALTY_REG.regDetailsTitle");
	}
	$scope.register = function () {
		if (!$scope.reg.isConfirmSms) return;
		if (_.get($scope.$storage.loyaltyClub, 'regInfo.termsFile') && !$scope.reg.$$readContract) return;
		if (_.get($scope.$storage.loyaltyClub, 'regInfo.clubRegMessage') && !$scope.reg.$$clubRegMessage) return;
		if (!$scope.challengeForm.$valid) return;

		blockUI.start();
		BenefitsService.register(_.omit($scope.reg, ['fromCard'])).then(res => {
			$uibModalInstance.dismiss('cancel');
			/*
			$scope.args.title = $translate.instant('_LOYALTY_REG.regSuccessTitle');
			if (loyaltyMember.requiereOffers) {
				$scope.args.regSuccesCaption = $translate.instant('_LOYALTY_REG.regPaySuccesCaption');
			} else {
				$scope.args.regSuccesCaption = $translate.instant('_LOYALTY_REG.regSuccesCaption');
			}
			$scope.args.mode = 'regSuccess';
			*/
		}).catch(err => {

		}).finally(o => {
			blockUI.stop();
		});
	}

	$scope.registrationCancel = function () {
		if ($scope.reg.fromCompensation) $scope.continue();//setAccountStep();
		else $scope.signOut();
	}

	$scope.checkReqContinue = function () {
		if (_.get($scope.$storage, 'loyaltyData.balance.moneyCompensation', 0)) $scope.requieredContinue(true);
		else $scope.signOut();
	}

	$scope.requieredContinue = function (withoutOffers) {
		$uibModalInstance.close({ withoutOffers: withoutOffers === true});
	}

	$scope.setRegUpdateStep = function () {
		//$scope.requieredContinue();
		let regInfo = _.get($scope.$storage.loyaltyClub, 'regInfo', {});
		let c = $scope.$storage.loyaltyCustomer;
		$scope.challengeForm.submitAttempt = false;
		$scope.reg = _.assignIn({
			isConfirmSms: regInfo.confirmSmsDefaultValue == true
		}, $scope.$storage.loyaltyCustomer);
		$scope.args.mode = 'regUpdate';
		$scope.args.title = $translate.instant("_LOYALTY_REG.regDetailsTitle");
	}

	$scope.regUpdate = function () {
		if (!$scope.reg.isConfirmSms) return;
		if (_.get($scope.$storage.loyaltyClub, 'regInfo.termsFile') && !$scope.reg.$$readContract) return;
		if (_.get($scope.$storage.loyaltyClub, 'regInfo.clubRegMessage') && !$scope.reg.$$clubRegMessage) return;
		if (!$scope.challengeForm.$valid) return;

		blockUI.start();
		BenefitsService.updateReg($scope.reg).then(res => {
			$scope.requieredContinue();
		}).catch(err => {

		}).finally(o => {
			blockUI.stop();
		});
	}

	//------------------------------------------------------------------------------------------------>

	$scope.selectManualBenefit = function (card) {
		if (card.active) card.active = false;
		else {
			let maxActive = $scope.member.$$maxManualBenfits;
			if (maxActive) {
				let activeBenfits = _.filter($scope.member.$$manualBenefits, { active: true });
				let activeCount = activeBenfits.length;
				if (activeCount && activeCount == maxActive) {
					if (maxActive == 1) {
						activeBenfits[0].active = false;
					} else {
						PDialog.info({ text: $translate.instant('_LOYALTY.select_benefits_max', { val: maxActive }) });
						return;
					}
				}
			}
			card.active = true;
		}
	}

	$scope.authResend = function () {
		$scope.challengeForm.submitAttempt = false;
		delete $scope.args.auth;
		$scope.login(undefined, true);
	}

	$scope.signOut = function () {
		BenefitsService.signOut();
		$uibModalInstance.dismiss('cancel');
	}

	$scope.continue =	$scope.cancel = function () {
		$uibModalInstance.dismiss('cancel');
	}

})


angular.module('app').factory('DelayOrderService', function ($q, $translate, $uibModal, MetaService, PDialog, blockUI) {
	var service = {
		$storage: MetaService.$storage,
		canOrderNow: canOrderNow,
		init: init,
		showFutureOrder_modal: showFutureOrder_modal,
		onFutureDateSelect: onFutureDateSelect,
		onFutureSlotSelect: onFutureSlotSelect,
		base: {
			minDelayDays: 0,
			maxDelayDays: 30,
			enableOutsideofWorkhours: true,
			showServiceButton: true,
			supplyTimeSteps: 30,
			supplyTimeDisclaimer: undefined,
			days: [
				{
					"day": -1,
					type: "custom",
					enableOrder: true,
					maxTimeForSameDay: 960,

					enableSupply: true,
					enableSameDaySupply: true,
					supplyMode: 'asap',
					supplyRange: {
						from: 480,
						to: 960
					},
					stopOrderSlots: []
				}, {
					"day": 0, type: "default", enableOrder: true, enableSupply: true,
				}, {
					"day": 1, type: "default", enableOrder: true, enableSupply: true,
				}, {
					"day": 2, type: "default", enableOrder: true, enableSupply: true,
				}, {
					"day": 3, type: "default", enableOrder: true, enableSupply: true,
				}, {
					"day": 4, type: "default", enableOrder: true, enableSupply: true,
				}, {
					"day": 5, type: "default", enableOrder: true, enableSupply: true,
				}, {
					"day": 6, type: "default", enableOrder: false, enableSupply: false,
				}
			],
			activeDays: [],
			"inactiveDates": [],
			"dates": [],
			"activeSlots": [],
		}
	}
	return service;

	function getFutureOrderConfig(site, mode) {
		let response;
		if (site.futureOrder2) {
			let service = _.find(site.futureOrder2.services, { service: mode });
			if (!service) service = _.find(site.futureOrder2.services, { service: 'takeaway' });
			response = _.cloneDeep(service);
			_.each(site.futureOrder2, (val, key) => {
				if (key != 'services' && key != 'minDelayDays') response[key] = val;
			});
		} else {
			if (site.futureOrder && site.futureOrder.days) {
				response = _.cloneDeep(site.futureOrder);
				response.supplyTimeDisclaimer = site.settings.supplyTimeDisclaimer;
				response.translations = site.translations

				_.each(response.days, day => {
					if (mode == 'delivery') {
						day.supplyMode = day.deliverySupplyMode;
						day.supplyRange = day.deliverySupply;
					} else {
						day.supplyMode = day.takeawaySupplyMode;
						day.supplyRange = day.takeawaySupply;
					}
				});
			}
		}
		return _.assignIn(_.cloneDeep(service.base), response);
	}

	function canOrderNow(site, mode) {
		let days;
		if (site.futureOrder2) {
			days = _.find(site.futureOrder2.services, { service: mode }).days;
		} else {
			days = _.get(site.futureOrder, 'days');
		}
		if (!days) days = service.base.days;
		let day = moment().toDate().getDay();

		let dataDay = _.find(days, { day: day });
		return dataDay && dataDay.enableOrder;
	}

	function init(order) {
		let branch = order.branch;
		let orderMode = order.mode;

		let delayedOrder = getFutureOrderConfig(branch, orderMode);
		let supplyModeAtt = 'supplyMode', supplyRangeAtt = 'supplyRange';

		let res = {
			"inactiveDates": [],
			"activeDays": [],
			"days": delayedOrder.days,
			"slots": [],
			"today": GETREALDATE(true).startOf('day'),
			"dates": [],
			"supplyTimeDisclaimer": delayedOrder.supplyTimeDisclaimer,
			"translations": delayedOrder.translations
		}

		let defaultDay = delayedOrder.days[0];
		delayedOrder.days.shift();
		let timeStep = delayedOrder.supplyTimeSteps || 30;
		_.each(res.days, day => {
			if (day.enableSupply) {
				res.activeDays.push(day.day);
				if (day.type == "default") {
					day.enableSameDaySupply = defaultDay.enableSameDaySupply;
					day.maxTimeForSameDay = defaultDay.maxTimeForSameDay;
					day.supplyMode = defaultDay[supplyModeAtt];
					day.supplyRange = defaultDay[supplyRangeAtt];
					day.stopOrderSlots = defaultDay.stopOrderSlots;
				} else {
					day.supplyMode = day[supplyModeAtt];
					day.supplyRange = day[supplyRangeAtt];
				}

				if (day.supplyMode == 'range' || day.supplyMode == 'range_max') {
					let slots = prepareDayTimeRange(day, timeStep);
					if (slots) day.slots = slots;
				}
			}
		});


		res.inactiveDates = _.map(delayedOrder.inactiveDates, o => {
			return moment(o);
		});

		let now = GETREALDATE(true);
		let weekDay = now.day();
		let metaDay = delayedOrder.days[weekDay];
		let minDelayDays = delayedOrder.minDelayDays;

		let mmtStart = now.clone().startOf('day');
		let diffMinutes = now.diff(mmtStart, 'minutes');
		if (minDelayDays == 0 && !metaDay.enableSameDaySupply) minDelayDays += 1;
		if (diffMinutes > metaDay.maxTimeForSameDay) minDelayDays += 1;
		// fix ranges for samesay supply
		if (minDelayDays == 0 && (metaDay.supplyMode == 'range' || metaDay.supplyMode == 'range_max')) {
			let orderSetup = branch[orderMode];
			let minOrderDelay = orderMode == "takeaway" ? orderSetup.preparationTime : (order.region && order.region.deliveryTime || orderSetup.deliveryTime);
			minOrderDelay = isNaN(minOrderDelay) ? 30 : Number(minOrderDelay);
			diffMinutes += minOrderDelay;

			let diffMinutesMod = diffMinutes % timeStep;
			if (diffMinutesMod) {
				diffMinutesMod = diffMinutes + (timeStep - diffMinutesMod);
			} else {
				diffMinutesMod = diffMinutes;
			}

			if (diffMinutesMod > metaDay.supplyRange.to) minDelayDays += 1;
			else {
				let slots = prepareDayTimeRange(metaDay, timeStep, diffMinutes);
				if (slots) metaDay.todaySlots = slots;
				else minDelayDays += 1;
			}
		}

		res.minDate = now.clone().add(minDelayDays, 'days').startOf('day');
		res.maxDate = now.clone().add(delayedOrder.maxDelayDays, 'days').endOf('day');

		let checkDay = GETREALDATE(true).startOf('day').add(minDelayDays, 'days');
		let nn = minDelayDays;
		do {
			if (res.activeDays.indexOf(checkDay.day()) != -1) {
				let _was;
				_.each(res.inactiveDates, mDay => {
					if (mDay.isSame(checkDay, 'day')) _was = true;
				});
				if (!_was) {
					let _date = checkDay.toDate();
					if (!res.date) res.date = _date;
					res.dates.push(_date);
				}
			}
			checkDay.add(1, 'day');
			++nn;
		} while (nn <= delayedOrder.maxDelayDays)

		// prepare day slots ---------------------------------------------------->
		_.each(delayedOrder.slots, (slot, index) => {
			slot.id = `a-${index}`;
			slot.startTime = getTimeFromMins(slot.from);
			slot.endTime = getTimeFromMins(slot.to);
			slot.text = `${slot.startTime}-${slot.endTime}`;
			_.each(slot.days, day => {
				let _metaDay = res.days[day];
				if (_metaDay.enableSupply && _metaDay.supplyMode == 'slots') {
					if (!_metaDay.slots) _metaDay.slots = [];
					_metaDay.slots.push(slot);
				}
			});
		});

		return res;
	}


	function prepareDayTimeRange(day, timeStep, min) {
		let isFromTo = day.supplyMode == 'range_max';

		let range = day.supplyRange;
		let slots = [];
		let val = range.from;
		do {
			if (!min || val > min) {
				let excluded = _.find(day.stopOrderSlots, slot => {
					return val >= slot.from && val <= slot.to;
				})
				if (!excluded) {
					let timeText = getTimeFromMins(val);
					let newSlot = {
						id: `a-${val}`,
						type: 'range',
						from: val,
						text: timeText
					}
					if (isFromTo) {
						newSlot.to = val + timeStep;
						newSlot.text = `${newSlot.text} - ${getTimeFromMins(newSlot.to)}`;
					}
					slots.push(newSlot);
				}
			}
			val += timeStep;

			if (isFromTo && val == range.to) {
				break;
			}
		} while (val <= range.to);
		return slots.length ? slots : null;
	}

	function onFutureDateSelect(data, dt) {
		if (!dt) return;
		let day = dt.getDay();
		let metaDay = data.days[day];
		let isToday = moment(dt).isSame(data.today, 'day');

		let slots = isToday ? metaDay.todaySlots : metaDay.slots;
		if (slots) {
			data.activeSlots = slots;
			if (data.slot) {
				data.slot = _.find(data.activeSlots, { id: data.slot.id });
			}
		} else {
			data.activeSlots = [{ id: 'ASAP', text: $translate.instant("ASAP") }];
			data.slot = data.activeSlots[0];
		}
		if (data.slot) onFutureSlotSelect(data, data.slot);
	}

	function onFutureSlotSelect(data, slot) {
		if (!slot) return;
		if (slot.id == 'ASAP') {
			slot.date = moment(data.date).endOf('day');
			data.text = $translate.instant("_DELAYED.for_date", { val: `${slot.date.format('ddd D MMM')}` });
		} else {
			let from = moment(data.date).add(slot.from, 'minutes');
			slot.date = from.toDate();
			
			if (slot.type == 'range') {
				data.text = $translate.instant("_DELAYED.for_date", { val: `${from.format('ddd D MMM')}, ${slot.text}` });
				if (slot.to) {
					let to = moment(data.date).add(slot.to, 'minutes');
					slot.dateTo = to.toDate();
				}
			} else {
				let to = moment(data.date).add(slot.to, 'minutes');
				slot.dateTo = to.toDate();
				data.text = $translate.instant("_DELAYED.for_date", { val: `${from.format('ddd D MMM')}, ${slot.text}` });
			}
		}
		data.slot = slot;
	}

	function showFutureOrder_modal(params) {
		var deferred = $q.defer();

		let modal = $uibModal.open({
			templateUrl: 'modules/delay/future_modal.html',
			controller: ($scope, $uibModalInstance, DelayOrderService, MetaService, modalParams, PDialog) => {
				$scope.data = _.cloneDeep(modalParams.data);
				$scope.$storage = MetaService.$storage;

				let supplyTimeDisclaimer = modalParams.data.supplyTimeDisclaimer;
				if (supplyTimeDisclaimer) {
					let catalogTrans = MetaService.$storage.catalogTrans;
					supplyTimeDisclaimer = _.get($scope.data, `translations.${catalogTrans}.supplyTimeDisclaimer`, supplyTimeDisclaimer);
					$scope.supplyTimeDisclaimer = supplyTimeDisclaimer;
				} 

				$scope.onFutureDateSelect = function (dt) {
					DelayOrderService.onFutureDateSelect($scope.data, $scope.data.date);
				}
				$scope.onFutureSlotSelect = function () {
					DelayOrderService.onFutureSlotSelect($scope.data, $scope.data.slot);
				}
				$scope.onFutureDateSelect();

				$scope.apply = function () {
					let slot = $scope.data.slot;
					if (!slot) {
						$scope.PDialog.info({ "text": $translate.instant(!$scope.data.date ? "_DELAYED.select_date" : "_DELAYED.select_slot_prompt") });
						return;
					}
					$scope.data.active = true;
					$scope.data.method = 'delayed';
					$uibModalInstance.close($scope.data);
				}
				$scope.cancel = function () {
					$uibModalInstance.dismiss('cancel');
				}
			},
			windowClass: "modal-center _dark",
			backdrop: 'static',
			resolve: {
				modalParams: {
					data: params
				}
			}
		});
		modal.result.then(result => {
			if (result) {
				deferred.resolve(result);
			}
			else deferred.reject();
		}, () => {
			deferred.reject();
		});
		return deferred.promise;
	}

	function getTimeFromMins(mins) {
		var h = mins / 60 | 0,
			m = mins % 60 | 0;
		return moment.utc().hours(h).minutes(m).format(MetaService.local.timeFormat);
	}
});





app.controller('start_controller', function ($scope, $timeout, $uibModal, $translate, $location, $q, ENV, blockUI, MetaService, BenefitsService, ExternalDeliveryService, DelayOrderService, $analytics) {
    $scope.$on('$viewContentLoaded', function () {
        $location.replace(); //clear last history route
    });

    $scope.order = $scope.$storage.order;
    $scope.messageSession = new Date().valueOf() + "_";
	
	$scope.uiStart = {
        orderMethods: {
            "takeaway": {
                name: $translate.instant("TAKE_AWAY"),
                icon: 'images/ta.svg',
                icon_dark: 'images/ta_dark.svg',
            },
            "eatin": {
                name: $translate.instant("EAT_IN"),
                icon: 'images/eatin.svg',
                icon_dark: 'images/eatin_dark.svg',
            },
            "delivery": {
                name: $translate.instant("HOME_DELIVERY"),
                icon: 'images/delivery.svg',
                icon_dark: 'images/delivery_dark.svg',
            }
		},
		addressTypes: ['apartment', 'office', 'house']
    }

    $scope.loadingStart = true;

	$scope.toggleFavoriteAddress = function (address, index, ev) {
		if (ev) ev.stopPropagation();
		if (address.isDefault) return;
		$scope.ES.setDefaultCachedAddress($scope.cachedAddresses, address, index);
	}

    $scope.init = function () {
		$scope.cachedAddresses = $scope.ES.getCachedAddresses() || [];
		$scope.uiStart.showAddressBar = !$scope.cachedAddresses[0];

        if ($scope.$storage.orderValid) {
            if (_.get($scope,'$storage.cacheOrder.providerTransactionToken')) $scope.ES.loadFromExternalProvider(UIUtils.getURLParams());
            else $scope.$state.go("app.order.menus");
            return;
        } else if ($scope.$storage.orderClosed) {
            $scope.$state.go('end');
            return;
        }
		if (!$scope.$storage.startLoaded) {// || true
			let keys = UIUtils.getURLParams();
			$scope.ES.loadEnterprise().then(o => {
				if ($scope.$storage.$tpOrder) return loadFromOrder();

				$scope.ES.getRelevantUserOrders();
				let settings = _.get($scope.$storage, "organization.branches[0].settings", {});

				$scope.$storage.enableAddressEntrance = settings.enableAddressEntrance;

				let addressSearchMethod;
				if (window.ISDESKTOP) {
					addressSearchMethod = settings.addressDesktopMethod;
					$scope.$storage.enableSearchOnMap = addressSearchMethod == 'address_or_map';
				} else {
					addressSearchMethod = settings.addressMobileMethod;
					$scope.$storage.enableSearchOnMap = addressSearchMethod == 'address_or_map';
					$scope.$storage.searchOnMapOnly = addressSearchMethod == 'map_only';
				}

                if ($scope.$storage.region == 'IL') {
                    $scope.$storage.enableAddressWithoutHouse = settings.enableAddressWithoutHouse;
				}
				translateServices();
				if (keys.service) {
					let service = _.find($scope.$storage.organization._services, { id: keys.service });
					if (service) {
						if (keys.service == "eatin" && keys.table) {
							$scope.order.tableNumber = keys.table;
						}
						let isFuture = keys.future || keys.isFuture;
						if (_.isUndefined(isFuture)) {
							if (service.enabled && service.delayedEnabled) {

							} else if (service.enabled) {
								isFuture = false;
							} else if (service.delayedEnabled) {
								isFuture = true;
							}
						} else {
							isFuture = isFuture == "true" || isFuture == "1";
						}
						if (_.isUndefined(isFuture)) {
							$scope.selectOrderMethod(service);
						} else {
							selectOrderMethod_cont(service.id, isFuture);
						}						
					}
				}
				$scope.ES.popMessages("home", null, $scope.messageSession);
				initContinued();
			}).catch(err => {
                if (!err) err = {}
                err.source = 'init';
                $scope.handleCriticalError(err);
            });
		} else {
			initContinued();
		}

		function loadFromOrder() {
			let site = $scope.$storage.organization.branches[0];
			let config = _.get(site, 'tabitpay', {});
			$scope.$storage.relevantSites = site;
			$scope.order.mode = config.addItems_addItemMenu || 'takeaway';
			$scope.order.modeCaption = '';
			$scope.order.forceDelay = false;
			$scope.order.branch = angular.copy(site);
			$scope.order.branch.wh = null;
			//$scope.ES.setOrderTimeOffsets($scope.order.branch);
			$scope.ES.loadCatalog().then(() => {
				$timeout(function () {
					$scope.ES.popMessages('afterService', $scope.order.mode, null, $scope.order.forceDelay ? 'future' : 'sameDay');
					if ($scope.$storage.loyaltyClub) {
						BenefitsService.init(_.get($scope.$storage, 'order.branch.settings.autoPOPLoyaltyDialog'), false);
					}
				}, 500);
				$scope.$storage.startLoaded = true;
				_.unset($scope.$storage, 'organization.branches');
				_.unset($scope.$storage, 'relevantSites');
				$scope.$state.go("app.order.menus");
			}).catch(err => {
				if (!err) err = {}
				err.source = 'startOrder_cont';
				$scope.handleCriticalError(err);
			});


		}

		function initContinued() {
			if (!$scope.$storage.searchOnMapOnly && !$scope.$storage.enableSearchOnMap) {
				$scope.loadingStart = false;
				return;
			}

			let key = ENV.gMapKey;
			loadGoogleMaps(3, key, $scope.$storage.gMapLanguage, $scope.$storage.gMapRegion).then(o => {
				$scope.$apply(function () {
					$scope.loadingStart = false;
				});

			}, function () {
				$scope.$storage.searchOnMapOnly = false;
				console.error("Error Loading Goggle Maps", e);
				$scope.$apply(function () {
					$scope.loadingStart = false;
				});
			});
		}
	}
	$scope.translateServices = function () {
		$timeout(() => { translateServices() }, 100);
	}
	function translateServices() {
		_.each($scope.$storage.organization._services, site => {
			site.text = getServiceCaption(site.id);
		})
	}

	function getServiceCaption(serviceId) {
		switch (serviceId) {
			case "eatin":
				return $translate.instant($scope.$storage.eatInCaption);
			case "takeaway":
				return $translate.instant($scope.$storage.takeawayCaption);
			case "delivery":
				return $translate.instant($scope.$storage.deliveryCaption);
			case "delay":
				return $translate.instant($scope.$storage.orderDelayCaption);
			default:
				return $translate.instant(serviceId);
		}
	}

    $scope.init();

    $analytics.eventTrack('landing page', { category: 'User Flow' });

	function getOrderMethodOption(group) {
		var modal = $uibModal.open({
			templateUrl: 'modules/start/modals/modal_service.html',
			controller: function ($scope, $uibModalInstance, modalParams, $translate) {
				$scope.group = modalParams.group;
				$scope.apply = function (group, member) {
					group[group.att] = member.id;
					$uibModalInstance.close(group);
				}
				$scope.cancel = function () {
					$uibModalInstance.dismiss('cancel');
				}
			},
			windowClass: "modal-list",
			size: "sm",
			resolve: {
				modalParams: function () {
					return {
						group: group
					};
				}
			}
		});
		modal.result.then((member) => {
			selectOrderMethod_cont(member.mode, member.delay);
		}).catch(err => { });
	}

	$scope.selectOrderMethod = function (_mode) {
		toastr.clear();
		let mode, delay;
		if (_mode.id == 'VIEW_MENU') return selectOrderMethod_cont(_mode.id, false);
		if (_mode.id == 'delay') {
			delay = true;
			if (_mode.members.length == 1) mode = _.get(_mode,'members[0].id');
			else {
				return getOrderMethodOption({
					att: 'mode',
					delay: true,
					text: $translate.instant('SELECT_SERVICE_TYPE'),
					members: _.map(_mode.members, member => {
						member.text = getServiceCaption(member.id);
						return member;
					})
				});
			}
		} else {
			mode = _mode.id;
			if (_mode.enabled && _mode.delayedEnabled) {
				return getOrderMethodOption({
					att: 'delay',
					mode: _mode.id,
					text: $translate.instant('_DELAYED.supply_time'),
					members: [
						{ id: false, text: $translate.instant("_DELAYED.service_today") },
						{ id: true, text: $translate.instant("_DELAYED.service_future") }
					]
				});				
			} else if (_mode.enabled) {
				delay = false;
			} else {
				delay = true;
			}
		}
		selectOrderMethod_cont(mode, delay);
	}

	function selectOrderMethod_cont(mode, delay) {

		if (!selectOrderMethod_check(mode, delay)) return;
		delete $scope.$storage.loadOrder;

		var sites = [];
		_.each($scope.$storage.organization.branches, (site) => {
			if (_.find(site._services, { id: mode })) sites.push(site);
		});

		$scope.$storage.relevantSites = sites;
		$scope.order.mode = mode;
		$scope.order.modeCaption = getServiceCaption(mode);
		$scope.order.forceDelay = delay;

		switch ($scope.order.mode) {
			case "delivery":
				$scope.order.step = 'address';
				$scope.ES.popMessages("address", null, $scope.messageSession, $scope.order.forceDelay ? 'future' : 'sameDay', !window.ISDESKTOP && { positionClass: "toast-bottom-right"});

				$scope.uiAddress = {
					mode: 'street',
					addresses: []
				}
				break;
			default:
				if (sites.length == 1) {
					$scope.selectBranch(sites[0]);
				} else {
					$scope.order.step = 'branch';
				}
		}
	}

	function selectOrderMethod_check(mode, delay, _org) {
		if (mode == 'VIEW_MENU') return true;
		var _branch;
		if (!_org) {
			_org = $scope.$storage.organization;
			_branch = _org.branches[0];
		} else {
			_branch = _org;
		}

		let enabled, service = _.find(_org._services, {id: mode});
		if (delay) {
			enabled = service.canDelay;
		} else {
			enabled = service.enabled;
		}

		if (!enabled) {
            var phone = '911';
			if (_branch) {
				phone = _branch.phone;
            }
            $scope.PDialog.info({ text: $translate.instant('MESSAGES.SERVICE_UNAVAILABLE', { st: $translate.instant(mode), phone: phone }) });
            return false;
        };
        return true;
    }

    $scope.doBranchBack = function () {
		toastr.clear();
        $scope.order.step = 'start';
        delete $scope.order.mode;
    };
	$scope.selectBranch = function (branch) {
		$scope.ES.setSiteTimeZone(branch);
		if ($scope.order.mode == 'VIEW_MENU') {
			let fileURL;
			if (branch.settings.enableMenuViewFile) {
				fileURL = checkBranchHours_file(branch, _.get(branch, 'menuView.file.url'));
			}
			if (fileURL) {
				window.open(fileURL, '_blank');
			} else {
				$scope.order.branch = angular.copy(branch);
				$scope.order.branch.wh = null;
				$scope.startOrder(true);
			}
			return;
		}

		if (!selectOrderMethod_check($scope.order.mode, $scope.order.forceDelay, branch)) return;
        checkBranchHours(branch).then(function (time) {
            $scope.order.branch = angular.copy(branch);
			$scope.order.branch.wh = time;

			$scope.ES.setOrderTimeOffsets($scope.order.branch);

			if (!$scope.order.tableNumber && $scope.order.mode == 'eatin' && _.get(branch, 'settings.requiereTableNumberEatin')) {
				$scope.getTableNumber();
			} else {
				$scope.startOrder();
			}			
        });
    };


    // ------------------------------------------------------------------------------------------------->
    // eatin
    // ------------------------------------------------------------------------------------------------->

	$scope.getTableNumber = function () {
		var modal = $uibModal.open({
			templateUrl: 'modules/start/modals/modal_table_number.html',
			controller: function ($scope, $uibModalInstance, MetaService) {
				$scope.response = {};
				$scope.$storage = MetaService.$storage;
				$scope.apply = function () {
					if ($scope.challengeForm.$valid) {
						$uibModalInstance.close($scope.response);
					}
				};
				$scope.cancel = function () {
					$uibModalInstance.dismiss('cancel');
				}
			},
			windowClass: "modal-default modal-table-number",
			size: "sm"
		});
		modal.result.then((res) => {
			if (res && res.table) {
				$scope.order.tableNumber = res.table;
				$scope.startOrder();
			}
		}).catch(err => { });
	}

    // ------------------------------------------------------------------------------------------------->
    // delivery
    // ------------------------------------------------------------------------------------------------->

	$scope.selectAddressOnMap = function () {
		toastr.clear();
		let modal = $uibModal.open({
			templateUrl: 'modules/start/modals/modal_select_onmap.html',
			controller: function ($scope, $uibModalInstance, modalParams) {
				$scope.args = {
					loadingMap: true
				}
				$scope.ui = modalParams.ui;
				$scope.$storage = modalParams.$storage;
				$scope.order = modalParams.order;

				$timeout(() => { $scope.args.loadingMap = false }, 100);

				$scope.selectAddress = function (res) {
					$uibModalInstance.close(res);
				}

				$scope.cancel = function () {
					$uibModalInstance.close();
				}
			},
			windowClass: "modal-default",
			size: $scope.DESKTOP ? 'lg' : "sm",
			resolve: {
				modalParams: {
					$storage: $scope.$storage,
					ui: $scope.ui,
					order: $scope.order
				}
			}
		}).result.then((result) => {
			if (result) $scope.selectAddress(result, 'map');
		});
	}

	$scope.addNewAddress = function () {
		$scope.uiStart.showAddressBar = true;
		MetaService.logTemplate("addNewAddress", {}, "Add New Address");
	}

    $scope.uiAddress = {
        mode: 'street',
        addresses: [],
	};

    $scope.doAddressBack = function () {
		toastr.clear();
        if ($scope.uiAddress.address) {
            if ($scope.uiAddress.branch){
                $scope.uiAddress.branch = null;            
                if (!$scope.uiAddress.sites){
                    $scope.uiAddress.address = null;
                }
            }else{
                $scope.uiAddress.sites = null;
                $scope.uiAddress.address = null;
                $scope.uiAddress.branch = null;            
            }
        } else {
            delete $scope.order.mode;
            $scope.order.step = 'start';
        }
    }

    $scope.getLocation = function (val) {
    	var locality = null
		toastr.clear();

		return $scope.ES.getAddresses_alt(val, locality).then(function (results) {
            $scope.uiAddress.addresses = [];
            $scope.uiAddress.addressValid = false;
			_.each(results, function (result) {
				if (result.isPlace) {
					$scope.uiAddress.addresses.push(result);
					return;
				}

                var valid = false, partial = false, hidden = false;
                var address = { formatted_address: UIUtils.fixSpecialChars(result.formatted_address) };

				if (!result.force_valid && locality && !_.find(result.address_components,{long_name:locality})) return;

                if (result.is_partial_address) {
                    address.locality = result.locality;
                    address.isPartialAddress = true;
                    valid = true;
                } else {
                    _.each(result.address_components, function (comp) {
                        var val = UIUtils.fixSpecialChars(comp.long_name);
                        switch (comp.types[0]) {
                            case "street_number":
                                address.streetNumber = val;
                                valid = true;
                                break;
                            case "route":
                                address.street = val;
                                partial = true;
                                break;
                            case "locality":
                                address.locality = val;
                                partial = true;
                                break;
                        }
                    });
                }
                if (valid) {
                    address.point = result.geometry.location;
                    $scope.uiAddress.addresses.push(address);
                    $scope.uiAddress.addressValid = true;
                } else if (partial) {
                    if (!_.find(results, { locality: address.locality })) {
                        $scope.uiAddress.addresses.push(address);
                        address.partial = true;
                    }
                }
            })
        });
    };

    $scope.clearSearchAddress = function () {
        $scope.uiAddress.addresses = [];
        $scope.uiAddress.asyncSelected = null;
		$scope.uiAddress.addressValid = false;
	};

	$scope.selectPlaceAddress = function (place) {
		toastr.clear();
		$scope.ES.geoCodePlace(place).then(address => {
			$scope.selectAddress(address, 'place');
		})
	}

	$scope.selectAddress = function (address, mode) {
		let logPayload = {
			formattedAddress: address.formatted_address,
			location: `${address.point.lat}, ${address.point.lng}`
		}
		switch (mode) {
			case "place": MetaService.logTemplate("selectAddressPlace", logPayload, "Select Address Place"); break;
			case "local": MetaService.logTemplate("selectAddressTabit", logPayload, "Select Address Tabit"); break;
			case "cache": MetaService.logTemplate("selectAddressCache", logPayload, "Select Address Cache"); break;
			case "map": MetaService.logTemplate("selectAddressMap", logPayload, "Select Address Map"); break;
		}
		$scope.uiStart.addressTypes = mode == 'map' || address.isMapAddress ? ['apartment', 'office', 'house', 'other'] : ['apartment', 'office', 'house'];
		toastr.clear();
		//added extra protection because of empty address bug
		if (address.partial || (!address.street && !address.isPartialAddress)) {
            $scope.PDialog.info({ text: $translate.instant('MISSING_STREET_NUMBER') }).then(function () {
                $("#txtAddressSearch").val(address.formatted_address + " ").trigger('change').focus();
            });
            return;
        }
        selectAddress_cont();

		function selectAddress_cont() {
			let isODelay = $scope.order.forceDelay;
            var sites = [], disabledRegion;
            _.each($scope.$storage.relevantSites, function (b) {
				var found = null;

				// fix region groups
				if (!b.delivery.$regionsPrepared) {
					b.delivery.$regionsPrepared = true;
					_.each(b.delivery.regionGroups, regionGroup => {
						let deliveryTime_add = regionGroup.deliveryTime_add;
						let isInactive = regionGroup.active === false;
						if (regionGroup.active === false || regionGroup.deliveryTime_add) {
							b.delivery.regions.forEach(region => {
								if (region.group == regionGroup.id) {
									if (isInactive) region.active = false;
									if (deliveryTime_add) region.groupTime_add = deliveryTime_add;
								}
							})
						}
					});
				}
				_.each(b.delivery.regions, function (r) {

					switch (r.orderTime) {
						case "sameDay": if (isODelay) return true; break;
						case "future": if (!isODelay) return true; break;
					}

					if (!found) {
						if (UIUtils.isPointInPolygon(address.point, r.paths)) {
                            if (r.active === false) {
                                disabledRegion = { branch: b, region: r };
                            } else {
								if (r.schedule) {// && r.orderTime != 'future'
                                    var timeSlot = _.find(b.timeslots, {_id: r.schedule});
                                    if (timeSlot && !$scope.ES.isTimeslotsActive(timeSlot)){
                                        disabledRegion = {branch:b, region:r};
                                        return ;
                                    }
                                }
                                found = {
                                    site: b,
                                    region: r
                                }
                                return false;
                            }
                        }
                    }
                });
                if (found) sites.push(found)
            });
            if (sites.length) {
                if (sites.length > 1) {
                    $scope.uiAddress.address = prepareAddress(address);
                    $scope.uiAddress.sites = sites;
				} else {
					if (!selectOrderMethod_check($scope.order.mode, $scope.order.forceDelay, sites[0].site)) return;
                    handleAddressBranchFound(sites[0].site, sites[0].region, address);
                };
            } else {
				MetaService.logTemplate("Address unavailable", {
					formattedAddress: address.formatted_address,
					location: `${address.point.lat}, ${address.point.lng}`,
					locationUnavailableReason: disabledRegion ? 'currently unavailable' : 'out of reach'
				}, "Address unavailable");
                handleBranchNotFound(disabledRegion);
            }
        }
        function handleBranchNotFound(disabledRegion) {
			var sMessage = disabledRegion ? "MESSAGES.DISABLED_REGION_MESSAGE" : mode == 'map' ? "MESSAGES.SORRY_NO_BRANCH_FOUND_SERVING_LOCATION" : "MESSAGES.SORRY_NO_BRANCH_FOUND_SERVING_YOUR_ADRESS";
            $scope.PDialog.warning({ text: $translate.instant(sMessage, { address: address.formatted_address }), htmlX: true }).then(function () {
                $scope.uiAddress.addresses = [];
            });
        }
    }

	$scope.selectAddressBranch = function (site) {
		if (!selectOrderMethod_check($scope.order.mode, $scope.order.forceDelay, site.site)) return;
        handleAddressBranchFound(site.site, site.region, $scope.uiAddress.address);
    }

	function confirmAddressBranch(branch, address) {
		if (!$scope.$storage.organization.isChain) return $q.resolve();
		let message = $translate.instant("CONFIRM_ADDRESS", { a: branch.name, b: address.formatted_address });
		return $scope.PDialog.info({
			html: message,
			showCancelButton: true,
			confirmButtonText: $translate.instant("APPROVE")
		}).then(function () {
			return true;
		});
	}

	function handleAddressBranchFound(branch, region, address) {
		confirmAddressBranch(branch, address).then(() => {
			ExternalDeliveryService.prepareSiteForDelivery(branch, address).then(() => {
				$scope.uiStart.showZipCode = $scope.$storage.region == 'US' && !address.postalCode;
				checkBranchHours(branch).then(function (time) {
					var _branch = angular.copy(branch);
					_branch.wh = time;

					$scope.ES.setSiteTimeZone(_branch);
					$scope.ES.setOrderTimeOffsets(_branch);

					var deliveryOffer = branch.delivery.deliveryPriceOffer;
					var deliveryTime = _getNum(branch.delivery.deliveryTime);
					var minOrderPrice = _getNum(branch.delivery.minOrderPrice);
					var freeDeliveryFrom = _getNum(branch.delivery.freeDeliveryFrom);

					if (_.get(branch, "settings.tabitOrderDisableDeliveryFee")) {
						deliveryOffer = null;
						region.deliveryPriceOffer = null;
						freeDeliveryFrom = 0;
						region.freeDeliveryFrom_add = 0;
					}

					if (!region.useDefaults) {
						if (region.deliveryPriceOffer) deliveryOffer = region.deliveryPriceOffer;
						deliveryTime += _getNum(region.deliveryTime_add);
						minOrderPrice += _getNum(region.minOrderPrice_add);
						freeDeliveryFrom += _getNum(region.freeDeliveryFrom_add)
					}
					deliveryTime += _.get(region, 'groupTime_add', 0);
					deliveryTime += _.get(branch, 'timesOffsetSetup.deliveryTimeOffset', 0);
					var _region = {
						name: region.name,
						id: region._id,
						deliveryOffer: deliveryOffer,
						deliveryPrice: deliveryOffer ? deliveryOffer.price / 100 : 0,
						deliveryTime: deliveryTime,
						minOrderPrice: (minOrderPrice || 0) * 1,
						freeDeliveryFrom: !isNaN(freeDeliveryFrom) && Number(freeDeliveryFrom) > 0 ? Number(freeDeliveryFrom) : null
					}
					if (_region.minOrderPrice <= 0) {
						delete _region.minOrderPrice;
					};
					$scope.uiAddress.branch = _branch;
					$scope.uiAddress.region = _region;
					$scope.uiAddress.address = prepareAddress(address);
				});
			});
		});

        function _getNum(val){
            if (isNaN(val)) return 0;
            return Number(val);
        }
    }
		
	function prepareAddress(address){
		if (!address.addressType) address.addressType = $scope.$storage.defaultAddressType || 'apartment';
		return address;
	}

	$scope.saveAddress = function (form) {
		if (form.$valid) {
			var _address = $scope.uiAddress.address;

			//added extra protection because of empty address bug
			if (_address.partial || (!_address.street && !_address.isPartialAddress)) {
				delete $scope.uiAddress.branch;
				delete $scope.uiAddress.address;
				$scope.PDialog.info({ text: $translate.instant('MISSING_STREET_NUMBER') }).then(function () {
					
				});
				return;
			}

            $scope.order.branch = $scope.uiAddress.branch;
            $scope.order.region = $scope.uiAddress.region;
            
            if (_address.formatted_address) {
                var arr = [_address.formatted_address];
                if (_address.appartment) {
                    arr.push($translate.instant("APPARTMENT") + ": " + _address.appartment);
                }
                if (_address.floor) {
                    arr.push($translate.instant("FLOOR") + ": " + _address.floor);
                }
                _address.full_delivery_address = arr.join(", ");
            }
            if ($scope.uiAddress.address.remarks) $scope.uiAddress.address.remarks = UIUtils.fixSpecialChars($scope.uiAddress.address.remarks);
            $scope.order.address = $scope.uiAddress.address;

						switch ($scope.order.address.addressType){
							case "house":
								delete $scope.order.address.floor;
								delete $scope.order.address.appartment;
								delete $scope.order.address.entrance;
								break;
              case "office":
                delete $scope.order.address.appartment;
                break;
			}
			if (!$scope.$storage.searchOnMapOnly) {
				$scope.ES.saveCachedAddresses($scope.cachedAddresses, $scope.order.address);
			}
			
            $scope.startOrder();
        }
	}

	$scope.startOrder = function (readonly) {
		delete $scope.$storage.delayedOrder;
		if (readonly) return startOrder_cont();
		if ($scope.$storage.order.forceDelay && $scope.order.mode != 'VIEW_MENU') {
			return setFutureOrder().then((res) => {
				$scope.$storage.delayedOrder = res;
				return checkExternalDelivery().then(() => { startOrder_cont(); });
			});
		}
		$scope.$storage.exDeliveryParams = null;
    	var wh = _.get($scope.$storage, "order.branch.wh", {});
    	var diff = wh.slotMax && wh.slotMax.diff(GETREALDATE(true), 'minutes');
    	if (isNaN(diff)) diff = 250;
    	if (diff < 30) {
    		$scope.PDialog.info({ text: $translate.instant("MESSAGES.SERVICE_END_WARN", { t: diff }) }).then(function () {
				checkExternalDelivery().then(() => { startOrder_cont(); });
    		});
		} else {
			getOrderDelay().then(res => {
				checkExternalDelivery().then(() => { startOrder_cont(); });
			});
		}

		function checkExternalDelivery() {
			if ($scope.order.mode != 'delivery' || !$scope.order.branch.exDeliveryProvider) return $q.resolve();
			return ExternalDeliveryService.estimateExDeliveryBeforeMenu($scope.order);
		}

    	function startOrder_cont() {
    		$scope.ES.loadCatalog().then(
				function () {
					if (readonly) {
						if (!window.__SIMULATED) $scope.$storage.READONLY = readonly;
						else console.log("removed readonly for simulation")
					} else {
						$timeout(function () {
							$scope.ES.popMessages('afterService', $scope.order.mode, null, $scope.order.forceDelay ? 'future' : 'sameDay');
							if ($scope.$storage.loyaltyClub) {
								BenefitsService.init(_.get($scope.$storage, 'order.branch.settings.autoPOPLoyaltyDialog'), false);
							}
						}, 500);
					}
					$scope.$storage.startLoaded = true;
					_.unset($scope.$storage, 'organization.branches');
					_.unset($scope.$storage, 'relevantSites');
					$scope.$state.go("app.order.menus");
				},
				function (err) {
				    console.log(err);
				    if (!err) err = {}
				    err.source = 'startOrder_cont';
					$scope.handleCriticalError(err);
				}
			);
    	}
	};

    // ------------------------------------------------------------------------------------------------->
    // future order
    // ------------------------------------------------------------------------------------------------->

	function setFutureOrder() {
		let futureOrder = DelayOrderService.init($scope.order);
		if (!futureOrder.date) {
			$scope.MS.displayOrderTimer("MESSAGES.SERVICE_UNAVAILABLE");
			return $q.reject();
		}
		return DelayOrderService.showFutureOrder_modal(futureOrder);
	}

    // ------------------------------------------------------------------------------------------------->
    // delay order
    // ------------------------------------------------------------------------------------------------->

	function getOrderDelay() {
		let order = $scope.order;
		let orderMode = order.mode;
		let site = order.branch;
		let enableDelay = site.settings.enableOrderDelay;
		if (!enableDelay || (orderMode != 'delivery' && orderMode != 'takeaway')) {
			checkBranchPreOrder(site, orderMode);
			return $q.resolve();
		}
		let params = getOrderDelayOptions(site, orderMode);

		let deferred = $q.defer();
		var modal = $uibModal.open({
			templateUrl: 'modules/start/modals/modal_order_delay.html',
			controller: function ($scope, $uibModalInstance, modalParams) {
				$scope.response = {};
				$scope.params = modalParams;
				$scope.apply = function () {
					if ($scope.challengeForm.$valid) {
						$uibModalInstance.close($scope.params);
					}
				};
				$scope.cancel = function () { $uibModalInstance.dismiss('cancel'); }
			},
			windowClass: "modal-default modal-table-number",
			backdrop: 'static',
			size: "sm",
			resolve: {
				modalParams: () => params
			}
		});
		modal.result.then((res) => {
			if (!res) res = {}
			let selected = res.selected;
			if (selected && selected.date) {
				let mdiff = getOrderPreptime(site, orderMode);

				$scope.$storage.order.orderDelay = {
					moment: moment(selected.date).subtract(mdiff, 'minutes'),
					method: "today",
					active: true,
					todaySlot: selected,
					text: $translate.instant("_DELAYED.for_hour", { val: moment(selected.date).format($scope.MS.local.timeFormat) })
				}
			} else {
				checkBranchPreOrder(site, orderMode);
			}
			deferred.resolve();
		}).catch(err => { });
		return deferred.promise;
	}

	function getOrderDelayOptions(site, orderMode) {
		let orderSetup = site[orderMode];
		let maxOrderDelay = Number(orderSetup.maxOrderDelay) || 1440;
		let minOrderDelay = getOrderPreptime(site, orderMode);
		minOrderDelay = isNaN(minOrderDelay) ? 30 : Number(minOrderDelay);

		var n = minOrderDelay, members = [{ date: null, text: $translate.instant("ASAP") }];
		var d = GETREALDATE(true);
		var wh = site.wh, slotMin, slotMax, nextSlots, diffDays;
		if (wh && wh.found) {
			diffDays = d.diff(moment([1970, 0, 1]), 'days');
			slotMin = moment(wh.found.from).add(diffDays, 'days');
			slotMax = moment(wh.found.to).add(diffDays, 'days');
			if (d.isBefore(slotMin)) {
				d = slotMin;
			}
			nextSlots = wh.found.nextSlots;
		}

		var moffset = d.minutes() % 15;
		if (moffset > 0) moffset = 15 - moffset;
		n += moffset;
		maxOrderDelay += n;
		n += 15;
		do {
			var _d = d.clone().add(n, 'minutes');
			if (slotMax && _d.isAfter(slotMax)) {
				//maxOrderDelay = 0;
				break;
			} else {
				members.push({ date: _d.toDate(), minutes: n, text: _d.format($scope.MS.local.timeFormat) });
				n += 15;
			}
		} while (n < maxOrderDelay);

		if (nextSlots && n < maxOrderDelay) {
			_.each(nextSlots, nextSlot => {
				if (n > maxOrderDelay) return false;
				slotMin = moment(nextSlot.from).add(diffDays, 'days');
				slotMax = moment(nextSlot.to).add(diffDays, 'days');
				do {
					var _d = d.clone().add(n, 'minutes');
					if (_d.isAfter(slotMax)) break;
					if (_d.isAfter(slotMin)) {
						members.push({ date: _d.toDate(), minutes: n, text: _d.format($scope.MS.local.timeFormat) });
					}
					n += 15;
				} while (n < maxOrderDelay);
			})
		}

		return {
			minOrderDelay: minOrderDelay,
			maxOrderDelay: maxOrderDelay,
			members: members,
			selected: members[0]
		}

	}

	function checkBranchPreOrder(site, orderMode) {
		$scope.$storage.realStartTime = GETREALDATE(true);
		if (!$scope.order.isPreOrder) return;
		if (!site.settings.schedulePreorderFire) {
			let md = _.get($scope.order.branch, 'wh.slotMin');
			if (md) $scope.$storage.realStartTime = moment(md);
			return;
		}

		let time = $scope.order.branch.wh;
		let start = time.slotMin

		let mdiff = getOrderPreptime(site, orderMode);

		let selected = {
			date: start.add(mdiff, 'minutes')
		}


		$scope.$storage.order.orderDelay = {
			preOrderM: $scope.$storage.preOrderM,
			moment: moment(selected.date).subtract(mdiff, 'minutes'),
			method: "today",
			active: true,
			todaySlot: selected,
			text: $translate.instant("_DELAYED.for_hour", { val: moment(selected.date).format($scope.MS.local.timeFormat) })
		}
	}

	function getOrderPreptime(site, orderMode) {
		switch (orderMode) {
			case "delivery": return _.get($scope.order, "region.deliveryTime", 60);
			case "takeaway": return _.get(site.takeaway, "preparationTime", 30) + _.get(site, "timesOffsetSetup.prepTimeOffset", 0);
			default: _.get(site[orderMode], "preparationTime", 30);
		}
	}

    // ------------------------------------------------------------------------------------------------->
    // load order
    // ------------------------------------------------------------------------------------------------->

    $scope.loadStoredOrder = function (_order) {
        var _branch = _.find($scope.$storage.organization.branches, { _id: _order.site_id });
		if (_branch) {
			if (_branch._services.length > 0) {

				let _services = _.cloneDeep(_branch._services);
				_.each(_services, service => {
					if (service.id == 'delay') {
						if (service.members.length == 1) {
							service.delay = true;
							service.mode = _.get(service,'members[0].id');
							delete service.members;
						} else {
							_.each(service.members, member => {
								member.text = getServiceCaption(member.id);
								member.delay = true;
								member.mode = member.id
							});
						}
					} else {
						if (service.enabled && service.delayedEnabled) {
							service.members = [
								{ mode: service.id, delay: false, text: $translate.instant("_DELAYED.service_today") },
								{ mode: service.id, delay: true, text: $translate.instant("_DELAYED.service_future") }
							]
						} else {
							service.delay = !service.enabled;
							service.mode = service.id;
						}
					}
				});

                var modalLoadOrder = $uibModal.open({
                    templateUrl: 'modules/start/modals/modal_load_order.html',
					controller: function ($scope, $uibModalInstance, modalParams) {
						$scope._services = modalParams._services;						
                        $scope._orderMethods = modalParams._orderMethods;
                        $scope.order = _order;
                        $scope.selectOrderMethod = function (m) {
                            $uibModalInstance.close(m);
                        }
                        $scope.cancel = function () {
                            $uibModalInstance.dismiss('cancel');
                        };
                    },
                    windowClass: "modal-list",
                    size: "sm",
                    resolve: {
                        modalParams: function () {
                            return {
                                branch: _branch,
								order: _order,
								_services: _services,
                                _orderMethods: $scope.uiStart.orderMethods
                            };
                        }
                    }
                });
				modalLoadOrder.result.then(service => {
					if (!selectOrderMethod_check(service.mode, service.delay, _branch)) return;//NEED FIX
					loadStoredOrder_cont(service.mode, service.delay);
                }).catch(() => {});
            } 
		}

        function loadStoredOrder_cont(mode, delay) {
            var sites = [_branch];
            $scope.$storage.relevantSites = sites;
			$scope.order.mode = mode;
			$scope.order.forceDelay = delay;

            $scope.$storage.loadOrder = _order;
            switch ($scope.order.mode) {
                case "delivery":
                    $scope.order.step = 'address';
					$scope.ES.popMessages("address", null, $scope.messageSession, $scope.order.forceDelay ? 'future' : 'sameDay');

                    $scope.uiAddress = {
                        mode: 'street',
						addresses: []
                    };

                    break;
                default:
					$scope.selectBranch(sites[0]);
            }
        }
    }

    // ------------------------------------------------------------------------------------------------->
    // ------------------------------------------------------------------------------------------------->

	function checkBranchDelay(required) {
		if (required) return $q.resolve({ requireDelayedOrder: true });
		let deferred = $q.defer();
		$scope.PDialog.info({
				"text": $translate.instant("MESSAGES.BRANCH_DISABLED_NOW_DELAYED_ENABLED"),
                showCancelButton: true,
                //confirmButtonText: $translate.instant("VIEW_MENU")
            }).then(function () {
				deferred.resolve({ requireDelayedOrder: true });
            }, function () {
                deferred.reject();
			});
		return deferred.promise;
	}

	function checkBranchHours(site) {
		var deferred = $q.defer();
		delete $scope.order.isPreOrder;

		var workHours = site.workHours, dwh;
		var mOffset = _.get(site, 'settings.preorderThreshhold', 120);

		switch ($scope.order.mode) {
			case "delivery": dwh = _.get(site, "delivery.workhours"); break;
			case "eatin": dwh = _.get(site, "eatin.workhours"); break;
		}

		if ($scope.order.forceDelay) {
			let slot = site.futureOrder2 ? site.futureOrder2.orderSlot : _.get(site, 'futureOrder.orderSlot');
			if (slot) {
				dwh = slot;
				mOffset = 0;
			} else {
				if (!DelayOrderService.canOrderNow(site, $scope.order.mode)) {
					$scope.PDialog.info({
						"text": $translate.instant("_DELAYED.service_disabled_today"),
						showCancelButton: true,
						confirmButtonText: $translate.instant("VIEW_MENU")
					}).then(function () {
						$scope.order.mode = 'VIEW_MENU';
						$scope.selectBranch(site);
						deferred.reject();
					}, function () {
						deferred.reject();
					});
					return deferred.promise;
				}
				let enableOutsideofWorkhours = site.futureOrder2 ? site.futureOrder2.enableOutsideofWorkhours : _.get(site, 'futureOrder.enableOutsideofWorkhours');
				if (enableOutsideofWorkhours) {
					return $q.resolve({ requireDelayedOrder: true });
				}
			}
		}

		if (dwh) {
			var dWorkHours = _.find(site.timeslots, { _id: dwh })
			if (dWorkHours) workHours = dWorkHours;
		}
        
        var md = GETREALDATE(true);
        var d = md.day();
        var dayM = 24 * 60;
        var startThreshhold = 5 * 60;
        var cmd = getMinutes(md);
        if (cmd < startThreshhold) {
            d -= 1;
            if (d < 0) d = 6;
            cmd += dayM;
        }

        var day = _.find(workHours.days, { day: d });
		if (!day || !day.active || !$scope.ES.isTimeslotRangeActive(workHours)) {//view only menu
            $scope.PDialog.info({
                "text": $translate.instant("MESSAGES.BRANCH_CLOSED_TODAY"),
                showCancelButton: true,
                confirmButtonText: $translate.instant("VIEW_MENU")
			}).then(function () {
				$scope.order.mode = 'VIEW_MENU';
				$scope.selectBranch(site);
				deferred.reject();
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }

        if (day.type == "default") day = _.find(workHours.days, { day: -1 });

        day.tslots = [];
		
        var slotFound, slotFoundOffset, nextSlots;
		for (var i = 0; i < day.slots.length; i++) {
			var slot = day.slots[i];

			slot.from = moment(slot.from);
			slot.to = moment(slot.to);

			slot.tfrom = slot.from.format($scope.MS.local.timeFormat);
			slot.tto = slot.to.format($scope.MS.local.timeFormat);

			slot.mfrom = getMinutes(slot.from);
			if (slot.from.date() == 2) slot.mfrom += dayM;
			slot.mto = getMinutes(slot.to);
			if (slot.to.date() == 2) slot.mto += dayM;

			if (nextSlots) {
				nextSlots.push(slot);
				if (slotFound) continue;
			}

			day.tslots.push(slot.tfrom + " - " + slot.tto);
			if (!day.firstSlotStart) day.firstSlotStart = slot.tfrom;

			if (cmd > slot.mfrom && cmd < slot.mto) {
				nextSlots = slot.nextSlots = [];
				slotFound = slot;
			} else if (!slotFoundOffset && cmd < slot.mfrom && cmd > slot.mfrom - mOffset) {
				nextSlots = slot.nextSlots = [];
				slotFoundOffset = slot;
            };
        };
        
        var retTime = {
            found: slotFound,
            text: day.tslots.length == 1 ? $translate.instant('BRANCH_TIME_0', { t0: day.tslots[0] }) : $translate.instant('BRANCH_TIME_1', { t0: day.tslots[0], t1: day.tslots[1] }),
            startText: day.firstSlotStart
		};
		delete $scope.$storage.preOrderM;
        if (!slotFound) {
			if (!slotFoundOffset) {
                $scope.PDialog.info({
                    "text": $translate.instant("MESSAGES.BRANCH_DISABLED_NOW", { t: retTime.text }),
                    showCancelButton: true,
                    confirmButtonText: $translate.instant("VIEW_MENU")
                }).then(function () {
					$scope.order.mode = 'VIEW_MENU';
					$scope.selectBranch(site);
                    deferred.reject();
                }, function () {
                    deferred.reject();
                });
            } else {
                retTime.found = slotFoundOffset;
                retTime.delayedOrder = true;
                checkBranchHours_retPrepare(retTime);

				$scope.$storage.preOrderM = slotFoundOffset.mfrom;
				if ($scope.order.forceDelay) {
					deferred.resolve(retTime);
				} else {
					$scope.PDialog.info({
						"text": $translate.instant("MESSAGES.BRANCH_CLOSED_NOW", { t: slotFoundOffset.tfrom }),
						showCancelButton: true,
						confirmButtonText: $translate.instant("CONFIRM")
					}).then(function () {
						$scope.order.isPreOrder = true;
						deferred.resolve(retTime);
					}, function () {
						deferred.reject();
					});
				}
            }
        } else {
            checkBranchHours_retPrepare(retTime);
            deferred.resolve(retTime);
        }

        return deferred.promise;

        function getMinutes(m) {
            return (m.hours() * 60) + m.minutes();
        }

        function checkBranchHours_retPrepare(wh) {
            var d = GETREALDATE(true);
            var slotMin, slotMax;

            var diffDays = d.diff(moment([1970, 0, 1]), 'days');
            wh.slotMin = moment(wh.found.from).add(diffDays, 'days');
            wh.slotMax = moment(wh.found.to).add(diffDays, 'days');
            wh.slotAlert = moment(wh.slotMax).add(-15, 'minutes');
        };
	}

	function checkBranchHours_file(site, fileURL) {
		let timeslots = _.get(site, 'timeslots', []), n = timeslots.length;
		for (let i = 0; i < n; i++) {
			let timeSolt = timeslots[i];
			if ($scope.ES.isTimeslotsActive(timeSolt)) {
				if (timeSolt.file && timeSolt.file.url) {
					fileURL = timeSolt.file.url;
					break;
				}
			}
		}
		return fileURL;
	}

});

// ------------------------------------------------------------------------------------------------->
// error_controller
// ------------------------------------------------------------------------------------------------->


app.controller('error_controller', function ($scope, $timeout, $translate, $location) {
    $scope.$on('$viewContentLoaded', function () {
        $location.replace(); //clear last history route
    });
})


// ------------------------------------------------------------------------------------------------->
// resetpassword_controller
// ------------------------------------------------------------------------------------------------->


app.controller('resetpassword_controller', function ($scope, $timeout, $translate, $location) {
    $scope.$on('$viewContentLoaded', function () {
        $location.replace(); //clear last history route
    });
    $scope.args = { loading: true };

    $scope.MS.resetStorage(true);

    $scope.ES.initResetPass().then(
        function (ret) {
            $scope.reset = ret;
            $scope.args.loading = false;
        },
        function (err) {
            if (!err) err = {};
            err.source = 'ES.initResetPass';
            $scope.handleCriticalError(err);
        }
    );

    $scope.resetPassword = function (dForm) {
        $scope.ES.resetPass($scope.reset).then(
            function (ret) {
                if (ret.redirect) {
                    $scope.PDialog.success({
                        "text": $translate.instant('MESSAGES.PASSWORD_RESET_SUCCESS'),
                    }).then(function () {
                        window.location.replace(ret.redirect);
                    });
                } else {
                    $scope.args.success = true;
                };
            },
            function (err) {
                err.source = 'resetPassword';
                switch (err.type) {
                    case "C": $scope.handleCriticalError(err); break;
                    case "G": $timeout(function () {
                        $scope.PDialog.info({
                            "text": err.message,
                        });
                    }); break;
                }
            }
        );
    };

})


// ------------------------------------------------------------------------------------------------->
// map_controller
// ------------------------------------------------------------------------------------------------->


app.controller('start_map_controller', function ($scope, blockUI, $q) {
	var vm = $scope;

	vm.ui = {
		loading: false,
		showMapInfo: true
	}

	vm.mapOptions = {
		zoom: $scope.$storage.lastMapZoom || 18,
		streetViewControl: false,
		mapTypeControl: false,
		fullscreenControl: false,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		zoomControl: true,
		zoomControlOptions: {
			style: google.maps.ZoomControlStyle.SMALL,
			position: google.maps.ControlPosition.TOP_LEFT
		}
	}

	let center, site = _.get($scope.$storage, "organization.branches[0]"), siteLocation = _.get(site, "location.geometry.location");
	center = $scope.$storage.lastMapPosition || siteLocation;
	if (center) {
		vm.mapOptions.center = new google.maps.LatLng(center.lat, center.lng);
	}

	vm.mapLoaded = false;
	let lookupMarker, mapCenter;

	$scope.onMapIdle = function () {
		if (vm.mapLoaded) return;
		vm.mapLoaded = true;
		vm.myMap = this.myMap
		vm.address = {};

		var map = vm.myMap;
		var bounds = new google.maps.LatLngBounds();

		
		var siteLocation = _.get(site, "location.geometry.location");
		if (!siteLocation) return;
		var marker = new google.maps.Marker({
			position: siteLocation,
			map: vm.myMap,
			title: site.name,
			icon: {
				path: google.maps.SymbolPath.CIRCLE,
				scale: 6,
				fillOpacity: 0.7,
				strokeWeight: 0,
				strokeColor: '#bbb'
			}
		});
		bounds.extend(marker.getPosition());
		_.each(site.delivery.regions, function (o) {
			if (!o.active) return;
			let isFuture = $scope.order.forceDelay;			
			switch (o.orderTime) {
				case "sameDay": if (isFuture) return; break;
				case "future": if (!isFuture) return; break;
			}
			let poly = new google.maps.Polygon({
				paths: o.paths,
				map: vm.myMap,
				strokeWeight: 0,
				fillColor: '#000000',
				fillOpacity: 0.1
			});

		});

		google.maps.event.trigger(map, 'resize');
		mapCenter = vm.myMap.getCenter();

		lookupMarker = new google.maps.Marker({
			position: vm.address.location || mapCenter,
			map: vm.myMap,
			icon: 'images/map_marker.png',
			//draggable: true
		});

		google.maps.event.addListener(vm.myMap, 'center_changed', function (event) {
			lookupMarker.setPosition(vm.myMap.getCenter());
		});

		if (!$scope.$storage.lastMapPosition && navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				vm.myMap.setCenter({
					lat: position.coords.latitude,
					lng: position.coords.longitude
				});
			}, (err) => {
				$scope.userLocationError = true
			}, {
				timeout: 500,
				maximumAge: 0
			});
		} 
	}

	let geocoder;
	function geocodePos() {
		let deferred = $q.defer();
		let pos = vm.myMap.getCenter();
		$scope.$storage.lastMapPosition = {
			lat: pos.lat(),
			lng: pos.lng()
		}
		$scope.$storage.lastMapZoom = vm.myMap.getZoom();

		if (!geocoder)  geocoder = new google.maps.Geocoder();
		geocoder.geocode({
			latLng: pos
		}, (results, status) => {
			try {
				if (status == google.maps.GeocoderStatus.OK) {
					var result = results[0];
					result = JSON.stringify(result);
					result = JSON.parse(result);
					deferred.resolve(parseMapGoeLocation(result));
				} else {
					deferred.resolve({
						address_components: [],
						geometry: {
							location: {
								lat: pos.lat(),
								lng: pos.lng()
							}
						}
					});
				}
			} catch (err) {
				console.error("geocode error", err);
				deferred.reject();
			}
		});
		return deferred.promise;

		function parseMapGoeLocation(geoLocation) {
			var address = { formatted_address: geoLocation.formatted_address }
			_.each(geoLocation.address_components, function (comp) {
				let val = UIUtils.fixSpecialChars(comp.long_name);
				switch (comp.types[0]) {
					case "street_number":
						address.streetNumber = val;
						break;
					case "route":
						address.street = val;
						break;
					case "locality":
						address.locality = val;
						break;
					case "postal_code":
						address.postalCode = val;
						break;
					case "administrative_area_level_1":
						address.state = comp.short_name;
						break;
				}
			});
			address.point = geoLocation.geometry.location;
			delete address.formatted_address;
			if (!address.streetNumber) {
				address.isPartialAddress = true;
			}
			address.isMapAddress = true;
			return address;
		}

	}

	vm.apply = function () {
		blockUI.start();
		geocodePos().then(res => {
			res.point = $scope.$storage.lastMapPosition;
			$scope.selectAddress(res, 'map');
		}).finally(() => {
			blockUI.stop();
		});		
	}

})





// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// order_controller
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('order_controller', function ($scope, $translate) {
	$scope.ES.clearPreCreatedOrder();
	$scope.$storage.wasCheckoutBenefits = false;
	$scope.ES.clearLoyaltyPromotions();

    $scope.UIArgs = {
        viewDefIcon: "images/meal-icon.svg",
        categoryDefIcon: "images/meal-icon.svg",
        itemDefIcon: "images/meal-icon.svg",
        selectedMenu: null
    };

    // ------------------------------------------------------------------------------------------------->

    $scope.menuClick = function (level, cat) {
        $scope.MS.selectedMenu = cat;
        $scope.$state.go("app.order.submenu", { level: level, cat: cat })
    };

    $scope.findCat = function (level, catId) {
        var retArr = [];
        var ret;
        _.each($scope.catalog.view, function (menu) {
            if (ret) return false;
            if (level === 0) {
                if (menu._id === catId) {
                    retArr.push({ level: 0, cat: menu });
                    ret = menu;
                }
            } else {
                ret = findRecursive(menu, catId, level, 1);
                if (ret) retArr.push({ level: 0, cat: menu });
            }
        });
        function findRecursive(o, _id, findLevel, currentLevel) {
            if (o.items) {
                for (var i = 0; i < o.items.length; i++) {
                    var c = o.items[i];
                    if (findLevel == currentLevel) {
                        if (_id == c._id) return c;
                    } else {
                        var _ret = findRecursive(c, _id, findLevel, currentLevel + 1);
                        if (_ret) {
                            retArr.push({ level: currentLevel, cat: c });
                            return _ret;
                        }
                    }
                }
            }
        };
        if (ret) {
            return {
                cat: ret,
                path: retArr.reverse()
            }
        }
    };
    $scope.findItem = function (level, catId) {
        var ret;
        _.each($scope.catalog.view, function (menu) {
            ret = findRecursive(menu, catId, level, 1);
            if (ret) return false;
        });

        function findRecursive(o, _id, findLevel, currentLevel) {
            if (findLevel != currentLevel) {
                if (!o.items) return;
                for (var i = 0; i < o.items.length; i++) {
                    var c = o.items[i];
                    var _ret = findRecursive(c, _id, findLevel, currentLevel + 1);
                    if (_ret) {
                        if (!_ret.image) _ret.image = o.icon;
                        return _ret;
                    }
                }
            } else {
                if (o.items) {
                    for (var i = 0; i < o.items.length; i++) {
                        var c = o.items[i];
                        if (_id == c._id) {
                            if (!c.image) c.image = o.icon;
                            return {
                                level: currentLevel - 1,
                                cat: o,
                                item: c
                            };
                        }
                    }
                }
            }
        }
        return ret;
    }
    $scope.findCatalogOffer = function (_offerId) {
        var ret;
        var arr = $scope.catalog.view;
        for (var i = 0; i < arr.length; i++) {
            var ret = findRecursive(arr[i], _offerId, true);
            if (ret) return ret;
        }

        function findRecursive(o, _offerId, enabled) {
            if (o.offer && o.offer == _offerId) return o;
            if (o.items) {
                var _arr = o.items;
                if (!$scope.isEnabled(o)) enabled = false;
                for (var i = 0; i < _arr.length; i++) {
                    var _ret = findRecursive(_arr[i], _offerId, enabled);
                    if (_ret){
                        if (!enabled || !$scope.isEnabled(_ret)) _ret.$$disabled = true;                        
                        return _ret;
                    }
                };
            }
        }
    }

    // ------------------------------------------------------------------------------------------------->
    // prepare functions
    // ------------------------------------------------------------------------------------------------->

	$scope.prepareItemsFolder = function (folder, _maxArgs) {
		if (folder._proccesd) return;

		folder.imageURL = _.get(folder, 'customImage.image', _.get(folder, 'icon.url', $scope.UIArgs.viewDefIcon));

		
		if (folder.maxOfferCount) {
			_maxArgs = {
				maxCatId: folder._id,
				maxCatCount: folder.maxOfferCount,
				maxCatName: folder.name
			}
		}
		
		_.each(folder.items, function (member) {
            if (member.type == 'folder') {
				$scope.MS.translateMember(member);
                folder.$$hasFolders = true;
                if (member.items && member.items.length) {
                    if (!member.icon) member.icon = folder.icon;
                    member._prepared = true;
                }
                
				member.$$level = 2;
				$scope.prepareItemsFolder(member, _maxArgs);
                
            } else {
				$scope.prepareViewItem(member, folder.icon);
				if (_maxArgs) _.assignIn(member, _maxArgs);
            }
        });
        folder._prepared = true;
    };
    $scope.prepareViewItem = function (item, defIcon) {
        if (item._proccesd) return;
        item._proccesd = true;
        item._enableEdit = true;
        if (!item.offer) {
            item.name = "[missing offer]";
        } else {
            var offer = _.find($scope.catalog.offers, { "_id": item.offer });
            var price = _.find($scope.catalog.prices, { "offer": item.offer, menu: item.menu });
            if (offer && price) {
				item._prepared = true;

				if (item.customName) {
					item.name = _.get(item, `translations.${$scope.$storage.catalogTrans}.customName`, item.customName);
				} else {
					item.name = offer.name;
				}
                
                item.realName = offer.realName || offer.name;
                item.description = item.customDescription || offer.description;
                item._offer = offer;
                item.price = item.viewPrice = price.price / 100;


                if (item.customImage) {
                    item.image = {
                        url: item.customImage.image
                    };
                    item._hasImage = true;
                } else if (item.icon) {
                    item.image = item.icon;
                    item._hasImage = true;
                };

                var offerItem = offer.items && offer.items[0];
				if (offerItem) {
					let inv = $scope.catalog.inventory;
					if (inv[offerItem]){
						if (inv.hideInventory) item.disabled = true;
						item.stockDisabled = true;
                    }

					var refItem = _.find($scope.catalog.items, { "_id": offerItem });

					if (refItem) {
						item._item = refItem;
						if (!item.description) item.description = refItem.description;
						if (!item._hasImage && refItem.image && refItem.image.url) {
							item.image = refItem.image;
							item._hasImage = true;
						}
						if (refItem.quantityType == 'Weight') {
							if (!$scope.$storage.weightParams) item.disabled = true;
							refItem.isWeight = item.isWeight = true;
							let defaultWeight = _.get(refItem, 'packageSize.units');
							if (defaultWeight) {

								if ($scope.$storage.weightParams.checkMin) {
									defaultWeight = Math.max(defaultWeight, $scope.$storage.weightParams.min);
								}

								let ratio = $scope.$storage.weightParams.ratio;
								item.viewPrice = _.round(defaultWeight / ratio * item.price, 2);
								if (offer.treatWeightAsSimple) {
									item._enableEdit = false;
									item.uom = $scope.$storage.weightParams.base;
									item.baseUnits = defaultWeight / ratio;									
									item.treatWeightAsSimple = true;
								}
								
							}

						}
					}					

					if (!item.isWeight && !item.stockDisabled && !_.get(offer, "selectionGroups[0]")) {
                        var sGroups = item._item.modifierGroups;
                        if (!sGroups || !sGroups.length) item._enableEdit = false;
                        else if (sGroups.length == 1 && isModifierGroupCompulsary(sGroups[0])) item._enableEdit = false;
                    };
                }
                if (!item._hasImage && defIcon) {
                    item.image = defIcon;
                    item._hasImage = true;
                    item._imageIcon = true;
                }
            } else {
                item.disabled = true;
                var errors = [];
                if (!offer) errors.push("missing offer");
                if (!price) errors.push("missing price");
                item.errors = errors;
                console.log("Proccess Item Error", errors.join(", "), item)
            }
        }
    };

	$scope.prepareSelectItem = function (item, forLoad) {
		if (item._proccesdAdd) return;
		if (item._item) {
			$scope.prepareItem(item._item, item, forLoad);
		}
		let wasPreSelection = false;
		_.filter(item._offer.selectionGroups, function (sGroup) {
			if (!sGroup.minSelectionCount) sGroup.minSelectionCount = 0;
			sGroup.validationMessage = $scope.ES.prepareGroupValidationMessage(sGroup.minSelectionCount, sGroup.selectionCount);
			var itemGroup = _.find($scope.catalog.itemGroups, { "_id": sGroup.itemGroup });

			if (itemGroup && !itemGroup.disabled) {
				item._enableEdit = true;
				sGroup._prepared = true;
				sGroup._visible = !itemGroup.hideGroup;
				sGroup.collapsed = itemGroup.collapseGroup;

				sGroup.name = itemGroup.name;
				sGroup.description = itemGroup.description;

				sGroup.showMemberImages = itemGroup.showMemberImages;
				if (sGroup.showMemberImages) {
					if (!itemGroup.cardStyle) setCardDisplayProps(itemGroup);
					sGroup.cardStyle = itemGroup.cardStyle;
				}

				if (!itemGroup.memberSelection) {
					itemGroup.memberSelection = 'single';//sGroup.selectionCount == 1 ? 'single' : 'multi';
				} else if (sGroup.selectionCount == 1) {
					itemGroup.memberSelection = 'single';
				}
				sGroup.memberSelection = itemGroup.memberSelection;
				sGroup.memberDisplay = itemGroup.memberDisplay;
				sGroup.enableQuickItemFlow = itemGroup.enableQuickItemFlow;
				$scope.prepareItemGroup(itemGroup);
				if (itemGroup._hidden) sGroup._visible = false;//no visible items
				sGroup.items = angular.copy(itemGroup.items);

				// force selection of all items in group
				let sName = sGroup.realName ? sGroup.realName : sGroup.name;
				if (sName && sName.indexOf("*") == 0) {
					wasPreSelection = true;
					$scope.addAllItemGroupItems(item, sGroup, $scope.catalog);
					if (!sGroup._visible) return true;
					sGroup.$isReversed = true;
				}

				if (!sGroup._visible) return false;

				return true;
				//sGroup.selection = { count: 0, items: [], itemHash: {} };
			}
		});
		if (wasPreSelection) $scope.prepareOfferSelectionGroups(item);
		item._proccesdAdd = true;
	}


	$scope.prepareItemGroup = function (itemGroup) {
		if (itemGroup._proccesd) return;
		itemGroup.items = _.filter(itemGroup.items, function (item) { return !item.disabled });
		let wasItem = false;
		_.each(itemGroup.items, function (sgItem, index) {
			if (!_.isObject(sgItem)) {
				itemGroup.items[index] = sgItem = { _id: sgItem }
			}
			let refItem = _.find($scope.catalog.items, { "_id": sgItem._id });
			let args = { itemGroup: itemGroup._id, item: sgItem._id };
			let price = _.find($scope.catalog.prices, args);
			sgItem.price = (price && price.price) || 0;
			if (sgItem.price > 0) {
				sgItem.price = sgItem.price / 100;
			} else {
				//sgItem.price = 2; //REMOVE
			}

			if (refItem) {
				sgItem.name = refItem.name;

				let inv = $scope.catalog.inventory;
				if (inv[sgItem._id]) {
					if (inv.hideInventory) sgItem.disabled = true;
					else sgItem._prepared = true;

					sgItem.stockDisabled = true;
					sgItem._hasDisabled = true;
				} else {
					sgItem._prepared = true;
					wasItem = true;
				}
				sgItem._item = angular.copy(refItem);
				sgItem._item.total = sgItem.price;
				sgItem._item.itemGroup = args.itemGroup;
				if (refItem.image && refItem.image.url) {
					sgItem.image = refItem.image;
				};
			}
		})
		if (!wasItem) itemGroup._hidden = true;
	}

	$scope.addAllItemGroupItems = function(viewItem, group, _catalog) {
		let that = this;
		group.$wasChecked = true;
		group.$isReversed = true;
		var maxCount = group.selectionCount || 100;
		var addedCount = 0;
		if (!viewItem.total) viewItem.total = viewItem.price;
		_.each(group.items, member => {
			if (member._prepared && !_catalog.inventory[member._id]) {
				if (member._item._hasRequiered) return;
				if (!member._item.summary) $scope.prepareItem(member._item, viewItem);
				if (!member._item.total) member._item.total = 0
				if (_.isUndefined(member._item._total)) member._item._total = member._item.total;

				if (addedCount == maxCount) return;

				var oldTotal = member._item.oldTotal || 0;
				var newTotal = member._item.total || 0;

				viewItem.total -= oldTotal;
				viewItem.total += newTotal;
				member._item.oldTotal = newTotal;

				member.count = 1;
				member._selected = true;
				delete group._requiered;
				++addedCount;
			}
		});
	}

	$scope.prepareOfferSelectionGroups = function(viewItem) {
		var selectionSummary = [];
		let hasSelectionSummary = false;
		_.each(viewItem._offer.selectionGroups, function (sG) {
			var arr = [];
			_.each(sG.items, function (item) {
				if (item._selected) {
					//$scope.ES.prepareItemSummary(item._item);
					arr.push(item);
				};
			});
			if (arr.length) {
				if (!hasSelectionSummary) hasSelectionSummary = sG._visible !== false;
				selectionSummary.push({
					_id: sG._id,
					name: sG.name,
					items: arr,
					_visible: sG._visible !== false
				});
			}
		});
		viewItem.hasSelectionSummary = hasSelectionSummary;
		viewItem.selectionSummary = selectionSummary;
	}

	function setCardDisplayProps(member, _style) {
		let style = _.assignIn({}, _style);
		if (member.cardBgImageColor) {
			style['background-color'] = member.cardBgImageColor;
		}
		if (member.cardBgImageSize) {
			let bgSize;
			switch (member.cardBgImageSize) {
				case "stretch": bgSize = "100% 100%"; break;
				case "padded-x": bgSize = "90% auto"; break;
				case "padded-y": bgSize = "auto 90%"; break;
				default: bgSize = member.cardBgImageSize;
			}
			style['background-size'] = bgSize;
		}
		if (member.cardImageHeight) {
			style['height'] = member.cardImageHeight + 'px';
		}
		member.cardStyle = style;
	}
  
    $scope.prepareItem = function (item, offer, forLoad) {
        if (item._proccesd) return;
        var _total = 0;
        item._hasRequiered = false;
        var isSingleModGroup = item.modifierGroups && item.modifierGroups.length == 1;
        _.each(item.modifierGroups, function (sGroup) {
            offer._enableEdit = true;
            item._enableEdit = true;
            var mGroup = _.find($scope.catalog.modifierGroups, { "_id": sGroup.modifierGroup });
            if (mGroup) {
                sGroup._prepared = true;
				sGroup._visible = !mGroup.hideGroup;
                item._hasModifierGroups = true;

                sGroup.name = mGroup.name;
                sGroup.singleSelection = mGroup.singleSelection;
                sGroup.modifiers = angular.copy(mGroup.modifiers);
				sGroup.modifiers = _.filter(sGroup.modifiers, (item) => {
					if (item.disabled) return false;
					if (item.item && $scope.catalog.inventory[item.item]) {
						item.disabled = true;
						if (forLoad) return true;
						return false;
					}
					return true;
				});

                sGroup._expanded = true;
                // currently hard coded set modifier behavior to checkbox
                sGroup.modifierBehavior = "checkbox";
                sGroup._hasSelected = false;
                sGroup._hasNotSelected = false;
                // ----------------------------------------------------->
                var defaults = sGroup.defaults && sGroup.defaults[0];
                if (defaults) {
                    if (sGroup.singleSelection) {
                        var mod = _.find(sGroup.modifiers, { '_id': sGroup.defaults[0].modifier });
                        if (mod){
                            mod._selected = true;
                            mod._isDefault = true;
                        }else{
                            item._hasRequiered = true;
                        }
                    } else {
                        _.each(sGroup.defaults, function (def) {
                            var mod = _.find(sGroup.modifiers, { '_id': def.modifier });
                            if (mod) {
                                mod.formationUnit = def.formationUnit;
                                mod.defaultFormationUnit = def.formationUnit;
                            }
                        });
                    }
                }else if (sGroup.singleSelection){
                    item._hasRequiered = true;
                };
				if (!sGroup.singleSelection) {
					sGroup._expanded = !mGroup.collapseGroup;
                    var compulsaryOP = 0;
                    _.each(sGroup.modifiers, function (mod) {
                        var args = { modifierGroup: mGroup._id, modifier: mod._id };
                        if (offer.menu) args.menu = offer.menu;
                        var price = _.find($scope.catalog.prices, args);
                        if (price) mod.price = price.price / 100;
                        else mod.price = 0;
                        if (mod.formationUnit){
                            _total += mod.price;
                            mod.$isDefault = true;
                            if (!mod.compulsory) sGroup._hasSelected = true;
                            else ++compulsaryOP;
                        }else{
                            delete mod.compulsory;
                            mod.$isDefault = false;
                            sGroup._hasNotSelected = true;
                        };
                    });
                    sGroup.compulsaryOP = compulsaryOP;
                    if (sGroup.min && sGroup.min > 0 && sGroup.min - compulsaryOP > 0){
                        item._hasRequiered = true;
                    };
                    if (isModifierGroupCompulsary(null, mGroup)){
                        sGroup._visible = false;
                        if (isSingleModGroup) item._enableEdit = false;
                    };
                };
                if (sGroup._hasSelected && sGroup._hasNotSelected){
                    sGroup._hasBoth = true;
                }
            } else {
                sGroup.$active = false
            };
        });
        item._addPrice = _total;
    }

    function isModifierGroupCompulsary(sGroup, mGroup){
        if (!mGroup) var mGroup = _.find($scope.catalog.modifierGroups, { "_id": sGroup.modifierGroup });
        if (mGroup && !mGroup.singleSelection) {
            for (var i = 0; i < mGroup.modifiers.length; i++) {
                if (!mGroup.modifiers[i].compulsory) return false;
            }
            return true;
        }
    }

    // ------------------------------------------------------------------------------------------------->
    // preload order
    // ------------------------------------------------------------------------------------------------->

    $scope.setLoadedOrder = function () {
        var _order = $scope.$storage.loadOrder;
		var errors = [];
		let INV = $scope.catalog.inventory;

		_.each(_order.orderedOffers, function (_offer) {

            var roffer = $scope.findCatalogOffer(_offer.offer);
            if (!roffer){
                if (_offer.name && errors.indexOf(_offer.name) == -1)errors.push(_offer.name);
            }if (roffer) {
                if (roffer.$$disabled){
                    if (errors.indexOf(roffer.name) == -1)errors.push(roffer.name);
                }else{
                    roffer = angular.copy(roffer);
					$scope.prepareViewItem(roffer);


					let canAdd = true;
					if (roffer.stockDisabled) canAdd = false;
					if (canAdd) {
						$scope.prepareSelectItem(roffer, true);
						roffer.total = roffer.price;
						roffer = angular.copy(roffer);
						_.each(_offer.orderedItems, function (_item) {
							if (_item.item && INV[_item.item]) canAdd = false;
							else {
								var ritem = findOfferItem(roffer, _item.item);
								if (ritem) {
									_.each(ritem.modifierGroups, function (rmg) {
										if (rmg.singleSelection) {
											_.each(rmg.modifiers, function (mod) {
												delete mod._selected;
												var match = _.find(_item.selectedModifiers, { 'modifier': mod._id });
												if (match) {
													if (mod.item && INV[mod.item]) canAdd = false;
													mod._selected = true;
												}
											});
										} else {
											_.each(rmg.modifiers, function (mod) {
												delete mod.formationUnit;
												var match = _.find(_item.selectedModifiers, { 'modifier': mod._id });
												if (match) {
													if (mod.item && INV[mod.item]) canAdd = false;
													mod.formationUnit = match.formationUnit;
													if (mod.price) roffer.total += mod.price;
												}
											});
										}
									});
								};
							}
						});
					}
					if (canAdd) $scope.addToBasket(roffer);
					else errors.push(roffer.name);
                }
            }
        });

        function findOfferItem(roffer, _id) {
            var _arr1, _arr2;
            if (roffer._item && roffer._item._id == _id) return roffer._item;
            if (roffer._offer && roffer._offer.selectionGroups) {
                var _arr1 = roffer._offer.selectionGroups;
                for (var i = 0; i < _arr1.length; i++) {
                    var match = _.find(_arr1[i].items, { _id: _id });
                    if (match) {
                        match._selected = true;
                        $scope.prepareItem(match._item, roffer);
                        return match._item;
                    }
                }
            }
        }


        if (errors.length){
            toastr['error']($translate.instant("MESSAGES.ORDER_ITEMS_UNAVALABLE") + "\n\n" + errors.join(", "), null, {
                timeOut: 0,
                positionClass: "toast-top-right",
                "closeButton": true,
            });
        }

        delete $scope.$storage.loadOrder;
    };
    if ($scope.$storage.loadOrder) {
        $scope.setLoadedOrder();
    }

});

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// order_fixed
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('order_fixed', function ($rootScope, $scope, $translate, $location, $timeout, $document) {
    $scope.MS.SectionCaption = $translate.instant("ORDER_FIXED");
    $scope.$on('$viewContentLoaded', function () {
        $location.replace(); //clear last history route
    });
    $scope.$on('fixorder_changed', function (event, data) {
		$scope.$apply(function () {
            
        }) 
    });

    
    $scope.PD = $scope.$storage.order.printData;

    $scope.uiOrderFixed = {
        isFromCheckout: $scope.$storage.wasPayment || $scope.$stateParams.isFromCheckout
    };

	$rootScope.$on('onOrderFinished', function (event, data) {
		$scope.PD = $scope.$storage.order.printData;
        $scope.$storage.wasPayment = true;
        $scope.uiOrderFixed.isFromCheckout = true;
    });

	$rootScope.$on('onOrderChange', function (event, data) {
		$scope.$apply(function () {
			$scope.PD = $scope.$storage.order.printData;
		});
	});

	$("html, body").scrollTop(0);
});

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// order_menus
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('order_menus', function ($rootScope, $scope, $translate, $location, $timeout, $document, BenefitsService, blockUI) {
	if (!window.ISDESKTOP) {
		$scope.MS.SectionCaption = _.get($scope.$storage, 'order.branch.name', $translate.instant("ORDER_MENU"))
	} else {
		$scope.MS.SectionCaption = $translate.instant("ORDER_MENU");
	}	
	$scope.benefitsService = BenefitsService;
    $scope.$on('$viewContentLoaded', function () {
        $location.replace(); //clear last history route
    });

	_.each($scope.catalog.view, function (_folder) { $scope.prepareItemsFolder(_folder); });
	

    $scope.catClick = function (cat, menu) {
        if (!cat.icon) cat.icon = menu.icon;
        $scope.$state.go("app.order.submenu", { level: 1, cat: cat._id });
		$scope.$storage.lastCatIndex = $scope.catalog.view.indexOf(menu);
		$scope.$storage.lastCat = menu._id;
    };
	$scope.itemClick = function (item, menu) {
		if (!$scope.$storage.READONLY && item.stockDisabled) {
			return $scope.PDialog.warning({ text: $translate.instant('MESSAGES.ITEM_OUTOF_STOCK', { item: item.name }) });
		}
		if (!$scope.ES.checkMaxOfferCount(item, 1)) return;
		if ($scope.DESKTOP && ($scope.$storage.READONLY || item._enableEdit === false)) {
			$scope.directItem.offer = item;
			return;
		}
        var level = menu.$$level ? menu.$$level : 1;
        $scope.$state.go("app.order.additem", { level: level, item: item._id });
        $scope.$storage.lastCat = menu._id;
    }

    $scope.directItem ={
        count: 1,
        opChange: function(op){
			var newCount = $scope.directItem.count + op;
			if (newCount >= 1 && $scope.ES.checkMaxOfferCount($scope.directItem.offer, newCount)) $scope.directItem.count = newCount;
        }
    };
	$scope.addOfferDirect = function (offer, op, remarks) {
		if (!$scope.ES.checkMaxOfferCount(offer, 1)) return;
        var viewItem = angular.copy(offer);
        $scope.prepareViewItem(viewItem);
		$scope.prepareSelectItem(viewItem);
		viewItem.offerRemrks = remarks;

		if (offer.isWeight && offer.baseUnits) {
			viewItem.total = viewItem.viewPrice;
		} else {
			viewItem.total = viewItem.price;			
			if (viewItem._item && viewItem._item._addPrice) {
				viewItem.total += viewItem._item._addPrice;
			}
		}
        viewItem.quantity = op;
        $scope.addToBasket(viewItem);
    };
    $scope.removeOfferDirect = function(offer){
        var ret = $scope.getBasketOfferQuantity(offer);
        if (ret.q > 1){
            ret.offer.quantity = ret.offer.quantity - 1;
            $scope.ES.calculateBasketTotal();
        }
    };

    $("html, body").scrollTop(0);
    $timeout(
        function () {
            var catId = $scope.$storage.lastCat;
            var $h = $("#btnm_" + catId);

            if ($scope.DESKTOP) {
                $h.click();
                return;
            }

            var isFirstCat;
            if (!$h[0]) {
                $h = $(".col-order-menu:eq(0)");
                catId = $h.attr("cat");
                isFirstCat = true;
            } else {
                isFirstCat = catId == $(".col-order-menu:eq(0)").attr("cat");
            };
            $h.addClass('active');
            if (!isFirstCat) {
                var someElement = angular.element(document.getElementById('menu_' + catId));
                $document.scrollToElement(someElement, 130, 300);
            }
        }, 100
    )
    $scope.$on("$destroy", function (event) {
        listener();
    });
    var listener = $rootScope.$on("duScrollspy:becameActive", function (e, h, ah) {
        h.scrollintoview({ direction: "horizontal" });
    });

});

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// order_submenu
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('order_submenu', function ($scope, $translate) {
    var params = $scope.$state.params;
    var level = Number(params.level);

    $scope.position = $scope.findCat(level, params.cat);

    if ($scope.position) {

        window.setTimeout(function () {
            $(".col-order-menu").removeClass("active");
            var $h = $("#btnm_" + $scope.position.path[0].cat._id);
            $h.addClass("active")
        }, 0);

        $scope.root = $scope.position.cat;
        $scope.MS.SectionCaption = $scope.root.name;
        $scope.defIcon = $scope.root.icon;
		$scope.prepareItemsFolder($scope.root);
    } else {
        alert("can't find folder");
    };

    $scope.catNav = function (cat, level) {
        $scope.$state.go("app.order.submenu", { level: level, cat: cat._id });
        if (level === 0) {
            //$scope.$state.go("app.order.menus")
        } else {

        }
    }
    $scope.catClick = function (cat, menu) {
        if (!cat.icon) cat.icon = menu.icon;
        $scope.$state.go("app.order.submenu", { level: level + 1, cat: cat._id })
    };
	$scope.itemClick = function (item) {
		if (!$scope.$storage.READONLY && item.stockDisabled) {
			return $scope.PDialog.warning({ text: $translate.instant('MESSAGES.ITEM_OUTOF_STOCK', { item: item.name }) });
		}
		if (!$scope.ES.checkMaxOfferCount(item, 1)) return;
        $scope.$state.go("app.order.additem", { level: level + 1, item: item._id })
    };

});

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// order_additem
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('order_additem', function ($scope, $rootScope, $timeout, $translate, $uibModal, $filter) {
	delete window.BLOCATIONHASH;

	//protection against delyed translation loading
    if ($translate.instant('GROUP_VALIDATION.SELECT_ATLEAST_ONE') == 'GROUP_VALIDATION.SELECT_ATLEAST_ONE') {
		window.history.back();
    	return;
    }


    order_item_logic($scope, $timeout, $uibModal, $translate);

	var isEdit = $scope.$state.includes('app.order.edititem');
	

    $scope.defIcon = 'images/icon-view-sub.png';
    $scope.args = {
        mode: $scope.$storage.READONLY ? "ro" : "offer",
        isEdit: isEdit
    }

    var params = $scope.$state.params;
    var level = Number(params.level);

	$('body').on('click.popclickhandler', () => {
		$('.popactive').removeClass('popactive');
	});

	if (isEdit) {

        $scope.originalItem = _.find($scope.$storage.basket, { _bid: params.bid });
		if ($scope.originalItem) {
            $scope.viewItem = angular.copy($scope.originalItem);
			if (!$scope.viewItem.quantity) $scope.viewItem.quantity = 1;
			$scope.currentQuantity = $scope.viewItem.quantity;
            $scope.currentItem = $scope.viewItem._item;
            if ($scope.DESKTOP) {
            	$scope.MS.SectionCaption = $scope.DESKTOP ? $scope.viewItem.name : "";
            }
        } else {
            alert("can't find basket item")
        }
    } else {
        $scope.position = $scope.findItem(level, params.item);
        if ($scope.position) {
            

            window.setTimeout(function () {
                $(".col-order-menu").removeClass("active");
                var $h = $("#btnm_" + $scope.position.cat._id);
                $h.addClass("active")
            }, 0);

            $scope.viewItem = $scope.position.item;
            $scope.viewItem = angular.copy($scope.viewItem);
            $scope.prepareViewItem($scope.viewItem);
			$scope.prepareSelectItem($scope.viewItem);

			$scope.currentItem = $scope.viewItem._item;

			if ($scope.viewItem.isWeight) {
				let weightParams = $scope.$storage.weightParams;
				$scope.viewItem.uom = weightParams.base;
				if ($scope.viewItem._enableEdit === false) {
					$scope.viewItem.total = $scope.viewItem.viewPrice;
				} else {
					$scope.viewItem.pricePer = $translate.instant("_WEIGHT.price_per_weight", { unit: weightParams.baseTxt, price: $filter('money')($scope.viewItem.price) });
					$scope.viewItem.total = 0;
					$scope.viewItem.units = _.get($scope.viewItem, '_item.packageSize.units', weightParams.default);
					calculateWeightTotal();
				}
			} else {
				$scope.viewItem.total = $scope.viewItem.total || $scope.viewItem.price;
				if ($scope.currentItem && $scope.currentItem._addPrice) {
					$scope.viewItem.total += $scope.currentItem._addPrice;
				}
			}

			if (!$scope.viewItem.quantity) $scope.viewItem.quantity = 1;
			$scope.currentQuantity = 0;
            
            if ($scope.DESKTOP) {
            	$scope.MS.SectionCaption = $scope.DESKTOP ? $scope.position.cat.name + " - " + $scope.viewItem.name : "";//$scope.viewItem.name;
            }            



            if ($scope.currentItem) $scope.validateItem($scope.currentItem, [], true);
        } else {
            alert("can't find item");
        };
    }
    $scope.showIGImages = $scope.viewItem.showIGImages;

	

	$scope.incrementWeight = function (_op) {
		let weightParams = $scope.$storage.weightParams;
		let op = weightParams.increment * _op;
		$scope.viewItem.units = $scope.viewItem.units + op;
		calculateWeightTotal();
	}
	$scope.onWeightEnter = function () {
		calculateWeightTotal();
	}
	function calculateWeightTotal() {
		let weightParams = $scope.$storage.weightParams;
		let units = Math.max($scope.viewItem.units, weightParams.min);
		let mod = units % weightParams.round;
		if (mod) units += (weightParams.round - mod);
		units = _.round(units, weightParams.decimals);
		
		$scope.currentItem.$summary = `${units} ${weightParams.useTxt}`;

		$scope.viewItem.baseUnits = units / weightParams.ratio;
		$scope.viewItem.units = units;
		$scope.viewItem.total = _.round(units / weightParams.ratio * $scope.viewItem.price, 2);
	}

    function animatePOS(from, to, val) {
        return;
        var $h = $('<span class="val-animate">' + val + '</span>').appendTo("body");
        var posFrom = from.offset();
        $h.css({ left: posFrom.left + 'px', top: posFrom.top + 'px' });

        var posTo = to.offset();

        $h.animate({ left: posTo.left, top: 10, opacity: 0.1 }, 600, function () {
            $h.remove();
        });
    }

    $scope.addOfferClick = function () {
		var arrErrors = [];
        if ($scope.currentItem) $scope.validateItem($scope.currentItem, arrErrors);
        var wasErrors = arrErrors.length > 0;
        _.each($scope.viewItem._offer.selectionGroups, (group) => {
			var min = group.minSelectionCount;
			if (min) {
				let selected = getItemGroupSelectionCount(group);
				if (selected < min) {
					group._requiered = true;
					arrErrors.push($translate.instant(min == 1 ? 'GROUP_SELECT_SINGLE_ERROR' : 'GROUP_SELECT_ERROR', { count: min, group: group.name }));
				}
			}
        });
        if (!wasErrors && arrErrors.length) {
            $scope.PDialog.warning({ text: arrErrors[0] });
        };

        if (arrErrors.length) {
            $scope.displayValidationErrors(arrErrors);
        } else {
            animatePOS($("#dvViewtemAmount"), $("#btnCheckout"), $scope.viewItem.total);
            if (isEdit) {
                $scope.updateBasket($scope.viewItem);
            } else {
                $scope.addToBasket($scope.viewItem, $scope.position);
            }
        }
    }

    $scope.setOfferQuantity = function (q) {
		if (q) {
			if (!$scope.ES.checkMaxOfferCount($scope.viewItem, q - $scope.currentQuantity)) return;
            $scope.viewItem.quantity = q;        
            return;
        }

        var modalQuantity = $uibModal.open({
            templateUrl: 'modules/order/modals/modal_quantity.html',
            controller: function ($scope, $uibModalInstance, modalParams, $translate) {
                $scope.modalParams = modalParams;
                $scope.apply = function (q) { $uibModalInstance.close({ quantity: q }); };
                $scope.cancel = function () { $uibModalInstance.dismiss('cancel'); };
            },
            windowClass: "modal-list",
            size: "sm",
            resolve: { modalParams: function () { return { q: $scope.viewItem.quantity || 1 }; } }
        });
        modalQuantity.result.then(function (args) {
			$scope.setOfferQuantity(args.quantity);
        });
    }


    // modifier groups multi ------------------------------------------------------>

    $scope.dropMGroupMulti = function (group, member, e) {
        if (group.modifierBehavior != 'checkbox') {
            e.stopPropagation();
            member._isopen = !member._isopen;
        };
    };

	// ----------------------------------------------------------------------------->
    // selection groups ------------------------------------------------------------>
	// ----------------------------------------------------------------------------->

	function showScrollMessage(text) {
		let _scrollTop = $(window).scrollTop() || 0;
		$scope.PDialog.warning({ text: text }).then(() => {
			window.setTimeout(() => {
				$(window).scrollTop(_scrollTop || 0);
			}, 200);
		});
	}

	$scope.addRemoveItemStart = function(group, member, ev) {
		ev.stopPropagation();
		let $h = $(ev.currentTarget);
		if ($h.hasClass('popactive')) {
			$h.removeClass('popactive')
			return;
		}
		$('.popactive').removeClass('popactive');
		$h.addClass('popactive');
		if (!member.count) $scope.addRemoveItem(group, member, true, ev);
	}

	$scope.addRemoveItem = function(group, member, doAdd, ev) {
		if (ev) ev.stopPropagation();
		let forceEdit = false;
		if (doAdd) {
			if (!member.count) {
				$scope.toggleSelectionGroupItem(group, member);
			} else {
				var selectionCount = getItemGroupSelectionCount(group);
				var count = group.selectionCount;
				if (selectionCount == count) {
					showScrollMessage($translate.instant('GROUP_VALIDATION.SELECT_UPTO_SOME', { max: count }));
					return;
				}
				++member.count;
				$scope.viewItem.total += member._item.total;
			}
			prepareOfferSelectionGroups();
		} else {
			if (member.count > 1) {
				if (member._item.total) $scope.viewItem.total -= member._item.total;
				--member.count;
			} else {
				$scope.clearSelectionGroupItem(group, member);
				prepareOfferSelectionGroups();
			}
		}
	}

	$scope.editItemGroupItem = function(group, member, e) {
		if (e) e.stopPropagation();
		$scope.toggleSelectionGroupItem(group, member, true);
	}

	$scope.toggleSelectionGroupItem = function(group, member, forceEdit) {
		if (member.stockDisabled) {
			//$scope.PDialog.warning({ text: $translate.instant('MESSAGES.ITEM_OUTOF_STOCK', { item: member.name }) });
			showScrollMessage($translate.instant('MESSAGES.ITEM_OUTOF_STOCK', { item: member.name }));
			return;
		}

		if (member._selected) {
			if (forceEdit || member.$wasEdit) {
				$scope.showSelectionGroupItemModal({
					viewItem: $scope.viewItem,
					groupItem: member,
					group: group,
					currentItem: member._item,
					isEditSGItem: true
				})

			} else {
				$scope.clearSelectionGroupItem(group, member);
			};
		} else {
			var count = group.selectionCount;
			var selectionCount = getItemGroupSelectionCount(group);
			if (count > 1) {
				if (selectionCount == count) {
					//$scope.PDialog.warning({ text: $translate.instant('GROUP_VALIDATION.SELECT_UPTO_SOME', { max: count }) });
					showScrollMessage($translate.instant('GROUP_VALIDATION.SELECT_UPTO_SOME', { max: count }));
					return;
				}
			}
			if (member._item.summary) {

			} else {
				$scope.prepareItem(member._item, $scope.viewItem);
			}
			if (!member._item.total) member._item.total = 0
			if (_.isUndefined(member._item._total)) member._item._total = member._item.total;


			if (!forceEdit && (!member._item._hasModifierGroups || !member._item._hasRequiered)) {
				$scope.addSelectionGroupItem(group, member);
				prepareOfferSelectionGroups();
			} else {
				$scope.showSelectionGroupItemModal({
					viewItem: $scope.viewItem,
					groupItem: member,
					group: group,
					currentItem: member._item
				})
			}
		}
	}

	$scope.clearSelectionGroupItem = function(group, member, e) {
		if (e) e.stopPropagation();
		delete member._selected;
		let _item = member._item;
		if (_item) {

			if (_item.total) $scope.viewItem.total -= _item.total;
			delete _item.oldTotal;
			_item.total = _item._total;

			delete member._item.oldTotal;
			member._item.total = member._item._total;
			delete member._item.summary;

			_.each(member._item.modifierGroups, group => {
				if (group.singleSelection) {
					_.each(group.modifiers, modifier => {
						if (modifier._isDefault) {
							modifier._selected = true;
						} else {
							delete modifier._selected;
						}
					})
				} else {
					_.each(group.modifiers, modifier => {
						if (!modifier.compulsory) {
							if (modifier.$isDefault) {
								modifier.formationUnit = modifier.defaultFormationUnit;
								modifier.count = 1;
							} else {
								delete modifier.formationUnit;
								modifier.count = 0;
							}
						}
					})
				}
			})

		}
		delete group._expandable;
		delete group._collpased;
		delete member.count;
		prepareOfferSelectionGroups();
	}

	$scope.addSelectionGroupItem = function(group, member, isEdit) {
		var selectionCount = getItemGroupSelectionCount(group);
		if (!isEdit) {
			var count = group.selectionCount;
			var selected = _.filter(group.items, { '_selected': true });
			var selectedN = selected.length;
			if (count == 1) {
				if (selectionCount) {
					var _item = selected[0] && selected[0]._item;
					if (_item) {
						$scope.clearSelectionGroupItem(group, selected[0]);
					}
					--selectedN;
				}
			}
		}

		var oldTotal = member._item.oldTotal || 0;
		var newTotal = member._item.total || 0;

		$scope.viewItem.total -= oldTotal;
		$scope.viewItem.total += newTotal;
		member._item.oldTotal = newTotal;

		if (!member.count) member.count = 0;
		++member.count;
		member._selected = true;
		delete group._requiered;

		if (!isEdit) {
			if (count == selectedN + 1) {
				group._expandable = true;
				group._collpased = true;
			}
		}
	}

	function prepareOfferSelectionGroups() {
		var selectionSummary = [];
		let hasSelectionSummary = false;
		_.each($scope.viewItem._offer.selectionGroups, function (sG) {
			var arr = [];
			_.each(sG.items, function (item) {
				if (item._selected) {
					arr.push(item);
				}
			});
			if (arr.length) {
				if (!hasSelectionSummary) hasSelectionSummary = sG._visible !== false;
				selectionSummary.push({
					_id: sG._id,
					name: sG.name,
					items: arr,
					_visible: sG._visible !== false
				});
			}
		});
		$scope.viewItem.hasSelectionSummary = hasSelectionSummary
		$scope.viewItem.selectionSummary = selectionSummary;
	}

	$scope.showSelectionGroupItemModal = function (args) {
		args.$storage = $scope.$storage;
		args.isDesktop = window.ISDESKTOP;
		var modalItem = $uibModal.open({
			templateUrl: 'modules/order/order_additem_desktop_modal.html',
			controller: 'order_additem_desktop_modal',
			//size: 'lg',
			windowClass: "modal-default",
			resolve: {
				modalParams: args
			}
		});
		modalItem.result.then(function (result) {
			if (result) {
				if (result.isClear) {
					$scope.clearSelectionGroupItem(args.group, args.groupItem);
				} else {
					$scope.ES.prepareItemSummary(result.groupItem._item, true);
					args.groupItem.$wasEdit = true;
					result.groupItem.count = 0;
					result.groupItem._selected = false;
					$scope.addSelectionGroupItem(result.group, result.groupItem, args.isEditSGItem);
					prepareOfferSelectionGroups();
				}
			}
		});

	}

	function getItemGroupSelectionCount(group) {
		let selectionCount = 0;
		_.each(group.items, item => {
			if (item._selected) {
				if (!item.count) item.count = 1;
				selectionCount += item.count;
			}
		});
		return selectionCount;
	}


	// --------------------------------------------------------------------------->
    // --------------------------------------------------------------------------->
	// --------------------------------------------------------------------------->

    $scope.closeSingleItem = function () {

        $scope.currentItem = $scope.viewItem._item;
        if ($scope.args.mode == "item") {
            $scope.args.mode = "offer";
            $scope.args.isEditSGItem = false;
            $timeout(function () { $(window).scrollTop($scope.args.cacheTop || 0) });
        }
    }

    var listener = $rootScope.$on("doBack", function (o, args) {
        if ($scope.args.mode == "item") {
            $scope.closeSingleItem();
        } else {
            window.history.back();
        }
    });
	$scope.$on("$destroy", function (event) {
		$('body').off('click.popclickhandler');
        listener();
    });

    $scope.dismissPopovers = function () {
        $("body").trigger('click');
    }

    function scrollEToTop(h, offset) {
        if (!offset) offset = 190;
        $('html,body').animate({ scrollTop: (h.offset().top - offset) }, 300);
    }

});



app.controller('modal_decision_controller', function ($scope, $uibModalInstance, modalParams, $translate) {
    $scope.group = modalParams.group;

    $scope.addMGroupSingle = function (group, item) {
        $uibModalInstance.close(item);
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

});

app.controller('order_additem_desktop_modal', function ($scope, $timeout, $translate, $uibModal, $uibModalInstance, PDialog, modalParams, EntityService) {
    $scope.ES = EntityService;
    $scope.PDialog = PDialog;
    $scope.$storage = modalParams.$storage;
	$scope.defIcon = 'images/summary-sg-dish.png';
	$scope.isDesktop = modalParams.isDesktop;
    $scope.args = {
        mode: "item",
        group: modalParams.group,
        groupItem: modalParams.groupItem,
        isEditSGItem: modalParams.isEditSGItem
	}

    if (modalParams.isEditSGItem){
        $scope.currentItem = angular.copy(modalParams.currentItem);
    }else{
        $scope.currentItem = modalParams.currentItem;
    };
    
    order_item_logic($scope, $timeout, $uibModal, $translate);
    $scope.dismissPopovers = function () {};

    $scope.validateItem($scope.currentItem, [], true);

	$scope.clearSelection = function () {
		$uibModalInstance.close({
			isClear: true
		})
	}

    $scope.addItemClick = function () {
        var arrErrors = [];
        if ($scope.validateItem($scope.currentItem, arrErrors)) {
            if ($scope.args.isEditSGItem){
                $scope.args.groupItem._item = $scope.currentItem;
            }
            $uibModalInstance.close({
                group: $scope.args.group,
                groupItem: $scope.args.groupItem
            })
        } else {
            $scope.displayValidationErrors(arrErrors);
		}
    }

});


function order_item_logic($scope, $timeout, $uibModal, $translate) {

    $scope.validateItem = function (item, arrErrors, dontInfo) {
        var valid = true;
        var modGroup;
        _.each(item.modifierGroups, function (group) {
            if (group.singleSelection) {
                var mod = _.find(group.modifiers, { '_selected': true });
                if (!mod) {
                    if (!modGroup) modGroup = group;
                    valid = false;
                    group._requiered = true;
                    arrErrors.push(group.name + ", requiered");
                };
            } else {
                if (checkModGroupSelection($scope.ES,group)) {
                    valid = false;
                    arrErrors.push(group.name + ": " + group._requieredMessage);
                }
            };
        });
        if (!dontInfo) {
            if (modGroup) $scope.toggleMGroupSingle(modGroup);
            else if ((arrErrors.length)) {
                $scope.PDialog.warning({ text: arrErrors[0] });
            }
        }
        return valid;
    };
    $scope.displayValidationErrors = function (arr) {
        $timeout(
            function () {

                var $h = $("._requiered").first();
                //if ($h.prop("tagName") == "BUTTON") $("html, body").scrollTop(0);
                //else $("html, body").scrollTop($(document).height());


                $timeout(function () {
                    $('html,body').animate({ scrollTop: ($("._requiered").first().offset().top - 200) }, 300);

                }, 100);
            }
            );
        //$scope.PDialog.warning({ text: arr.join("<br>"), html: true }).then(function () {
        //    $("html, body").scrollTop($(document).height());
        //    $("._requiered").scrollIntoView();
        //});
    }

    // modifier groups single ------------------------------------------------------>

    $scope.getMGroupSingleSelection = function (group) {
        return _.find(group.modifiers, { _selected: true });
    };

    $scope.toggleMGroupSingle = function (group, e) {
        var modalDecision = $uibModal.open({
            templateUrl: 'modules/order/modals/modal_decision.html',
            controller: 'modal_decision_controller',
            windowClass: "modal-list",
            size: "sm",
            resolve: {
                modalParams: function () {
                    return {
                        group: group
                    };
                }
            }
        });
        modalDecision.result.then(function (member) {
            $scope.addMGroupSingle(group, member);
        }, function () {

        });
    }

    $scope.addMGroupSingle = function (group, member, e) {
        delete group._isopen;
        if (member._selected) {
            //delete item._selected;
        } else {
            var selected = _.find(group.modifiers, { '_selected': true });
            if (selected) delete selected._selected;
            member._selected = true;
            delete group._requiered;
        }
        group.refreshing = true;
        $scope.ES.prepareItemSummary($scope.currentItem); //new summary
        $timeout(function () { group.refreshing = false; })
    };

    $scope.addSelectionGroupItem = function (group, member, isEdit) {
        if (!isEdit){
            var count = group.selectionCount;
            var selected = _.filter(group.items, { '_selected': true });
            var selectedN = selected.length;
            if (count == 1) {
                if (selected.length) {
                    var _item = selected[0] && selected[0]._item;
                    if (_item){
                        if (_item.total) $scope.viewItem.total -= _item.total;
                        delete selected[0]._selected;
                        delete _item.oldTotal;
                        _item.total = _item._total;
                    }
                    --selectedN;
                }
            }
        }

        var oldTotal = member._item.oldTotal || 0;
        var newTotal = member._item.total || 0;

        $scope.viewItem.total -= oldTotal;
        $scope.viewItem.total += newTotal;
        member._item.oldTotal = newTotal;

        member._selected = true;
        delete group._requiered;
        $scope.closeSingleItem();
        
        /* if (!isEdit){ //depricated
            if (count == selectedN + 1) {
                group._expandable = true;
                group._collpased = true;
            }
        }*/
    }

    // modifier groups multi ------------------------------------------------------>

    $scope.toggleMGroupMulti = function (group, member, placement) {
        if (member.formationUnit) $scope.addMGroupMulti(group, member);
        else{
            if (!placement){
                toastr['error']($translate.instant("Missing item formations"), null);
                return;
            };
            $scope.addMGroupMulti(group, member, placement);
        }
        $scope.ES.prepareItemSummary($scope.currentItem); // //new summary
    };
    $scope.addMGroupMulti = function (group, member, placement, silent) {
        var price = member.price;
        if (!placement) {
            if (price > 0) {
                if ($scope.args.mode == 'offer') $scope.viewItem.total -= price;
                else $scope.currentItem.total -= price;
            }
            delete member.formationUnit;
            if (silent) return;
        } else {
            if (group.max) {
                var elms = _.filter(group.modifiers, function (elem) { return elem.formationUnit != null; }), count = elms.length;
                if (count >= group.max) {
                    if (count == 1){
                        $scope.addMGroupMulti(group, elms[0], null, true);
                    }else{
                        var maxOP = group.compulsaryOP || 0;
                        $scope.PDialog.info({ text: $translate.instant("MESSAGES.MOD_CANT_SELECT_MORE_THEN", { name: group.name, max: count - maxOP }) });
                        return;                    
                    }
                }
            }
            if (price > 0) {
                if ($scope.args.mode == 'offer') $scope.viewItem.total += price;
                else $scope.currentItem.total += price;
            }
            member.formationUnit = placement;
        }
        checkModGroupSelection($scope.ES,group);
        $scope.dismissPopovers();
    };

    function checkModGroupSelection(ES, group) {
        var min = group.min;
        if (min || group.max) {
        	var op = group.compulsaryOP || 0;
        	group._requieredMessage = ES.prepareGroupValidationMessage(min - op, group.max - op);
        }
        if (min && min > 0) {
            var count = _.filter(group.modifiers, function (elem) { return elem.formationUnit != null; }).length;
            if (count < min) {
                group._requiered = true;
                return true;
            }
        }
        delete group._requiered;
        delete group._requieredMessage;
        return false;
    }

}

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// checkout_controller
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('checkout_controller', function ($scope, $translate, BenefitsService) {
    delete window.BLOCATIONHASH;

    $scope.MS.SectionCaption = $translate.instant("PAYMENT");
    $scope.isInCheckout = true;

	$scope.init = function (qrc, oid, redirect) {
		let $storage = $scope.$storage, site = $storage.order.branch;
		let order = $storage.order;

		if (!$scope.canCheckout(true)) {
			$scope.$state.go("app.order.menus");
			return;
		}

		if (!$storage.loyaltyClub || $storage.loyaltyMember || !_.get(order, 'branch.settings.forceLoyaltyServices', []).includes(order.mode)) { }
		else {
			$scope.PDialog.warning({ "text": $translate.instant("_LOYALTY.loyalty_required") }).then(() => {
				$scope.$state.go("app.order.menus");
			}).catch(() => {
				$scope.$state.go("app.order.menus");
			});
			return;
		}

		$storage.isDelivery = $storage.order.mode == 'delivery';
		const service = _.get(site, `${$storage.order.mode}`);
		if (service.enableGratuity) {
			const tipOptions = service.tipOptions ? service.tipOptions : $storage.region == 'US' ? {
				values: [10, 15, 18, 20],
				valueType: "percent"
			} : {
				values: [10, 15, 20, 25],
				valueType: "amount"
			};
			$storage.enableGratuity = true;
			$storage.gratuityMode = tipOptions.valueType;
			$storage.defaultGratuity = tipOptions.defaultValue;
			switch (tipOptions.valueType) {
				case "amount":
					$scope.MS.courierGratuities = tipOptions.values;
					if (tipOptions.defaultValue) $storage.defaltGratuity = { type: 'v', value: tipOptions.defaultValue };
					break;
				case "percent":
					$scope.MS.gratuities = tipOptions.values;
					if (tipOptions.defaultValue) $storage.defaltGratuity = { type: 'p', value: tipOptions.defaultValue };
					break;
			}
		}
		

		if (!$storage.wasCheckoutBenefits && $storage.loyaltyData) {
			$storage.wasCheckoutBenefits = true;
			$scope.ES.clearLoyaltyPromotions();
			BenefitsService.getBenefits().then(res => {
				if (!$storage.loyaltyClub.pointOnly) {
					BenefitsService.showPromotions().then(o => {
						$scope.ES.calculateBasketTotal();
					})
				}
			});
		}


    };
    $scope.init();
});

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// checkout_extra
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('checkout_extra', function ($scope, $rootScope, $uibModal, $translate, $location) {
	$scope.ES.clearPreCreatedOrder();


	function init() {
		// prepare groups
		let extraOffersGroups = $scope.$storage.order.extraOffersGroups;
		$scope.extraOffersGroups = [];

		_.each($scope.$storage.extraOffersGroups, (group) => {
			let maxCount = group.maxCount;
			if (_.get(group, 'subGroups[0]')) {
				let max = 0;
				_.each($scope.$storage.basket, (o) => {
					var menuId = findOfferMenu(o.offer);
					_.each(group.subGroups, subGroup => {
						if (subGroup.menus && subGroup.menus.indexOf(menuId) != -1) {
							if (!subGroup.menuMultiplier) subGroup.menuMultiplier = 1;
							max += ((o.quantity || 1) * subGroup.menuMultiplier);
							return false;
						}
					});
				});
				maxCount = Math.min(max, group.maxCount);
			}
			if (maxCount > 0) {
				let newGroup = _.find(extraOffersGroups, { _id: group._id });
				if (!newGroup || newGroup.count > maxCount) {
					newGroup = _.cloneDeep(group);
				} 
				$scope.extraOffersGroups.push(newGroup);
				newGroup.maxCount = maxCount;
				newGroup.$info = $translate.instant('GROUP_VALIDATION.SELECT_UPTO_SOME', { max: maxCount });
			}
		});
		if ($scope.extraOffersGroups.length) {
			$scope.hasExtraOffersGroups = true;
		} else {
			$scope.$storage.order.extraOffersGroups = [];
		}


		// prepare single
		let extraOffers = $scope.$storage.order.extraOffers;
		$scope.extraOffers = [];
		let extraOffersMethod = 'single';
		_.each($scope.$storage.extraOffers, function (_offer) {
			if (!_offer.maxCount) _offer.maxCount = 5;
			let newOffer = angular.copy(_offer);
			let chk = _.find(extraOffers, { offer: _offer.offer });
			if (chk) newOffer.count = chk.count;

			if (_.get(newOffer, 'subGroups[0]')) {
				let max = 0;
				_.each($scope.$storage.basket, function (o) {
					var menuId = findOfferMenu(o.offer);
					_.each(newOffer.subGroups, group => {
						if (group.menus && group.menus.indexOf(menuId) != -1) {
							if (!group.menuMultiplier) group.menuMultiplier = 1;
							max += ((o.quantity || 1) * group.menuMultiplier);
							return false;
						}
					});
				});
				newOffer.maxCount = Math.min(max, _offer.maxCount);
				if (newOffer.count && newOffer.count > newOffer.maxCount) newOffer.count = newOffer.maxCount;
			}
			if (newOffer.maxCount == 0) return;
			if (newOffer.maxCount > 1) {
				extraOffersMethod == 'multi';
			}
			$scope.extraOffers.push(newOffer);
		});
		if ($scope.extraOffers.length) {
			$scope.hasExtraOffers = true;
			$scope.$storage.extraOffersMethod == extraOffersMethod;
		} else {
			$scope.$storage.order.extraOffers = [];
		}


		if (!$scope.hasExtraOffers && !$scope.hasExtraOffersGroups) {
			$location.replace();
			$scope.$storage.hasExtraOffers = false;
			$scope.$state.go('app.checkout.contact');
			return;
		}

		$scope.$storage.hasExtraOffers = true;
	}
	init();


    function findOfferMenu(_offerId) {
        var ret;
        var arr = $scope.catalog.view;
        for (var i = 0; i < arr.length; i++) {
            var ret = findRecursive(arr[i], _offerId, true);
            if (ret) return arr[i]._id;
        }

        function findRecursive(o, _offerId) {
            if (o.offer && o.offer == _offerId) return o;
            if (o.items) {
                var _arr = o.items;
                for (var i = 0; i < _arr.length; i++) {
                    var _ret = findRecursive(_arr[i], _offerId);
                    if (_ret){
                        return true;
                    }
                };
            }
        }
    }

	$scope.addGroupOffer = function (group, _offer, op) {
		var newVal = group.count + op;
		if (newVal < 0 || newVal > group.maxCount) return;
		_offer.count = _offer.count + op;
		group.count = newVal;
	}

    $scope.addExtraOffer = function (_offer, op) {
        var newVal = _offer.count + op;
        if (newVal < 0 || newVal > _offer.maxCount) return;
        _offer.count = newVal;
    }

    $scope.save = function () {
        if ($scope.dForm.$valid) {
            let extraOffers = [];
            _.each($scope.extraOffers, (offer) => {
                if (offer.count > 0) extraOffers.push(offer);
            });
			$scope.$storage.order.extraOffers = extraOffers;

			let extraOffersGroups = [];
			_.each($scope.extraOffersGroups, (group) => {
				if (group.count > 0) extraOffersGroups.push(group);
			});
			$scope.$storage.order.extraOffersGroups = extraOffersGroups;

			$scope.$state.go('app.checkout.contact');
        };
    }
});

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// checkout_contact
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('checkout_contact', function ($scope, $rootScope, $uibModal, $translate, ServiceCommon, ExternalDeliveryService) {
	$scope.ES.clearPreCreatedOrder();
	

	$rootScope.$on('benefitsChange', function (event, data) {
		checkBenefits()
	});

	function checkBenefits() {
		$scope.isNextExecute = !$scope.$storage.enablePartialPayment && !$scope.$storage.enableGratuity && $scope.$storage.order.grandTotalPlusTip == 0;
	}
	
	$scope.delay = {}
	function init() {
		$scope.hasAlcohol = $scope.$storage.region == 'US' && ServiceCommon.hasBasketAlcohol();
		$scope.ES.popMessages("ContactPage", $scope.$storage.order.mode);
		checkBenefits();
		$scope.splitContactName = _.get($scope.$storage.order.branch, 'settings.splitContactName');
		_.each($scope.$storage.extraQuestions, question => {
			question._visible = true;
			if (question.limitByMenu && _.get(question.menus, '[0]')) {
				question._visible = false;
				_.each($scope.$storage.basket, function (o) {
					var menuId = findOfferMenu(o.offer);
					if (question.menus.indexOf(menuId) != -1) {
						question._visible = true;
						return false;
					}
				});
				if (!question._visible) question.active = false;
			}
		});

		function findOfferMenu(_offerId) {
			var ret;
			var arr = $scope.catalog.view;
			for (var i = 0; i < arr.length; i++) {
				var ret = findRecursive(arr[i], _offerId, true);
				if (ret) return arr[i]._id;
			}
			function findRecursive(o, _offerId) {
				if (o.offer && o.offer == _offerId) return o;
				if (o.items) {
					var _arr = o.items;
					for (var i = 0; i < _arr.length; i++) {
						var _ret = findRecursive(_arr[i], _offerId);
						if (_ret) {
							return true;
						}
					};
				}
			}
		}

		$scope.enableCurbPickup = $scope.$storage.order.mode == 'takeaway' && $scope.$storage.settings.enableCurbPickup;

		if ($scope.$storage.order.contact) {
			$scope.contact = angular.copy($scope.$storage.order.contact);
		} else if ($scope.$storage.user) {
			$scope.contact = _.extend({
				dinerCount: 1,
				includeCutlery: false,
				contractApprove: true
			}, $scope.$storage.user.contact);
		} else {
			$scope.contact = { dinerCount: 1, includeCutlery: false, contractApprove: false };
		}

		if ($scope.$storage.settings.hideIncludeCutlery) {
			$scope.contact.includeCutlery = 0;
		}

		var dn = $scope.contact.dinerCount;
		if (!dn || isNaN(dn)) $scope.contact.dinerCount = 1;

		//----------------------------------------------------------------------------------->
		// order delay
		//----------------------------------------------------------------------------------->

		var maxOrderDelay = $scope.$storage.maxOrderDelay;
		if (!maxOrderDelay || $scope.$storage.order.forceDelay ) return;

		var n = $scope.$storage.minOrderDelay, members = [{ id: 'NAN', date: null, text: $translate.instant("ASAP") }];
		var d = GETREALDATE(true);

		var wh = $scope.$storage.order.branch.wh, slotMin, slotMax, nextSlots, diffDays;
		if (wh && wh.found) {
			diffDays = d.diff(moment([1970, 0, 1]), 'days');
			slotMin = moment(wh.found.from).add(diffDays, 'days');
			slotMax = moment(wh.found.to).add(diffDays, 'days');
			if (d.isBefore(slotMin)) {
				d = slotMin;
			}
			nextSlots = wh.found.nextSlots;
		};

		var moffset = d.minutes() % 15;
		if (moffset > 0) moffset = 15 - moffset;
		n += moffset
		var nn = 0;
		maxOrderDelay += n;
		n += 15;
		do {
			var _d = d.clone().add(n, 'minutes');
			if (slotMax && _d.isAfter(slotMax)) {
				//maxOrderDelay = 0;
				break;
			} else {
				members.push({ id: `A-${++nn}`, date: _d.toDate(), minutes: n, text: _d.format($scope.MS.local.timeFormat) });
				n += 15;
			}
		} while (n < maxOrderDelay);

		if (nextSlots && n < maxOrderDelay) {
			_.each(nextSlots, nextSlot => {
				if (n > maxOrderDelay) return false;
				slotMin = moment(nextSlot.from).add(diffDays, 'days');
				slotMax = moment(nextSlot.to).add(diffDays, 'days');
				do {
					var _d = d.clone().add(n, 'minutes');
					if (_d.isAfter(slotMax)) break;
					if (_d.isAfter(slotMin)) {
						members.push({ date: _d.toDate(), minutes: n, text: _d.format($scope.MS.local.timeFormat) });
					}
					n += 15;
				} while (n < maxOrderDelay);
			})
		}

		$scope.orderDelayMembers = members;

		$scope.delay.todaySlot = members[0];
		let _slot = $scope.$storage.delayedOrder.todaySlot;
		if (_slot) {
			let _found = _.find($scope.orderDelayMembers, { id: _slot.id });
			if (_found) $scope.delay.todaySlot = _found;
		}
	}
	init();

    $scope.save = function () {
		if ($scope.dForm.$valid) {
			let contact = $scope.contact;

			if ($scope.splitContactName) {
				contact.firstName = UIUtils.fixSpecialChars(contact.firstName);
				contact.lastName = UIUtils.fixSpecialChars(contact.lastName);
				contact.name = `${contact.firstName} ${contact.lastName}`;
			} else {
				contact.name = UIUtils.fixSpecialChars($scope.contact.name);
			}

			checkBenefits();
            
            if (contact.cell) contact.cell = UIUtils.fixSpecialChars(contact.cell);
            if (contact.email) contact.email = UIUtils.fixSpecialChars(contact.email);
            if (!contact.contractApprove && !$scope.$storage.user) {
                $scope.PDialog.info(
                    {
                        "text": $translate.instant("MESSAGES.DO_YOU_APPROVE_CONTRACT"),
                        showCancelButton: true,
                        confirmButtonText: $translate.instant("YES"),
                        cancelButtonText: $translate.instant("NO"),
                    }
                ).then(function () {
                    contact.contractApprove = true;
                    $scope.save();
                });
			} else {
				if ($scope.$storage.order.orderDelay) {
					$scope.$storage.delayedOrder = _.cloneDeep($scope.$storage.order.orderDelay);
				}
				else if (!$scope.$storage.order.forceDelay && $scope.$storage.maxOrderDelay) {
					let todaySlot = $scope.delay.todaySlot;
					if (todaySlot && todaySlot.id != 'NAN') {
						$scope.$storage.delayedOrder.method = "today";
						$scope.$storage.delayedOrder.active = true;
						$scope.$storage.delayedOrder.todaySlot = todaySlot;
						$scope.$storage.delayedOrder.text = $translate.instant("_DELAYED.for_hour", { val: moment(todaySlot.date).format($scope.MS.local.timeFormat) });
					} else {
						$scope.$storage.delayedOrder.active = false;
						$scope.$storage.delayedOrder.text = $translate.instant("ASAP");
					}	
				}
				$scope.$storage.order.contact = contact;

				ExternalDeliveryService.estimateExDeliveryBeforePay().then(res => {					
					if ($scope.isNextExecute) {
						$scope.ES.processNoAmountOrder();
					} else if ($scope.$storage.enableGratuity) $scope.$state.go('app.checkout.gratuity');
					else $scope.$state.go('app.checkout.pay');
				}).catch(err => {
					$scope.$state.go('app.checkout.contact');
					return;
				});
            }
		} 
	}

    $scope.doReadContract = function (isPrivacy) {
        var modalContract = $uibModal.open({
            templateUrl: 'modules/app/modals/modal_contract.html',
            controller: 'modal_contract_controller',
            windowClass: "modal-default",
            resolve: {
                modalParams: {
                    $storage: $scope.$storage,
                    isPrivacy: isPrivacy
                }
            }
        });
        modalContract.result.then(function (result) {
            $scope.contact.contractApprove = true;
        });
    }

});
app.controller('modal_contract_controller', function ($scope, $uibModalInstance, modalParams) {
    $scope.$storage = modalParams.$storage;
    $scope.params = modalParams;
    $scope.maxHeight = ($(window).height() - 147) + 'px';
    $scope.params.region = modalParams.$storage.region;
    

    $scope.approve = function () {
        $uibModalInstance.close({ approve: true });
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

});

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// checkout_gratuity
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('checkout_gratuity', function ($scope, $rootScope) {
	$scope.ES.fixGratuity();


	$rootScope.$on('benefitsChange', function (event, data) {
		checkBenefits()
	});
	$scope.setGratuity = setGratuity;
	$scope.setValGratuity = setValGratuity;

    var total = $scope.$storage.order.grandTotal;
	$scope.gratuityRange = { min: 0, max: _.round($scope.$storage.order.itemsTotal, $scope.$storage.gratuityDecimals) };
	if ($scope.$storage.order.gratuity) {
		$scope.gratuity = angular.copy($scope.$storage.order.gratuity);
	} else {
		$scope.gratuity = { type: $scope.$storage.simpleTipDelivery ? "m" : "c" };
		let defaltGratuity = $scope.$storage.defaltGratuity;
		if (defaltGratuity) {
			if (defaltGratuity.type == 'p')setGratuity(defaltGratuity.type, defaltGratuity.value);
			else setValGratuity(defaltGratuity.value);
		}
	}

	
	if ($scope.$storage.gratuityMode == 'percent') {
		$scope.gratuitiesAmount = _.map($scope.MS.gratuities, opt => {
			return $scope.ES.calculateGratuityFromPercentage(opt);
		});
	}

    function setGratuity (_type, pVal) {
        $scope.gratuity.type = _type;
        $scope.gratuity.percent = pVal;
        if (!isNaN(pVal)) {
            $scope.gratuity.amount = $scope.ES.calculateGratuityFromPercentage(pVal);//total * (pVal / 100);
        } else {
            delete $scope.gratuity.percent;
            delete $scope.gratuity.amount;
		}
		checkBenefits();
    };

    function setValGratuity (val) {
        var gratuity = $scope.gratuity;
        gratuity.type = 'mm';
		gratuity.amount = val;
		checkBenefits();
    };

    $scope.fixGratuityAmount = function () {
        let decimals = $scope.$storage.gratuityDecimals;
        let amount = $scope.gratuity.amount;
        if (amount && !isNaN($scope.$storage.gratuityDecimals)) {
            $scope.gratuity.amount = Number($scope.gratuity.amount.toFixed(decimals));
		}
		checkBenefits();
    }

	function checkBenefits() {
		$scope.isNextExecute = $scope.$storage.order.grandTotalPlusTip == 0 && !$scope.gratuity.amount;
	}
	checkBenefits();

    $scope.save = function (data) {
        if ($scope.dForm.$valid) {
            var gratuity = $scope.gratuity;
            switch (gratuity.type) {
                case "mm": //fixed amount
                    gratuity.percent = gratuity.amount / total * 100
                    break;
                case "m"://manual gratuity
                    var amount = gratuity.amount;
                    if (isNaN(amount) || amount == 0) {
                        gratuity.type == 'c';
                        gratuity.amount = 0;
                        gratuity.percent = 0;
                    } else {
                        gratuity.percent = gratuity.amount / total * 100
                    }
                    break;
                case "c":
                    gratuity.amount = 0;
                    gratuity.percent = 0;
                    break;
            }
			$scope.$storage.order.gratuity = gratuity;

			if ($scope.isNextExecute) {
				$scope.ES.processNoAmountOrder();
			} else {
				$scope.$state.go('app.checkout.pay')
			}            
        }
    };

	$rootScope.$on('onOrderChange', function (event, data) {
		
    });

});

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// checkout_pay
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('checkout_pay', function ($scope, $rootScope, $uibModal, $translate, $filter, MarkettingService, BenefitsService, ServiceCommon, ExternalDeliveryService) {
	$scope.payInPrgress = false;
	$scope.total = $scope.$storage.order.grandTotal;
	$scope.benefitsService = BenefitsService;

	$rootScope.$on('benefitsChange', function (event, data) {
		$scope.$state.reload();
	});

	var storage = $scope.$storage;
	var order = $scope.$storage.order;

	$scope.$on("$destroy", function () {
		$scope.$storage.giftCards = [];
		$scope.ES.calculateBasketTotal();
	});

    ServiceCommon.getClientOrderId();
	$scope.ES.calculateBasketTotal();
	$scope.ES.popMessages("beforePayment", storage.order.mode);

    if (!order.contact) {
		$scope.$state.go('app.checkout.contact');
		return;
    }

    $scope.ui = {
        enableSplitPayment: !storage.disablePaymentSplit && storage.settings.enablePaymentSplit,
        isSplitPayment: false
    }

    //prepare payment methods
    var arr = [], arrEx = [];
    $scope.disableGratuityCash = !storage.disableCashPayment && storage.enableGratuity && order.gratuityAmount && order.gratuityAmount != 0;
	var disableCash = storage.disableCashPayment || $scope.disableGratuityCash || storage.order.$$leaveOutside;
	_.each(order.branch.paymentMethods, function (pm) {

        var sch = pm.schedule;
        if (!sch || storage.activeTimeslots[sch] !== false) {
			if (pm.paymentType != "cash" || !disableCash) {
				if ($scope.disableGratuityCash && $scope.$storage.enableGratuityCreditOnly && pm.paymentType != 'creditCard' && pm.paymentType != 'CreditGuard') {

				} else {
					if (pm.paymentType == "CreditGuard") pm.isExternal = true;
					if (pm.isExternal) arrEx.push(pm);
					else {
						arr.push(pm);
						if (pm.paymentType == "cash") $scope.ui.cashMethod = pm;
					}
				}
            }
        };
	});

	$scope.hasLEUMIPAY = _.get($scope.$storage, 'pmAccountTypes.LeumiPay');


    if ($scope.ui.enableSplitPayment) { //disable split payment if the only available pm is cash 
        if (arr.length == 1 && $scope.ui.cashMethod) {
            $scope.ui.enableSplitPayment = false;
        } else {
            $scope.ui.wasEnabledSplitPayment = true; //cash split payment for external accounts
        }
    }
    $scope.ui.hasExAccounts = arrEx.length > 0;
    $scope.ui.paymentMethods = arr, $scope.ui.exPaymentMethods = arrEx;



	$scope.setPaymentMethod = function (ccinfo, pm, fromLoad) {
		if (pm.paymentType == "CreditGuard") {
			if (fromLoad) return;
		}
		if (pm.paymentType == "LeumiPay" && $scope.$storage.order.total < 50) {
			$scope.PDialog.info({ "text": $translate.instant("_LEUMIPAY.min_order_amount_error")});
			return;
		}
        ccinfo.paymentMethod = pm.paymentType;
		ccinfo.account = pm._id;
		ccinfo.$$account = pm;
		ccinfo.merchantNumber = pm.merchantNumber;
		ccinfo.iframeUrl = pm.iframeUrl;
		ccinfo.$$isExternal = pm.isExternal;
		ccinfo.paymentVerificationStrategy = _.get(pm, 'paymentVerificationStrategy','none');

		if (pm.isExternal || pm.paymentType == "CreditGuard") $scope.ui.enableSplitPayment = false; //disable split payment for external accounts
		else if ($scope.ui.wasEnabledSplitPayment) $scope.ui.enableSplitPayment = true;

		if (pm.paymentType == "LeumiPay" && !fromLoad) {
			$scope.save(ccinfo, true);
		}
    };
    $scope.ccinfo = { type: undefined }
    $scope.register = { email: order.contact.email };

    if ($scope.ui.paymentMethods.length == 1) {
		$scope.setPaymentMethod($scope.ccinfo, $scope.ui.paymentMethods[0], true);
    } else if ($scope.ui.paymentMethods.length == 0 && $scope.ui.exPaymentMethods.length == 1) {
    	$scope.setPaymentMethod($scope.ccinfo, $scope.ui.exPaymentMethods[0], true);
    }

    $scope.paymentMethodChanged = function () {
        $scope.ui.submitAttempt = $scope.dForm.submitAttempt = false;
    };

	$scope.save = function (data, forceValid) {
		if (!$scope.MS.checkOrderTimer()) return;
    	var args = { gratuityAmount: $scope.$storage.order.gratuityAmount };

		// check if the site is closed
		var wh = _.get($scope.$storage, "order.branch.wh", {});

		args = _.extend(args, $scope.ES.getSupplyTime());
		if (!$scope.$storage.delayedOrder.active) {
			if (wh.slotMax && GETREALDATE(true).isAfter(wh.slotMax)) {
				$scope.PDialog.warning({ "text": $translate.instant("MESSAGES.BRANCH_DISABLED_NOW", { t: wh.text }) });
				return;
			}
		}         

		if ($scope.$storage.order.grandTotalForPay === 0) {
			if (!_.get($scope.$storage.giftCards, [0])) args.noAmount = true;
			$scope.ES.payAnonymous(args).then(handlePaySuccess, handlePayFail);
			return;
		}

		if ($scope.dForm.$valid || forceValid) {
            if (!data || $scope.splitPayments) {
                var diff = $scope.splitPayments.gtotal - $scope.splitPayments.total;
                if (diff != 0) {
                    $scope.PDialog.warning({
                        text: $translate.instant('SPLIT_PAYMENTS_DIFF_ERROR', {
                            total: $filter('money')($scope.splitPayments.gtotal),
                            diff: $filter('money')(diff)
                        })
                    });
                    return;
                }
                if ($scope.splitPayments.$$desktop) {
                    args.splitPayments = _.filter($scope.splitPayments.payments, function (payment) {
                        return payment.amount > 0;
                    });
                    if ($scope.ccinfo.amount) {
                        if ($scope.ui.alternatePayment || !$scope.wallet) {
                            args.splitPayments.unshift($scope.ccinfo);
                        } else {
                            var _ccinfo = angular.copy($scope.wallet.ccinfo);
                            _ccinfo.amount = $scope.ccinfo.amount;
                            args.splitPayments.unshift(_ccinfo);
                        }
                    }
                } else {
                    args.splitPayments = $scope.splitPayments.payments;
                }
            }

            $scope.$storage.order.$ccinfo = null;

            if (args.splitPayments) {
                $scope.ES.checkForIdCard_split(args).then(function () {
                    $scope.ES.payAnonymous(args).then(handlePaySuccess, handlePayFail);
                });
            } else if ($scope.$storage.user) {
                if ($scope.ui.alternatePayment || !$scope.wallet) {
                    $scope.ES.checkForSignature($scope.ccinfo).then(function () {
                        args.ccinfo = $scope.ccinfo;

                        args.alternatePayment = true;
                        if ($scope.ccinfo.paymentMethod != 'cash') {
                            $scope.$storage.order.$ccinfo = $scope.ccinfo;
                            if ($scope.ui.updatePaymentInfo) { args.updatePaymentInfo = true; };
						};
						$scope.payInPrgress = true;
                        $scope.ES.payRegistered(args).then(handlePaySuccess, handlePayFail);
                    });
                } else {
                    var ccinfo = angular.copy($scope.wallet.ccinfo);
                    $scope.ES.checkForIdCard(ccinfo).then(function () {
                        var ccdetails = _.get($scope, 'ccinfo.customerDetails');
                        if (ccdetails) ccinfo.customerDetails = ccdetails;

                        $scope.ES.checkForSignature(ccinfo).then(function () {
							args.ccinfo = ccinfo;
							$scope.payInPrgress = true;
                            $scope.ES.payRegistered(args).then(handlePaySuccess, handlePayFail);
                        });
                    });
                }
            } else {
                $scope.ES.checkForSignature($scope.ccinfo).then(function () {
                    args.ccinfo = $scope.ccinfo;
                    if ($scope.register.saveInfo) args.register = $scope.register;
                    if ($scope.ccinfo.paymentMethod != 'cash') {
                        $scope.$storage.order.$ccinfo = $scope.ccinfo;
					}
					$scope.payInPrgress = true;
                    $scope.ES.payAnonymous(args).then(handlePaySuccess, handlePayFail);
                });
            }
        }
		function handlePaySuccess() {
			var _storage = $scope.$storage;
			MarkettingService.trackConversion(_.get(_storage, 'order.grandTotalPlusTip'));
            _storage.orderClosed = true;
            _storage.dEnd = GETREALDATE(true).format();
            if (args.eta) _storage.order.supplyTime = args.eta;
            $scope.$state.go("end");
		}

		function handlePayFail(args) {
			$scope.payInPrgress = false;
			if (args.CANCELED) {
				$scope.MS.checkOrderTimer();
				return;
			}

            if (args.phone) {
                $scope.ES.showMessageWithPhone(args.message, args.phone).then(function () {
                    if (args.orderClosed) {
                        handlePaySuccess();
					} else {
						$scope.MS.checkOrderTimer();
					}
                });
            } else {
                $scope.PDialog.warning({ text: args.message }).then(function () {
					if (args.orderClosed) {
						handlePaySuccess();
					} else {
						$scope.MS.checkOrderTimer();
					}
                });
            }
        }
    }
    // ------------------------------------------------------------------------------------------------->
    // GIFT cards
    // ------------------------------------------------------------------------------------------------->

	//let gratuity = $scope.$storage.order.gratuityAmount;
	let loyaltyData = $scope.$storage.loyaltyData || {};
	if (loyaltyData) {
		let maxPoints = Math.min(loyaltyData.pontsBalance, $scope.$storage.order.grandTotalClean - loyaltyData.totalBenefits);
		if (maxPoints && maxPoints > 0) $scope.hasLoyaltyPoints = _.get($scope.$storage, 'loyaltyData.hasPoints');
	} 
	
	$scope.setLoyaltyPoints = function () {
		$scope.benefitsService.showPromotions({ mode: 'points' }).then((o) => {
			if ($scope.$storage.region == 'US') {
				$scope.ES.preCreateOrder(null, true);
			}
			//if ($scope.$storage.order.grandTotalForPay === 0) $scope.save(null, true);
		});
	}

	$scope.deleteGiftCard = function (pm) {
		$scope.PDialog.info(
			{
				"text": $translate.instant("_GIFTCARD.delete_prompt"),
				showCancelButton: true,
				confirmButtonText: $translate.instant("YES"),
				cancelButtonText: $translate.instant("NO"),
			}
		).then(() => {
			let index = $scope.$storage.giftCards.indexOf(pm);
			$scope.$storage.giftCards.splice(index, 1);
			$scope.ES.calculateBasketTotal();
		});

	}

	$scope.addGiftCard = function (pm) {
		if (!pm && !order.grandTotalForPay) {
			$scope.PDialog.info({"text": $translate.instant("_GIFTCARD.no_order_amount")});
			return;
		}

		if (!$scope.$storage.discountRoundTo) {
			$scope.$storage.discountRoundTo = (() => {
				let denomination = _.get($scope.$storage, 'rosConfig.currencySettings.minimalDiscountDenomination', 1);
				switch (denomination) {
					case 1: return 2;
					case 10: return 1;
					case 100: return 0;
					default: return 2;
				}
			})()
		}

		let maxAmount = order.grandTotal - order.giftCardsTotal;
		if (pm) {
			maxAmount += pm.amount;
		}
		maxAmount = _.floor(maxAmount, $scope.$storage.discountRoundTo);
		if (!maxAmount) {
			$scope.PDialog.info({ "text": $translate.instant("_GIFTCARD.no_order_amount") });
			return;
		}

		if ($scope.ui.splitPayment) {
			$scope.PDialog.info({
				"text": $translate.instant("_GIFTCARD.reset_split_payments_prompt"),
				showCancelButton: true,
				confirmButtonText: $translate.instant("YES"),
				cancelButtonText: $translate.instant("NO"),
			}).then(() => {
				if (_.get($scope.splitPayments, 'payments[0]')) {
					$scope.splitPayments.payments = [];
				}
				$scope.cancelSplitPayments();
				$scope.addGiftCard(pm);
			});
			return;
		}

		var modalGiftCards = $uibModal.open({
			templateUrl: 'modules/auth/benefits_giftcard_modal.html',
			backdrop: "static",
			controller: function ($scope, $uibModalInstance, BenefitsService, MetaService, EntityService, $translate, PDialog, modalParams, $filter) {
				$scope.$storage = MetaService.$storage;

				$scope.args = {
					title: '_GIFTCARD.select_card',
					actionTitle: '_LOYALTY.continue',
					mode: 'set',
					denomination: _.get($scope.$storage, 'rosConfig.currencySettings.minimalDiscountDenomination', 1),
					roundTo: $scope.$storage.discountRoundTo
				}

				if (modalParams.pm) {
					$scope.pm = _.cloneDeep(modalParams.pm);
					setAmountMode();
				} else {
					$scope.accounts = $scope.$storage.giftCardAccounts;
					$scope.selectAccount = selectAccount;

					if ($scope.accounts.length == 1) {
						selectAccount($scope.accounts[0]);
						$scope.isSingleAccount = true;
					} 
				}

				function selectAccount(account) {
					$scope.pm = { paymentMethod: "giftCard" };
					$scope.args.mode = 'select';
					_.assignIn($scope.pm, {
						$$loyaltyApiKey: account.loyaltyApiKey,
						$$accountName: account.name,
						account: account._id,
						hideCVV: $scope.$storage.region != 'US'
					});
					$scope.args.title = $scope.pm.$$accountName;//'_GIFTCARD.title';
				}
				$scope.apply = function (form) {
					if (!$scope.pm) {
						PDialog.info({ "text": $translate.instant('_GIFTCARD.please_select_card') });
						form.submitAttempt = false;
						return;
					}
					if (!$scope.challengeForm.$valid) return;
					form.submitAttempt = false;
					if ($scope.args.mode == 'select') checkGiftCard();
					else applyGiftCard();
				}

				$scope.resendOTP = function() {
					delete $scope.pm.otp;
					checkGiftCard()
				}
				function checkGiftCard() {
					
					if (_.find($scope.$storage.giftCards, { cardNum: $scope.pm.cardNum })) {
						PDialog.info({ "text": $translate.instant("_GIFTCARD.inuse_error") });
						return;
					}
					BenefitsService.checkGiftCard($scope.pm).then(res => {
						delete $scope.pm.otp;
						delete $scope.args.showOTP;

						if (!res) throw ({ code: 1 })
						$scope.pm.balance = res;
						setAmountMode(true);
					}).catch(err => {
						delete $scope.pm.otp;
						let message = '_GIFTCARD.notfound_error';
						switch (err.Key) {
							case "ExternalProviderErrror": message = err.Message; break;
							case "CannotPayOnOnline": message = '_GIFTCARD.cannotPayOnOnline'; break;
							case "incorrectCvv": message = '_GIFTCARD.invalid_cvv'; break;
							case "auth_otpSent":
								$scope.args.otpMessage = $translate.instant("_GIFTCARD.otp_required");
								let last3Digits = _.get(err, 'ResponseData.last3Digits');
								if (last3Digits && last3Digits.length) {
									$scope.args.otpMessage = $translate.instant("_GIFTCARD.otp_required_alt", {v:last3Digits});
								}								
								$scope.args.showOTP = true; return;
							case "InvalidPinCode":
								delete $scope.pm.otp;
								message = '_GIFTCARD.invalid_otp';
								break;
							case "cvvRequired":
								message = '_GIFTCARD.cvv_required';
								if ($scope.pm.hideCVV) {
									$scope.pm.hideCVV = false;
									return;									
								}
								break;
							case "balance": message = '_GIFTCARD.balance_error'; break;
						}
						PDialog.warning({ "text": $translate.instant(message, { balance: err.balance }) });
					});
				}

				function setAmountMode(isNew) {
					$scope.args.mode = 'amount';

					let order = $scope.$storage.order;
					let maxAmount = order.grandTotal - order.giftCardsTotal;
					if (modalParams.pm) {
						$scope.args.actionTitle = '_GIFTCARD.edit_card';
						maxAmount += modalParams.pm.amount;
					} else {
						$scope.args.actionTitle = '_GIFTCARD.add_card';
					}
                    $scope.args.order = order;
                    $scope.args.balanceDue = maxAmount;
					$scope.args.maxAmount = _.round(_.floor(Math.min(maxAmount, $scope.pm.balance), $scope.args.roundTo),2);
					$scope.args.minT = { min: $filter('money')(1) };
					$scope.args.maxT = { max: $filter('money')($scope.args.maxAmount) };
                    if (isNew) $scope.pm.amount = $scope.args.maxAmount;
				}

				function applyGiftCard() {
					if (!$scope.checkAmount()) return;
					if (modalParams.pm) {
						_.assignIn(modalParams.pm, $scope.pm);
					} else {
						if (!$scope.$storage.giftCards) $scope.$storage.giftCards = [];
						$scope.$storage.giftCards.push($scope.pm);
					}
					EntityService.calculateBasketTotal();
					$uibModalInstance.close(true);
				}

				$scope.cancel = function () {
					$uibModalInstance.dismiss('cancel');
				}

				$scope.checkAmount = function () {
					let val = $scope.pm.amount;
					if (!isNaN(val)) {
						let oVal = val;
						if (oVal > $scope.args.maxAmount) oVal = $scope.args.maxAmount;
						oVal = _.floor(val, $scope.args.roundTo);
						if (val != oVal) $scope.pm.amount = oVal;
						return true;
					} return false;
				}
			},
			resolve: {
				modalParams: function () {
					return {
                        pm: pm
					};
				}
			},
			windowClass: "modal-center _dark",
		});
		modalGiftCards.result.then(result => {
			//deferred.resolve();
		}).catch(err => {
			//deferred.resolve();
		});
	}

    // ------------------------------------------------------------------------------------------------->
    // split payment
    // ------------------------------------------------------------------------------------------------->

    $scope.cancelSplitPayments = function () {
        $scope.ui.splitPayment = false;
        $scope.splitPayments = null;
        delete $scope.ccinfo.amount;
    };
    $scope.startSplitPayments = function (oPayments) {
        if (!oPayments) {
            var mParams = {
                alternatePayment: $scope.ui.alternatePayment,
                ccinfo: angular.copy($scope.ccinfo),
                wallet: $scope.$storage.user && $scope.wallet,
                paymentMethods: $scope.ui.paymentMethods,
                $storage: $scope.$storage,
                disableGratuityCash: $scope.disableGratuityCash
            };
        } else {
            var mParams = {
                splitPayments: oPayments,
                $storage: $scope.$storage,
                wallet: $scope.$storage.user && $scope.wallet,
                disableGratuityCash: $scope.disableGratuityCash
            }
        }

        var modalSplit = $uibModal.open({
            templateUrl: 'modules/checkout/modals/modal_split_payments.html',
            controller: 'modal_splitpayments_controller',
            windowClass: "modal-default",
            resolve: {
                modalParams: function () {
                    return mParams;
                }
            }
        });
        modalSplit.result.then(function (result) {
            if (result) {
                $scope.ui.splitPayment = true;
                $scope.splitPayments = result;
                //$scope.save();
            }
            ;
        }, function () {

        });
    };


    $scope.deleteSplitPayment = function (pm, index) {
        $scope.PDialog.info(
            {
                "text": $translate.instant("Q_REMOVE_PAYMENT"),
                showCancelButton: true,
                confirmButtonText: $translate.instant("YES"),
                cancelButtonText: $translate.instant("NO"),
            }
        ).then(function () {
            var o = $scope.splitPayments;
            if (pm.isCash) {
                o.paymentMethods.unshift($scope.ui.cashMethod);//NEEDFIX
            }
            o.payments.splice(index, 1);
            --o.index;
            o.total -= pm.amount;
            o.remainning += pm.amount;
        });
    };
    $scope.addSplitPayment = function () {
        var o = angular.copy($scope.splitPayments);
        o.mode = "edit";
        ++o.index;
        if (true) {
            o.payments.push({ type: undefined });
        } else {
            o.payments.push({ type: undefined, amount: o.remainning });
        }
        $scope.startSplitPayments(o);
    };

    // ------------------------------------------------------------------------------------------------->
    // split payments desktop
    // ------------------------------------------------------------------------------------------------->


    $scope.addSplitPayment_desktop = function () {
        if (!$scope.ui.splitPayment) {
			var gTotal = $scope.$storage.order.grandTotalForPay;
            $scope.ui.splitPayment = true;
            $scope.splitPayments = {
                $$desktop: true,
                total: gTotal,
                gtotal: gTotal,
                payments: []
            };
            //$scope.ccinfo.amount = gTotal;
        }
        $scope.splitPayments.payments.push({ $$isAdditionalPayment: true, type: undefined });
        $scope.ui.submitAttempt = $scope.dForm.submitAttempt = false;
    };
    $scope.deleteSplitPayment_desktop = function (o, index) {
        $scope.splitPayments.payments.splice(index, 1);
        if (!$scope.splitPayments.payments[0]) {
            $scope.cancelSplitPayments();
        }
        $scope.ui.submitAttempt = $scope.dForm.submitAttempt = false;
    }
    $scope.clearCCinfoAmount = function (o) {
        delete o.amount;
        $scope.splitPaymentAmountChange(o);
    }
    $scope.splitPaymentAmountChange = function (o) {
        var total = 0;
        var v = $scope.ccinfo.amount;
        total += isNaN(v) ? 0 : v;
        _.each($scope.splitPayments.payments, function (payment) {
            var v = payment.amount;
            total += isNaN(v) ? 0 : v;
        });
        $scope.splitPayments.total = total;
    }

    // ------------------------------------------------------------------------------------------------->
    // wallet 
    // ------------------------------------------------------------------------------------------------->

    if ($scope.$storage.user) {
        var wallet = $scope.$storage.user.wallet, defPM;
        if (wallet) {
			var supportedPm = $scope.ui.paymentMethods;

			var pms = [];
			_.each(wallet.paymentMethods, pm => {
				var pmAccount = supportedPm.find((spm) => { return spm.paymentType === pm.paymentType });
				if (pmAccount) {
					pms.push(_.assignIn({}, pm, { account: pmAccount._id, $$account: pmAccount, merchantNumber: pmAccount.merchantNumber }));
				}
			});

            if (pms && pms.length) {
                defPM = _.find(pms, { _id: wallet.defaultPaymentMethod });
                if (!defPM) defPM = pms[0];
                if (defPM) {
                    $scope.wallet = {
                        pms: pms,
                        isSinglePM: pms.length == 1,
                        ccinfo: defPM
                    };
                }
            };
        };
        if (!defPM) {
            $scope.ui.updatePaymentInfo = true;
        };
    };
});

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// modal_splitpayments_controller
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('modal_splitpayments_controller', function ($scope, $uibModalInstance, EntityService, MetaService, modalParams, $timeout, $translate, $q) {
    $scope.$storage = modalParams.$storage;
    $scope.maxHeight = ($(window).height() - 147) + 'px';
    $scope.MS = MetaService;
    $scope.ES = EntityService;
    $scope.wallet = modalParams.wallet;
    $scope.disableGratuityCash = modalParams.disableGratuityCash;

    $scope.calcTotal = function () {
        var total = 0;
        _.each($scope.ui.payments, function (o) {
            if (!isNaN(o.amount)) total += Number(o.amount);
        })
        $scope.ui.total = total;
        $scope.ui.remainning = $scope.ui.gtotal - $scope.ui.total;
    }

    if (modalParams.splitPayments) {//edit mode
        $scope.ui = modalParams.splitPayments;
        $scope.ccinfo = $scope.ui.payments[$scope.ui.index - 1];
    } else {
        $scope.ui = {
            paymentMethods: angular.copy(modalParams.paymentMethods),
            cashMethod: _.find(modalParams.paymentMethods, { paymentType: 'cash' }),
            alternatePayment: modalParams.alternatePayment,
            index: 1,
            total: 0,
			gtotal: $scope.$storage.order.grandTotalForPay,
            payments: [modalParams.ccinfo]
        };



        if ($scope.wallet && $scope.wallet.ccinfo) {
            $scope.ui.isWallet = true;
        }
        $scope.ccinfo = $scope.ui.payments[0];
		if ($scope.ui.paymentMethods.length == 1) {
			setPaymentMethod($scope.ccinfo, $scope.ui.paymentMethods[0]);
        }
        $scope.calcTotal();
    }
	$scope.setPaymentMethod = setPaymentMethod;
	function setPaymentMethod(ccinfo, pm) {
        ccinfo.paymentMethod = pm.paymentType;
		ccinfo.account = pm._id;
		ccinfo.merchantNumber = pm.merchantNumber;
		ccinfo.$$account = pm;
		ccinfo.iframeUrl = pm.iframeUrl;
		ccinfo.$$isExternal = pm.isExternal;
		ccinfo.paymentVerificationStrategy = _.get(pm, 'paymentVerificationStrategy', 'none');
    };

    $scope.$watch('ccinfo', function () {
        $scope.calcTotal();
    }, true);

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
    //----------------------------------------------------------------------->

    $scope.checkPayment = function () {
        var deferred = $q.defer();
        var needCheckSignature = true;

        if ($scope.dForm.$valid) {
            if ($scope.ui.isWallet) {
                $scope.ui.cashWallet = {
                    ccinfo: angular.copy($scope.ccinfo)
                };
                $scope.ui.isWallet = false;
                if (!$scope.ui.alternatePayment) {
                    var ccInfo = angular.copy($scope.wallet.ccinfo);
                    ccInfo.amount = $scope.ccinfo.amount;
                    var ccdetails = _.get($scope, 'ccinfo.customerDetails');
                    if (ccdetails) ccInfo.customerDetails = ccdetails;

                    $scope.ui.payments = [ccInfo];
                    needCheckSignature = false;
                }
                ;
            }
            ;
            if ($scope.ccinfo.paymentMethod == "cash") {
                needCheckSignature = false;
                $scope.ui.wasCash = true;
                $scope.ccinfo.isCash = true;
                var index = _.findIndex($scope.ui.paymentMethods, { paymentType: 'cash' });
                $scope.ui.paymentMethods.splice(index, 1);
            }
            ;
            $scope.dForm.submitAttempt = false;
            if (needCheckSignature) {
                $scope.ES.checkForSignature($scope.ccinfo).then(function () {
                    deferred.resolve();
                });
            } else {
                deferred.resolve();
            }
        }
        return deferred.promise;
    };

    $scope.apply = function () {
        $scope.checkPayment().then(function () {
            $uibModalInstance.close($scope.ui);
        });
    };
    $scope.splitBack = function () {
        var index = $scope.ui.index;
        if (index == 1) {
            $scope.cancel();
            return;
        }
        --index;
        $scope.ui.index = index;

        $scope.dForm.submitAttempt = false;
        $scope.ui.payments.splice(index, 1);
        if (index == 1 && $scope.ui.cashWallet) {
            $scope.ui.isWallet = true;
            $scope.ui.payments = [$scope.ui.cashWallet.ccinfo];
        } else {
            $scope.ccinfo = $scope.ui.payments[--index];
        }
        if ($scope.ccinfo.isCash) {
            $scope.ui.paymentMethods.unshift($scope.ui.cashMethod);
            delete $scope.ccinfo.isCash;
        }
        $scope.calcTotal();
    };


    $scope.addPayment = function () {
        $scope.ES.clickProtection().then(function () {
            if ($scope.checkPayment().then(function () {
                    if (true) {//add without auto filling the amount
                        $scope.ui.payments.push({ type: undefined });
            } else {
                        $scope.ui.payments.push({ type: undefined, amount: $scope.ui.remainning });
                        $scope.ui.remainning = 0;
            }
                    $scope.ccinfo = $scope.ui.payments[$scope.ui.payments.length - 1];
                    if ($scope.ui.paymentMethods.length == 1) {
						setPaymentMethod($scope.ccinfo, $scope.ui.paymentMethods[0]);
					}
                    $scope.ui.index = $scope.ui.payments.length;
            }));
        });
    };
    $scope.clearCCinfoAmount = function (ccinfo) {
        delete ccinfo.amount;
    }
})

// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->
// modal_excheck
// ------------------------------------------------------------------------------------------------->
// ------------------------------------------------------------------------------------------------->

app.controller('modal_excheck', function ($scope, $uibModalInstance, $sce, modalParams, $timeout, $translate, EntityService, blockUI) {
    $scope.exPaySRC = $sce.trustAsResourceUrl(modalParams.location);
    $timeout(function(){
        blockUI.stop();
    },500);
    
    $scope.onFrameLoaded = function(contentLocation) {
        if (contentLocation){
            EntityService.loadFromExternalProvider(UIUtils.getURLParams(contentLocation));
            $uibModalInstance.close();
        }
    };

})



// ------------------------------------------------------------------------------------------------->
// end_controller
// ------------------------------------------------------------------------------------------------->


app.controller('end_controller', function ($scope, BenefitsService, $uibModal, $translate, $location, $q, $sessionStorage, $http, blockUI, $analytics) {
    $scope.$on('$viewContentLoaded', function () {
        $location.replace(); //clear last history route
    });
    $scope.isClosed = true;
    //$scope.$storage.startLoaded = false;
    //$scope.$storage.order.step = 'start';

    $scope.order = $scope.$storage.order;

	$scope.init = function () {
		$scope.regSucceessInfo = (_.get($scope.$storage, 'loyaltyMember.isRegister') || _.get($scope.$storage, 'loyaltyMember.isRenew')) && _.get($scope.$storage, 'loyaltyClub.regInfo.updateDetailFormFinalText')

        if (!$scope.$storage.orderClosed) {
            $scope.$state.go("app.order.menus");
            return;
		}

		if ($scope.$storage.delayedOrder.active) {
			$scope.supplyTimeParsed = $scope.$storage.delayedOrder.text;
		} else {
			var supplyTime = $scope.$storage.hanoffETA;
			if (!supplyTime) supplyTime = $scope.$storage.order.supplyTime;
			if (supplyTime) {
				$scope.supplyTimeParsed = moment(supplyTime).format($scope.MS.local.timeFormat)
			}
		}

        if (!_.get($scope, '$storage.order.haveErrors')){
            // show order end messages
			$scope.ES.popMessages("afterOrder", $scope.$storage.order.mode);
            var _canSaveCCInfo = canSaveCCInfo();
            if (!_canSaveCCInfo) delete $scope.$storage.order.$ccinfo;
            else if ($scope.$storage.user && !_.get($scope.$storage.user, "wallet.paymentMethods[0]")){
                var orders =  $scope.$storage.orders || [];
                if (orders.length > 2){
                    $scope.promptSaveCCInfo = true;
                }
            };

        };        
    };
    $scope.init();

    function canSaveCCInfo() {
    	if (_.get($scope, '$storage.rosConfig.payments.walletDisabled')) return false;
    	var ccinfo = $scope.$storage.order.$ccinfo;
    	if (!ccinfo) return false;
    	if (ccinfo._id) return false;
    	var pm = _.find($scope.$storage.order.branch.paymentMethods, { _id: ccinfo.account });
    	if (!pm || pm.isExternal || pm.paymentType == 'cash') return false;
    	return true;
    }

    $analytics.eventTrack('payment completed', {  category: 'User Flow' });


	$scope._register = function () {
		$scope.signIn({
			ccInfo: $scope.$storage.order.$ccinfo
		}).then(function () {

		});
	}; 

    $scope.toggleSaveInfo = function () {};

    $scope.addPMToWallet = function () {
        var ccinfo = $scope.$storage.order.$ccinfo;
        $scope.ES.addAccountPaymentMethod(ccinfo).then(function (ret) {
            var wallet = $scope.$storage.user.wallet;
            if (!wallet) wallet = $scope.$storage.user.wallet = {paymentMethods:[]};
            else if (!wallet.paymentMethods) wallet.paymentMethods = [];
            wallet.paymentMethods.push(ret);
            delete $scope.$storage.order.$ccinfo;
            $scope.promptSaveCCInfo = false;

            if (!wallet.defaultPaymentMethod) {
                wallet.defaultPaymentMethod = wallet.paymentMethods[0]._id;
            };

            $scope.PDialog.success({ text: $translate.instant("MESSAGES.ACCOUNT_UPDATE_SUCCESS") }).then(
                function () {}
            );

        });
    
    };

    $scope.promptResetOrder = $scope.resetOrder = function () {
        $scope.MS.resetStorage();
    };

    $scope.viewOrderItems = function () {
        var modalCheckout = $uibModal.open({
            templateUrl: 'modules/checkout/modals/modal_checkout.html',
            controller: 'modal_checkout_controller',
            windowClass: "modal-default",

            resolve: {
                modalParams: function () {
                    return {
                        isClosed: true,
                        $storage: $scope.$storage
                    };
                }
            }
        });
    };

    $scope.doReadContract = function (isPrivacy) {
        var modalContract = $uibModal.open({
            templateUrl: 'modules/app/modals/modal_contract.html',
            controller: 'modal_contract_controller',
            windowClass: "modal-default",
            resolve: {
                modalParams: {
                    $storage: $scope.$storage,
                    isPrivacy: isPrivacy
                }
            }
        });
        modalContract.result.then(function (result) {
            $scope.register.contractApprove = true;
        });
    };

    $scope._signIn = function(){
        //$scope.UIAPPArgs.menuOpen = false;
        $scope.signIn().then(function(){
            $scope.$state.reload();
        });
    };
    $scope._signOut = function(){
        //$scope.UIAPPArgs.menuOpen = false;
        $scope.signOut().then(function(){
            $scope.$state.reload();
        });
    };

});

app.controller('receipt', function ($scope, ServiceAgent, EntityService) {
    let urlParams = UIUtils.getURLParams();
    let tlogId = urlParams.tid;
    let paymentId = urlParams.pid;
    let checkId = urlParams.cid;

    let url = `online-shopper/receipts?tlogId=${tlogId}`;
    if(paymentId)
        url += `&paymentId=${paymentId}`;
    if (checkId)
        url += `&checkId=${checkId}`;

    EntityService.loadOrganization();
    ServiceAgent.get({url: "online-shopper/configuration"}).then(config => {
        ServiceAgent.get({url: url}, false, null, urlParams.site).then(documents => {
            let documentViewer = new DocumentViewer({locale: config.region === 'US' ? 'en-US' : 'he-IL'});
            documents.forEach(document => document.html = documentViewer.getDocumentHtml(prepareDocument(document, documents)));

            let creditSlip = config.region === 'US' && paymentId && generateCreditSlip(documents, paymentId);
            if (creditSlip) {
                creditSlip.html = documentViewer.getDocumentHtml(creditSlip, {excludeHeader: true});
                documents.push(creditSlip);
            }

            let refundPaymentId = getRefundPaymentId(paymentId, documents);
            let refundCreditSlip = config.region === 'US' && refundPaymentId && generateCreditSlip(documents, refundPaymentId);
            if (refundCreditSlip) {
                refundCreditSlip.html = documentViewer.getDocumentHtml(refundCreditSlip, {excludeHeader: true});
                documents.push(refundCreditSlip);
            }

            $scope.documents = documents;
        });
    });
});

function generateCreditSlip(documents, paymentId) {
    let orderBill = _.cloneDeep(documents.find(document => document.documentType === 'orderBill'));
    if (!orderBill)
        return;

    orderBill.printData.collections.PAYMENT_LIST
        = orderBill.printData.collections.PAYMENT_LIST.filter(paymentEntry => paymentEntry.P_ID === paymentId);

    return {
        documentType: 'creditSlip',
        printData: orderBill.printData
    };
}

function getRefundPaymentId(paymentId, documents) {
    let orderBill = _.cloneDeep(documents.find(document => document.documentType === 'orderBill'));
    if (!orderBill)
        return;

    let payment = orderBill.printData.collections.PAYMENT_LIST.find(payment => payment.P_ID === paymentId);
    return payment && payment.CANCELING_PAYMENT;
}

function prepareDocument(document, documents) {
    let orderBill = documents.find(document => document.documentType === 'orderBill');
    if (!orderBill)
        return document;

    document.printData.variables.ORDER_BILL_TYPE = orderBill.printData.variables.ORDER_TYPE;
    return document;
}